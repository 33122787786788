import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

type Props = {
  isOpen: boolean;
  closeModal?: () => void;
  backgroundColor?: string;
  children: React.ReactNode;
};

export default function Modal(props: Props) {
  const { isOpen, closeModal, children, backgroundColor = '#0002' } = props;

  // Support Esc key to close modal
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape' && closeModal) {
        closeModal();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleKeyDown);
    } else {
      document.removeEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, closeModal]);

  return createPortal(
    <>
      {isOpen ? (
        <>
          <Wrapper backgroundColor={backgroundColor}>
            <div
              style={{ position: 'relative' }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {closeModal && (
                <CloseButton
                  onClick={() => closeModal()}
                  aria-label="Close modal"
                >
                  <svg
                    width={12}
                    height={12}
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    focusable="false"
                  >
                    <path
                      d="M11 1 1 11M1 1l10 10"
                      stroke="#F3E9D7"
                      strokeWidth="1.667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                </CloseButton>
              )}

              {children}
            </div>
          </Wrapper>
          <Overlay></Overlay>
        </>
      ) : null}
    </>,
    document.body,
  );
}

const Wrapper = styled.div
  .withConfig({ displayName: 'ModalWrapper' })
  .attrs({ 'data-testid': 'modal-wrapper' })<{
  backgroundColor: string;
}>`
  background-color: ${(props) => props.backgroundColor};
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  inset: 0;
  z-index: 100001;
  outline: none;
`;

const Overlay = styled.div
  .withConfig({ displayName: 'ModalOverlay' })
  .attrs({ 'data-testid': 'modal-overlay' })`
  opacity: 0.25;
  position: fixed;
  inset: 0;
  z-index: 40;
  background-color: black;
`;

const CloseButton = styled.button
  .withConfig({ displayName: 'ModalCloseButton' })
  .attrs({ 'data-testid': 'modal-close-button' })`
  position: absolute;
  z-index: 10;
  padding: 12px;
  top: 6px;
  right: 6px;
  color: white;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;
