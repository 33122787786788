import { makeAutoObservable } from 'mobx';
import { RootStore } from './RootStore';
import { AIPrompt, ClipProducerPreset } from '@src/types.ts/ai_prompts';
import { TemplatedPromptsRepository } from '@src/repositories';

export class TemplatedPromptsStore {
  private rootStore: RootStore;

  readonly customClipProducerPreset: ClipProducerPreset = {
    title: 'Custom',
    category: 'clip_producer_custom',
  };

  clipProducerPresets: ClipProducerPreset[] = [];
  aiBlogTemplates: AIPrompt[] = [];
  aiEmailTemplates: AIPrompt[] = [];
  aiShareableImagesTemplates: AIPrompt[] = [];
  aiTalkingPointsTemplate: AIPrompt | null = null;

  private get repository(): TemplatedPromptsRepository {
    if (!this.rootStore.datoClientStore) {
      throw new Error('Templated prompts repository not initialized');
    }
    return this.rootStore.datoClientStore.templatedPromptsRepository;
  }

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this, {}, { autoBind: true });
  }

  private setClipProducerPresets(presets: ClipProducerPreset[]) {
    this.clipProducerPresets = presets;
  }

  private setAiBlogTemplates(templates: AIPrompt[]) {
    this.aiBlogTemplates = templates;
  }

  private setAiEmailTemplates(templates: AIPrompt[]) {
    this.aiEmailTemplates = templates;
  }

  private setAiShareableImagesTemplates(templates: AIPrompt[]) {
    this.aiShareableImagesTemplates = templates;
  }

  private setAiTalkingPointsTemplate(template: AIPrompt | null) {
    this.aiTalkingPointsTemplate = template;
  }

  async initTemplatedPrompts(storyId: string) {
    const {
      clipProducerPresets,
      aiBlogTemplates,
      aiEmailTemplates,
      aiShareableImagesTemplates,
      aiTalkingPointsTemplate,
    } = await this.repository.fetchTemplatedPrompts(storyId);

    this.setClipProducerPresets(clipProducerPresets);
    this.setAiBlogTemplates(aiBlogTemplates);
    this.setAiEmailTemplates(aiEmailTemplates);
    this.setAiShareableImagesTemplates(aiShareableImagesTemplates);
    this.setAiTalkingPointsTemplate(aiTalkingPointsTemplate);
  }
}
