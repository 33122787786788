import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import styled, { css } from 'styled-components';

import { Studio } from '../../types.ts/general';
import {
  getAiDescriptionResponse,
  requestMissingFields,
} from '../../utility/story';
import { AlbumStory, DashboardStory } from '../../types.ts/story';
import { useStore } from '@src/stores-v2/StoreContext';

import { convertTimeToMMSS } from '../../utility/timeFormat';
import SocialShareStatus from '../content-components/SocialShareStatus';
import SocialStats from '../content-components/SocialStats';

import ParagraphSkeleton from '../../svgs/ParagraphSkeleton';
import SpinningLoading from '../SpinningLoading';
import ActionDeleteStory from './ActionDeleteStory';
import ActionRenameStory from './ActionRenameStory';
import { buildTargetUrl, hasUploadFailed, isStoryLoading } from './utils';

type Props = {
  stories: DashboardStory[];
  resetStoryStates(storyId: string): void;
  onStoryDelete: (s: DashboardStory) => void;
  onStoryRename: (
    storyId: DashboardStory['id'],
    newTitle: DashboardStory['title'],
  ) => void;
  initPercentCompleted: (story: DashboardStory) => void;
};
const StoryDashboardContent = observer((props: Props) => {
  const userIdentityStore = useStore().userIdentityStore;
  const { onStoryDelete, onStoryRename } = props;

  const storyContent = props.stories?.filter(
    (s) =>
      s.byExternalUser ||
      ((s.finalVideo || s.originalVideo || isStoryLoading(s)) &&
        (userIdentityStore.isInternalUser() || !!s.otherVideos.length)),
  );

  return (
    <>
      <div>
        <RowHeader>
          <ThumbnailHeader>
            <span>Story</span>
          </ThumbnailHeader>
          <SummaryHeader>
            <span>Summary</span>
          </SummaryHeader>
          <PlatformHeader>
            <span>Platform</span>
          </PlatformHeader>
          <AnalyticsHeader>
            <span>Analytics</span>
          </AnalyticsHeader>

          <DateHeader>
            <span>Published</span>
          </DateHeader>
          <ActionsHeader>
            <span>Actions</span>
          </ActionsHeader>
        </RowHeader>
      </div>
      {storyContent.map((story) => (
        <StoryDashboardItem
          key={story.id}
          story={story}
          resetStoryStates={props.resetStoryStates}
          onStoryDelete={onStoryDelete}
          onStoryRename={onStoryRename}
          initPercentCompleted={props.initPercentCompleted}
        />
      ))}
    </>
  );
});

const StoryDashboardItem = (
  props: Omit<Props, 'stories'> & {
    story: DashboardStory;
  },
) => {
  const {
    story: _story,
    onStoryDelete,
    onStoryRename,
    initPercentCompleted,
  } = props;
  const urlSearchParams = new URLSearchParams(window.location.search);

  const [fullStory, setFullStory] = useState<DashboardStory>(_story);
  const [initialized, setInitialized] = useState(false);
  useEffect(() => {
    if (!initialized && (!_story?.originalVideo || _story.useAws)) {
      (async () => {
        try {
          const selfHostedVideo = (
            await requestMissingFields(_story.id, ['originalVideo'])
          ).originalVideo;
          const fullStory = {
            ..._story,
            originalVideo: _story.useAws
              ? selfHostedVideo!
              : _story.originalVideo,
            selfHostedVideo,
            datoHostedVideo: _story.originalVideo,
          };
          setFullStory(fullStory);
          initPercentCompleted(fullStory);
        } catch (error) {
          console.log('Failed to fetch self hosted video:', error);
        } finally {
          setInitialized(true);
        }
      })();
    }
  }, [initialized, _story, initPercentCompleted]);

  const story = _story.isNew ? _story : fullStory;

  const getStoryThumbnailUrl = (story: AlbumStory) => {
    if (story.thumbnail?.url) return story.thumbnail?.url;
    if (story?.finalVideo?.thumbnail?.url)
      return story?.finalVideo?.thumbnail?.url;
    return story?.originalVideo?.video?.thumbnailUrl;
  };

  const videoDuration = (story: AlbumStory) => {
    const finalVideoDuration =
      story?.finalVideo?.videoFilePrimary?.video?.duration;
    if (finalVideoDuration) {
      return convertTimeToMMSS(finalVideoDuration);
    }
    const originalVideoDuration = story?.originalVideo?.video?.duration;
    if (originalVideoDuration) {
      return convertTimeToMMSS(Number(originalVideoDuration));
    }
  };

  const getDescription = (story: AlbumStory) => {
    const descriptionResponse = getAiDescriptionResponse(story);
    const result = descriptionResponse?.response?.toString() || '';
    if (!result && (isStoryLoading(story) || !descriptionResponse)) {
      return <ParagraphSkeleton />;
    }
    return result;
  };

  const handleRowLinkClick = (event: React.MouseEvent, story: AlbumStory) => {
    if (isStoryLoading(story)) {
      event.preventDefault();
    } else {
      props.resetStoryStates(story.id);
    }
  };

  return (
    <>
      <HorizontalLine />
      <Row>
        <RowLink
          to={`/${Studio.content}${buildTargetUrl(
            urlSearchParams,
            story.id,
            story?.finalVideo?.videoFilePrimary?.video?.muxPlaybackId,
          )}`}
          onClick={(event) => handleRowLinkClick(event, story)}
          disabled={isStoryLoading(story)}
        >
          <ThumbnailColumn>
            <ThumbContainer>
              {isStoryLoading(story) ? (
                <ThumbLoader>
                  <SpinningLoading
                    Ico={
                      <StoryCompletionProgressText>
                        {story.completionPercent || 0}%
                      </StoryCompletionProgressText>
                    }
                    text=""
                    spinnerStyle={{
                      width: '52px',
                      height: '52px',
                    }}
                    positionTop="12px"
                  />
                </ThumbLoader>
              ) : hasUploadFailed(story) ? (
                <ThumbLoader>
                  <FailedTextWrapper>
                    <FailedText>Error Uploading</FailedText>
                  </FailedTextWrapper>
                </ThumbLoader>
              ) : (
                <Thumb src={getStoryThumbnailUrl(story)} />
              )}
            </ThumbContainer>
            <Info>
              <span className="story-title">{story.title}</span>
              <span className="teller-name">{story.storyTeller.name}</span>
              <span className="duration">{videoDuration(story)}</span>
            </Info>
          </ThumbnailColumn>

          <StoryDescription>{getDescription(story)}</StoryDescription>
          <Platform>
            <SocialShareStatus
              showOnlyShared={true}
              sharedContents={story?._allReferencingSharedContents}
            />
          </Platform>
          <Analytics>
            <SocialStats
              type="story"
              id={story?.id}
              allReferencingSharedContents={
                story?._allReferencingSharedContents
              }
              layout="dashboard"
            />
          </Analytics>

          <PublishedDate>
            {story._publishedAt
              ? new Date(story._publishedAt).toLocaleDateString()
              : '-'}
          </PublishedDate>
          <ActionsColumn>
            <ActionRenameStory story={story} onRename={onStoryRename} />
            <ActionDeleteStory story={story} onDelete={onStoryDelete} />
          </ActionsColumn>
        </RowLink>
      </Row>
    </>
  );
};

export default StoryDashboardContent;

const HorizontalLine = styled.div`
  min-width: 1190px;
  width: 100%;
  height: 1px;
  background-color: #848484;
`;

const Row = styled.div`
  display: flex;
  // border-top: 1px solid #848484;
  height: 90px;
  gap: 30px;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
`;

const RowHeader = styled(Row)`
  border-top: unset;
  height: unset;
  margin-top: 20px;
  padding: 0 5px;
  padding-bottom: 10px;
  text-align: left;
  // to make compatible with RowLink styling
  display: flex;
  justify-content: space-between;
  gap: 0;
  min-width: 1190px;
  width: 100%;
  box-sizing: border-box;
`;
const Column = styled.div`
  height: 90px;
  display: flex;
  align-items: center;
  gap: 10px;
  min-width: 183px;
  width: 183px;
`;

const ThumbnailColumn = styled(Column)`
  gap: 15px;
  min-width: 380px;
`;

const ThumbnailHeader = styled(ThumbnailColumn)`
  gap: 8px;
  font-size: 12px;
  font-weight: 600;
  color: #848484;
  align-items: center;
  width: 350px;
  height: 100%;
`;
const ColumnHeader = styled(Column)`
  text-align: left;
  min-width: 200px;
  width: 200px;
  font-size: 12px;
  font-weight: 600;
  color: #848484;
  height: 100%;
`;

const SummaryHeader = styled(ColumnHeader)`
  width: 300px;
  min-width: 300px;
  height: 100%;
`;

const Thumb = styled.img`
  width: 140px;
  height: 90px;
  border-radius: 8px;
  object-fit: cover;
  object-position: top;
`;

const ThumbLoader = styled.div`
  border-radius: 8px;
  width: 140px;
  height: 90px;
  background: #484848;
  position: relative;
  overflow: hidden;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  gap: 2px;
  .story-title {
    font-weight: 700;
    font-size: 14px;
    color: #f3e9d7;
    text-overflow: ellipsis;
  }
  .teller-name,
  .duration {
    font-weight: 500;
    font-size: 12px;
    color: #848484;
  }
`;

const StoryDescription = styled(Column)`
  font-weight: 400;
  font-size: 12px;
  width: 300px;
  min-width: 300px;

  line-height: 15px;
  color: #f3e9d7;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow-y: hidden;
  max-height: 90px;
`;

const StoryTeller = styled(Column)`
  width: 190px;
  min-width: 200px;
  font-weight: 500;
  font-size: 12px;
  color: #848484;
`;

const Platform = styled(Column)`
  width: 170px;
  min-width: 170px;
  font-weight: 500;
  font-size: 12px;
  color: #848484;
`;

const PlatformHeader = styled(Platform)`
  height: 100%;
`;

const Analytics = styled(Column)`
  min-width: 100px;
`;

const AnalyticsHeader = styled(Analytics)`
  font-size: 12px;
  color: #848484;
  height: 100%;
`;

const PublishedDate = styled(Column)`
  font-weight: 400;
  text-align: right;
  color: #f3e9d7;
  margin-left: 50px;
  justify-content: flex-end;
  width: 80px;
  min-width: 80px;
`;

const DateHeader = styled(PublishedDate)`
  font-size: 12px;
  font-weight: 600;
  color: #848484;
  height: 100%;
`;

const ActionsColumn = styled(Column)`
  justify-content: flex-end;
  width: 60px;
  min-width: 60px;
`;

const ActionsHeader = styled(ActionsColumn)`
  font-size: 12px;
  font-weight: 600;
  color: #848484;
  height: 100%;
`;

const RowLink = styled(Link)<{ disabled?: boolean }>`
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  padding: 0 5px;
  align-items: center;
  min-width: 1190px;
  width: 100%;
  height: 100px;
  cursor: ${(props) => (props.disabled ? 'auto' : 'pointer')};
  ${(props) =>
    !props.disabled &&
    css`
      &:hover {
        background-color: #48484866;
        border-radius: 8px;
        .view-clip {
          display: flex;
        }
      }
    `}
`;

const ThumbContainer = styled.div`
  height: 100%;
  position: relative;
`;

const StoryCompletionProgressText = styled.span`
  color: #17c964;
  font-weight: 700;
  font-size: 14px;
  line-height: 120%;
  text-align: center;
  white-space: nowrap;
`;

const FailedText = styled.span`
  color: rgb(196, 52, 52);
  font-weight: 700;
  font-size: 14px;
  line-height: 120%;
  text-align: center;
  white-space: nowrap;
`;

const FailedTextWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
