import styled, { css } from 'styled-components';

type PagerProps = {
  page: number; // 0-based
  totalPages?: number; // 1-based
  toPage: (page: number) => void;
};

export const Pager = (props: PagerProps) => {
  const { page, totalPages = 0, toPage } = props;
  return (
    <PagerWrapper>
      {page > 0 && (
        // prev arrow
        <PageButton
          isArrow
          onClick={() => {
            toPage(page - 1);
          }}
        >
          {'←'}
        </PageButton>
      )}

      {page === totalPages - 1 && totalPages > 3 && (
        // 3rd to last page when on last page
        <PageButton
          onClick={() => {
            toPage(page - 2);
          }}
        >
          {page - 1}
        </PageButton>
      )}

      {page - 1 >= 0 && (
        // prev page
        <PageButton
          onClick={() => {
            toPage(page - 1);
          }}
        >
          {page}
        </PageButton>
      )}

      <PageButton active>{page + 1}</PageButton>

      {page === 0 && totalPages > 3 && (
        // ellipsis & last page when on first page
        <>
          <PageButton disabled>...</PageButton>
          <PageButton
            onClick={() => {
              toPage(totalPages - 1);
            }}
          >
            {totalPages}
          </PageButton>
        </>
      )}

      {page > 0 && page < totalPages - 1 && (
        // next page
        <PageButton
          onClick={() => {
            toPage(page + 1);
          }}
        >
          {page + 2}
        </PageButton>
      )}

      {page < totalPages - 1 && (
        // next arrow
        <PageButton
          isArrow
          onClick={() => {
            toPage(page + 1);
          }}
        >
          {'→'}
        </PageButton>
      )}
    </PagerWrapper>
  );
};

const PagerWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;
  gap: 4px;
`;

const PageButton = styled.button.attrs(
  (props: { active?: boolean; disabled?: boolean; isArrow?: boolean }) => props,
)`
  background: ${(props) => (props.active ? '#17c964' : '#484848')};
  border: none;
  color: #f3e9d7;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  padding: 8px 16px;
  border-radius: 8px;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  &:hover {
    background: ${(props) => (props.disabled ? '#484848' : '#17c964')};
  }
  ${(props) =>
    props.isArrow &&
    css`
      margin: 0 8px;
    `}
`;
