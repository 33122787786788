// Code taken from here
// https://github.com/aws-samples/amazon-s3-multipart-upload-transfer-acceleration
import axios from 'axios';

// initializing axios ${API_ORIGIN}/api/
const api = axios.create();

type UploaderOptions = {
  useTransferAcceleration: boolean;
  chunkSize: number;
  threadsQuantity: number;
  file: File;
  fileName: string;
  baseURL: string;
  fileKey: string;
  onProgress?: (progress: {
    sent: number;
    total: number;
    percentage: number;
  }) => void;
  onCompleteFn?: (progress: { total: number }) => void;
  onError?: (error: any) => void;
};

type Part = {
  partNumber: number;
  signedUrl: string;
};

type UploadedPart = {
  PartNumber: number;
  ETag: string;
};

const RETRY_BACKOFF_MS_FACTOR = 400;
const MAX_RETRIES = 6;

export class Uploader {
  useTransferAcceleration: boolean;
  chunkSize: number;
  threadsQuantity: number;
  timeout: number;
  file: File;
  fileName: string;
  aborted: boolean;
  uploadedSize: number;
  progressCache: Record<string, number>;
  activeConnections: Record<string, XMLHttpRequest>;
  parts: Part[];
  uploadedParts: UploadedPart[];
  fileId: string | null;
  fileKey: string | null;
  onProgressFn?: (progress: {
    sent: number;
    total: number;
    percentage: number;
  }) => void;
  onCompleteFn?: (progress: { total: number }) => void;
  onErrorFn?: (error: any) => void;
  baseURL: string;

  constructor(options: UploaderOptions) {
    this.useTransferAcceleration = options.useTransferAcceleration;
    // this must be bigger than or equal to 5MB,
    // otherwise AWS will respond with:
    // "Your proposed upload is smaller than the minimum allowed size"
    options.chunkSize = options.chunkSize || 0;
    this.chunkSize = Math.max(1024 * 1024 * options.chunkSize, 1024 * 1024 * 5);
    // number of parallel uploads
    options.threadsQuantity = options.threadsQuantity || 0;
    this.threadsQuantity = Math.min(options.threadsQuantity || 5, 15);
    this.onProgressFn = options.onProgress;
    this.onErrorFn = options.onError;
    this.onCompleteFn = options.onCompleteFn;
    this.baseURL = options.baseURL;
    this.file = options.file;
    this.fileName = options.fileName;
    this.fileKey = options.fileKey;

    // adjust the timeout value to activate exponential backoff retry strategy
    this.timeout = 0;
    this.aborted = false;
    this.uploadedSize = 0;
    this.progressCache = {};
    this.activeConnections = {};
    this.parts = [];
    this.uploadedParts = [];
    this.fileId = null;
  }

  start() {
    this.initialize();
  }

  async initialize() {
    try {
      // initializing the multipart request
      const videoInitializationUploadInput = {
        fileName: this.fileName,
        fileKey: this.fileKey,
        contentType: this.file.type,
      };
      const initializeReponse = await api.request({
        url: '/initialize',
        method: 'POST',
        data: videoInitializationUploadInput,
        baseURL: this.baseURL,
      });

      const awsFileDataOutput = initializeReponse.data;

      this.fileId = awsFileDataOutput.fileId;
      this.fileKey = awsFileDataOutput.fileKey;

      // retrieving the pre-signed URLs
      const numberOfparts = Math.ceil(this.file.size / this.chunkSize);

      const AWSMultipartFileDataInput = {
        uploadId: this.fileId,
        fileKey: this.fileKey,
        partsNumber: numberOfparts,
        transferAcceleration: this.useTransferAcceleration,
        expiresIn: 3600,
      };

      const urlsResponse = await api.request<{
        partUrls: { signedUrl: string; partNumber: number }[];
      }>({
        url: 'pre-sign-urls',
        method: 'POST',
        data: AWSMultipartFileDataInput,
        baseURL: this.baseURL,
      });

      const newParts = urlsResponse.data.partUrls;
      this.parts.push(...newParts);

      this.sendNext();
    } catch (error) {
      await this.complete(error);
    }
  }

  sendNext(retry = 0) {
    const activeConnections = Object.keys(this.activeConnections).length;

    if (activeConnections >= this.threadsQuantity) {
      return;
    }

    if (!this.parts.length) {
      if (!activeConnections) {
        this.complete();
      }

      return;
    }

    const part = this.parts.pop();
    if (this.file && part) {
      const sentSize = (part.partNumber - 1) * this.chunkSize;
      const chunk = this.file.slice(sentSize, sentSize + this.chunkSize);

      const sendChunkStarted = () => {
        this.sendNext();
      };

      this.sendChunk(chunk, part, sendChunkStarted)
        .then(() => {
          this.sendNext();
        })
        .catch((error) => {
          if (retry <= MAX_RETRIES) {
            retry++;
            const wait = (ms: number) =>
              new Promise((res) => setTimeout(res, ms));
            //exponential backoff retry before giving up
            console.log(
              `Part#${part.partNumber} failed to upload, backing off ${
                2 ** retry * RETRY_BACKOFF_MS_FACTOR
              } before retrying...`,
            );
            wait(2 ** retry * RETRY_BACKOFF_MS_FACTOR).then(() => {
              this.parts.push(part);
              this.sendNext(retry);
            });
          } else {
            console.log(`Part#${part.partNumber} failed to upload, giving up`);
            this.complete(error);
          }
        });
    }
  }

  async complete(error?: any) {
    if (error && !this.aborted && this.onErrorFn) {
      this.onErrorFn(error);
      return;
    }

    if (error && this.onErrorFn) {
      this.onErrorFn(error);
      return;
    }

    try {
      await this.sendCompleteRequest();
    } catch (error) {
      this.onErrorFn && this.onErrorFn(error);
    }
  }

  async sendCompleteRequest() {
    if (this.fileId && this.fileKey) {
      const videoFinalizationMultiPartInput = {
        fileId: this.fileId,
        fileKey: this.fileKey,
        parts: this.uploadedParts,
      };

      await api.request({
        url: '/finalize',
        method: 'POST',
        data: videoFinalizationMultiPartInput,
        baseURL: this.baseURL,
      });

      if (this.onCompleteFn) {
        this.onCompleteFn({ total: this.file.size });
      }
    }
  }

  sendChunk(chunk: Blob, part: Part, sendChunkStarted: () => void) {
    return new Promise<void>((resolve, reject) => {
      this.upload(chunk, part, sendChunkStarted)
        .then((status) => {
          if (status !== 200) {
            reject(new Error('Failed chunk upload'));
            return;
          }

          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  handleProgress(partNumber: number, event: ProgressEvent) {
    if (this.file) {
      if (
        event.type === 'progress' ||
        event.type === 'error' ||
        event.type === 'abort'
      ) {
        this.progressCache[partNumber] = event.loaded;
      }

      if (event.type === 'uploaded') {
        this.uploadedSize += this.progressCache[partNumber] || 0;
        delete this.progressCache[partNumber];
      }

      const inProgress = Object.keys(this.progressCache)
        .map(Number)
        .reduce((memo, id) => (memo += this.progressCache[id]), 0);

      const sent = Math.min(this.uploadedSize + inProgress, this.file.size);
      const total = this.file.size;
      const percentage = Math.round((sent / total) * 100);

      this.onProgressFn &&
        this.onProgressFn({
          sent: sent,
          total: total,
          percentage: percentage,
        });
    }
  }

  upload(file: Blob, part: Part, sendChunkStarted: () => void) {
    // uploading each part with its pre-signed URL
    return new Promise((resolve, reject) => {
      const throwXHRError = (error: any, part: Part, abortFx: () => void) => {
        delete this.activeConnections[part.partNumber - 1];
        reject(error);
        window.removeEventListener('offline', abortFx);
      };
      if (this.fileId && this.fileKey) {
        if (!window.navigator.onLine) reject(new Error('System is offline'));

        const xhr = (this.activeConnections[part.partNumber - 1] =
          new XMLHttpRequest());
        xhr.timeout = this.timeout;
        sendChunkStarted();

        const progressListener = this.handleProgress.bind(
          this,
          part.partNumber - 1,
        );

        xhr.upload.addEventListener('progress', progressListener);

        xhr.addEventListener('error', progressListener);
        xhr.addEventListener('abort', progressListener);
        xhr.addEventListener('loadend', progressListener);

        xhr.open('PUT', part.signedUrl);
        const abortXHR = () => xhr.abort();
        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4 && xhr.status === 200) {
            const ETag = xhr.getResponseHeader('ETag');

            if (ETag) {
              const uploadedPart = {
                PartNumber: part.partNumber,
                ETag: ETag.replaceAll('"', ''),
              };

              this.uploadedParts.push(uploadedPart);

              resolve(xhr.status);
              delete this.activeConnections[part.partNumber - 1];
              window.removeEventListener('offline', abortXHR);
            }
          }
        };

        xhr.onerror = (error) => {
          throwXHRError(error, part, abortXHR);
        };
        xhr.ontimeout = (error) => {
          throwXHRError(error, part, abortXHR);
        };
        xhr.onabort = () => {
          throwXHRError(
            new Error('Upload canceled by user or system'),
            part,
            () => {},
          );
        };
        window.addEventListener('offline', abortXHR);
        xhr.send(file);
      }
    });
  }

  onProgress(onProgress: UploaderOptions['onProgress']) {
    this.onProgressFn = onProgress;
    return this;
  }

  onComplete(onComplete: UploaderOptions['onCompleteFn']) {
    this.onCompleteFn = onComplete;
    return this;
  }

  onError(onError: UploaderOptions['onError']) {
    this.onErrorFn = onError;
    return this;
  }

  abort() {
    Object.keys(this.activeConnections)
      .map(Number)
      .forEach((id) => {
        this.activeConnections[id].abort();
      });

    this.aborted = true;
  }
}
