import { Uploader } from './uploader';

const API_ORIGIN = process.env.REACT_APP_API_URL;
const PART_SIZE_MB = 10;
const UPLOAD_THREADS = 5;
const USE_TRANSFER_ACCELERATION = true;

export async function uploadFileToS3ExternalUploads(
  file: File,
  organization_id: string | null | undefined,
  options?: {
    onUploadProgress?: (
      progress: number,
      done: boolean,
      total?: number,
    ) => void;
  },
): Promise<void> {
  return new Promise((resolve, reject) => {
    const uploaderOptions = {
      file,
      fileName: file.name,
      fileKey: getExternalUploadsBucketPathKey(file.name, organization_id),
      baseURL: `${API_ORIGIN}/api/upload`,
      chunkSize: PART_SIZE_MB,
      threadsQuantity: UPLOAD_THREADS,
      useTransferAcceleration: USE_TRANSFER_ACCELERATION,
    };

    let percentage: number = 0;

    const uploader = new Uploader(uploaderOptions);
    uploader
      .onProgress(({ percentage: newPercentage }) => {
        if (options?.onUploadProgress && newPercentage !== percentage) {
          options.onUploadProgress(newPercentage, false);
        }
      })
      .onComplete(({ total }) => {
        if (options?.onUploadProgress) {
          options.onUploadProgress(100, true, total);
        }
        resolve();
      })
      .onError((error) => {
        console.error('Uploader error', error);
        reject(error);
      });

    uploader.start();
  });
}

export function renameFile(file: File, newBasename: string): File {
  const fileExtension = file.name.split('.').slice(-1)[0];
  const newName = `${newBasename}.${fileExtension}`;

  return new File([file], newName, {
    type: file.type,
    lastModified: file.lastModified,
  });
}

export function getExternalUploadsBucketPathKey(
  fileKey: string,
  organization_id: string | null | undefined,
): string {
  return `externalUploads/${organization_id || 'no_org'}/${fileKey}`;
}
