const ParagraphSkeleton = ({
  bgColor,
  fillColor = '#484848',
  width = 196,
}: {
  bgColor?: string;
  fillColor?: string;
  width?: number;
}) => {
  return (
    <svg
      width={width}
      height="92"
      viewBox={`0 0 ${width} 92`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {!!bgColor && <rect width={width} height="92" fill={bgColor} />}
      <mask
        id="mask0_19187_26115"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="6"
        y="11"
        width={(width * 179) / 196}
        height="69"
      >
        <rect
          x="6"
          y="11.5"
          width={(width * 168.085) / 196}
          height="8"
          rx="3"
          fill="#28293F"
        />
        <rect
          x="6"
          y="23.5"
          width={(width * 179) / 196}
          height="8"
          rx="3"
          fill="#28293F"
        />
        <rect
          x="6"
          y="35.5"
          width={(width * 158.626) / 196}
          height="8"
          rx="3"
          fill="#28293F"
        />
        <rect
          x="6"
          y="47.5"
          width={(width * 172.451) / 196}
          height="8"
          rx="3"
          fill="#28293F"
        />
        <rect
          x="6"
          y="59.5"
          width={(width * 164.447) / 196}
          height="8"
          rx="3"
          fill="#28293F"
        />
        <rect
          x="6"
          y="71.5"
          width={(width * 176.817) / 196}
          height="8"
          rx="3"
          fill="#28293F"
        />
      </mask>
      <g mask="url(#mask0_19187_26115)">
        <rect
          x="6"
          y="12"
          width={(width * 190) / 196}
          height="68"
          fill={fillColor}
        />
      </g>
    </svg>
  );
};

export default ParagraphSkeleton;
