import BrollService from '@src/components/BrollPage/BrollService';
import { createContext, ReactNode, useContext, useMemo } from 'react';
import { useVideoCreatorStore } from './VideoCreatorStoreContext';
import { useDatoClient } from './StoreContext';

const BRollStoreContext = createContext<BrollService | null>(null);

interface BRollStoreProviderProps {
  children: ReactNode;
  initialStore?: BrollService;
}

export const BRollStoreProvider = ({
  children,
  initialStore,
}: BRollStoreProviderProps) => {
  const videoCreator = useVideoCreatorStore();
  const datoClientStore = useDatoClient();

  const store = useMemo(
    () => initialStore || new BrollService(videoCreator, datoClientStore),
    [initialStore, videoCreator, datoClientStore],
  );

  return (
    <BRollStoreContext.Provider value={store}>
      {children}
    </BRollStoreContext.Provider>
  );
};

export const useBRollStore = () => {
  const context = useContext(BRollStoreContext);
  if (!context) {
    throw new Error('useBRollStore must be used within BRollStoreProvider');
  }

  return context;
};
