import { createContext, useContext, ReactNode } from 'react';

import { configure, computed } from 'mobx';
import { RootStore } from './RootStore';

// MobX configuration
// We want to enforce actions in development to catch accidental state changes
// but given how pervasive MobX is in our codebase, we can't enforce actions in production
// without a lot of refactoring.
if (process.env.NODE_ENV === 'development') {
  configure({
    enforceActions: 'observed',
  });
} else {
  configure({
    enforceActions: 'never',
  });
}

const StoreContext = createContext<RootStore | null>(null);

interface StoreProviderProps {
  children: ReactNode;
  initialStore?: RootStore;
}

export const StoreProvider = ({
  children,
  initialStore,
}: StoreProviderProps) => {
  const store = initialStore || new RootStore();

  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
};

// Base store hook
export const useStore = () => {
  const context = useContext(StoreContext);
  if (!context) {
    throw new Error('useStore must be used within StoreProvider');
  }

  // if (process.env.REACT_APP_ENVIRONMENT === 'development') {
  //   const callingModule = module.parent?.filename;
  //   if (!callingModule?.includes('StoreContext')) {
  //     console.warn(
  //       'Warning: useStore() was called directly. This may cause unnecessary re-renders. ' +
  //         'Use specific store hooks like useUserIdentity() instead. ' +
  //         'See StoreContext.tsx for more details.',
  //     );
  //   }
  // }

  return context;
};

// Store Hooks
//
// These hooks are optimized to prevent unnecessary re-renders by using granular observers.
// Each hook wraps its return value in observer(() => ...)() which tells MobX to:
// 1. Only track changes to the specific store/data being returned
// 2. Only trigger re-renders when that specific data changes
// 3. Ignore changes to unrelated parts of the store tree
//
// Example:
// - Bad:  const { userIdentityStore } = useStore();
//         // Will re-render on ANY store change
//
// - Good: const userIdentity = useUserIdentity();
//         // Only re-renders when userIdentityStore changes

export const useUserIdentity = () => {
  const store = useStore();
  return computed(() => store.userIdentityStore).get();
};

export const useAnalytics = () => {
  const store = useStore();
  return computed(() => store.analyticsStore).get();
};

export const useTemplatedPrompts = () => {
  const store = useStore();
  return computed(() => store.templatedPromptsStore).get();
};

export const useDatoClient = () => {
  const store = useStore();
  return computed(() => store.datoClientStore).get();
};

export const useTranscription = () => {
  const store = useStore();
  return computed(() => store.transcriptionStore).get();
};

export const useDashboard = () => {
  const store = useStore();
  return computed(() => store.dashboardStore).get();
};

export const useUserStoryUploadFlow = () => {
  const store = useDashboard();
  return computed(() => store.userStoryUploadFlowStore).get();
};
