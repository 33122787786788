import React, { useEffect, useState } from 'react';
import styled, { keyframes, css } from 'styled-components';
import CircleCheckIcon from '../../svgs/CircleCheckIcon';
import SendIcon from '../../svgs/SendIcon';
import CloseIcon from '../../svgs/CloseIcon';

import CircleExclammationIcon from '../../svgs/CircleExclammationIcon';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';

const ToastNotification = observer(() => {
  const videoCreator = useVideoCreatorStore();
  const {
    state = 'success',
    message,
    delay = 5000,
    position = 'bottom',
  } = videoCreator.toastState!;
  const [slidingOut, setSlidingOut] = useState<boolean>(false);

  useEffect(() => {
    if (delay < 0) return;

    let timer = setTimeout(() => {
      setSlidingOut(true);
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    if (!slidingOut) return;

    let timer = setTimeout(() => {
      runInAction(() => (videoCreator.toastState = null));
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [slidingOut]);

  const getBgColor = () => {
    if (state === 'success') {
      return '#17C964';
    } else if (state === 'publishing') {
      return '#F2D093';
    }

    return '#F178B6';
  };

  const getStatusIcon = () => {
    if (state === 'success') {
      return <CircleCheckIcon />;
    } else if (state === 'publishing') {
      return <SendIcon />;
    }

    return <CircleExclammationIcon />;
  };

  function handleClose(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.stopPropagation();
    videoCreator.toastState = null;
  }

  return (
    <Main bgColor={getBgColor()} slidingOut={slidingOut} position={position}>
      {getStatusIcon()}
      {message}
      <Close onClick={handleClose}>
        {' '}
        <CloseIcon strokeColor="currentColor" />{' '}
      </Close>
    </Main>
  );
});

export default ToastNotification;

const bottomSlideUp = keyframes`
  from {
    transform: translateY(100%) translateX(-50%);
  }
  to {
    transform: translateY(0) translateX(-50%);
  }
`;

const bottomSlideDown = keyframes`
  from {
    transform: translateY(0) translateX(-50%);
  }
  to {
    transform: translateY(100%) translateX(-50%);
  }
`;

const topSlideUp = keyframes`
  from {
    transform: translateY(0%) translateX(-50%);
  }
  to {
    transform: translateY(-100%) translateX(-50%);
  }
`;

const topSlideDown = keyframes`
  from {
    transform: translateY(-100%) translateX(-50%);
  }
  to {
    transform: translateY(0) translateX(-50%);
  }
`;

const Main = styled.div<{
  bgColor: string;
  slidingOut: boolean;
  position: 'top' | 'bottom';
}>`
  text-align: center;
  padding: 0 15px;
  height: 60px;
  border-radius: 8px;
  gap: 10px;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  ${(props) =>
    props.position === 'bottom' &&
    css`
      bottom: 20px;
      animation: ${props.slidingOut ? bottomSlideDown : bottomSlideUp} 0.5s
        ease-in-out;
    `}
  ${(props) =>
    props.position === 'top' &&
    css`
      top: 20px;
      animation: ${props.slidingOut ? topSlideUp : topSlideDown} 0.5s
        ease-in-out;
    `}
  background-color: ${(props) => props.bgColor};
  box-shadow: 8px 8px 16px 0px #00000066;
  color: #03041a;
  font-weight: 700;
  font-size: 14px;
  line-height: 16.94px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000001;
`;

const Close = styled.button`
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
`;
