import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { PhotoArtifactTab, DashboardStory } from '../../types.ts/story';
import { observer } from 'mobx-react-lite';

import ChatGPTService from '../../services/ChatGPTService';
import SearchInput from '../sidepanel/SearchInput';
import StoryDashboardContent from './StoryDashboardContent';
import ClipDashboardContent from './ClipDashboardContent';
import { checkWordsInString } from '../../utility/general';
import PrimaryActionButton from '../sidepanel/PrimaryActionButton';
import PlayIconRectOutline from '../../svgs/PlayIconRectOutline';
import NewStoriesQuerySubscriptionComponent, {
  NewStoryUpdate,
} from '../NewStoriesQuerySubscriptionComponent';
import ToastNotification from '../common/ToastNotification';
import SimpleLoadingSpinner from '../SimpleLoadingSpinner';
import { useFlagsCombination } from '../../utility/useFlagsCombination';
import {
  getAiDescriptionResponse,
  requestMissingFields,
} from '../../utility/story';
import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';
import { analytics } from '@src/utility/analytics';
import {
  useDatoClient,
  useUserIdentity,
  useDashboard,
  useUserStoryUploadFlow,
} from '@src/stores-v2/StoreContext';
import { useSearchParams } from 'react-router-dom';
import {
  calculateCompletionPercent,
  getDescription,
  hasUploadFailed,
  isStoryCompressedVideoReady,
  isStoryLoading,
  isStoryOriginalVideoReady,
  isStoryTranscriptionReady,
  isVideoUploadStuck,
} from './utils';
import { useStoryUploadAnalytics } from './user-story/useStoryUploadAnalytics';

type Props = {
  params: {
    [k: string]: string;
  };
};

enum ContentTypes {
  story = 'Stories',
  clip = 'Clips',
}

const Dashboard: FC<Props> = observer(() => {
  const videoCreator = useVideoCreatorStore();
  const userIdentity = useUserIdentity();
  const datoClientStore = useDatoClient();
  const storyTracker = useStoryUploadAnalytics({});

  const { allStories, setAllStories } = useDashboard();
  const {
    handleNewStoryProgress,
    getNewStoryCompletionPercent,
    setNewStoryCompletionPercent,
    openNewModal,
    bannerHeight,
  } = useUserStoryUploadFlow();

  const album = videoCreator.organization;
  const [urlSearchParams] = useSearchParams();
  const [selectedTab, setSelectedTab] = useState<ContentTypes>(
    ContentTypes.story,
  );
  const { storyManagerEnableAddStoriesButton } = useFlagsCombination(
    userIdentity.currentRole,
  );
  const [searchQuery, setSearchQuery] = useState<string>('');
  const gptServiceRef = useRef(
    new ChatGPTService(videoCreator, datoClientStore.aiPromptRepository),
  );
  const gptService = gptServiceRef.current;

  const searchParams = Object.fromEntries(urlSearchParams.entries());
  const { storyId: storyIdParam, showcase: showcaseParam } = searchParams;

  const shouldInitializeWithNewData = () => {
    const isStoryChanged =
      storyIdParam && videoCreator.story?.id !== storyIdParam;
    const isNoAlbum = showcaseParam && !album?.id;
    return isStoryChanged || isNoAlbum;
  };

  useEffect(() => {
    if (shouldInitializeWithNewData()) {
      videoCreator.initializeData({
        storyId: storyIdParam,
        showcaseSlug: showcaseParam,
      });
    }
  }, [storyIdParam, showcaseParam]);

  const [storiesLoading, setStoriesLoading] = useState(false);
  useEffect(() => {
    if (!album?.id) return;
    (async () => {
      try {
        setStoriesLoading(true);
        const stories = (await videoCreator.findManyStories(
          album.id,
        )) as DashboardStory[];
        setAllStories(stories);
      } finally {
        setStoriesLoading(false);
      }
    })();
  }, [album?.id]);

  function resetStoryStates(storyId: string) {
    const prevStoryId = urlSearchParams.get('storyId');
    if (prevStoryId !== storyId) {
      videoCreator.selectedPhotoAssets = {
        tab: PhotoArtifactTab.story,
        resource: undefined,
      };
      videoCreator.talkingPointContent = null;
      if (gptService.talkingPointController) {
        gptService.talkingPointController.abort();
      }
    }
  }

  const initNewStoryCompletionPercentRecordItem = useCallback(
    (story: DashboardStory) => {
      if (isStoryLoading(story)) {
        setNewStoryCompletionPercent(
          story.id,
          calculateCompletionPercent(story),
        );
      }
    },
    [],
  );

  const handleSearchQueryChange = useCallback((text: string) => {
    setSearchQuery(text);
  }, []);

  const filterStoriesBySearchQuery = useCallback(
    (text: string) => {
      if (!text.length) {
        return allStories;
      }
      const value = text.toLowerCase();
      const stories = allStories.filter((s) => {
        const summary = getDescription(s);
        return (
          checkWordsInString(s.storyTeller.name, value) ||
          checkWordsInString(s.title, value) ||
          checkWordsInString(summary, value)
        );
      });
      return stories;
    },
    [allStories],
  );

  const filterClipsBySearchQuery = useCallback(
    (text: string) => {
      if (!text.length) {
        return allStories;
      }
      const value = text.toLowerCase();

      const newStories = [];
      for (let s of allStories) {
        const isInStory =
          checkWordsInString(s.storyTeller.name, value) ||
          checkWordsInString(s.title, value);
        if (isInStory) {
          newStories.push(s);
          continue;
        }
        const clips = s.otherVideos.filter((v) => {
          return checkWordsInString(v.title, value);
        });
        newStories.push({ ...s, otherVideos: clips });
      }
      return newStories;
    },
    [allStories],
  );

  const handleNewStoryUpdateReceived = async (
    storyUpdates: NewStoryUpdate[],
  ) => {
    const newAllStories: DashboardStory[] = [];
    for (let story of allStories) {
      const storyUpdate = storyUpdates.find((u) => u.id === story.id);
      if (storyUpdate) {
        // setting correct original video in update before checking if story should be updated
        // original video isn't coming for AWS, avoid resetting it to undefined if story has it already
        // original video coming as datoHostedVideo for Dato
        storyUpdate.originalVideo = story.useAws
          ? story.originalVideo
          : storyUpdate.datoHostedVideo;
        if (
          (!isStoryOriginalVideoReady(story) &&
            isStoryOriginalVideoReady(storyUpdate)) ||
          (!isStoryCompressedVideoReady(story) &&
            isStoryCompressedVideoReady(storyUpdate))
        ) {
          const selfHostedVideo = (
            await requestMissingFields(story.id, ['originalVideo'])
          ).originalVideo;
          if (story.useAws) {
            storyUpdate.originalVideo = selfHostedVideo!;
          }
        }
        notifyAboutNewStoryUpdate(storyUpdate, story);
        newAllStories.push({ ...story, ...storyUpdate, isNew: true });
      } else {
        newAllStories.push(story);
      }
    }
    setAllStories(newAllStories);
  };

  const notifyAboutNewStoryUpdate = (
    storyUpdate: NewStoryUpdate,
    item: DashboardStory,
  ) => {
    if (hasUploadFailed(storyUpdate)) {
      handleNewStoryProgress(100, storyUpdate.id);
      // videoCreator.toastState = {
      //   state: 'error',
      //   message: 'Failed to upload new story',
      // };
      return;
    }

    if (
      isStoryOriginalVideoReady(storyUpdate) &&
      isStoryTranscriptionReady(storyUpdate) &&
      isStoryCompressedVideoReady(storyUpdate)
      // prevent if update is not new
      // !(
      //   isStoryOriginalVideoReady(item) &&
      //   isStoryTranscriptionReady(item) &&
      //   isStoryCompressedVideoReady(item)
      // )
    ) {
      handleNewStoryProgress(100, storyUpdate.id);
      // videoCreator.toastState = {
      //   state: 'success',
      //   message: 'Story successfully uploaded',
      // };
      return;
    }

    if (
      isStoryOriginalVideoReady(storyUpdate)
      // prevent if update is not new
      // !isStoryOriginalVideoReady(item)
    ) {
      handleNewStoryProgress(75, storyUpdate.id);
      return;
    }

    // if last update is over one minute ago, consider it failed
    if (!hasUploadFailed(storyUpdate) && isVideoUploadStuck(storyUpdate)) {
      handleNewStoryProgress(100, storyUpdate.id);
      // save upload failed state
      try {
        const timestamp = new Date();
        datoClientStore.storyRepository!.updateStoryStepsLog({
          id: storyUpdate.uploadLog.id,
          initial_upload: JSON.stringify({
            status: 'fail',
            updatedAt: timestamp,
          }),
          handle_failure: JSON.stringify({
            status: 'fail',
            error: 'Video upload stuck',
            updatedAt: timestamp,
          }),
        });
        console.log('Video upload stuck, marked as failed', storyUpdate.id);
      } catch (err) {
        console.error(
          'Error updating story upload log with video upload progress',
          err,
        );
      } finally {
        storyTracker.analyticsLog('error', 'Video upload stuck', {
          story: {
            id: storyUpdate.id,
          },
          showcase: {
            id: videoCreator.organization?.id,
            name: videoCreator.organization?.title,
          },
        });
      }
      videoCreator.toastState = {
        state: 'error',
        message: 'Failed to upload new story',
      };
      return;
    }

    if (
      storyUpdate.useAws &&
      storyUpdate.uploadLog?.combineResults?.status === 'fail'
      // prevent if update is not new
      // !(item.uploadLog?.combineResults?.status === 'fail')
    ) {
      handleNewStoryProgress(100, storyUpdate.id);
      videoCreator.toastState = {
        state: 'error',
        message: 'Transcription generation failed for new story',
      };
      return;
    }

    if (
      !storyUpdate.useAws &&
      storyUpdate.externalUploadStatus === 'FAILED'
      // prevent if update is not new
      // !(item.externalUploadStatus === 'FAILED')
    ) {
      handleNewStoryProgress(100, storyUpdate.id);
      videoCreator.toastState = {
        state: 'error',
        message: 'Failed to upload new story',
      };
      return;
    }

    if (storyUpdate.useAws && storyUpdate.uploadLog?.initialUpload?.progress) {
      handleNewStoryProgress(
        Math.round(storyUpdate.uploadLog?.initialUpload?.progress / 2),
        storyUpdate.id,
      );
    }
  };

  const handleAddStoriesButtonClick = () => {
    analytics.track('add_stories_button_clicked');

    openNewModal();
  };

  const newStoryIds = allStories
    .filter((s) => isStoryLoading(s) || !getAiDescriptionResponse(s))
    .map((s) => s.id);

  const getStoryCompletionPercent = useCallback(
    (s: DashboardStory): number => {
      return getNewStoryCompletionPercent(s.id);
    },
    [getNewStoryCompletionPercent],
  );

  const getStoriesWithCompletionPercent = useCallback(
    (stories: DashboardStory[]) => {
      return stories.map((s) => {
        return { ...s, completionPercent: getStoryCompletionPercent(s) };
      });
    },
    [getStoryCompletionPercent],
  );

  const handleStoryDelete = async (story: DashboardStory) => {
    setAllStories(allStories.filter((s) => s.id !== story.id));

    try {
      await videoCreator.softDeleteStory(story.id);
    } catch (e) {
      videoCreator.toastState = {
        state: 'error',
        message: 'Failed to delete story',
      };
      setAllStories(allStories);
    }
  };

  const handleStoryRename = async (
    storyId: DashboardStory['id'],
    newTitle: DashboardStory['title'],
  ) => {
    setAllStories(
      allStories.map((s) => (s.id === storyId ? { ...s, title: newTitle } : s)),
    );
    try {
      await videoCreator.updateStory({ id: storyId, title: newTitle });
    } catch (e) {
      videoCreator.toastState = {
        state: 'error',
        message: 'Failed to rename story',
      };
      setAllStories(allStories);
    }
    if (videoCreator.story?.id === storyId) {
      videoCreator.story.title = newTitle;
    }
  };

  const stories = useMemo(() => {
    if (selectedTab === ContentTypes.story) {
      return filterStoriesBySearchQuery(searchQuery);
    } else if (selectedTab === ContentTypes.clip) {
      return filterClipsBySearchQuery(searchQuery);
    }
    return allStories;
  }, [
    allStories,
    searchQuery,
    selectedTab,
    filterStoriesBySearchQuery,
    filterClipsBySearchQuery,
  ]);

  const renderContent = () => {
    switch (selectedTab) {
      case ContentTypes.story:
        return (
          <StoryDashboardContent
            stories={getStoriesWithCompletionPercent(stories)}
            resetStoryStates={resetStoryStates}
            onStoryDelete={handleStoryDelete}
            onStoryRename={handleStoryRename}
            initPercentCompleted={initNewStoryCompletionPercentRecordItem}
          />
        );

      case ContentTypes.clip:
        return (
          <ClipDashboardContent
            stories={stories}
            resetStoryStates={resetStoryStates}
          />
        );
    }
  };

  return (
    <Main bannerHeight={bannerHeight}>
      <TopContent>
        <Title>Story Manager</Title>
        <Tabs>
          {Object.entries(ContentTypes).map(([k, v]) => (
            <Tab
              onClick={() => setSelectedTab(v)}
              isSelected={selectedTab === v}
            >
              {v}
            </Tab>
          ))}
        </Tabs>
        <SearchInput
          iconRight={true}
          placeholder={
            selectedTab === ContentTypes.story
              ? 'Search your stories'
              : 'Search your clips'
          }
          radius="10px"
          width="300px"
          handleAction={(text) => handleSearchQueryChange(text)}
          autoSubmitDelay={500}
        />
        {selectedTab === ContentTypes.story &&
          storyManagerEnableAddStoriesButton && (
            <>
              <AddStoriesButton
                onClick={handleAddStoriesButtonClick}
                isActivated
              >
                <PlayIconRectOutline />
                Add Stories
              </AddStoriesButton>
            </>
          )}
      </TopContent>

      {storiesLoading ? (
        <LoadingContainer>
          <SimpleLoadingSpinner />
        </LoadingContainer>
      ) : (
        <Table>{renderContent()}</Table>
      )}

      {!!newStoryIds.length && (
        <NewStoriesQuerySubscriptionComponent
          key={newStoryIds.join('_')}
          storyIds={newStoryIds}
          onDataReceived={handleNewStoryUpdateReceived}
          onError={() => {
            videoCreator.toastState = {
              state: 'error',
              message: 'Failed to update new story',
            };
          }}
        />
      )}

      {videoCreator.toastState && <ToastNotification />}
    </Main>
  );
});

export default Dashboard;

const Main = styled.div<{ bannerHeight?: number }>`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 0 80px;
  padding-bottom: ${(props) => `${(props.bannerHeight || 0) + 20}`}px;
`;

const TopContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  min-width: 440px;
`;
const LoadingContainer = styled.div`
  padding: 40px 0;
`;
const Title = styled.h2`
  margin: 0;
  font-size: 32px;
  color: #f3e9d7;
  font-weight: 700;
`;

const Table = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

const Tabs = styled.div`
  display: flex;
  gap: 16px;
  margin-right: auto;
  margin-left: 50px;
`;

const Tab = styled.div<{ isSelected: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f3e9d7;
  cursor: pointer;
  &:hover {
    color: #f2d093;
    text-decoration: underline;
  }
  ${(props) =>
    props.isSelected &&
    css`
      color: #f2d093;
      text-decoration: underline;
    `}
  font-size: 14px;
  font-weight: 700;
  line-height: 16.94px;
  font-family: 'Inter', sans-serif;
`;

const AddStoriesButton = styled(PrimaryActionButton)`
  width: 240px;
  max-width: 240px;
  margin-left: 16px;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
`;
