import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { useUser } from '@clerk/clerk-react';

import { ContentViewData } from '../../../types.ts/story';
import ContentShare from '../../common/ContentShare';
import SpinningLoading from '../../SpinningLoading';
import { BlogOrEmailContent } from '../../../types.ts/general';
import ContentSelector from '../../common/ContentSelector';
import { useFlagsCombination } from '../../../utility/useFlagsCombination';
import { BlogProducer } from './BlogProducer';
import SavedBlogList from './SavedBlogList';
import { initializeContentStudioContent } from '../../../utility/general';
import ChatGPTService from '../../../services/ChatGPTService';
import BlogEditor from './BlogEditor';
import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';

import { analytics } from '@src/utility/analytics';
import { AIPrompt } from '@src/types.ts/ai_prompts';
import {
  useDatoClient,
  useTemplatedPrompts,
  useUserIdentity,
} from '@src/stores-v2/StoreContext';
import AIProducerModal from '../AIProducerModal';
import ContentActions from '../ContentActions';
import BrandVoiceRevisionModal from '../BrandVoiceRevisionModal';

const BlogView = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const [contentHtml, setContentHtml] = useState<string | null>(null);
  const videoCreator = useVideoCreatorStore();
  const userIdentity = useUserIdentity();
  const datoClientStore = useDatoClient();
  const gptServiceRef = useRef(
    new ChatGPTService(videoCreator, datoClientStore.aiPromptRepository),
  );
  const gptService = gptServiceRef.current;

  const { story } = videoCreator;
  const [dragPosition, setDragPosition] = useState<Record<
    string,
    Record<'xAxis' | 'yAxis', string>
  > | null>(null);
  const [imageSize, setImageSize] = useState<Record<
    string,
    Record<'width' | 'height', number>
  > | null>(null);
  const [isResizing, setIsResizing] = useState<string | null>(null);
  const [openDropdown, toggleDropdown] = useState<number | null>(null);
  const [editEnabled, setEditEnabled] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [pageHash, setPageHash] = useState<string | null>(null);

  const userInfo = useUser();
  const blogContent = videoCreator.selectedBlogContent;
  const {
    contentStudioEnableBlogSave,
    contentStudioEnableBlogShare,
    enableBlogRegeneration,
  } = useFlagsCombination(userIdentity.currentRole);

  const saved_blogs = videoCreator.story?.savedBlog;
  const blogProducer = new BlogProducer(
    videoCreator,
    datoClientStore.assetRepository,
    datoClientStore.storyRepository,
  );

  const entries = blogProducer.getEntries();
  const [currentContent, setCurrentContent] =
    useState<BlogOrEmailContent | null>(null);

  useEffect(() => {
    initializeContentStudioContent(videoCreator, story?.id, 'Blog');
  }, [story?.id]);

  const data = videoCreator.contentStudioGeneratedContent?.Blog;

  const hasBeenGenerated = data?.hasBeenGenerated;
  let generatedContent = data?.content?.response || '';

  const handleCopyRichText = () => {
    if (!contentHtml) return;
    blogProducer.copyBlogContent(contentHtml);
  };

  const handleSave = async () => {
    if (!story?.id || !contentHtml) {
      return;
    }

    analytics.track('save_blog', {
      storyId: story.id,
      storyTitle: story.title,
    });

    setIsSaving(true);
    const name = userInfo?.user?.fullName;
    const data = await blogProducer.saveBlogContent(name, contentHtml);

    if (data) {
      const { id, title, content, username } = data;
      setCurrentContent({
        id,
        title,
        content,
        username,
      });
      if (videoCreator.contentStudioGeneratedContent?.Blog) {
        videoCreator.contentStudioGeneratedContent.Blog.hasBeenGenerated =
          false;
      }
    }

    setIsSaving(false);
  };

  useEffect(() => {
    const generated_content = generatedContent as string;

    if (generatedContent && hasBeenGenerated) {
      const htmlData = blogProducer.produceHtmlFromGeneratedContent(
        generatedContent as string,
        editEnabled,
        imageSize,
        openDropdown,
        isResizing,
        dragPosition,
      );

      setContentHtml(htmlData);
      return;
    }

    if (currentContent?.id && saved_blogs) {
      const htmlData = blogProducer.produceHtmlFromSavedContent(
        currentContent.id,
        saved_blogs,
      );
      setContentHtml(htmlData || null);
    }

    if (currentContent?.id && saved_blogs) {
      const contentId = currentContent?.id;
      const data = saved_blogs?.[contentId]?.content;
      const hash = blogProducer.currentContentHash(generated_content, data);

      setPageHash(hash);
    }
  }, [currentContent, saved_blogs, generatedContent]);

  const templatedPromptsStore = useTemplatedPrompts();
  const aiBlogTemplates = templatedPromptsStore.aiBlogTemplates;
  const [aiProducerOpen, setAiProducerOpen] = useState(false);
  const [promptTemplate, setPromptTemplate] = useState<AIPrompt | undefined>();
  const [customPrompt, setCustomPrompt] = useState<string | undefined>();
  const handleGenerate = async (
    template: AIPrompt,
    brandVoice?: string,
    customInstructions?: string,
    avoidWords?: string,
  ) => {
    setImageSize(null);
    setIsLoading(true);
    analytics.track('generate_blog', {
      storyId: videoCreator.story?.id,
      storyTitle: videoCreator.story?.title,
      template,
      customInstructions,
      brandVoice,
    });
    await gptService.regenerateStreamResponse({
      key: 'Blog',
      type: 'new',
      setLoading: setIsLoading,
      template,
      customInstructions,
      brandVoice,
      avoidWords,
    });
    if (customInstructions) setCustomPrompt(customInstructions);
    else if (template) setPromptTemplate(template);
  };

  const [revisionModalOpen, setRevisionModalOpen] = useState(false);
  const handleRegenerate = async (revision: string) => {
    setImageSize(null);
    setIsLoading(true);
    setRevisionModalOpen(false);
    analytics.track('regenerate_blog', {
      storyId: story?.id,
      storyTitle: videoCreator.story?.title,
      template: promptTemplate,
      customInstructions: customPrompt,
      revision,
    });
    await gptService.regenerateStreamResponse({
      key: 'Blog',
      type: 'regenerate',
      setLoading: setIsLoading,
      template: promptTemplate,
      customInstructions: customPrompt,
      revision,
    });
  };

  return (
    <>
      {isLoading && !hasBeenGenerated && (
        <SpinningLoading
          customStyle={{
            top: 0,
            position: 'fixed',
            alignItems: 'center',
          }}
          text="Generating blog..."
        />
      )}

      <Panel>
        <ContentActions
          hasBeenGenerated={!!blogContent?.type}
          renderCreateNew={
            Boolean(entries?.length) || blogContent
              ? () => (
                  <ContentSelector
                    entries={entries}
                    currentContent={currentContent}
                    setCurrentContent={setCurrentContent}
                    type="savedBlog"
                    enableCreateAndEditing={contentStudioEnableBlogSave}
                    onCreateNew={() => setAiProducerOpen(true)}
                  />
                )
              : undefined
          }
          onGenerate={() => setAiProducerOpen(true)}
          onRegenerate={() => setRevisionModalOpen(true)}
          renderShare={
            contentStudioEnableBlogShare && blogContent?.type
              ? () => <ContentShare type="Blog" onCopy={handleCopyRichText} />
              : undefined
          }
          onSave={
            contentStudioEnableBlogSave && blogContent?.type
              ? handleSave
              : undefined
          }
          saveDisabled={isSaving}
          saveButtonLabel={isSaving ? 'Saving...' : 'Save'}
        />

        {Boolean(entries?.length) && !blogContent && (
          <SavedBlogList
            entries={entries}
            currentContent={currentContent}
            setCurrentContent={setCurrentContent}
            type="savedBlog"
          />
        )}

        {videoCreator.selectedBlogContent && contentHtml ? (
          <Main>
            <Content>
              <BlogEditor
                content={contentHtml}
                onContentUpdate={(html: string) => {
                  setContentHtml(html);
                  videoCreator.contentStudioGeneratedContent = {
                    ...videoCreator.contentStudioGeneratedContent,
                    Blog: {
                      ...data,
                      hasBeenGenerated: true,
                      content: {
                        ...(data?.content || {}),
                        response: html,
                      },
                    },
                  } as ContentViewData;
                }}
                disabled={isLoading}
              />
            </Content>
          </Main>
        ) : null}
      </Panel>

      {aiProducerOpen ? (
        <AIProducerModal
          customType="Blog"
          title="Let’s write a blog post"
          templates={aiBlogTemplates || []}
          onClose={() => setAiProducerOpen(false)}
          onConfirm={handleGenerate}
        />
      ) : null}

      {revisionModalOpen ? (
        <BrandVoiceRevisionModal
          title="Regenerate Blog"
          onClose={() => setRevisionModalOpen(false)}
          handleGenerate={handleRegenerate}
        />
      ) : null}
    </>
  );
});

export default BlogView;

const Panel = styled.div`
  .buttons {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
  }
`;

const TopActions = styled.div`
  display: flex;
  gap: 5px;
  justify-content: space-between;
`;

const Create = styled.div`
  margin-top: 80px;
`;

const Main = styled.div`
  margin: 15px auto;
  position: relative;
  border-radius: 8px;
  border: 1px solid #484848;
  max-height: 800px;
  overflow: scroll;
`;
const Content = styled.div`
  color: #030419;
  margin: 40px auto;
  padding: 10px 50px;
  border-radius: 5px;
  user-select: none;
  max-width: 800px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  h1 {
    text-align: center;
  }
  p strong {
    font-weight: 800;
  }
  & > p:has(img) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  blockquote {
    font-style: italic;
  }
  a {
    color: #030419;
  }
`;

const Save = styled.button`
  border: 0;
  outline: 0;
  background-color: #17c964;
  gap: 8px;
  width: 148px;
  height: 40px;
  border-radius: 10px;
  padding: 10px 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #03041a;
  cursor: pointer;
  transition: 0.25s;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
