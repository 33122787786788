import { AlbumStory } from '@src/types.ts/story';
import { getAiDescriptionResponse } from '@src/utility/story';
import { NewStoryUpdate } from '../NewStoriesQuerySubscriptionComponent';

export const buildTargetUrl = (
  params: URLSearchParams,
  storyId: string,
  playbackId: string | undefined,
) => {
  return `?storyId=${storyId}&env=${params.get('env')}&playbackId=${
    playbackId || ''
  }`;
};

export const hasUploadFailed = (s: NewStoryUpdate): boolean => {
  return s.useAws
    ? !!s.uploadLog?.handleFailure
    : !!s.uploadLog?.handleFailure &&
        s.uploadLog?.initialUpload?.status !== 'success';
};

export const isVideoUploadStuck = (s: NewStoryUpdate): boolean => {
  return (
    s.uploadLog?.initialUpload?.status !== 'success' &&
    Date.now() - new Date(s.uploadLog?.initialUpload?.updatedAt).getTime() >
      120000
  );
};

export const isStoryOriginalVideoReady = (s: NewStoryUpdate): boolean => {
  return !s.useAws
    ? !!s.originalVideo?.video?.thumbnailUrl
    : s.uploadLog?.ffmpegProcessing?.status === 'success';
};

export const isStoryTranscriptionReady = (s: NewStoryUpdate): boolean => {
  return s.useAws
    ? s.uploadLog?.combineResults?.status === 'success'
    : ['FAILED', 'COMPLETED'].includes(s.transcription?.jobStatus);
};

export const isStoryCompressedVideoReady = (s: NewStoryUpdate): boolean => {
  return s.useAws
    ? s.uploadLog?.extractThumbnailsFromLowRes?.status === 'success'
    : !!s.originalVideo?.video;
};

export const isStoryProcessingStarted = (s: NewStoryUpdate): boolean => {
  return s.uploadLog?.initialUpload?.status === 'success';
};

export const calculateCompletionPercent = (story: AlbumStory): number => {
  if (
    isStoryOriginalVideoReady(story) &&
    isStoryTranscriptionReady(story) &&
    isStoryCompressedVideoReady(story)
  ) {
    return 100;
  } else if (isStoryOriginalVideoReady(story)) {
    return 75;
  } else if (isStoryProcessingStarted(story)) {
    return 50;
  }
  return Math.round((story.uploadLog?.initialUpload?.progress || 0) / 2);
};

export const getDescription = (story: AlbumStory) => {
  return getAiDescriptionResponse(story)?.response?.toString() || '';
};

export const isStoryLoading = (s: AlbumStory): boolean => {
  return (
    s.externalUploadStatus !== 'FAILED' &&
    !hasUploadFailed(s) &&
    (!isStoryOriginalVideoReady(s) ||
      !isStoryTranscriptionReady(s) ||
      !isStoryCompressedVideoReady(s))
  );
};
