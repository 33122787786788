import { useEffect } from 'react';
import { NEW_STORIES_COMPLETION_QUERY } from '../gql/story-gql';
import { AlbumQueryResult } from '../types.ts/story';
import { useDatoQuerySubscription } from '@src/hooks/useDatoQuerySubscription';
export type NewStoryUpdate = Pick<
  AlbumQueryResult['stories'][0],
  | 'id'
  | 'externalUploadStatus'
  | 'aiResponse'
  | 'uploadLog'
  | 'useAws'
  | 'originalVideo'
  | 'transcription'
  | 'datoHostedVideo'
>;

export const useQueryStories = (
  ids: string[],
): {
  status: string;
  error: any;
  data: { allStories: NewStoryUpdate[] } | undefined;
} => {
  const { status, error, data } = useDatoQuerySubscription<
    { allStories: NewStoryUpdate[] },
    { ids: string[] }
  >({
    query: NEW_STORIES_COMPLETION_QUERY,
    variables: { ids },
  });

  return { status, error, data };
};

const NewStoriesQuerySubscriptionComponent = (props: {
  storyIds: string[];
  onDataReceived: (storyUpdates: NewStoryUpdate[]) => void;
  onError: (error: any) => void;
}) => {
  const { storyIds, onDataReceived, onError } = props;
  const { error, data } = useQueryStories(storyIds);

  useEffect(() => {
    if (error) {
      onError(error);
    } else if (data?.allStories?.length) {
      onDataReceived(data.allStories);
    }
  }, [data, error]);

  return <></>;
};

export default NewStoriesQuerySubscriptionComponent;
