import styled, { css } from 'styled-components';
import { ClipProducerUIProps } from '../ClipProducer/AIClipProducer';
import { ProduceIcon } from '../common/icons';
import Modal from '../common/Modal';
import SocialClipButtonList from '../common/SocialClipButtonList';
import SpinningLoading from '../SpinningLoading';
import AIClipProducerResults from '../ClipProducer/AIClipProducerResults';
import ClipDurationButtonList from '../ClipProducer/ClipDurationButtonList';

const ClipProducerSidebarUI = (props: ClipProducerUIProps) => {
  const {
    supportedPresets,
    isProducing,
    retryCount,
    selectedDuration,
    setSelectedDuration,
    selectedPresets,
    setSelectedPresets,
    onPresetClick,
    customPresetText,
    setCustomPresetText,
    selectedSocials,
    setSelectedSocials,
    producedClips,
    showModal,
    isProducingDisable,
    onProduceButtonClick,
    closeProducingModal,
  } = props;

  const onSocialSelect = (social: string) => {
    if (selectedSocials.includes(social)) {
      setSelectedSocials(selectedSocials.filter((s) => s !== social));
    } else {
      setSelectedSocials([social]);
    }
  };

  return (
    <Wrapper>
      {isProducing && (
        // <Modal isOpen={true}>
        // <LoadingModal>
        <div style={{ color: '#17c964' }}>
          <SpinningLoading
            Ico={<ProduceIcon width={'24'} height={'24'} />}
            text={`Generating clips${
              retryCount > 0 ? `. Retrying (${retryCount})` : ''
            }`}
          />
        </div>
        // </LoadingModal>
        // </Modal>
      )}

      <PresetsList>
        <Heading>Find social clips based on theme</Heading>
        {supportedPresets.map((preset) => (
          <PresetOption
            active={selectedPresets.includes(preset.title)}
            key={preset.title}
            onClick={() => onPresetClick(preset.title)}
          >
            {preset.title}
          </PresetOption>
        ))}
        <Heading as="label" htmlFor="customPresetInput">
          Customize
        </Heading>
        <PresetInput
          id="customPresetInput"
          placeholder="Type in what you'd like to see"
          value={customPresetText}
          onChange={(e) => {
            setCustomPresetText(e.target.value);
            setSelectedPresets([]);
          }}
        />
      </PresetsList>

      <div>
        <Heading> Select social platform to optimize clips</Heading>
        <SocialClipButtonList
          onSocialSelect={onSocialSelect}
          selectedSocials={selectedSocials}
        />
      </div>

      <div>
        <Heading>{'Choose your desired length in seconds'}</Heading>
        <ClipDurationButtonList
          setSelectedDuration={setSelectedDuration}
          selectedDuration={selectedDuration}
        />
      </div>

      <ProduceButton
        onClick={onProduceButtonClick}
        disabled={isProducingDisable}
      >
        <ProduceIcon /> Produce
      </ProduceButton>

      {showModal && Boolean(producedClips?.length) && (
        <Modal isOpen={true} closeModal={closeProducingModal}>
          <AIClipProducerResults
            onClose={closeProducingModal}
            fragments={producedClips!}
            isProducing={isProducing}
          />
        </Modal>
      )}
    </Wrapper>
  );
};

export default ClipProducerSidebarUI;

const Wrapper = styled.div`
  padding: 8px 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Heading = styled.div`
  width: 100%;
  color: #f3e9d7;
  font-size: 12px;
`;

const PresetInput = styled.input`
  border: 1px solid #5d5d5d;
  border-radius: 8px;
  padding: 8px;
  background: #03041a;
  color: #f3e9d7;
  width: 100%;
  font-size: 10px;
  line-height: 150%;
  outline: none;
  &::placeholder {
    color: #949494;
  }
`;

const ProduceButton = styled.div.attrs((props: { disabled: boolean }) => props)`
  width: 100%;
  display: flex;
  height: 48px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  background: #17c964;
  box-sizing: border-box;

  color: #03041a;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;

  ${(props) =>
    props.disabled &&
    `
    cursor: not-allowed;
    color: #030419;
    opacity: 0.6;
  `}
`;

const PresetsList = styled.div`
  width: 100;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const PresetOption = styled.div.attrs((props: { active: boolean }) => props)`
  padding: 8px;
  position: relative;
  border-radius: 8px;
  width: calc(50% - 4px);
  box-sizing: border-box;
  border: 1px solid #5d5d5d;
  cursor: pointer;
  color: #9b9b9b;
  font-size: 10px;
  font-weight: 500;
  line-height: 150%;
  transition: 150ms;

  ${(props) =>
    props.active &&
    css`
      color: #03041a;
      border-color: #f2d093;
      background-color: #f2d093;
    `}

  ${(props) =>
    !props.active &&
    css`
      &:hover {
        color: #f2d093;
      }
    `}

  &::after {
    content: '';
    position: absolute;
    width: 14px;
    height: 14px;
    border-radius: 14px;
    border: 1px solid #949494;
    right: 8px;
    top: 0;
    bottom: 0;
    margin: auto;
    ${(props) =>
      props.active &&
      `
      font-size: 9px;
      font-weight: 600;
      text-align: center;
      content: '✓';
      color: #4ad067;
      background: #030419;
    `}
  }
`;
