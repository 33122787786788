import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import styled, { css } from 'styled-components';
import { Tooltip } from 'react-tooltip';

import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';
import { SaveOrDiscardModal } from './SaveOrDiscardModal';
import { AspectRatio } from '../../types.ts/video';

const AspectRatiosDropdown = observer(() => {
  const videoCreator = useVideoCreatorStore();
  const selectedAspectRatio =
    videoCreator.currentVideo?.aspectRatio || AspectRatio.AR_16_9;
  const [dropdownOpen, toggleDropdown] = useState<boolean>(false);
  const [aspectRatioToProceed, setAspectRatioToProceed] =
    useState<AspectRatio | null>(null);

  const renderAspectSquare = () => {
    switch (selectedAspectRatio) {
      case AspectRatio.AR_16_9:
        return <SixteenByNine />;
      case AspectRatio.AR_1_1:
        return <OneByOne />;
      case AspectRatio.AR_9_16:
        return <NineBySixteen />;
    }
  };

  const handleChange = async (aspectRatio: AspectRatio) => {
    setAspectRatioToProceed(null);
    await videoCreator.loadVideoWithAspectRatio(aspectRatio);
    if (aspectRatio === AspectRatio.AR_1_1) {
      await videoCreator.removeBlackFrames();
    }
  };

  return (
    <Main disabled={!videoCreator.isVideoCreatorReady}>
      <AspectItem
        isDropdownOpen={dropdownOpen}
        onClick={() =>
          videoCreator.isVideoCreatorReady && toggleDropdown(!dropdownOpen)
        }
      >
        {renderAspectSquare()}
        <SelectedAspectRatio>{selectedAspectRatio}</SelectedAspectRatio>
        <DropdownIcon isOpen={dropdownOpen}>
          <ArrowDownIcon />
        </DropdownIcon>
      </AspectItem>
      {dropdownOpen && (
        <>
          <DropdownBackground onClick={() => toggleDropdown(false)} />
          <AspectDropDown>
            {Object.entries(ASPECT_RATIO_ICONS).map(
              ([aspectRatio, aspectRatioComponent]) => {
                const AspectRatioIcon = aspectRatioComponent.Icon;
                const isSelected = selectedAspectRatio === aspectRatio;
                const tooltipId = `aspect-ratio-${aspectRatio}-tooltip`;
                return (
                  <AspectRow
                    key={aspectRatio}
                    isSelected={isSelected}
                    onClick={async () => {
                      toggleDropdown(false);
                      if (
                        !videoCreator.currentVideo?.id &&
                        videoCreator.currentVideo?.aspectRatio !==
                          AspectRatio.AR_16_9
                      ) {
                        setAspectRatioToProceed(aspectRatio as AspectRatio);
                        return;
                      } else {
                        await videoCreator.saveStoryAndVideo();
                      }
                      handleChange(aspectRatio as AspectRatio);
                    }}
                    data-tooltip-id={tooltipId}
                    data-tooltip-content={`Ideal for ${SOCIALS_BY_ASPECT_RATIO[
                      aspectRatio as AspectRatio
                    ].join(', ')}`}
                  >
                    <AspectRatioLogo>
                      <AspectRatioIcon isSelected={isSelected} />
                    </AspectRatioLogo>
                    {`${aspectRatio} (${aspectRatioComponent.label})`}
                    <Tooltip
                      id={tooltipId}
                      style={TOOLTIP_STYLE}
                      place="right"
                      positionStrategy="fixed"
                      noArrow
                    />
                  </AspectRow>
                );
              },
            )}
          </AspectDropDown>
        </>
      )}
      {aspectRatioToProceed && (
        <SaveOrDiscardModal
          closeModal={() => setAspectRatioToProceed(null)}
          action={() => handleChange(aspectRatioToProceed)}
        />
      )}
    </Main>
  );
});

export default AspectRatiosDropdown;

const Main = styled.div<{ disabled: boolean }>`
  position: relative;
  z-index: 1;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  cursor: ${(props) => (props.disabled ? 'wait' : 'pointer')};
`;

const DropdownBackground = styled.div`
  box-sizing: border-box;
  position: fixed;
  inset: 0;
`;

const ArrowDownIcon = () => (
  <svg width="10" height="6" viewBox="0 0 10 6" fill="none">
    <path d="M9 5.5L5 1.5L1 5.5" stroke="currentColor" strokeWidth="2" />
  </svg>
);

const OneByOne = styled.div<{ isSelected?: boolean }>`
  border: 1px solid ${({ isSelected }) => (isSelected ? '#45D483' : '#f3e9d7')};
  width: 8px;
  height: 8px;
  border-radius: 1px;
`;

const NineBySixteen = styled.div<{ isSelected?: boolean }>`
  border: 1px solid ${({ isSelected }) => (isSelected ? '#45D483' : '#f3e9d7')};
  width: 6px;
  height: 10px;
  border-radius: 1px;
`;

const SixteenByNine = styled(NineBySixteen)`
  transform: rotate(90deg);
`;

const DropdownIcon = styled.div<{ isOpen: boolean }>`
  width: 10px;
  height: 6px;
  margin-left: auto;
  display: flex;
  color: #f2d093;

  ${(props) =>
    !props.isOpen &&
    css`
      rotate: 180deg;
    `}
`;

const SelectedAspectRatio = styled.div`
  color: #f3e9d7;
  font-size: 10px;
  font-weight: 500;
`;

const AspectRow = styled.div<{ isSelected: boolean }>`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  font-size: 10px;
  color: ${({ isSelected }) => (isSelected ? '#45D483' : '#f3e9d7')};
  padding: 8px;
  gap: 8px;
  &:not(:first-child) {
    border-top: 1px solid #484848;
  }
  &:hover {
    background-color: #484848;
  }
`;

const AspectRatioLogo = styled.div`
  width: 10px;
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AspectDropDown = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 1000;
  top: 23px;
  left: 0;
  width: 130px;
  border-radius: 0 0 4px 4px;
  border: 1px solid #484848;
  background: #03041a;
  box-shadow: 8px 16px 8px 0px rgba(0, 0, 0, 0.4);
`;

const AspectItem = styled.div<{ isDropdownOpen: boolean }>`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  width: 130px;
  height: 24px;
  padding: 4px 8px;
  border-radius: ${({ isDropdownOpen }) =>
    isDropdownOpen ? '4px 4px 0 0' : '4px'};
  border: 1px solid #484848;
`;

const SOCIALS_BY_ASPECT_RATIO = {
  [AspectRatio.AR_16_9]: ['YouTube', 'Facebook (Feed)'],
  [AspectRatio.AR_9_16]: [
    'Instagram & Facebook (Reels)',
    'LinkedIn',
    'TikTok',
    'YouTube Shorts',
  ],
  [AspectRatio.AR_1_1]: ['Twitter (X)'],
};

const ASPECT_RATIO_ICONS = {
  [AspectRatio.AR_16_9]: {
    label: 'Wide',
    Icon: (props: { isSelected: boolean }) => <SixteenByNine {...props} />,
  },
  [AspectRatio.AR_9_16]: {
    label: 'Vertical',
    Icon: (props: { isSelected: boolean }) => <NineBySixteen {...props} />,
  },
  [AspectRatio.AR_1_1]: {
    label: 'Square',
    Icon: (props: { isSelected: boolean }) => <OneByOne {...props} />,
  },
};

const TOOLTIP_STYLE = {
  padding: '8px',
  fontSize: '10px',
  lineHeight: '120%',
  color: '#F3E9D7',
  background: '#484848',
};
