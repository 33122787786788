import { makeAutoObservable } from 'mobx';
import { RootStore } from './RootStore';
import {
  TranscriptData,
  TranscriptElement,
  TranscriptChange,
} from '../types.ts/transcript';
import { TranscriptRepository } from '../repositories/TranscriptRepository';
import { TranscriptionStatus } from '../types.ts/video';
import { requestMissingFields } from '@src/utility/story';

export class TranscriptionStore {
  // Core state
  transcriptionElements: TranscriptElement[] = [];
  transcriptionChanges: TranscriptChange[] = [];
  originalTranscription: TranscriptData;
  transcriptionLoadingStatus: TranscriptionStatus = TranscriptionStatus.Idle;
  transcriptionUrl: string;
  language: string = 'en';

  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    // These should be null by default but legacy typescript nonsense.
    this.originalTranscription = this.defaultTranscriptData();
    this.transcriptionUrl = '';
    makeAutoObservable(this);
  }

  // Repository accessor. This is a private method that should only be used internally
  // but due to intialization order, while it should always be available, it may not be
  // so we need to handle that case.
  private get repository(): TranscriptRepository {
    if (!this.rootStore.datoClientStore.transcriptRepository) {
      throw new Error('Transcript repository not initialized');
    }
    return this.rootStore.datoClientStore.transcriptRepository;
  }

  /**
   * Loads transcription data for a video
   * @param storyId The story ID to load transcription for
   */
  async loadTranscription(storyId: string, useAws: boolean) {
    this.setTranscriptionLoadingStatus(TranscriptionStatus.Loading);

    try {
      let transcriptionInfo;
      if (useAws) {
        const { transcription: selfHostedTranscription } =
          await requestMissingFields(storyId, ['transcription']);
        transcriptionInfo = selfHostedTranscription;
      } else {
        transcriptionInfo =
          await this.repository.getTranscriptionFromDato(storyId);
      }
      console.log('transcriptionInfo:', transcriptionInfo, useAws);
      if (
        !transcriptionInfo ||
        !transcriptionInfo.elementsJson ||
        !transcriptionInfo.elementsJson.url
      ) {
        this.setTranscriptionLoadingStatus(TranscriptionStatus.Failed);
        return;
      }

      const transcriptUrl = transcriptionInfo.elementsJson.url;
      this.setTranscriptionUrl(transcriptUrl);

      const transcriptData =
        await this.repository.fetchTranscriptFromUrl(transcriptUrl);
      this.setOriginalTranscription(transcriptData);
      this.setTranscriptionElements(transcriptData.elements);
      this.setLanguage(transcriptData.language || 'en');

      this.setTranscriptionLoadingStatus(TranscriptionStatus.Loaded);
    } catch (error) {
      console.error('Error loading transcription:', error);
      this.setTranscriptionLoadingStatus(TranscriptionStatus.Failed);
    }
  }

  /**
   * Get final transcription elements with all changes applied
   */
  getFinalTranscriptionElements() {
    return this.transcriptionElements;
  }

  /**
   * Get original transcription data before any changes
   */
  getOriginalTranscription() {
    return this.originalTranscription;
  }

  /**
   * Get the full text of the transcription
   */
  get fullTranscriptionText() {
    return this.transcriptionElements
      .filter((el) => el.value && el.state !== 'removed' && el.state !== 'cut')
      .map((el) => el.value)
      .join('');
  }

  /**
   * Get all transcription changes
   */
  getTranscriptionChanges() {
    return [...this.transcriptionChanges];
  }

  /**
   * Reset the transcription store state
   */
  reset() {
    this.transcriptionElements = [];
    this.transcriptionChanges = [];
    this.originalTranscription = this.defaultTranscriptData();
    this.transcriptionLoadingStatus = TranscriptionStatus.Idle;
    this.transcriptionUrl = '';
    this.language = 'en';
  }

  // Private setters
  private setTranscriptionElements(elements: TranscriptElement[]) {
    this.transcriptionElements = elements;
  }

  private setOriginalTranscription(transcriptData: TranscriptData) {
    this.originalTranscription = transcriptData;
  }

  private setTranscriptionLoadingStatus(status: TranscriptionStatus) {
    this.transcriptionLoadingStatus = status;
  }

  private setTranscriptionUrl(url: string) {
    this.transcriptionUrl = url;
  }

  private setLanguage(language: string) {
    this.language = language;
  }

  private setTranscriptionChanges(changes: TranscriptChange[]) {
    this.transcriptionChanges = changes;
  }

  /**
   * Update transcription elements from the VideoTranscriptionProcessor
   * This method enables two-way sync during the transition period
   * @param elements Updated transcription elements
   */
  updateFromProcessor(elements: TranscriptElement[]) {
    this.setTranscriptionElements(elements);
  }

  /**
   * Set transcription changes from the VideoTranscriptionProcessor
   * @param changes Updated transcription changes
   */
  setChangesFromProcessor(changes: TranscriptChange[]) {
    this.setTranscriptionChanges(changes);
  }

  async regenerateTranscription() {
    // TODO FIX, re-work
    throw new Error('Not implemented');
    // try {
    //   await this.saveCurrentStory();
    //   await this.datoClientStore.storyRepository!.deleteTranscription(
    //     this.story!.id,
    //   );
    //   this.story!.transcription = undefined;
    //   this.finalTranscriptionElements = undefined;
    //   this.originalVideoAudioUrl = '';
    //   this.originalWaveForm = null;
    //   this.transcriptionLoadingStatus = TranscriptionStatus.Loaded;
    //   this.pollTranscriptionUrl();
    // } catch (err) {
    //   this.transcriptionLoadingStatus = TranscriptionStatus.Failed;
    // }
  }

  private defaultTranscriptData(): TranscriptData {
    return {
      elements: [],
      fullText: '',
      language: 'en',
    };
  }
}
