type Props = {
  width?: string;
  height?: string;
  viewBox?: string;
  strokeColor?: string;
};

const ZoomMinusIcon = (props: Props) => {
  const {
    width = '14',
    height = '14',
    viewBox = '0 0 14 14',
    strokeColor = '#777777',
  } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
    >
      <path
        d="M12.5 13.5L8.89788 10.1073C8.46594 10.4528 7.96922 10.7264 7.4077 10.928C6.84618 11.1295 6.24867 11.2303 5.61517 11.2303C4.0458 11.2303 2.71774 10.6867 1.63099 9.59934C0.54424 8.51202 0.000576372 7.18396 4.57075e-07 5.61517C-0.000575457 4.04638 0.543088 2.71832 1.63099 1.63099C2.71889 0.543663 4.04695 0 5.61517 0C7.18338 0 8.51173 0.543663 9.60021 1.63099C10.6887 2.71832 11.2321 4.04638 11.2303 5.61517C11.2303 6.24867 11.1295 6.84618 10.928 7.4077C10.7264 7.96922 10.4528 8.46594 10.1073 8.89788L13.7094 12.2906L12.5 13.5ZM5.61517 9.50259C6.69501 9.50259 7.61301 9.12479 8.36919 8.36919C9.12537 7.61359 9.50317 6.69558 9.50259 5.61517C9.50201 4.53475 9.12421 3.61703 8.36919 2.86201C7.61417 2.10698 6.69616 1.7289 5.61517 1.72774C4.53417 1.72659 3.61646 2.10468 2.86201 2.86201C2.10756 3.61933 1.72947 4.53705 1.72774 5.61517C1.72602 6.69328 2.1041 7.61129 2.86201 8.36919C3.61991 9.12709 4.53763 9.50489 5.61517 9.50259ZM3.45549 6.47904V4.75129H7.77485V6.47904H3.45549Z"
        fill={strokeColor}
      />
    </svg>
  );
};

export default ZoomMinusIcon;
