import { FC, useEffect, useState } from 'react';
import { AiGeneratedContent, Story, Video } from '../types.ts/story';
import { observer } from 'mobx-react-lite';
import styled, { css, CSSProperties } from 'styled-components';
import ContentView from './content-components/ContentView';
import MuxPlayer from '@mux/mux-player-react';
import CopyToClipboard from './common/CopyToClipboard';

import { useFlags } from 'launchdarkly-react-client-sdk';
import SocialShareStatus from './content-components/SocialShareStatus';
import SocialStats from './content-components/SocialStats';
import { getPosterUrl } from '../utility/general';
import { useSearchParams } from 'react-router-dom';
import { useFlagsCombination } from '../utility/useFlagsCombination';
import ToastNotification from './common/ToastNotification';
import QuerySubscriptionComponent from './content-components/QuerySubscriptionComponent';
import { useClickAway } from '../utility/useClickAway';
import SavedTag from './common/SavedTag';
import useRequestState from '../utility/useRequestState';
import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';
import {
  useStore,
  useTemplatedPrompts,
  useUserIdentity,
} from '@src/stores-v2/StoreContext';

type Props = {
  params: {
    [k: string]: string;
  };
};

const playerStyle = {
  height: '373px',
  width: '100%',
  minWidth: '500px',
  maxWidth: '683px',
  borderRadius: 10,
  overflow: 'hidden',
  border: '1px solid #484848',
  aspectRatio: 4 / 3,
  '--pip-button': 'none',
  '--center-controls': 'none',
} as CSSProperties;

const ContentStudio: FC<Props> = observer((props) => {
  const videoCreator = useVideoCreatorStore();
  const userIdentity = useUserIdentity();
  const { datoClientStore } = useStore();
  const templatedPromptsStore = useTemplatedPrompts();

  const story = videoCreator?.story;
  const { enableAiImageGeneration } = useFlags();
  const { enableSharing } = useFlagsCombination(userIdentity.currentRole);
  const [urlSearchParams, setUrlSearchParams] = useSearchParams();
  const { storyId, playbackId } =
    Object.fromEntries(urlSearchParams.entries()) || {};

  useEffect(() => {
    if (storyId && story?.id !== storyId) {
      videoCreator.initializeData({
        storyId: storyId,
      });
    }
    if (storyId) {
      templatedPromptsStore.initTemplatedPrompts(storyId);
    }
  }, [storyId]);

  useEffect(() => {
    videoCreator.setAiImageFeatureFlag(enableAiImageGeneration);
  }, [storyId, enableAiImageGeneration]);

  const savedDescriptionContent = getDescriptionContentFromStory(story);
  const [description, setDescription] = useState(
    savedDescriptionContent?.response || '',
  );
  useEffect(() => {
    if (savedDescriptionContent?.response || storyId == story?.id)
      setDescription(savedDescriptionContent?.response || '');
  }, [Boolean(savedDescriptionContent?.response), storyId == story?.id]);
  const { isSaving, isError, isSaved, request } = useRequestState();
  const [isEditing, setIsEditing] = useState(false);
  const handleSave = async () => {
    if (story && description.trim() !== savedDescriptionContent?.response) {
      await request(async () => {
        if (!story.aiResponse) story.aiResponse = { responses: [] };
        const descriptionContent = getDescriptionContentFromStory(story);
        const updatedDescriptionContent: AiGeneratedContent<'Description'> = {
          ...descriptionContent,
          title: 'Description',
          response: description || '',
        };
        const descriptionContentIndex = story.aiResponse.responses.findIndex(
          (i) => i.title.toLowerCase() === 'description',
        );
        if (
          descriptionContentIndex !== undefined &&
          descriptionContentIndex !== -1
        ) {
          story.aiResponse.responses.splice(
            descriptionContentIndex,
            1,
            updatedDescriptionContent,
          );
        } else {
          story.aiResponse.responses.push(updatedDescriptionContent);
        }
        await datoClientStore.storyRepository!.update(story);
      });
    }
    setIsEditing(false);
  };
  const inputRef = useClickAway(handleSave);
  useEffect(() => {
    if (isEditing) {
      inputRef.current?.focus();
    }
  }, [isEditing]);

  const finalVideo = story?.finalVideo as Video | undefined;
  const originalVideo = story?.originalVideo;

  const datoThumnbailUrl = finalVideo?.thumbnail?.url || story?.thumbnail?.url;
  const posterUrl = datoThumnbailUrl
    ? getPosterUrl(datoThumnbailUrl, 780, 440)
    : originalVideo?.video?.thumbnailUrl;

  let finalPlaybackId =
    playbackId ||
    finalVideo?.videoFilePrimary?.video?.muxPlaybackId ||
    originalVideo?.video?.muxPlaybackId;

  async function refreshStory() {
    // update story in store, so that the new profile is added to the list
    if (storyId) {
      const story = await videoCreator.findOneStory(storyId);

      if (story) {
        videoCreator.story = story;
      }
    }
  }

  return (
    <Main>
      <TopContent>
        <Title>
          <h2>{story?.title || ''}</h2>
          <h5>{story?.storyTeller?.name || ''}</h5>
        </Title>
        <StoryContent>
          <MuxPlayer
            style={playerStyle}
            streamType="on-demand"
            playbackId={finalPlaybackId}
            src={finalPlaybackId ? undefined : originalVideo?.video.mp4Url}
            poster={posterUrl}
          />

          <DescriptionAndAnalytis>
            {enableSharing && (
              <Analytics>
                <AnalyticsTop>
                  <span>Aggregate Analytics</span>
                  <SocialShareStatus
                    padding="0"
                    sharedContents={story?._allReferencingSharedContents}
                  />
                </AnalyticsTop>

                <SocialStats
                  type="story"
                  id={story?.id}
                  allReferencingSharedContents={
                    story?._allReferencingSharedContents
                  }
                  layout="studio-main"
                />
              </Analytics>
            )}
            {/* {enableSharing && (<SocialShareStatus sharedContents={story?._allReferencingSharedContents}></SocialShareStatus>)} */}
            {/* {enableSharing && (<SocialStats
              type="story"
              id={story?.id}
              allReferencingSharedContents={story?._allReferencingSharedContents}
            ></SocialStats>)} */}

            <Description isSaving={isSaving}>
              <span className="description-title">
                <h3>Description</h3>
                <SavedTag isSaved={isSaved} isError={isError} />
                <CopyToClipboard text={description || ''} />
              </span>
              {isEditing ? (
                <div className="description-edit-content">
                  <textarea
                    ref={inputRef}
                    value={description}
                    disabled={isSaving}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
              ) : (
                <p
                  className="description-content"
                  onClick={() => setIsEditing(true)}
                >
                  {description ? (
                    description
                  ) : (
                    <span>Click to add description...</span>
                  )}
                </p>
              )}
            </Description>
          </DescriptionAndAnalytis>
        </StoryContent>
      </TopContent>
      <ContentView />
      {Boolean(videoCreator.pendingSharedContentIds?.length) && (
        <QuerySubscriptionComponent
          sharedContentIds={videoCreator.pendingSharedContentIds}
          onDataReceived={async (sharedContent) => {
            videoCreator.toastState = {
              state: 'success',
              message: 'Story successfully published',
            };
            videoCreator.pendingSharedContentIds =
              videoCreator.pendingSharedContentIds.filter(
                (id) =>
                  !sharedContent.find((content: any) => content.id === id),
              );
            refreshStory();
          }}
          onError={() => {
            console.error('QuerySubscriptionComponent error');
          }}
        />
      )}
      {videoCreator.toastState && <ToastNotification />}
    </Main>
  );
});

const getDescriptionContentFromStory = (story?: Story) => {
  const aiContent = story?.aiResponse?.responses;
  const descriptionContent = aiContent?.find(
    (c) => c.title.toLowerCase() === 'description',
  ) as AiGeneratedContent<'Description'> | undefined;
  return descriptionContent;
};

export default ContentStudio;

const Main = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 0 100px;
  padding-bottom: 20px;
  @media only screen and (max-width: 1020px) {
    padding: 0 60px;
  }
`;

const TopContent = styled.div`
  margin-top: 30px;
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
  h2,
  h5 {
    margin: 0;
  }
  h2 {
    font-size: 32px;
    font-weight: 700;
    color: #f3e9d7;
    line-height: 1.3;
  }
  h5 {
    font-size: 12px;
    font-weight: 500;
    color: #a9a9a9;
  }
`;

const Analytics = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const AnalyticsTop = styled.div`
  display: flex;
  justify-content: space-between;

  span {
    font-weight: 700;
    font-size: 20px;
    line-height: 24.2px;
    color: #f3e9d7;
  }
`;

const StoryContent = styled.div`
  display: flex;
  gap: 50px;
  margin-top: 20px;
  justify-content: space-between;
  max-height: 373px;

  @media only screen and (max-width: 1200px) {
    flex-direction: column;
    max-height: 746px;
  }
`;

const DescriptionAndAnalytis = styled.div`
  flex-basis: 568px;
  display: flex;
  flex-direction: column;
  color: #f3e9d7;
  height: 373px;

  @media only screen and (max-width: 1200px) {
    margin: 0 auto;
  }

  &,
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
`;

const Description = styled.div<{ isSaving: boolean }>`
  .description-title {
    display: flex;
    align-items: center;
    height: fix-content;
    border-top: 1px solid #484848;
    margin-top: 10px;
    padding-top: 15px;
    gap: 16px;
    button {
      margin-left: auto;
      color: transparent;
      background-color: transparent;
      cursor: pointer;
      border: 0;
      outline: 0;
      &:active {
        svg {
          fill: white;
        }
      }
    }
  }

  h3 {
    margin-bottom: 5px;
    margin-top: 0;
    font-size: 20px;
    font-weight: 700;
  }

  .description-edit-content {
    padding-top: 8px;
    > textarea {
      display: block;
      width: 100%;
      color: #f3e9d7;
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.5;
      background: transparent;
      outline: none;
      border: 1px solid #484848;
      border-radius: 8px;
      resize: none;
      padding: 12px;
      min-height: 156px;
      max-height: 156px;
      opacity: 1;
      transition: 0.2s;
      &::placeholder {
        color: #848484;
      }
      ${({ isSaving }) =>
        isSaving &&
        css`
          cursor: wait;
          opacity: 0.6;
        `}
    }
  }

  .description-content {
    font-size: 14px;
    font-weight: 400;
    line-height: 19.6px;
    color: #bdbdbd;
    max-height: 164px;
    overflow-y: auto;
    padding-top: 8px;
    margin: 0;

    > span {
      color: #848484;
      font-style: italic;
    }
  }
`;
