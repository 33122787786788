import { Role } from 'datocms-plugin-sdk';
import { useFlags } from 'launchdarkly-react-client-sdk';

export function useFlagsCombination(currentRole: Role) {
  const isDatoUser =
    currentRole?.attributes && currentRole.attributes.name !== 'Unknown';

  const {
    contentStudioEnableSharing,
    contentStudioEnableClipProduceBtn,
    contentStudioEnableClipPublishBtn,
    contentStudioEnableEmailRegenerationBtn,
    contentStudioEnableShareableImageRegeneration,
    contentStudioEnableBlogRegeneration,
    contentStudioEnableTalkingPointsRegenerationBtn,
    contentStudioEnableEmailShareBtn,
    contentStudioEnableBlogShareBtn,
    contentStudioEnableShareableImageShareBtn,
    contentStudioEnableClipShareBtn,
    contentStudioEnableBlogSaveBtn,
    contentStudioEnableEmailSaveBtn,
    contentStudioEnableShareableImageSaveBtn,
    contentStudioEnableTalkingPointsSaveBtn,
    contentStudioEnableClipEdit,
    isEnterprisePlatform,
    enableCreatorStudio,
    hideStoryDashboardNav,
    enableImportXml,
    enableAiStoryProducer,
    enableFixTranscriptButton,
    storyUploadUseAws,
    storyManagerEnableAddStories,
    renderUsingChunks,
    enableReframingModeIn169,
    enableLargeFileSizeUpload,
  } = useFlags();

  const enableB2B = isEnterprisePlatform || isDatoUser;

  function resolveB2BFlag(flag: 'on' | 'off' | 'unset') {
    if (isDatoUser) return true;
    if (flag === 'off') return false;
    return flag === 'on' || enableB2B;
  }

  function resolveAdminFlag(...flags: Array<'on' | 'off' | 'unset'>) {
    return (
      isDatoUser ||
      (flags.some((flag) => flag === 'on') &&
        !flags.some((flag) => flag === 'off'))
    );
  }

  return {
    enableSharing: resolveB2BFlag(contentStudioEnableSharing),
    contentStudioEnableClipProducer: resolveB2BFlag(
      contentStudioEnableClipProduceBtn,
    ),
    contentStudioEnableClipPublish: resolveB2BFlag(
      contentStudioEnableClipPublishBtn,
    ),
    contentStudioEnableEmailShare: resolveB2BFlag(
      contentStudioEnableEmailShareBtn,
    ),
    contentStudioEnableBlogShare: resolveB2BFlag(
      contentStudioEnableBlogShareBtn,
    ),
    contentStudioEnableImageShare: resolveB2BFlag(
      contentStudioEnableShareableImageShareBtn,
    ),
    contentStudioEnableClipShare: resolveB2BFlag(
      contentStudioEnableClipShareBtn,
    ),
    contentStudioEnableBlogSave: resolveB2BFlag(contentStudioEnableBlogSaveBtn),
    contentStudioEnableEmailSave: resolveB2BFlag(
      contentStudioEnableEmailSaveBtn,
    ),
    contentStudioEnableShareableImageSave: resolveB2BFlag(
      contentStudioEnableShareableImageSaveBtn,
    ),
    isEnterprisePlatform: enableB2B || false,
    isDatoUser,
    storyUploadUseAws: storyUploadUseAws ?? false,
    renderUsingChunks: renderUsingChunks ?? false,
    enableCreatorStudioNav: resolveAdminFlag(enableCreatorStudio),
    storyManagerEnableAddStoriesButton: resolveAdminFlag(
      enableCreatorStudio,
      storyManagerEnableAddStories,
    ),
    hideStoryDashboardNav: hideStoryDashboardNav || false,
    enableBlogRegeneration: resolveB2BFlag(contentStudioEnableBlogRegeneration),
    enableEmailRegeneration: resolveB2BFlag(
      contentStudioEnableEmailRegenerationBtn,
    ),
    enableShareableImageRegeneration: resolveB2BFlag(
      contentStudioEnableShareableImageRegeneration,
    ),
    enableCaptionTemplatesTab: false,
    enableVideoClipEdit: resolveAdminFlag(contentStudioEnableClipEdit),
    enableTalkingPointsStudioSave: resolveB2BFlag(
      contentStudioEnableTalkingPointsSaveBtn,
    ),
    enableTalkingPointsStudioRegeneration: resolveB2BFlag(
      contentStudioEnableTalkingPointsRegenerationBtn,
    ),
    enableImportXml: resolveAdminFlag(enableImportXml),
    enableAiStoryProducer: resolveAdminFlag(enableAiStoryProducer),
    enableFixTranscriptButton: resolveAdminFlag(enableFixTranscriptButton),
    enableReframingModeIn16_9: enableReframingModeIn169,
    enableLargeFileSizeUpload,
  };
}
