import { useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { runInAction } from 'mobx';

import TimelinePhotoIcon from '../../svgs/TimelinePhotoIcon';
import EllipsisIcon from '../../svgs/EllipsisIcon';
import DeleteIcon from '../../svgs/DeleteIcon';
import { useOutsideAlerter } from '../transcript/useClickOutside';
import AutoResizeableMultilineInput from './AutoResizeableMultilineInput';
import applyImageOptimizations from '../../utility/applyImageOptimizations';
import { observer } from 'mobx-react-lite';
import { Artifact, Showcase } from '../../types.ts/story';
import {
  ActionsWrapper as ActionsWrapperBase,
  ActionWrapperRow as ActionWrapperRowBase,
} from '../../styles/mainStyle';
import RotatingSquare from '../../svgs/RotatingSquare';
import SpinningLoading from '../SpinningLoading';

import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';
import { useStore } from '@src/stores-v2/StoreContext';
import EnlargedImageRenderProp from './EnlargedImageRenderProp';

type Props = {
  item: {
    id: string;
    src: string;
    title?: string;
    description: string;
    thumbnailUrl?: string;
  };
  type:
    | 'storyArtifacts'
    | 'storyArtifactsVideo'
    | 'organizationArtifacts'
    | 'organizationArtifactsVideo'
    | 'organizationLogos';
  isSelected: boolean;
  handleClick: () => void;
  handleRemove: () => void;
  handleRegenerateTitle?: () => Promise<void>;
  callback?: (artifact?: Artifact[], showcases?: Showcase[]) => void;
};
const SMALL_PHOTO_HEIGHT = 70;

const FileListItem = observer((props: Props) => {
  const videoCreator = useVideoCreatorStore();
  const { datoClientStore } = useStore();

  const {
    item,
    type,
    isSelected = false,
    callback,
    handleClick = () => {},
  } = props;
  const [isActionClicked, setIsActionClicked] = useState(false);
  const [description, setDescription] = useState(item.description);
  const actionsWrapperRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [isUpdating, setIsUpdating] = useState(false);
  useOutsideAlerter(actionsWrapperRef, () => {
    setIsActionClicked(false);
  });

  const handleUpdateDescription = async () => {
    if (item.description === description) return;

    switch (type) {
      case 'storyArtifacts':
        videoCreator.story!.storyArtifacts =
          videoCreator.story?.storyArtifacts?.map((f) =>
            f.id === item.id ? { ...f, title: description } : f,
          );
        callback && callback(videoCreator.story!.storyArtifacts!);
        await videoCreator.updateStory(videoCreator.story!);
        break;
      case 'storyArtifactsVideo':
        videoCreator.story!.storyArtifactsVideo =
          videoCreator.story?.storyArtifactsVideo?.map((f) =>
            f.id === item.id ? { ...f, title: description } : f,
          );
        props.callback &&
          props.callback(videoCreator.story!.storyArtifactsVideo!);
        await videoCreator.updateStory(videoCreator.story!);
        break;
      case 'organizationArtifacts':
        const idx = videoCreator.story?._allReferencingShowcases?.findIndex(
          (s) => s.organizationArtifacts?.some((a) => a.id === item.id),
        );
        if (idx !== undefined && idx > -1) {
          videoCreator.story!._allReferencingShowcases[
            idx
          ].organizationArtifacts =
            videoCreator.story!._allReferencingShowcases[
              idx
            ].organizationArtifacts?.map((f) =>
              f.id === item.id ? { ...f, title: description } : f,
            );
          props.callback &&
            props.callback(
              undefined,
              videoCreator.story?._allReferencingShowcases,
            );
          await datoClientStore.albumRepository?.update(
            videoCreator.story!._allReferencingShowcases[idx],
          );
        }
        break;
      case 'organizationLogos': {
        const idx = videoCreator.story?._allReferencingShowcases?.findIndex(
          (s) => s.organizationLogos?.some((a) => a.id === item.id),
        );
        if (idx !== undefined && idx > -1) {
          videoCreator.story!._allReferencingShowcases[idx].organizationLogos =
            videoCreator.story!._allReferencingShowcases[
              idx
            ].organizationLogos?.map((f) =>
              f.id === item.id ? { ...f, title: description } : f,
            );
          props.callback &&
            props.callback(
              undefined,
              videoCreator.story?._allReferencingShowcases,
            );
          await datoClientStore.albumRepository?.update(
            videoCreator.story!._allReferencingShowcases[idx],
          );
        }
        break;
      }
      case 'organizationArtifactsVideo':
        const index = videoCreator.story?._allReferencingShowcases?.findIndex(
          (s) => s.organizationArtifactsVideo?.some((a) => a.id === item.id),
        );
        if (index !== undefined && index > -1) {
          videoCreator.story!._allReferencingShowcases[
            index
          ].organizationArtifactsVideo =
            videoCreator.story!._allReferencingShowcases[
              index
            ].organizationArtifactsVideo?.map((f) =>
              f.id === item.id ? { ...f, title: description } : f,
            );
          await datoClientStore.albumRepository?.update(
            videoCreator.story!._allReferencingShowcases[index],
          );
        }
        break;
    }

    await datoClientStore.assetRepository?.update(item.id, {
      title: description,
      alt: description,
    });
  };

  const isPhotoType =
    type === 'storyArtifacts' ||
    type === 'organizationArtifacts' ||
    type === 'organizationLogos';

  return (
    <Entry
      ref={containerRef}
      key={item.id}
      onClick={handleClick}
      isSelected={isSelected}
    >
      <EnlargedImageRenderProp
        src={
          item.thumbnailUrl ||
          applyImageOptimizations(item.src, {
            height: 337,
          })
        }
        smallPhotoHeight={SMALL_PHOTO_HEIGHT}
      >
        {({ enlargedState, setEnlargedState, renderEnlargedImage }) => (
          <>
            <Wrapper
              className="target"
              hasImage={!!item.src}
              onClick={() => {
                runInAction(async () => {
                  videoCreator.setActiveElements(item.id!.toString());
                });
              }}
              onMouseEnter={(e) => {
                if (isPhotoType) {
                  setEnlargedState(e.currentTarget.getBoundingClientRect());
                }
              }}
              onMouseMove={(e) => {
                if (isPhotoType && !enlargedState) {
                  setEnlargedState(e.currentTarget.getBoundingClientRect());
                }
              }}
              onMouseLeave={() => {
                setEnlargedState(null);
              }}
            >
              {item.src ? (
                <>
                  {isPhotoType || item.thumbnailUrl ? (
                    <Image
                      src={
                        item.thumbnailUrl ||
                        applyImageOptimizations(item.src, {
                          width: 100,
                        })
                      }
                    />
                  ) : (
                    <Video src={item.src} />
                  )}
                </>
              ) : (
                <TimelinePhotoIcon strokeColor="#828282" />
              )}
            </Wrapper>
            {enlargedState && renderEnlargedImage()}
          </>
        )}
      </EnlargedImageRenderProp>
      <Text>
        {item.title && <div className="quote">{item.title}</div>}
        <TextDescription>
          {/* <div className="text">{item.description}</div> */}
          <AutoResizeableMultilineInput
            customStyles={{
              color: '#D7D7E1',
              fontSize: '10px',
              fontWeight: '300',
              lineHeight: '12px',
              paddingLeft: '5px',
              paddingRight: '5px',
              minHeight: '54px',
            }}
            hasOutline={false}
            hasBorder={false}
            borderOnFocus={true}
            placeholder="(Type to update description)"
            width="100%"
            customHeight="54px"
            value={item.description}
            maxResizeHeight={70}
            disableAutoResize={false}
            onBlur={handleUpdateDescription}
            getValue={(description) => {
              setDescription(description);
            }}
          />
          <div
            className="ellipsis"
            onClick={(e) => {
              e.stopPropagation();
              setIsActionClicked(true);
            }}
          >
            <EllipsisIcon width="12" fillColor="#484848" />
          </div>
        </TextDescription>
      </Text>
      <ActionsWrapper isVisible={isActionClicked} ref={actionsWrapperRef}>
        {props.handleRegenerateTitle && (
          <ActionWrapperRow
            role="button"
            onClick={async (e) => {
              e.stopPropagation();
              setIsUpdating(true);
              setIsActionClicked(false);
              try {
                await props.handleRegenerateTitle!();
              } finally {
                setIsUpdating(false);
              }
            }}
          >
            <span>Regenerate Description</span>{' '}
            <RotatingSquare strokeColor="#17C964" />
          </ActionWrapperRow>
        )}
        <ActionWrapperRow
          role="button"
          onClick={async (e) => {
            e.stopPropagation();
            props.handleRemove();
            setIsActionClicked(false);
          }}
        >
          <span>Remove</span> <DeleteIcon />
        </ActionWrapperRow>
      </ActionsWrapper>
      {isUpdating && (
        <SpinningLoading
          customStyle={{
            width: '100%',
            height: '100%',
          }}
          iconStyle={{ left: '11px', top: '11px' }}
          spinnerStyle={{ width: '46px', height: '46px' }}
          spinnerCircleStyle={{ borderWidth: '2px', boxSizing: 'border-box' }}
          textStyle={{ display: 'none' }}
        />
      )}
    </Entry>
  );
});

export default FileListItem;

const Entry = styled.div<{ isSelected: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  margin: 0 auto;
  cursor: pointer;
  border-radius: 8px;
  height: ${SMALL_PHOTO_HEIGHT}px;
  position: relative;

  ${(props) =>
    props.isSelected &&
    css`
      // background-color: #4f4f4f;
      border-radius: 8px;
    `}
`;
const Text = styled.div`
  width: 65%;
  padding-right: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1px;
  height: 59px;

  .quote {
    width: 100%;
    white-space: nowrap;
    color: #45d483;
    font-weight: 500;
    line-height: 19.2px;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 12px;
  }
`;

const TextDescription = styled.div`
  display: flex;
  gap: 3px;
  .text {
    color: #bdbdbd;
    font-size: 10px;
    font-weight: 300;
    line-height: 12px;
    width: 90%;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: 38px;
    overflow-y: hidden;
  }
  .ellipsis {
    width: 10%;
    margin-left: auto;
    text-align: right;
  }
`;
const Wrapper = styled.div<{ hasImage: boolean }>`
  border: 1px solid #03041926;
  color: #4f4f4f;
  width: 100px;
  height: ${SMALL_PHOTO_HEIGHT}px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  object-position: top;
`;

const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  // object-position: top;
`;

const ActionsWrapper = styled(ActionsWrapperBase)`
  top: 28px;
`;

const ActionWrapperRow = styled(ActionWrapperRowBase)`
  padding: 14px 12px;
  &:hover {
    background-color: initial;
    color: #f3e9d7;
  }
  &:not(:first-child) {
    border-top-color: #484848;
  }
`;
