type Props = {
  width?: string;
  height?: string;
  viewBox?: string;
  fillColor?: string;
};

const FrameLockIcon = (props: Props) => {
  const {
    width = '12',
    height = '12',
    viewBox = '0 0 12 12',
    fillColor = '#F3E9D7',
  } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
    >
      <path
        d="M1.2 12C0.87 12 0.5876 11.8826 0.3528 11.6478C0.118 11.413 0.0004 11.1304 0 10.8V8.4H1.2V10.8H3.6V12H1.2ZM0 3.6V1.2C0 0.87 0.1176 0.5876 0.3528 0.3528C0.588 0.118 0.8704 0.0004 1.2 0H3.6V1.2H1.2V3.6H0ZM8.4 12V10.8H10.8V8.4H12V10.8C12 11.13 11.8826 11.4126 11.6478 11.6478C11.413 11.883 11.1304 12.0004 10.8 12H8.4ZM10.8 3.6V1.2H8.4V0H10.8C11.13 0 11.4126 0.1176 11.6478 0.3528C11.883 0.588 12.0004 0.8704 12 1.2V3.6H10.8ZM6 6C5.49 6 5.0626 5.8276 4.7178 5.4828C4.373 5.138 4.2004 4.7104 4.2 4.2C4.2 3.7 4.3726 3.275 4.7178 2.925C5.063 2.575 5.4904 2.4 6 2.4C6.5 2.4 6.925 2.575 7.275 2.925C7.625 3.275 7.8 3.7 7.8 4.2C7.8 4.71 7.625 5.1376 7.275 5.4828C6.925 5.828 6.5 6.0004 6 6ZM2.4 9.6V8.46C2.4 8.25 2.4526 8.0526 2.5578 7.8678C2.663 7.683 2.8054 7.5354 2.985 7.425C3.445 7.155 3.9276 6.95 4.4328 6.81C4.938 6.67 5.4604 6.6 6 6.6C6.5396 6.6 7.0622 6.67 7.5678 6.81C8.0734 6.95 8.5558 7.155 9.015 7.425C9.195 7.535 9.3376 7.6826 9.4428 7.8678C9.548 8.053 9.6004 8.2504 9.6 8.46V9.6H2.4Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default FrameLockIcon;
