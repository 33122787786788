import { Client } from '@datocms/cma-client-browser';
import ApiClient from '@src/apiClient/ApiClient';
import { GraphQLClient } from 'graphql-request';
import { AIFlowQueryResult, AIPrompt } from '../types.ts/ai_prompts';
import { CUSTOMER_TYPE_DEFAULT_TEMPLATES_QUERY } from '@src/gql/misc-gql';
import { CustomerType, Story } from '@src/types.ts/story';
import { STORY_QUERY } from '@src/gql/story-gql';

export class TemplatedPromptsRepository {
  private dClient: Client | ApiClient;
  private gqlClient: GraphQLClient;

  constructor(dClient: Client | ApiClient, gqlClient: GraphQLClient) {
    this.dClient = dClient;
    this.gqlClient = gqlClient;
  }

  async fetchTemplatedPrompts(storyId: string) {
    const storyResponse = await this.gqlClient.request<{
      story: Story;
    }>(STORY_QUERY, { id: storyId });

    const { customerType: defaultTemplates } = await this.gqlClient.request<{
      customerType: CustomerType;
    }>(CUSTOMER_TYPE_DEFAULT_TEMPLATES_QUERY);

    let clipProducerTemplates: AIFlowQueryResult[] =
      defaultTemplates.clipProducerTemplates || [];
    let aiBlogTemplates: AIPrompt[] = defaultTemplates.blogTemplates || [];
    let aiEmailTemplates: AIPrompt[] = defaultTemplates.emailTemplates || [];
    let aiShareableImagesTemplates: AIPrompt[] =
      defaultTemplates.shareableImagesTemplates || [];
    let aiTalkingPointsTemplate: AIPrompt | null =
      defaultTemplates.talkingPointsTemplate || null;

    const showcase = storyResponse.story._allReferencingShowcases[0];
    if (showcase?.customerType) {
      clipProducerTemplates =
        showcase.customerType?.clipProducerTemplates || clipProducerTemplates;
      aiBlogTemplates = showcase.customerType?.blogTemplates || aiBlogTemplates;
      aiEmailTemplates =
        showcase.customerType?.emailTemplates || aiEmailTemplates;
      aiShareableImagesTemplates =
        showcase.customerType?.shareableImagesTemplates ||
        aiShareableImagesTemplates;
      aiTalkingPointsTemplate =
        showcase.customerType?.talkingPointsTemplate || aiTalkingPointsTemplate;
    }

    const clipProducerPresets = clipProducerTemplates.map((flow) => ({
      title: flow.title,
      category: flow.category,
    }));

    return {
      clipProducerPresets,
      aiBlogTemplates,
      aiEmailTemplates,
      aiShareableImagesTemplates,
      aiTalkingPointsTemplate,
    };
  }
}
