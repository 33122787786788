import styled from 'styled-components';
import Modal from '../../common/Modal';
import { Story } from '../../../types.ts/story';
import { useStoryCreator, Step } from './useStoryCreator';
import { StepOne, StepTwo, StepThree, StepFour } from './steps';
import { useState } from 'react';
import StatusMessageModal from '../../common/StatusMessageModal';
import AddStoriesModalBanner from './AddStoriesModalBanner';
import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';
import { useDatoClient, useUserIdentity } from '@src/stores-v2/StoreContext';
import { observer } from 'mobx-react-lite';

const AddStoriesModal = observer(
  ({
    closeModal,
    onStoryInitialized,
    onStoryCreated,
    onStoryProgress,
    modalId,
    setBannerHeight,
  }: {
    closeModal: (modalId: string) => void;
    onStoryInitialized: (s: Story) => void;
    onStoryCreated: (s: Story, modalId: string) => void;
    onStoryProgress: (percent: number, storyId?: string) => void;
    modalId: string;
    setBannerHeight: (height: number | undefined) => void;
  }) => {
    const videoCreator = useVideoCreatorStore();
    const userIdentity = useUserIdentity();
    const datoClientStore = useDatoClient();
    const [isStatusMessageModalOpen, setIsStatusMessageModalOpen] =
      useState<boolean>(false);
    const [statusMessage, setStatusMessage] = useState<string | null>(null);
    const [percent, setPercent] = useState<number>(0);
    const handleStoryProgress = (percent: number, storyId?: string) => {
      setPercent(percent);
      console.log('onStoryProgress', percent);
      onStoryProgress(percent, storyId);
    };
    const {
      step,
      goToNextStep,
      canGoToStep,
      handleStoryVideoUpload,
      handleStoryBRollUpload,
      storyName,
      setStoryName,
      storyLanguage,
      setStoryLanguage,
      storyType,
      setStoryType,
      videoMusicStrategy,
      setVideoMusicStrategy,
      storyArtifactsFiles,
      storyArtifactsVideoFiles,
      storyArtifacts,
      storyArtifactsVideo,
      saveArtifactNotes,
      setArtifactNotes,
      deleteArtifact,
      saveArtifactVideoNotes,
      setArtifactVideoNotes,
      deleteArtifactVideo,
      maxUploadSizeGB,
    } = useStoryCreator({
      videoCreator,
      datoClientStore,
      userIdentity,
      onStoryInitialized,
      onStoryCreated: (s) => onStoryCreated(s, modalId),
      onStoryProgress: handleStoryProgress,
      onError: (...data: any[]) => {
        console.error(...data);
        setIsStatusMessageModalOpen(true);
        handleStoryProgress(100);
      },
    });

    const renderStep = () => {
      switch (step) {
        case Step.one:
          return (
            <StepOne
              goToNextStep={goToNextStep}
              canGoToStep={canGoToStep(Step.two)}
              handleStoryVideoUpload={(files: File[]) => {
                if (files.length === 0) {
                  return;
                }
                if (files[0].size > maxUploadSizeGB * 1000 * 1000 * 1000) {
                  console.warn(
                    `File size exceeds ${maxUploadSizeGB} GB, size:`,
                    files[0].size,
                  );
                  setStatusMessage(
                    `File size exceeds the maximum upload size of ${maxUploadSizeGB} GB`,
                  );
                  setIsStatusMessageModalOpen(true);
                  return;
                }
                handleStoryVideoUpload(files);
              }}
              maxUploadSizeGB={maxUploadSizeGB}
            />
          );
        case Step.two:
          return (
            <StepTwo
              goToNextStep={goToNextStep}
              canGoToStep={canGoToStep(Step.three)}
              storyType={storyType}
              setStoryType={setStoryType}
              videoMusicStrategy={videoMusicStrategy}
              setVideoMusicStrategy={setVideoMusicStrategy}
            />
          );
        case Step.three:
          return (
            <StepThree
              goToNextStep={goToNextStep}
              canGoToStep={canGoToStep(Step.four)}
              storyName={storyName}
              setStoryName={setStoryName}
              storyLanguage={storyLanguage}
              setStoryLanguage={setStoryLanguage}
              allowLangSelect={
                videoCreator?.organization?.allowLangSelectAtUserStoryUpload
              }
            />
          );

        case Step.four:
          return (
            <StepFour
              goToNextStep={goToNextStep}
              handleStoryBRollUpload={handleStoryBRollUpload}
              storyArtifactsFiles={storyArtifactsFiles}
              storyArtifactsVideoFiles={storyArtifactsVideoFiles}
              storyArtifacts={storyArtifacts}
              storyArtifactsVideo={storyArtifactsVideo}
              saveArtifactNotes={saveArtifactNotes}
              setArtifactNotes={setArtifactNotes}
              deleteArtifact={deleteArtifact}
              saveArtifactVideoNotes={saveArtifactVideoNotes}
              setArtifactVideoNotes={setArtifactVideoNotes}
              deleteArtifactVideo={deleteArtifactVideo}
            />
          );
        default:
          return null;
      }
    };

    const handleCloseModal = () => {
      closeModal(modalId);
    };

    if (isStatusMessageModalOpen) {
      return (
        <StatusMessageModal
          enabled
          variant="user-upload-failed"
          customMessage={statusMessage}
          onClose={handleCloseModal}
        />
      );
    }

    if (step === Step.five) {
      return null;
    }

    return (
      <>
        <Modal isOpen closeModal={handleCloseModal}>
          <Wrapper>
            <Heading>
              Story producer (Step {step} of {Step.five - 1})
            </Heading>
            {renderStep()}
          </Wrapper>
        </Modal>
        {percent > 0 && percent < 100 && (
          <AddStoriesModalBanner
            setBannerHeight={setBannerHeight}
            percent={percent}
          />
        )}
      </>
    );
  },
);

const Wrapper = styled.div`
  width: 440px;
  border-radius: 16px;
  border: 1px solid #484848;
  padding: 48px 24px;
  box-sizing: content-box;
  background: #03041a;
`;

const Heading = styled.div`
  color: #45d483;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.92px;
  text-transform: uppercase;
  margin-bottom: 24px;
`;

export default AddStoriesModal;
