import React from 'react';
import styled from 'styled-components';

import { observer } from 'mobx-react-lite';
import Modal from '../common/Modal';
import InfoIcon from '../../svgs/InfoIcon';
import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';
import { useFlagsCombination } from '@src/utility/useFlagsCombination';
import { useUserIdentity } from '@src/stores-v2/StoreContext';

type Props = {
  closeModal: () => void;
};

const RetryPublishModal: React.FC<Props> = observer((props: Props) => {
  const videoCreator = useVideoCreatorStore();
  const userIdentity = useUserIdentity();
  const { renderUsingChunks } = useFlagsCombination(userIdentity.currentRole);
  const { closeModal } = props;

  const handleRetryPublishing = async () => {
    try {
      await videoCreator.finishVideo(videoCreator.renderVideoResLevel!, renderUsingChunks);
    } catch (error) {
      videoCreator.handleRenderingError(error);
    }
  };

  return (
    <Modal isOpen closeModal={closeModal}>
      <Wrapper>
        <Heading>
          <InfoIcon /> <span>Publishing failed</span>
        </Heading>
        <Buttons>
          <CancelButton onClick={closeModal}>Cancel</CancelButton>
          <SaveButton onClick={handleRetryPublishing}>Retry</SaveButton>
        </Buttons>
      </Wrapper>
    </Modal>
  );
});

export default RetryPublishModal;

const Wrapper = styled.div`
  width: 459px;
  height: 200px;
  border-radius: 16px;
  border: 1px solid #484848;
  padding: 48px 24px;
  box-sizing: border-box;
  background: #03041a;
`;

const Heading = styled.div`
  color: #ef5da8;

  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.92px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
`;

const Buttons = styled.div`
  margin-top: 32px;
  display: flex;
  height: 48px;
  gap: 8px;
`;

const CancelButton = styled.div`
  box-sizing: border-box;
  flex: 1;
  display: flex;
  height: 48px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #ef5da8;
  cursor: pointer;

  font-size: 14px;
  font-weight: 700;

  color: #ef5da8;
`;

const SaveButton = styled(CancelButton)`
  background: #ef5da8;
  color: #03041a;
`;
