import { uniqBy } from 'lodash';

import VideoCreatorStore from '@src/stores/VideoCreatorStore';
import { proximaNovaFonts } from '@src/utility/general';
import { BrandingCustomFont } from '@src/types.ts/story';

const getFontId = (font: BrandingCustomFont) =>
  `${font.fontFamily}-${font.postScriptName}`;

export default class CustomFontsService {
  protected videoCreator: VideoCreatorStore;

  constructor(videoCreator: VideoCreatorStore) {
    this.videoCreator = videoCreator;
  }

  getCustomFontsSource(initialSource?: Record<string, any>) {
    const customFonts = uniqBy(
      this.videoCreator.organization?.branding?.customFonts?.filter(
        (f) => f.style !== 'italic',
      ),
      (f) => getFontId(f),
    );

    let fontsConfig: {
      family: string;
      weight: number;
      style: string;
      source: string;
    }[] = proximaNovaFonts;

    if (customFonts && customFonts.length) {
      fontsConfig = [
        ...fontsConfig,
        ...customFonts.map((font) => ({
          family: font.postScriptName,
          weight: font.usWeightClass,
          style: font.style,
          source: font.source,
        })),
      ];
    }

    const source = initialSource || this.videoCreator.renderer?.getSource();
    return {
      ...source,
      fonts: uniqBy(
        [...(source?.fonts || []), ...fontsConfig],
        (f) => f.family,
      ),
    } as Record<string, any>;
  }
}
