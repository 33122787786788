import { useCallback, useEffect, useMemo, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import styled from 'styled-components';


import { handleCopyToClipboard } from '@src/utility/general';
import CopyIcon from '@src/svgs/CopyIcon';
import { useTranscription } from '@src/stores-v2';

const CopyTranscriptIconButton = () => {
  const transcriptionStore = useTranscription();
  const [hovered, setHovered] = useState(false);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    }
  }, [copied]);

  const transcript = useMemo(
    () =>
      transcriptionStore
        .getFinalTranscriptionElements()
        ?.filter((el) => !el.state || !['removed', 'cut'].includes(el.state))
        .map((el) => el.value)
        .join('') || '',
    [transcriptionStore.getFinalTranscriptionElements()],
  );
  const handleCopy = useCallback(() => {
    handleCopyToClipboard(transcript);
    setCopied(true);
  }, [transcript]);

  return (
    <>
      <IconContainer
        tabIndex={-1}
        disabled={!transcript}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onClick={handleCopy}
        data-tooltip-id="copy-transcript-tooltip"
        data-tooltip-content={copied ? 'Copied!' : 'Copy'}
      >
        <CopyIcon strokeColor={hovered ? '#F2D093' : '#a9a9a9'} />
      </IconContainer>
      {transcript && (
        <Tooltip
          id="copy-transcript-tooltip"
          style={{
            padding: '8px',
            fontSize: '12px',
            lineHeight: '120%',
            zIndex: '10',
            fontWeight: 600,
            ...(copied && {
              backgroundColor: '#17C964',
              color: '#F3E9D7',
            }),
          }}
          place="top"
          positionStrategy="fixed"
        />
      )}
    </>
  );
};

const IconContainer = styled.button`
  padding: 0;
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 26px;
  width: 16px;

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

export default CopyTranscriptIconButton;
