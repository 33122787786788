import { RendererState } from '@src/renderer/RendererState';
import VideoCreatorStore from '@src/stores/VideoCreatorStore';

type HandlerSignature = ((
  videoCreator: VideoCreatorStore,
  newState: RendererState,
) => boolean) & { displayName: string };

export default class StateChangeObserver {
  constructor(private handlers: HandlerSignature[]) {
    this.handlers = handlers;
  }

  handle(videoCreator: VideoCreatorStore, newState: RendererState) {
    for (let i = 0; i < this.handlers.length; i++) {
      let shouldAbort: boolean;
      try {
        shouldAbort = this.handlers[i](videoCreator, newState);
      } catch (err) {
        console.error(
          `StateChangeObserver failed due to ${this.handlers[i].displayName} handler error`,
          err,
        );
        shouldAbort = true;
      }
      if (shouldAbort) {
        break;
      }
    }
  }
}
