import { action, makeAutoObservable } from 'mobx';
import { UserStoryUploadFlowStore } from './UserStoryUploadFlowStore';
import { RootStore } from './RootStore';
import { DashboardStory } from '@src/types.ts/story';

export class DashboardStore {
  userStoryUploadFlowStore: UserStoryUploadFlowStore;
  allStories: DashboardStory[] = [];

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this, {
      userStoryUploadFlowStore: false,
      setAllStories: action.bound,
    });
    this.userStoryUploadFlowStore = new UserStoryUploadFlowStore(this);
  }

  setAllStories(stories: DashboardStory[]) {
    this.allStories = stories;
  }

  addStory(story: DashboardStory) {
    this.setAllStories([story, ...this.allStories]);
  }

  updateStory(story: DashboardStory) {
    this.setAllStories(
      this.allStories.map((item) => (item.id === story.id ? story : item)),
    );
  }
}
