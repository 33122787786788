import { useAnalytics, useVideoCreatorStore } from '@src/stores-v2';
import { useRef } from 'react';
import { v4 as uuid } from 'uuid';

type ReturnType = {
  correlationId: string | null;
  trackInit: (params: any) => void;
  analyticsLog: (
    level: LogLevel,
    message: string,
    payload?: Record<string, any>,
  ) => void;
  analyticsContext: {
    showcase?: any;
    user?: any;
    env?: any;
  };
};
type Params = {};

type LogLevel = 'debug' | 'info' | 'warn' | 'error';

export function useStoryUploadAnalytics(params: Params): ReturnType {
  const correlationId = useRef<string | null>(null);
  const showcaseProperties = useRef<any>({});

  const analyticsStore = useAnalytics();
  const videoCreator = useVideoCreatorStore();

  const trackInit = (event: any) => {
    correlationId.current = uuid().substring(0, 8);
    showcaseProperties.current = {
      id: videoCreator.organization?.id,
      name: videoCreator.organization?.title,
    };
    analyticsStore.trackEvent({
      logMethod: 'info',
      eventName: 'story_upload',
      label: 'story-upload-analytics',
      correlationId: correlationId.current,
      extraProperties: {
        showcase: showcaseProperties.current,
        ...event,
      },
    });
  };

  const analyticsLog = (level: LogLevel, message: string, payload?: any) => {
    analyticsStore.log({
      label: 'story-upload-analytics',
      level: level,
      correlationId: correlationId.current,
      msg: message,
      extraProperties: {
        showcase: showcaseProperties.current,
        ...payload,
      },
    });
  };

  return {
    trackInit,
    analyticsLog,
    correlationId: correlationId.current,
    analyticsContext: {
      showcase: showcaseProperties.current,
      ...analyticsStore.getTrackedUserData(),
      ...analyticsStore.getTrackedEnvData(),
    },
  };
}
