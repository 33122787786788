import { ContentViewData, PunchListItem } from './../types.ts/story';
import { AIPrompt } from '../types.ts/ai_prompts';
import axios from 'axios';
import TurndownService from 'turndown';
import { TalkingPointContent } from '../types.ts/general';
import VideoCreatorStore from '@src/stores/VideoCreatorStore';
import { logChatGptOutput } from '@src/utility/datadog';
import { AIPromptRepository } from '@src/repositories';
import { TranscriptionStore } from '@src/stores-v2';

const turndownService = new TurndownService();

type SupportedKey =
  | 'Email'
  | 'Quotes'
  | 'Blog'
  | 'Music Selection'
  | 'Photo Punchlist';

type GenerateStreamResponseParams = {
  key: SupportedKey;
  template?: AIPrompt;
  version?: '1' | '2';
  type?: 'existing' | 'new' | 'regenerate';
  customInstructions?: string;
  revision?: string;
  brandVoice?: string;
  avoidWords?: string;
};

export default class ChatGPTService {
  protected videoCreator: VideoCreatorStore;
  protected transcriptionStore: TranscriptionStore;
  protected aiPromptRepository: AIPromptRepository;
  public streamProcessDone = false;
  private controller: AbortController | null = null;
  public talkingPointController: AbortController | null = null;

  constructor(
    videoCreator: VideoCreatorStore,
    aiPromptRepository: AIPromptRepository,
  ) {
    this.videoCreator = videoCreator;
    this.transcriptionStore = videoCreator.rootStore.transcriptionStore;
    this.aiPromptRepository = aiPromptRepository;
  }

  fetchAiPrompt = async (key: string) => {
    const prompt = await this.aiPromptRepository.getPromptByTitle(key);
    return prompt;
  };

  private getExistingResponseData = (key: SupportedKey) => {
    return (
      this.videoCreator.contentStudioGeneratedContent?.[key]?.content
        ?.response || ''
    );
  };

  public getTranscript = (key: SupportedKey): string | undefined => {
    let elements;
    if (key === 'Music Selection' || key === 'Photo Punchlist') {
      elements = this.transcriptionStore
        .getFinalTranscriptionElements()
        ?.filter((el: any) => el.state !== 'removed' && el.state !== 'cut');
    } else {
      elements = this.transcriptionStore.originalTranscription?.elements;
    }
    return elements?.map((e) => e.value || '').join('');
  };

  private async getResponseInputData(params: GenerateStreamResponseParams) {
    const {
      key,
      template,
      version = '1',
      type = 'existing',
      customInstructions,
      revision,
      brandVoice,
      avoidWords,
    } = params;
    const transcript = this.getTranscript(key);
    let aiPrompt = template;
    if (!aiPrompt && customInstructions) {
      aiPrompt = await this.fetchAiPrompt(`Custom ${key}`);
    }
    if (!aiPrompt) {
      aiPrompt = await this.fetchAiPrompt(key);
    }

    if (!aiPrompt || !transcript || !this.videoCreator.story?.id) return;
    let existingResponse: any =
      type === 'new' ? '' : this.getExistingResponseData(key);
    if (version === '2') {
      existingResponse = { ...aiPrompt, response: existingResponse.response };
    }

    let prevQuestion = aiPrompt.description;
    if (customInstructions) prevQuestion += `\n\n${customInstructions}`;

    let currQuestion = '';
    if (type === 'regenerate') currQuestion += aiPrompt.followUp;
    if (revision) currQuestion += `${currQuestion ? '\n\n' : ''}${revision}`;

    if (brandVoice) {
      const brandVoicePrompt = await this.fetchAiPrompt('Brand Voice');
      if (brandVoicePrompt) {
        currQuestion += `${currQuestion ? '\n\n' : ''}${
          brandVoicePrompt.description
        }\n\n${brandVoice}`;
      }
    }

    if (avoidWords) {
      const avoidWordsPrompt = await this.fetchAiPrompt('Avoid Words');
      if (avoidWordsPrompt) {
        currQuestion += `${currQuestion ? '\n\n' : ''}${
          avoidWordsPrompt.description
        }: ${avoidWords}`;
      }
    }

    return {
      prevQuestion,
      currQuestion,
      transcript,
      existingResponse,
    };
  }

  private updateGeneratedContent = (
    key: SupportedKey,
    response: string = '',
  ) => {
    let generatedContent = this.videoCreator.contentStudioGeneratedContent;
    this.videoCreator.contentStudioGeneratedContent = {
      ...(generatedContent || {}),
      [key]: {
        ...(generatedContent?.[key] || {}),
        content: {
          ...(generatedContent?.[key]?.content || {}),
          response,
        },
      },
    } as ContentViewData;
  };

  public async regenerateStreamResponse(
    params: GenerateStreamResponseParams & { setLoading: (e: boolean) => void },
  ) {
    const {
      key,
      template,
      setLoading,
      version = '1',
      type = 'existing',
      customInstructions,
      revision,
      brandVoice,
      avoidWords,
    } = params;
    try {
      if (this.controller) {
        this.controller.abort();
      }
      this.controller = new AbortController();
      const signal = this.controller.signal;

      const items = await this.getResponseInputData({
        key,
        template,
        version,
        type,
        customInstructions,
        revision,
        brandVoice,
        avoidWords,
      });
      if (!items) return;
      const { transcript, prevQuestion, currQuestion, existingResponse } =
        items;
      const storyId = this.videoCreator.story?.id!;

      const aiPromptPathSegment = `regenerate-gpt-response-stream${
        version === '2' ? '-v2' : ''
      }`;
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/api/aiprompts/${aiPromptPathSegment}`,
        {
          signal,
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            transcript,
            existingResponse,
            storyId,
            prevQuestion,
            currQuestion,
          }),
        },
      );

      this.updateGeneratedContent(key, '');

      const data = res.body;

      if (!data) return;
      const reader = data.getReader();
      const decoder = new TextDecoder();
      this.videoCreator.contentStudioGeneratedContent![key].hasBeenGenerated =
        true;

      await this.processGeneratedContentByChunk(
        reader,
        decoder,
        setLoading,
        key,
        aiPromptPathSegment,
      );
    } catch (err: any) {
      if (err.name === 'AbortError') {
        console.log('Request aborted', err, err.message);
      } else {
        console.error('Error occurred:', err);
      }
      setLoading(false);
    }
  }

  public async matchPunchListWithSources(
    punchList: PunchListItem[],
    sources: string[],
  ): Promise<{ punchList: PunchListItem[] }> {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/aiprompts/match-punchlist-with-sources`,
      { punchList, sources, storyId: this.videoCreator.story?.id },
    );
    return data;
  }

  public async generatePunchListItem(line: string) {
    const storyId = this.videoCreator.story?.id;
    if (!line || !storyId) return;

    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/aiprompts/generate-punchlist-item`,
      { line, storyId },
    );
    return data;
  }

  private async processGeneratedContentByChunk(
    reader: any,
    decoder: TextDecoder,
    setLoading: (e: boolean) => void,
    key: SupportedKey,
    loggingKey: string,
  ) {
    let i = 0;
    while (!this.streamProcessDone) {
      const { value, done: doneReading } = await reader.read();
      this.streamProcessDone = doneReading;
      let chunk = decoder.decode(value);
      let addition = '';

      if (key === 'Email') {
        if (i === 0 && !chunk.includes('##')) addition = '## ';
        if (chunk === ' ') chunk = '\n\n';
        if (chunk.includes('Subject:')) {
          chunk = chunk.replace('Subject:', '').trim();
        }
      } else if (key === 'Blog') {
        this.videoCreator.selectedBlogContent = {
          type: doneReading ? 'generated' : 'generating',
        };
      } else if (key === 'Quotes') {
      }

      const existingResponse = this.getExistingResponseData(key);
      const text = existingResponse + addition + chunk;
      this.updateGeneratedContent(key, text);
      if (doneReading) {
        logChatGptOutput({ [loggingKey]: text });
        setLoading(false);
      }
      i++;
    }
    if (this.streamProcessDone) {
      this.controller = null;
      this.streamProcessDone = false;
    }
  }

  async generateTalkingPoint({
    setLoading,
    brandText,
    key = null,
    template,
    avoidWords,
  }: {
    setLoading: (e: boolean) => void;
    brandText?: string;
    key?: (keyof TalkingPointContent & 'all') | null;
    template?: AIPrompt | null;
    avoidWords?: string;
  }) {
    try {
      if (this.talkingPointController) {
        this.talkingPointController.abort();
      }
      this.talkingPointController = new AbortController();
      const signal = this.talkingPointController.signal;

      const transcript = this.transcriptionStore
        .getFinalTranscriptionElements()
        ?.map((e) => e.value || '')
        .join('');

      let prompts = template;
      if (!prompts) {
        prompts = await this.fetchAiPrompt('Fundraising prompt');
      }
      if (!prompts?.promptFields || !transcript) return;

      const model = 'gpt-4o';
      const temperature = 0;

      if (!key || key === 'all') {
        if (!key || !this.videoCreator.talkingPointContent) {
          this.videoCreator.talkingPointContent = {} as TalkingPointContent;
        } else {
          this.videoCreator.talkingPointContent = Object.fromEntries(
            Object.entries(this.videoCreator.talkingPointContent).map(
              ([key, value]) => [key, { prompt: value.prompt, content: '' }],
            ),
          ) as TalkingPointContent;
        }

        for (let prompt of prompts.promptFields) {
          await this.runTalkingPointAi(
            transcript,
            prompts.description,
            setLoading,
            prompt,
            temperature,
            model,
            key,
            brandText,
            avoidWords,
            signal,
          );
        }
      } else {
        const prompt = prompts.promptFields.find((f) => f.name === key);
        if (!prompt) return;

        await this.runTalkingPointAi(
          transcript,
          prompts.description,
          setLoading,
          prompt,
          temperature,
          model,
          key,
          brandText,
          avoidWords,
          signal,
        );
      }
    } catch (error: any) {
      if (error.name === 'AbortError') {
        console.log('Request aborted', error, error.message);
      } else {
        console.error('Error occurred:', error);
      }
    }
  }

  getPrevContent(content: TalkingPointContent | null, promptName: string) {
    try {
      if (content && promptName in content) {
        return content[promptName as keyof TalkingPointContent];
      }
      return { prompt: '', content: '' };
    } catch (error) {
      console.log('Error: ', error);
    }
  }

  async runTalkingPointAi(
    transcript: string,
    description: string,
    setLoading: (e: boolean) => void,
    prompt: AIPrompt['promptFields'][0],
    temperature: number,
    model: string,
    key: (keyof TalkingPointContent & 'all') | null,
    brandText: string | undefined,
    avoidWords: string | undefined,
    signal: AbortSignal,
  ) {
    let prevContent = '';
    let prevPrompt = '';
    if (key) {
      const prevData = this.getPrevContent(
        this.videoCreator.talkingPointContent,
        prompt.name,
      );
      prevContent = turndownService.turndown(prevData?.content ?? '');
      prevPrompt = prevData?.prompt ?? '';
    }

    const promptDescription = `${prompt.description}.  Return only markdown with no other comments.`;
    let currentPrompt = `${promptDescription}`;

    if (brandText) currentPrompt += ` ${brandText}`;

    if (avoidWords) {
      const avoidWordsPrompt = await this.fetchAiPrompt('Avoid Words');
      if (avoidWordsPrompt) {
        currentPrompt += `${currentPrompt ? '\n\n' : ''}${
          avoidWordsPrompt.description
        }: ${avoidWords}`;
      }
    }

    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/api/aiprompts/stream-talking-point-response`,
      {
        signal,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          transcript,
          systemMessage: description,
          prevPrompt: prevPrompt || promptDescription,
          currentPrompt,
          temperature,
          model,
          prevContent,
        }),
      },
    );

    const data = res.body;
    if (!data) return;
    const reader = data.getReader();
    const decoder = new TextDecoder();
    let done = false;

    if (key && this.videoCreator.talkingPointContent?.hasOwnProperty(key)) {
      this.videoCreator.talkingPointContent[
        key as keyof TalkingPointContent
      ].content = '';
    }

    while (!done) {
      const { value, done: doneReading } = await reader.read();
      done = doneReading;
      let chunk = decoder.decode(value);
      setLoading(false);

      const key = prompt.name as keyof TalkingPointContent;
      const existingContent = (this.videoCreator.talkingPointContent ||
        {}) as TalkingPointContent;

      const content = (existingContent[key]?.content || '') + chunk;
      this.videoCreator.talkingPointContent = {
        ...(existingContent as TalkingPointContent),
        [key]: {
          content,
          prompt: currentPrompt,
        },
      };
      if (done) {
        logChatGptOutput({ 'stream-talking-point-response': content });
      }
    }
  }

  regenerateArtifactTitle = async ({
    artifactId,
    storyId,
    organizationId,
  }: {
    artifactId: string;
    storyId: string | undefined;
    organizationId: string | undefined;
  }) => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/aiprompts/regenerate-artifact-title`,
      {
        artifactId,
        storyId,
        organizationId,
      },
    );
    return data;
  };
}
