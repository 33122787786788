type LanguageOption = {
  caption: string;
  value: string;
};

// Taken from datocms' Story.original_video_language presentation
export const ORIGINAL_VIDEO_LANGUAGE_OPTIONS: LanguageOption[] = [
  {
    caption: 'English (US)',
    value: 'en-us',
  },
  {
    caption: 'English (UK)',
    value: 'en-gb',
  },
  {
    caption: 'Arabic',
    value: 'ar',
  },
  {
    caption: 'Dutch',
    value: 'nl',
  },
  {
    caption: 'French',
    value: 'fr',
  },
  {
    caption: 'German',
    value: 'de',
  },
  {
    caption: 'Hebrew',
    value: 'he',
  },
  {
    caption: 'Hindi',
    value: 'hi',
  },
  {
    caption: 'Italian',
    value: 'it',
  },
  {
    caption: 'Russian',
    value: 'ru',
  },
  {
    caption: 'Spanish',
    value: 'es',
  },
  {
    caption: 'Ukrainian',
    value: 'uk',
  },
];
