import { getDocumentHeight } from '@src/utility/general';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

type RenderProp = {
  enlargedState: DOMRect | null;
  setEnlargedState: Dispatch<SetStateAction<DOMRect | null>>;
  renderEnlargedImage: () => React.ReactNode;
};

export default function EnlargedImageRenderProp({
  children,
  src,
  smallPhotoHeight,
  enlargedPhotoHeight = 345,
  direction = 'left',
}: {
  children: (r: RenderProp) => React.ReactElement;
  src: string;
  smallPhotoHeight: number;
  enlargedPhotoHeight?: number;
  direction?: 'left' | 'right';
}) {
  const [enlargedState, setEnlargedState] = useState<DOMRect | null>(null);
  const [enlargedImageActualWidth, setEnlargedImageActualWidth] = useState<
    number | null
  >(null);

  useEffect(() => {
    if (enlargedState == null) {
      setEnlargedImageActualWidth(null);
    }
  }, [enlargedState]);

  useEffect(() => {
    setEnlargedState(null);
  }, [src]);

  const renderEnlargedImage = () => {
    const top = Math.max(
      enlargedState!.top - enlargedPhotoHeight / 2 + smallPhotoHeight / 2 - 8,
      0,
    );
    const arrowPosCorrection = () => {
      const winHeight = getDocumentHeight() - enlargedPhotoHeight;
      if (winHeight >= top) {
        return 50;
      }
      const diff = top - winHeight;
      const percentageDiff = (diff / enlargedPhotoHeight) * 100;
      return 50 + percentageDiff;
    };

    const containerActualWidth = (enlargedImageActualWidth || 0) + 8;
    return (
      <EnlargedImageContainer
        onClick={(e) => e.stopPropagation()}
        top={Math.max(
          enlargedState!.top -
            enlargedPhotoHeight / 2 +
            smallPhotoHeight / 2 -
            8,
          0,
        )}
        left={
          direction === 'left'
            ? enlargedState!.left - containerActualWidth - 10
            : enlargedState!.right + 10
        }
        actualWidth={containerActualWidth}
        arrowPos={arrowPosCorrection()}
        arrowDirection={direction}
        className="enlarged"
        isVisible={!!enlargedImageActualWidth}
        enlargedPhotoHeight={enlargedPhotoHeight}
      >
        <EnlargedImage
          src={src}
          onLoad={(e) => {
            setEnlargedImageActualWidth(
              e.currentTarget.getBoundingClientRect().width,
            );
          }}
          actualWidth={enlargedImageActualWidth}
        />
      </EnlargedImageContainer>
    );
  };

  return children({
    enlargedState,
    setEnlargedState,
    renderEnlargedImage,
  });
}

const EnlargedImageContainer = styled.div<{
  top: number;
  left: number;
  arrowPos: number;
  arrowDirection: 'left' | 'right';
  isVisible: boolean;
  actualWidth: number;
  enlargedPhotoHeight: number;
}>`
  position: fixed;
  left: ${(props) => props.left}px;
  top: ${(props) =>
    Math.min(props.top, getDocumentHeight() - props.enlargedPhotoHeight)}px;
  width: ${(props) => props.actualWidth}px;
  height: ${(props) => props.enlargedPhotoHeight}px;
  border-radius: 12px;
  background-color: #f3e9d7;
  z-index: 9999999;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  pointer-events: none;
  &::after {
    content: '';
    position: absolute;
    top: ${(props) => props.arrowPos}%;
    ${(props) =>
      props.arrowDirection === 'left' &&
      css`
        left: 100%;
        transform: translateY(-50%);
      `}
    ${(props) =>
      props.arrowDirection === 'right' &&
      css`
        left: -8px;
        transform: translateY(-50%) rotate(180deg);
      `}
    border-style: solid;
    border-width: 12px 0 12px 8px;
    border-color: transparent transparent transparent #f3e9d7;
  }
`;

const EnlargedImage = styled.img<{ actualWidth: number | null }>`
  width: ${(props) => props.actualWidth || 'auto'};
  border-radius: inherit;
  height: 337px;
`;
