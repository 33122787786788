import { useEffect, useMemo } from 'react';
import { uniqBy } from 'lodash';

import VideoCreatorStore from '../../../stores/VideoCreatorStore';
import { BrandingCustomFont } from '../../../types.ts/story';

const LOADED_FONTS = new Set<string>();

const getFontId = (font: BrandingCustomFont) =>
  `${font.fontFamily}-${font.postScriptName}`;

const loadFontFace = async (font: BrandingCustomFont, fontFace: FontFace) => {
  const loadedFont = await fontFace.load();
  LOADED_FONTS.add(getFontId(font));
  document.fonts.add(loadedFont);
};

const useLoadBrandKitFonts = (videoCreator: VideoCreatorStore) => {
  const fontsToLoad = useMemo(
    () =>
      uniqBy(videoCreator.organization?.branding?.customFonts, (f) =>
        getFontId(f),
      )
        ?.filter((font) => !LOADED_FONTS.has(getFontId(font)))
        .map((font) => ({
          font,
          fontFace: new FontFace(font.postScriptName, `url(${font.source})`, {
            style: font.style,
            weight: font.usWeightClass.toString(),
          }),
        })),
    [videoCreator.organization?.branding?.customFonts],
  );

  // Load fonts to the browser
  useEffect(() => {
    if (!fontsToLoad?.length) return;
    fontsToLoad?.forEach(({ font, fontFace }) => {
      loadFontFace(font, fontFace);
    });
  }, [fontsToLoad]);

  // Load fonts to renderer
  useEffect(() => {
    if (!videoCreator.renderer?.ready || !videoCreator.currentVideo) return;
    const fn = async () => {
      const source =
        videoCreator.customFontsService?.getCustomFontsSource(
          videoCreator.currentVideo?.videoSource,
        ) || {};
      await videoCreator.renderer?.setSource(source);
    };
    fn();
  }, [
    videoCreator.renderer?.ready,
    videoCreator.currentVideo,
    videoCreator.organization?.branding?.customFonts,
  ]);
};

export default useLoadBrandKitFonts;
