import {
  computed,
  makeAutoObservable,
  reaction,
  runInAction,
  toJS,
} from 'mobx';
import { v4 as uuid } from 'uuid';
import { RenderModalCtx } from 'datocms-plugin-sdk';
import { Renderer } from '../renderer/Renderer';
import { RendererState } from '../renderer/RendererState';
import { ElementState } from '../renderer/ElementState';
import { groupBy } from '../utility/groupBy';
import { deepClone } from '../utility/deepClone';
import {
  TalkingPointContent,
  ImageKey,
  ImageWithType,
  SidebarOption,
  AIProducerCard,
  MediaCard,
} from '../types.ts/general';
import _isEqual from 'lodash/isEqual';
import Fuse from 'fuse.js';

import {
  Showcase,
  ContentViewData,
  ExtraElementData,
  Music,
  PhotoAssetData,
  Story,
  StoryDTO,
  Video,
  VideoVersion,
  VideoRenderingStatus,
  VolumeKeyPoint,
  VideoClip,
  AssociatedVideo,
  ShareableImageType,
  PhotoArtifactTab,
  myAudio,
  VideoAction,
  Person,
  DatoStory,
} from '../types.ts/story';

import {
  TranscriptData,
  TranscriptElement,
  TranscriptChange,
} from '../types.ts/transcript';

import WaveformData from 'waveform-data';

// Cleanup Classes
import {
  VideoResolution,
  TranscriptionStatus,
  SubtitleStatus,
  AspectRatio,
} from '../types.ts/video';

import PunchListManager from './lib/PunchListManager';
import { TrackManager } from './lib/TrackManager';
import StatusUpdateManager from './lib/StatusUpdateManager';

import {
  getClosestNotRemovedTextIndexToLeft,
  getClosestNotRemovedTextIndexToRight,
  getClosestRemovedIndexToLeft,
  getClosestRemovedIndexToRight,
  test_injectTranscriptionElementsInTimeline,
  getClosestNotRemovedElementIndexToLeft,
  getClosestNotRemovedElementIndexToRight,
  convertFromPixels,
  mapToElementState,
  mapToSource,
} from '../videoTranscriptionProcessor/utils';
import { ApiError, SimpleSchemaTypes } from '@datocms/cma-client-browser';

import { delay } from '../utility/general';
import KaraokeProducer from '../videoTranscriptionProcessor/KaraokeProducer';
import { DEFAULT_KARAOKE_CONFIG } from '../videoTranscriptionProcessor/constants/karaokeConstants';
import { KaraokeConfig } from '../videoTranscriptionProcessor/types/karaokeTypes';

import { MAX_CANVAS_WIDTH } from '../components/timeline/WaveForm';
import CaptionService from '../services/CaptionService';
import FadeProducer from '../fadeEffectProcessor/FadeProducer';
import { TextBrandingService } from '../components/textProcessor/TextBrandingService';
import { restoreTranscriptionFromVideoSource } from '../videoTranscriptionProcessor/VideoTranscriptionRestorer';
import { TextProducer } from '../components/textProcessor/TextProducer';
import SubtitlesProcessor from '../videoTranscriptionProcessor/SubtitlesProcessor';
import { inDebugMode } from '../utility/debug';
import VideoTranscriptionProcessor from '../videoTranscriptionProcessor/VideoTranscriptionProcessor';
import { requestMissingFields } from '../utility/story';
import { cutOffLastPathSegmentFromUrl } from '../utility/url';
import { PERSON_BY_NAME_QUERY } from '../gql/misc-gql';
import { ClipFragment } from '../services/AIClipProducer';
import { getVolumeKeyPointsOf } from '../utility/volumeKeyPoints';
import { getImageWithBlackFrameElements } from '../utility/elements';

import TimelineStore from './TimelineStore';
import { analytics } from '@src/utility/analytics';
import { RootStore } from '@src/stores-v2/RootStore';
import ReframingModeManager from './lib/ReframingModeManager';
import {
  getImageNaturalDimensions,
  getVideoNaturalDimensions,
  NaturalDimensions,
} from '@src/utility/naturalDimensions';
import { DatoClientStore } from '@src/stores-v2/DatoClientStore';
import { DEFAULT_ANIMATION_SPEED } from '@src/config/imageSettings';
import CustomFontsService from '@src/services/CustomFontsService';
import detectAndApplyManualChangesToKaraokeConfig from './lib/onStateChangeHandlers/detectAndApplyManualChangesToKaraokeConfig';
import StateChangeObserver from './lib/StateChangeObserver';

export const KARAOKE_TRACK_NUMBER = 32;
const DEBUG_TRANSCRIPTION_TIMELINE = false;
const DEFAULT_PREVIEW_VIDEO_RES = VideoResolution.Low;
const MAX_UNDO_REDO_STACK_SIZE = 10;
const AUTOSAVE_INTERVAL = 2 * 60 * 1000; // 2 minutes
export const VIDEO_DEFAULT_WIDTH = 1280;
export const VIDEO_DEFAULT_HEIGHT = 720;

export type CurrentLoadedVideo = Video & { versionId?: string } & {
  editor?: SimpleSchemaTypes.User | SimpleSchemaTypes.ItemVersion['editor'];
  ai_generated_content?: Array<{
    attributes: { prompt: string; generated_content: string };
  }>;
};

class VideoCreatorStore {
  // New Context Store.  Only giving access here to simpify refactor.
  rootStore: RootStore;
  datoClientStore: DatoClientStore;

  renderer?: Renderer = undefined;

  timelineStore: TimelineStore;

  videoTranscriptionProcessor: VideoTranscriptionProcessor;
  subtitlesProcessor: SubtitlesProcessor;
  _textBrandingService: TextBrandingService | null = null;
  customFontsService: CustomFontsService | null = null;
  karaokeProducer: KaraokeProducer;
  textProducer: TextProducer;

  state?: RendererState = undefined;
  stateReady = false;

  tracks?: Map<number, ElementState[]> = undefined;

  activeElementIds: string[] = [];
  selectedTrack: number = -1;

  isInitialized = false;

  isLoading = true;
  isVersionsHistoryLoading = false;
  isError = false;
  justLoaded = false;
  isSaving = false;
  savingStockPhoto = false;
  savingError?: Error;

  isPlaying = false;
  whenPaused: number | null = null;

  time = 0;
  smootherTime = 0;
  keyPointsLastUpdateTime = 0;
  isShiftKeyDown = false;

  duration = 180;

  timelineScale = 100;
  defaultTimelineScale = 100;
  maxTimelineScale = 400;
  maxCanvasScale = 400;

  isScrubbing = false;

  // startingState?: RendererState = undefined;

  subtitleLoadingStatus: SubtitleStatus = SubtitleStatus.None;
  renderingStatus = ''; //todo video.status
  renderQueueing = false;
  renderVideoResLevel: VideoResolution | null = null;
  renderingVideoIds: string[] = [];
  // Transcription state is now managed by TranscriptionStore

  storyId?: string;
  storyName?: string = '';

  datoContext: RenderModalCtx = {} as RenderModalCtx;

  originalVideoDuration: string = '0 s';
  originalVideoAudioUrl?: string;

  // Only keeping subtitle elements here as they're not part of the TranscriptionStore yet
  subtitleElements?: TranscriptElement[];

  sidebarOptions = SidebarOption.media;
  showSubtitles = false;
  aiProducerSubMenu: AIProducerCard = AIProducerCard.clip;
  mediaSubMenu: MediaCard = MediaCard.photo;

  musicOptions?: Music['collection'] = undefined;
  musicProducerLoading: boolean = false;
  punchListGenerateCount: number = 0;
  uploadingMyAudio: boolean = false;

  selectedPhotoAssets = {
    tab: PhotoArtifactTab.story,
    resource: undefined,
    lastSelectedStock: undefined,
    lastSelectedAi: undefined,
    selectedId: undefined,
  } as PhotoAssetData;

  stockMusic: Music[] = [];
  organization?: Showcase = undefined;
  story?: Story = undefined;
  originalWaveForm: WaveformData | null = null;
  resampledOriginalWaveForm: WaveformData | null = null;
  maxOriginalWaveformSample: number = 0;
  audioTracksData: Record<
    string,
    | {
        waveform: WaveformData;
        originalTrackDuration: number;
        resampledWaveform?: WaveformData;
      }
    | 'loading'
  > = {};

  audioContext: AudioContext | null = null;
  audioContextReady = false;

  currentVideo?: CurrentLoadedVideo;
  currentVideoVersions?: VideoVersion[];
  currentVideoVersionsPage: number = 0;
  currentVideoVersionsTotalPages?: number;

  punchListManager: PunchListManager;
  punchListLoading = false;
  addedPunchListItemId: string | null = null;
  fuse: any = undefined;

  trackManager: TrackManager;
  statusUpdateManager: StatusUpdateManager;
  reframingModeManager: ReframingModeManager;
  stateChangeObserver: StateChangeObserver;

  unsavedShareableImages:
    | Omit<ShareableImageType, '_allReferencingSharedContents'>[]
    | null = null;

  contentStudioGeneratedContent?: ContentViewData;
  isPlayerFullScreen = false;

  karaokeLoading = false;

  isPlayheadDragging: boolean = false;
  tempDragTime: number | null = null;
  openPhotoElementReplacementModal: {
    element: ElementState;
    isForPunchlist?: boolean;
  } | null = null;
  replacementImages: {
    blog: Record<
      string,
      {
        value: ImageWithType[ImageKey] | null;
        isRemoved: boolean;
      }
    >;
    email: { value: ImageWithType[ImageKey] | null; isRemoved: boolean };
  } = { email: { value: null, isRemoved: false }, blog: {} };

  savedItemReplacementImages: {
    blogs: Record<
      number,
      Record<
        string,
        {
          value: ImageWithType[ImageKey] | null;
          isRemoved: boolean;
        }
      >
    >;
    emails: Record<
      number,
      { value: ImageWithType[ImageKey] | null; isRemoved: boolean }
    >;
  } = { emails: {}, blogs: {} };
  selectedBlogContent: {
    id?: string;
    type: 'generating' | 'generated' | 'saved';
    content?: string;
  } | null = null;

  toastState: {
    state?: 'success' | 'warning' | 'publishing' | 'loading' | 'error';
    message: string;
    delay?: number;
    position?: 'top' | 'bottom';
  } | null = null;
  showRefreshStoryForSocialProfile: boolean = false;
  pendingSharedContentIds: string[] = [];

  talkingPointContent: TalkingPointContent | null = null;
  cachedAssets: Set<string> = new Set();
  timelineHeight: string = '30%';
  frameLockedTracks: number[] = [];
  timelineClipboard: {
    pos: Record<'left' | 'top', number> | null;
    action: 'copy' | 'copied' | 'paste';
    copied: {
      element: ElementState;
      data: Record<'time' | 'track', number> | null;
    } | null;
    tmp: {
      element: ElementState;
    };
    secondaryActions?: 'clearVolumeKeyPoints'[];
  } | null = null;
  videoClipPreview: (VideoClip & { autoPlay?: boolean }) | null = null;
  selectedVolumeKeyPoint?: VolumeKeyPoint | null;

  videoSnapshots: Array<CurrentLoadedVideo & { label?: string }> = [];
  redoSnapshots: Array<CurrentLoadedVideo & { label?: string }> = [];

  undoStack: {
    undoCommand: () => void;
    redoCommand: () => void;
    isSaved?: boolean;
  }[] = [];
  redoStack: {
    undoCommand: () => void;
    redoCommand: () => void;
    isSaved?: boolean;
  }[] = [];

  resetUndoRedo() {
    this.undoStack = [];
    this.redoStack = [];
    this.videoTranscriptionProcessor.resetUndoRedo();
    this.videoSnapshots = [];
    this.redoSnapshots = [];
  }

  undo() {
    if (this.undoStack.length === 0) return;
    const { undoCommand, redoCommand } = this.undoStack.pop()!;
    undoCommand();
    this.redoStack.push({ undoCommand, redoCommand });
  }

  redo() {
    if (this.redoStack.length === 0) return;
    const { undoCommand, redoCommand } = this.redoStack.pop()!;
    redoCommand();
    this.undoStack.push({ undoCommand, redoCommand });
  }

  resetStateBeforeInitialising() {
    runInAction(() => {
      this.tracks = undefined;
      this.story = undefined;
      this.currentVideo = undefined;
      this.currentVideoVersions = undefined;
      this.currentVideoVersionsPage = 0;
      this.currentVideoVersionsTotalPages = undefined;
      console.log('state reset');
    });
  }

  //todo refactor
  photoDataForDato: Record<
    string,
    {
      type: 'quotes' | 'stock' | 'ai' | 'artifact';
      url: string;
      title?: string;
      alt?: string;
      cat?: string;
    } & (
      | {
          fileName: string;
        }
      | { uploadId: string }
    )
  > = {};

  // punchListData: PunchListItem[] | null = null;

  refreshStory: number = 0;
  disableAiImageGenerate = false;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {
      canRedo: computed,
      canUndo: computed,
      rootStore: false, // Mark references to other stores as non-observable
    });
    this.rootStore = rootStore;
    this.datoClientStore = this.rootStore.datoClientStore;

    this.timelineStore = new TimelineStore(this);
    this.videoTranscriptionProcessor = new VideoTranscriptionProcessor(this);
    this.karaokeProducer = new KaraokeProducer(this);
    this.subtitlesProcessor = new SubtitlesProcessor((elements) => {
      console.log('subtitle elements updated', elements);
      this.subtitleElements = elements;
      this.karaokeProducer.setSubtitleElements(elements);
    });

    this.textProducer = new TextProducer(this);
    // Set up sync between VideoTranscriptionProcessor and TranscriptionStore
    this.videoTranscriptionProcessor.onFinalTranscriptionElementsChange = (
      elements,
    ) => {
      // Update TranscriptionStore with changes from processor
      this.rootStore.transcriptionStore.updateFromProcessor(elements);
      this.rootStore.transcriptionStore.setChangesFromProcessor(
        this.videoTranscriptionProcessor.getTranscriptionChanges(),
      );

      // Update karaoke with elements
      this.karaokeProducer.setTranscriptionElements(elements);
    };

    this.punchListManager = new PunchListManager(this, this.datoClientStore);
    this.trackManager = new TrackManager(this);
    this.statusUpdateManager = new StatusUpdateManager(this);
    this.reframingModeManager = new ReframingModeManager(this);
    this.customFontsService = new CustomFontsService(this);
    this.stateChangeObserver = new StateChangeObserver([
      detectAndApplyManualChangesToKaraokeConfig,
    ]);

    this.listenToVideoClipPreviewChange();
  }

  listenToVideoClipPreviewChange() {
    reaction(
      () => this.videoClipPreview?.id,
      (newValue, _oldValue) => {
        if (newValue) {
          this.signVideoClipPreviewSource();
          this.timelineStore.setShouldCalcTimelineScale(true);
          this.loadVideo(newValue);
        }
      },
    );
  }

  private signVideoClipPreviewSource() {
    if (!this.story?.originalVideo || !this.videoClipPreview) {
      return;
    }
    for (const key_ of ['videoSource', 'videoJson']) {
      const key = key_ as 'videoSource';
      if (this.videoClipPreview[key]) {
        this.videoClipPreview[key] = this.replaceWithValidVideoSourceUrl(
          this.videoClipPreview[key],
          VideoResolution.High,
        );
      }
    }
  }

  // Lazy Initialization for stateful services
  // until we have a better way to handle this
  // App expects to to be entirely global lol
  get textBrandingService() {
    if (!this._textBrandingService)
      this._textBrandingService = new TextBrandingService(
        this,
        this.datoClientStore,
      );

    return this._textBrandingService;
  }

  fixPhotoHighlights() {
    if (!this.isVideoCreatorReady) {
      const dispose = reaction(
        () => this.isVideoCreatorReady,
        (newValue, oldValue) => {
          if (newValue && !oldValue) {
            this.resetPhotoHighlights();
            dispose();
          }
        },
      );
    } else {
      this.resetPhotoHighlights();
    }
  }

  async initializeWithStory(
    storyId: string,
    videoId?: string | null,
    showcaseSlug?: string | null,
  ) {
    if (!this.datoClientStore.storyRepository)
      throw new Error('Story repository is not initialized');
    this.resetStateBeforeInitialising();
    const story = await this.findOneStory(storyId);

    if (!story) throw new Error('Story not found. Cannot initialize data.');
    this.story = story;

    const organization = showcaseSlug
      ? story._allReferencingShowcases.find((s) => (s.slug = showcaseSlug))
      : story._allReferencingShowcases[0];
    this.organization = organization;

    this.storyId = story.id;
    this.storyName = story.title;
    this.originalVideoAudioUrl = story.transcription?.audio?.url;
    this.originalVideoDuration = `${story?.originalVideo.video?.duration} s`;
    this.isInitialized = true;

    // Check for transcription and load it using TranscriptionStore if available
    if (story.transcription?.elementsJson?.url) {
      await this.loadTranscription();
    }

    if (
      videoId &&
      this.story?.otherVideos?.find(
        (v) =>
          v.id === videoId ||
          v.associatedVideos.find((av) => av.id === videoId),
      )
    ) {
      await this.loadVideo(videoId);
    } else if (story.finalVideo?.id) {
      await this.loadVideo(story.finalVideo?.id);
    } else if (this.story?.otherVideos?.length) {
      await this.loadVideo(this.story.otherVideos[0].id!);
    } else {
      await this.createNewVideoFromSource();
    }
    await this.loadOriginalWaveformData();

    this.renderingVideoIds = story.otherVideos
      .flatMap((v) => [v, ...v.associatedVideos])
      .filter((v) => v.videoStatus === 'rendering')
      .map((v) => v.id!);
  }

  async initializeWithShowcase(showcaseSlug: string) {
    if (!this.datoClientStore.albumRepository)
      throw new Error('Album repository is not initialized');

    const showcase =
      await this.datoClientStore.albumRepository.findOneBySlug(showcaseSlug);
    if (!showcase)
      throw new Error('Showcase not found. Cannot initialize data.');
    this.organization = showcase;
    const storyWithVideo = showcase.stories.find((s) => s.originalVideo);
    if (storyWithVideo) {
      await this.initializeWithStory(storyWithVideo.id, null, showcaseSlug);
    }
  }

  async initializeData(data: {
    storyId?: string | null;
    videoId?: string | null;
    showcaseSlug?: string | null;
  }) {
    const { storyId, videoId, showcaseSlug } = data;

    if (!storyId && !showcaseSlug)
      throw new Error('Story id or showcase id are required');

    if (storyId) {
      await this.initializeWithStory(storyId, videoId, showcaseSlug);
    } else if (showcaseSlug) {
      await this.initializeWithShowcase(showcaseSlug);
    }
  }

  async setCurrentVersionVideo(versionId: string) {
    const videoVersion = this.currentVideoVersions?.find(
      (videoVersion) => videoVersion.versionId === versionId,
    );
    if (videoVersion) {
      await this.renderVideo(videoVersion, false, DEFAULT_PREVIEW_VIDEO_RES);
      this.textBrandingService.loadDefaultTemplateOnVideoLoad();
      this.resetUndoRedo();
    }
  }

  async restoreVideoVersion(videoVersionId: string) {
    const itemVersions =
      await this.datoClientStore.datoClient?.itemVersions.restore(
        videoVersionId,
      );
    this.setCurrentVersionVideo(
      (
        itemVersions?.[0] as unknown as Video & {
          versionId: string;
        }
      ).versionId,
    );
  }

  async cacheVideoSource(sourceUrl: string) {
    if (!this.renderer) {
      throw new Error('Renderer is not initialized');
    }

    try {
      console.log('Downloading media', sourceUrl);
      let blob = await fetch(sourceUrl).then((r) => r.blob());
      console.log('Media downloaded', sourceUrl);
      if (this.renderer) {
        await this.renderer.cacheAsset(sourceUrl, blob);
        console.log('Video source cached', sourceUrl);
      } else {
        console.warn('Renderer was disposed');
      }
    } catch (err) {
      console.error('Error caching video source', err);
    }
  }

  async loadCurrentVideoVersionsHistory(page: number = 0) {
    return this.loadVersionsHistory(this.currentVideo!.id!, page);
  }

  async loadVersionsHistory(videoId: string, page: number = 0) {
    if (!this.datoClientStore.videoRepository) {
      throw new Error('Video repository is not initialized');
    }
    try {
      this.isVersionsHistoryLoading = true;
      const pageSize = 5;
      const { videoVersions, totalCount } =
        await this.datoClientStore.videoRepository!.getVersionsByVideoId(
          videoId,
          pageSize,
          page,
          this.rootStore.userIdentityStore.isExternalUser(),
        );
      runInAction(() => {
        if (videoVersions.length > 0) {
          this.currentVideoVersions = videoVersions;
          this.currentVideoVersionsPage = page;
          const currentVideoVersion = this.currentVideoVersions!.find(
            (videoVersion) => videoVersion.meta.is_current,
          )!;
          if (this.currentVideo && currentVideoVersion) {
            this.currentVideo!.versionId = currentVideoVersion.versionId;
          }
          this.currentVideoVersionsTotalPages = Math.ceil(
            totalCount / pageSize,
          );
        }
      });
    } catch (err) {
      throw err;
    } finally {
      this.isVersionsHistoryLoading = false;
    }
  }

  async loadVideo(videoId: string, resetTimeline = true) {
    if (!this.datoClientStore.videoRepository) {
      throw new Error('Video repository is not initialized');
    }

    this.isLoading = true;
    this.isError = false;
    // await until queued and saved
    while (this.renderQueueing || this.isSaving) {
      await delay(500);
    }
    this.renderVideoResLevel = null;
    try {
      this.currentVideoVersionsTotalPages = undefined;
      await this.loadVersionsHistory(videoId);
      const currentVideoVersion = this.currentVideoVersions!.find(
        (videoVersion) => videoVersion.meta.is_current,
      )!; //todo handle error

      await this.renderVideo(
        currentVideoVersion,
        resetTimeline,
        DEFAULT_PREVIEW_VIDEO_RES,
      );
      this.textBrandingService.loadDefaultTemplateOnVideoLoad();
      this.resetUndoRedo();
      console.log('Video loaded');
    } catch (err) {
      console.log('error loading video', err);
      console.log('loadVideo: Set is Loading FALSE');
      this.isLoading = false;
      this.isError = true;
    } finally {
      // this.isLoading = false;
    }
  }

  async loadVideoWithoutRendering(videoId: string, loadElements = false) {
    await this.reframingModeManager.exitReframingMode();
    const { videoVersions, totalCount } =
      await this.datoClientStore.videoRepository?.getVersionsByVideoId(
        videoId,
        1,
      );
    this.currentVideoVersions = videoVersions;

    const currentVideoVersion = this.currentVideoVersions?.find(
      (videoVersion) => videoVersion.meta.is_current,
    )!;

    if (!currentVideoVersion.videoSource.height) {
      const height =
        this.story?.finalVideo?.videoFilePrimary?.height ||
        this.story?.originalVideo?.height;
      currentVideoVersion.videoSource.height = height;
    }
    this.currentVideo = currentVideoVersion;
    this.assignValidVideoSourceUrlsAndOptimize(DEFAULT_PREVIEW_VIDEO_RES);
    if (loadElements) {
      await this.loadVideoElementsWithoutRendering(this.currentVideo);
    }
  }

  abortIfNotReady() {
    if (!this.isVideoCreatorReady) {
      throw new Error('Video creator is not ready');
    }
  }

  async loadVideoWithAspectRatio(aspectRatio: Video['aspectRatio']) {
    this.abortIfNotReady();
    const parentVideo = this.currentVideo!.parentVideo || this.currentVideo;
    const currentAspectRatio = this.currentVideo!.aspectRatio;
    const associatedVideo =
      parentVideo?.aspectRatio === aspectRatio
        ? parentVideo
        : parentVideo!.associatedVideos.find(
            (video) => video.aspectRatio === aspectRatio,
          );

    const activeElement = this.getActiveElement();
    await this.reframingModeManager.exitReframingMode();
    const restoreReframingMode = async () => {
      if (activeElement) {
        await this.setActiveElements(activeElement.source.id);
      }
    };

    if (associatedVideo) {
      await this.loadVideo(associatedVideo.id!);
      await restoreReframingMode();
      return;
    }

    // todo copy parent
    this.isLoading = true;
    await this.copyCurrentVideo(this.currentVideo!.title);
    // parentVideo!.associatedVideos.push(this.currentVideo!);
    this.currentVideo!.aspectRatio = aspectRatio;
    this.currentVideo!.parentVideo = parentVideo;
    await this.adjustVideoSourceToAspectRatio(aspectRatio);
    await this.adjustLogoElements(currentAspectRatio, aspectRatio);
    this.assignValidVideoSourceUrlsAndOptimize(DEFAULT_PREVIEW_VIDEO_RES);
    await this.renderer?.setSource(this.currentVideo!.videoSource);
    this.textBrandingService.loadDefaultTemplateOnVideoLoad();
    await this.adjustTextDimensions(aspectRatio);
    await restoreReframingMode();
  }

  private async adjustLogoElements(
    currentAspectRatio: AspectRatio,
    nextAspectRatio: AspectRatio,
  ) {
    function aspectRatioDimension(aspectRatio: AspectRatio) {
      if (aspectRatio === AspectRatio.AR_16_9) {
        return {
          x: 94,
          y: 4,
          width: 12,
        };
      } else if (aspectRatio === AspectRatio.AR_9_16) {
        return {
          x: 92,
          y: 5,
          width: 25,
        };
      }
      return {
        x: 95,
        y: 5,
        width: 19,
      };
    }

    const logoElementIndices = this.currentVideo!.videoSource.elements.reduce(
      (acc: number[], el: Record<string, any>, index: number) => {
        if (this.isLogoElement({ source: el } as ElementState)) {
          // include logo elements that weren't changed
          // see src/components/sidepanel/ArtifactsAndAssets.tsx:167
          return acc.concat(index);
        }
        return acc;
      },
      [],
    );

    for (const index of logoElementIndices) {
      const logoEl = this.currentVideo!.videoSource.elements[index];

      let sourceAspectRatio = 1;
      for (const album of this?.story?._allReferencingShowcases || []) {
        const logo = (album.organizationLogos || []).find(
          (a) => a.responsiveImage?.src === logoEl.source,
        );
        if (logo?.width && logo?.height) {
          sourceAspectRatio = parseFloat(logo.width) / parseFloat(logo.height);
          break;
        }
      }
      const objectData = aspectRatioDimension(currentAspectRatio);

      function calculatePosX(defaultValue: number, width: number) {
        const posX = parseFloat(logoEl.x) - parseFloat(logoEl.width);
        const pos = parseFloat(logoEl.x);
        if (pos === objectData.x) return `${defaultValue}%`;

        if (pos >= 50) return `${(defaultValue / objectData.x) * pos}%`;
        return `${Math.max(
          (defaultValue / objectData.x) * pos,
          width + posX * (defaultValue / objectData.x),
        )}%`;
      }

      function calculatePosY(defaultValue: number) {
        const value =
          ((100 - defaultValue) / (100 - objectData.y)) * parseFloat(logoEl.y);
        return `${value}%`;
      }

      if (nextAspectRatio === AspectRatio.AR_16_9) {
        const width = (12 / objectData.width) * parseFloat(logoEl.width);

        logoEl.x = calculatePosX(94, width);
        logoEl.y = calculatePosY(4);
        logoEl.width = `${width}%`;
        logoEl.height = `${(width / sourceAspectRatio) * (16 / 9)}%`;
      } else if (nextAspectRatio === AspectRatio.AR_9_16) {
        const width = (25 / objectData.width) * parseFloat(logoEl.width);

        logoEl.x = calculatePosX(92, width);
        logoEl.y = calculatePosY(5);
        logoEl.width = `${width}%`;
        logoEl.height = `${(width / sourceAspectRatio) * (9 / 16)}%`;
      } else if (nextAspectRatio === AspectRatio.AR_1_1) {
        const width = (19 / objectData.width) * parseFloat(logoEl.width);

        logoEl.x = calculatePosX(95, width);
        logoEl.y = calculatePosY(5);
        logoEl.width = `${width}%`;
        logoEl.height = `${width / sourceAspectRatio}%`;
      }
    }
  }

  async copyVideo(
    videoId: string,
    newTitle: string,
    sourcePlatform: Video['sourcePlatform'],
  ) {
    try {
      const lastActionJson = JSON.stringify({
        editor: this.rootStore.userIdentityStore.currentEditor,
        date: new Date().getTime(),
        type: 'save',
      });
      const newVideoId =
        await this.datoClientStore.videoRepository!.copyParentAndAssociatedVideos(
          videoId,
          newTitle,
          sourcePlatform,
          lastActionJson,
        );
      await this.loadVideo(newVideoId);
      await this.addCurrentToStoryVideos();
      this.currentVideoVersionsTotalPages = undefined;
      await this.loadCurrentVideoVersionsHistory(0);
    } catch (err) {
      console.error('Error copying video', err);
    }
  }

  async createNewVideoFromSource(
    newTitle?: string,
    newSource?: any,
    transcriptionChanges?: TranscriptChange[],
    transcriptionSnapshot?: Pick<TranscriptData, 'elements'>,
    extraElementData?: Video['extraElementData'],
    sourcePlatform: 'creator-studio' | 'content-studio' = 'creator-studio',
  ) {
    // console.log('creating new video');
    const video: Video = {
      title: newTitle || this.story!.title,
      videoSource:
        newSource ||
        this.getDefaultSource({ videoRes: DEFAULT_PREVIEW_VIDEO_RES }),
      videoStatus: 'editing',
      extraElementData: extraElementData || {},
      transcriptionChanges: transcriptionChanges || [],
      transcriptionSnapshot: transcriptionSnapshot,
      associatedVideos: [],
      aspectRatio: AspectRatio.AR_16_9,
      sourcePlatform,
    };
    this.currentVideoVersions = [];
    await this.renderVideo(video, true, DEFAULT_PREVIEW_VIDEO_RES);
    this.resetUndoRedo();
  }

  createNewVideoFromSourceForContentClip(
    originalVideo: Pick<
      Video,
      | 'title'
      | 'videoSource'
      | 'extraElementData'
      | 'transcriptionChanges'
      | 'transcriptionSnapshot'
    >,
    clip: ClipFragment,
  ) {
    const newTitle = clip.title || `${originalVideo.title} - ${clip.theme}`;

    const source =
      deepClone(originalVideo.videoSource) || this.getDefaultSource();
    let elements = source.elements;
    if (elements.every((e: any) => e.type !== 'video')) {
      elements = elements.concat({
        ...this.getDefaultSource()?.elements[0],
        duration: clip.duration,
        trim_start: clip.startTime,
      });
    }

    const video = {
      title: newTitle || this.story!.title,
      videoSource: {
        ...source,
        // duration: clip.duration,
        duration: undefined,
        elements,
      },
      videoStatus: 'editing',
      extraElementData: originalVideo.extraElementData,
      transcriptionChanges: originalVideo.transcriptionChanges,
      transcriptionSnapshot: originalVideo.transcriptionSnapshot,
      associatedVideos: [],
      aspectRatio: AspectRatio.AR_16_9,
      sourcePlatform: 'content-studio',
    } as CurrentLoadedVideo;
    this.currentVideo = video;
    this.loadVideoElementsWithoutRendering(video);
  }

  async createNewVideoForContentClip(
    originalVideo: Pick<
      Video,
      | 'title'
      | 'videoSource'
      | 'extraElementData'
      | 'transcriptionChanges'
      | 'transcriptionSnapshot'
    >,
    clip: ClipFragment,
  ) {
    this.createNewVideoFromSourceForContentClip(originalVideo, clip);

    await this.commitChangeWithoutRenderer(async (noRendererOutput) => {
      await this.videoTranscriptionProcessor.cropVideoToKeepTextElements(
        clip.transcriptPosition.startIndex,
        clip.transcriptPosition.endIndex,
        noRendererOutput,
      );
    }, clip.duration);
  }

  async createNewVideoForContentClipsReel(
    originalVideo: Pick<
      Video,
      | 'title'
      | 'videoSource'
      | 'extraElementData'
      | 'transcriptionChanges'
      | 'transcriptionSnapshot'
    >,
    combinedClip: ClipFragment,
    clipRanges: { fromElement: number; toElement: number }[],
  ) {
    this.createNewVideoFromSourceForContentClip(originalVideo, combinedClip);

    await this.commitChangeWithoutRenderer(async (noRendererOutput) => {
      await this.videoTranscriptionProcessor.cropVideoToKeepTextElementsInMultiplePlaces(
        clipRanges,
        noRendererOutput,
      );
    }, combinedClip.duration);
  }

  private async commitChangeWithoutRenderer(
    change: (noRendererOutput: {
      duration: number;
      source: Record<string, any>;
    }) => Promise<void>,
    duration: number,
  ) {
    const videoSourceCopy = deepClone(toJS(this.currentVideo!.videoSource));
    const noRendererOutput = {
      duration,
      source: {
        ...videoSourceCopy,
        elements: videoSourceCopy.elements.map(mapToElementState),
      },
    };

    await change(noRendererOutput);

    const sourceNew = {
      ...noRendererOutput.source,
      ...mapToSource({ elements: noRendererOutput.source.elements }),
    };
    this.currentVideo!.videoSource = sourceNew;
    this.createUndoPointForTranscription();
  }

  async copyCurrentVideo(newTitle: string) {
    // console.log('copying video');
    const video = this.getSafeCurrentVideoCopy();
    delete video.id;
    video.title = newTitle;
    this.currentVideoVersions = [];
    await this.renderVideo(video, true, DEFAULT_PREVIEW_VIDEO_RES);
    this.resetUndoRedo();
  }

  private getSafeCurrentVideoCopy(): CurrentLoadedVideo {
    const copy: CurrentLoadedVideo = toJS(this.currentVideo!);
    // This fixes recursion error thrown from within mobx
    if (copy.parentVideo) {
      copy.parentVideo.associatedVideos = copy.parentVideo.associatedVideos.map(
        (v) => ({
          ...v,
          parentVideo: undefined,
        }),
      );
    }
    return copy;
  }

  async renameVideo(videoId: string, newTitle: string, withRenderer = true) {
    console.log('renaming video', videoId);

    if (videoId === 'original_story') {
      await this.updateStory({
        id: this.story!.id,
        title: newTitle,
      });
      this.story!.title = newTitle;
      return;
    }

    if (videoId && this.currentVideo?.id === videoId) {
      this.currentVideo!.title = newTitle;
      await this.saveCurrentAndParentVideos(withRenderer);
    } else if (videoId) {
      await this.datoClientStore.videoRepository?.updateVideo({
        id: videoId,
        title: newTitle,
      });
      if (this.currentVideo?.parentVideo?.id === videoId) {
        this.currentVideo!.parentVideo!.title = newTitle;
      }
    } else if (this.currentVideo?.id === videoId) {
      this.currentVideo!.title = newTitle;
      await this.saveStoryAndVideo(false, withRenderer);
    }

    const updatedVideos = (this.story?.otherVideos?.map((v) => {
      if (videoId === v.id) return { ...v, title: newTitle };
      return v;
    }) || []) as Video[];
    this.story!.otherVideos = updatedVideos;

    // this.resetUndoRedo();
  }

  async hideVideo(videoId?: string) {
    if (videoId === 'original_story' || !videoId) {
      return;
    }
    await this.updateVideoField('isHidden', true, videoId);
  }

  async updateIsClientReady(isClientReady: boolean, videoId?: string) {
    if (videoId === 'original_story' || !videoId) {
      return;
    }
    await this.updateVideoField('isClientReady', isClientReady, videoId);
  }

  private async updateVideoField<K extends keyof AssociatedVideo>(
    key: K,
    value: Video[K],
    videoId: string,
  ) {
    try {
      if (this.currentVideo?.id === videoId) {
        this.currentVideo![key] = value;
        await this.saveCurrentAndParentVideos(false, false);
      } else {
        await this.datoClientStore.videoRepository?.updateVideo({
          id: videoId,
          [key]: value,
        });
        if (this.currentVideo?.parentVideo?.id === videoId) {
          this.currentVideo!.parentVideo![key] = value;
        }
      }
    } catch (err) {
      console.error(
        `Failed updating video ${videoId} field [${key}]: ${value}`,
        err,
      );
      return;
    }

    const updatedVideos = (this.story?.otherVideos?.map((v) => {
      if (videoId === v.id) return { ...v, [key]: value };
      return v;
    }) || []) as Video[];
    this.story!.otherVideos = updatedVideos;
  }

  private lockVideoElement() {
    // //debugger;
    const videoElement = this.currentVideo?.videoSource.elements.find(
      (el: any) => el.type === 'video',
    );
    if (videoElement) {
      console.log('locking video element');
      videoElement.locked = true;
    }
  }

  private unlockVideoElement() {
    // //debugger;
    console.log('this.video', this.currentVideo);
    const videoElement = this.currentVideo?.videoSource?.elements?.find(
      (el: any) => el.type === 'video',
    );
    if (videoElement) {
      console.log('unlocking video element');
      videoElement.locked = false;
    }
  }

  private updateCurrentRenderingStatus() {
    this.renderingStatus = this.currentVideo?.videoStatus || 'none';
  }

  isOriginalVideoElement(elementSource: any) {
    if (elementSource.type !== 'video') return false;
    // TODO: replace this.story?.selfHostedVideo with this.story?.originalVideo
    // and remove datoHostedVideo when migration is done
    const selfHostedVideo = this.story?.selfHostedVideo;
    const datoHostedVideo = this.story?.datoHostedVideo;
    // Check for mux, dato or cloudfront urls
    if (
      selfHostedVideo?.url &&
      elementSource.source.startsWith(
        cutOffLastPathSegmentFromUrl(selfHostedVideo.url),
      )
    )
      return true;

    if (datoHostedVideo && elementSource.source === datoHostedVideo.url)
      return true;
    if (
      datoHostedVideo?.video?.mp4Url &&
      elementSource.source.startsWith(
        cutOffLastPathSegmentFromUrl(datoHostedVideo.video.mp4Url),
      )
    )
      return true;
    return false;
  }

  private assignValidVideoSourceUrlsAndOptimize(resLevel: VideoResolution) {
    if (!this.currentVideo || !this.story) {
      throw new Error(
        'Current video or story is not initialized to optimize video source',
      );
    }
    this.replaceWithValidVideoSourceUrl(
      this.currentVideo.videoSource,
      resLevel,
    );
  }

  private getDimensionsForResLevel(
    currentDimensions: { width: number; height: number },
    resLevel: VideoResolution,
  ) {
    const originalVideoHeight =
      this.story!.originalVideo.height || VIDEO_DEFAULT_HEIGHT;
    const { width, height } = this.story!.originalVideo; //currentDimensions;
    let newWidth, newHeight, scaleFactor;
    if (resLevel === VideoResolution.High) {
      scaleFactor = Math.max(1, originalVideoHeight / 720);
    } else if (resLevel === VideoResolution.Low) {
      scaleFactor = Math.max(1, originalVideoHeight / 270);
    } else if (resLevel === VideoResolution.Medium) {
      scaleFactor = Math.max(1, originalVideoHeight / 480);
    } else {
      scaleFactor = 1;
    }
    newWidth = Math.ceil(width / scaleFactor);
    newHeight = Math.ceil(height / scaleFactor);
    return { width: newWidth, height: newHeight };
  }

  private getValidVideoSourceUrlForResLevel(resLevel: VideoResolution) {
    if (!this.story) {
      throw new Error(
        'Current video or story is not initialized to optimize video source',
      );
    }
    let sourceVideoUrl;

    if (resLevel === VideoResolution.High) {
      sourceVideoUrl = this.story.originalVideo.video.mp4UrlHigh;
    } else if (resLevel === VideoResolution.Low) {
      sourceVideoUrl = this.story.originalVideo.video.mp4UrlLow;
    } else if (resLevel === VideoResolution.Medium) {
      sourceVideoUrl = this.story.originalVideo.video.mp4UrlMedium;
    }

    if (!sourceVideoUrl) {
      console.warn('No video source found for optimization, using original');
      sourceVideoUrl = this.story.originalVideo.url;
    }
    return sourceVideoUrl;
  }

  private replaceWithValidVideoSourceUrl(
    source: any,
    resLevel: VideoResolution,
  ) {
    const videoSourceUrl = this.getValidVideoSourceUrlForResLevel(resLevel);
    source.elements.forEach((element: any) => {
      if (this.isOriginalVideoElement(element)) {
        element.source = videoSourceUrl;
      }
    });
    return source;
  }

  private async renderVideo(
    video: CurrentLoadedVideo,
    resetTimeline = true,
    resLevel: VideoResolution = VideoResolution.Medium,
  ) {
    await this.reframingModeManager.exitReframingMode();
    this.currentVideo = video;
    console.log('load video', video);
    this.updateCurrentRenderingStatus();
    // this.lockVideoElement();
    this.unlockVideoElement();

    this.punchListManager.punchListItems = this.currentVideo!.punchList || [];
    this.loadVideoElementsWithoutRendering(video);

    // render video
    this.assignValidVideoSourceUrlsAndOptimize(resLevel);
    const videoSourceUrl = this.getValidVideoSourceUrlForResLevel(resLevel);

    if (this.renderer?.ready && this.currentVideo) {
      if (resetTimeline) {
        this.stateReady = false;
        await this.setTime(0, true);
      }
      if (!this.cachedAssets.has(videoSourceUrl)) {
        await this.cacheVideoSource(videoSourceUrl);
        this.cachedAssets.add(videoSourceUrl);
      }
      await this.renderer?.setSource(this.currentVideo.videoSource);

      const fadeProducer = new FadeProducer(this);
      await fadeProducer.tidyOriginalVideoOverlay();
    }
  }

  private async loadVideoElementsWithoutRendering(video: CurrentLoadedVideo) {
    // Apply transcription changes from the video to the processor
    if (video.transcriptionSnapshot?.elements) {
      console.log(
        'loadVideoElementsWithoutRendering: setting transcription snapshot',
      );
      this.videoTranscriptionProcessor.setTranscriptionSnapshot(
        toJS(video.transcriptionSnapshot.elements),
      );
      if (video.transcriptionChanges?.length) {
        console.log(
          'loadVideoElementsWithoutRendering: apply changes to snapshot',
        );
        this.videoTranscriptionProcessor.applyChangesToCurrentTranscription(
          toJS(video.transcriptionChanges) || [],
        );
        this.fixPhotoHighlights();
      }
    } else if (this.rootStore.transcriptionStore.getOriginalTranscription()) {
      console.log(
        'loadVideoElementsWithoutRendering: apply changes to original',
      );
      this.videoTranscriptionProcessor.applyChangesToOriginalTranscription(
        toJS(this.currentVideo!.transcriptionChanges) || [],
      );
      this.fixPhotoHighlights();
    }

    // Set up subtitles and karaoke
    this.subtitlesProcessor.setSubtitleElements(
      this.getVideoSubtitleElements(),
    );
    this.videoTranscriptionProcessor.setOriginalSource(video.videoSource);
    this.karaokeProducer.setKaraokeClipsFromSource(video.videoSource);

    const defaultKaraokeConfig = {
      ...DEFAULT_KARAOKE_CONFIG,
      ...this.karaokeProducer.getKaraokeTextSettingByAspectRatio(),
    };

    const config = (video.extraElementData.karaokeConfig ??
      defaultKaraokeConfig) as KaraokeConfig;

    this.karaokeProducer.setConfig(config);
  }

  async saveCurrentStory() {
    if (!this.story) return;
    if (!this.datoClientStore.storyRepository) {
      throw new Error('Story repository is not initialized');
    }
    try {
      const storyDTO =
        await this.punchListManager.attachPunchListPhotosToStory();
      await this.updateStory(storyDTO);
      this.photoDataForDato = {};
    } catch (err) {
      console.error('error saving story', err);
    }
  }

  async saveStoryAndVideo(
    asFinal: boolean = false,
    withRenderer = true,
    resetTimeline = true,
    autoSave = false,
  ) {
    await this.saveCurrentAndParentVideos(
      withRenderer,
      resetTimeline,
      autoSave,
    );
    if (asFinal) {
      this.story!.finalVideo =
        this.currentVideo?.parentVideo ?? this.currentVideo;
    }
    await this.saveCurrentStory();
  }

  prepareCurrentVideoForSave = (withRenderer = true) => {
    if (!this.currentVideo) return;
    if (withRenderer) {
      const videoSource = this.renderer!.getSource();
      if (!videoSource.elements) {
        throw { humanMessage: 'Video is not yet loaded' };
      }
      this.currentVideo.videoSource =
        this.reframingModeManager.restoreVideoSourceForExit(videoSource);
      if (this.currentVideo.clipJson?.duration !== this.duration) {
        this.currentVideo.clipJson = {
          ...(this.currentVideo.clipJson || {}),
          duration: this.duration,
        };
        for (const storyVideo of this.story?.otherVideos || []) {
          if (storyVideo.id === this.currentVideo.id) {
            storyVideo.clipJson = {
              ...(storyVideo.clipJson || {}),
              duration: this.duration,
            };
          }
        }
      }
    }
    if (this.story?.byExternalUser) {
      this.currentVideo.isClientReady = true;
    }

    this.currentVideo.extraElementData.karaokeConfig =
      this.reframingModeManager.restoreKaraokeConfigForExit(
        this.karaokeProducer.getKaraokeConfig(),
      );

    // Get transcription changes from our store
    this.currentVideo.transcriptionChanges =
      this.rootStore.transcriptionStore.getTranscriptionChanges();

    const extraElementData = this.currentVideo.extraElementData || {};
    for (let element of Object.values(extraElementData)) {
      delete (element as ExtraElementData)?.punchListData;
    }

    this.currentVideo.punchList?.forEach((punchListItem) => {
      extraElementData[punchListItem.id!] = {
        ...(extraElementData[punchListItem.id!] || {}),
        punchListData: punchListItem,
      };
    });
    this.currentVideo.extraElementData = extraElementData;
  };

  async saveCurrentAndParentVideos(
    withRenderer = true,
    resetTimeline = true,
    autoSave = false,
  ) {
    console.log('save video', this.currentVideo);
    // Early returns for exceptional cases
    if (
      !this.currentVideo ||
      !this.story ||
      !this.datoClientStore.videoRepository ||
      !this.datoClientStore.storyRepository
    ) {
      console.error('Invalid input or repositories not initialized');
      return;
    }

    this.isSaving = true;
    this.savingError = undefined;
    let videoData: { id: string; slug: string; hash: string };

    try {
      // Update current video properties
      this.prepareCurrentVideoForSave(withRenderer);
      this.addVideoAction({
        editor: this.rootStore.userIdentityStore.currentEditor,
        date: new Date().getTime(),
        type: autoSave ? 'autosave' : 'save',
      });
      // Save or update current video
      videoData = await this.datoClientStore.videoRepository.saveOrUpdateVideo({
        ...this.currentVideo,
        transcriptionText:
          this.rootStore.transcriptionStore.fullTranscriptionText,
        transcriptionSnapshot: {
          elements:
            this.rootStore.transcriptionStore.getFinalTranscriptionElements(),
        },
        subtitles: this.subtitlesProcessor.getSubtitleLines(),
      });
      this.currentVideo._publishedAt = new Date().toISOString();

      if (!this.currentVideo.id) {
        this.currentVideo.id = videoData.id;
        // Handle parent video or story update (TODO move to saveStoryAndVideo ?)
        if (this.currentVideo.parentVideo) {
          await this.addCurrentToParentVideos();
        } else {
          await this.addCurrentToStoryVideos();
        }
      }
      this.currentVideo.slug = videoData.slug;
      this.currentVideo.hash = videoData.hash;

      this.currentVideoVersionsTotalPages = undefined;
      await this.loadCurrentVideoVersionsHistory(0);
    } catch (error) {
      console.error('Error saving video', error);
      this.savingError = error as ApiError;
    } finally {
      this.isSaving = false;
    }

    // Load saved video if available
    // if (savedVideoId && withRenderer && !this.renderQueueing) {
    //   this.currentVideo.id = savedVideoId;
    //   // await this.loadVideo(savedVideoId, resetTimeline);
    // }
  }

  // Helper methods for saveCurrentAndParentVideos
  async addCurrentToParentVideos() {
    this.currentVideo!.parentVideo!.associatedVideos.push(this.currentVideo!);
    const videoData =
      await this.datoClientStore.videoRepository!.saveOrUpdateVideo(
        this.currentVideo!.parentVideo!,
      );
    this.currentVideo!.parentVideo!.id = videoData.id;
    const parentStoryVideo = this.story!.otherVideos.find(
      (v) => v.id === this.currentVideo!.parentVideo!.id,
    );
    if (parentStoryVideo) {
      parentStoryVideo.associatedVideos.push(this.currentVideo!);
    }
  }

  // Helper methods for saveCurrentAndParentVideos
  async addCurrentToStoryVideos() {
    if (!this.currentVideo?.id) {
      throw new Error('Current video id is not set');
    }
    await this.datoClientStore.storyRepository!.addVideoToStory(
      this.story!.id,
      this.currentVideo!.id!,
    );
    this.story!.otherVideos = [this.currentVideo!, ...this.story!.otherVideos];
  }

  async removeStoryVideo(videoId?: string) {
    if (videoId) {
      await this.datoClientStore.storyRepository!.removeVideoFromStory(
        this.story!.id,
        videoId,
      );
    }
    // remove video from list of other videos
    this.story!.otherVideos = this.story!.otherVideos.filter(
      (v) => v.id !== videoId,
    );
  }

  async loadOriginalWaveformData() {
    if (!this.story?.transcription?.waveformData) return;
    try {
      let waveformData;

      if (this.story.useAws) {
        waveformData =
          await this.datoClientStore.transcriptRepository.fetchWaveformDataFromUrl(
            this.story!.transcription!.waveformData!.url,
          );
      } else {
        waveformData =
          await this.datoClientStore.transcriptRepository.fetchWaveformData(
            this.story!.transcription!.waveformData!.id,
          );
      }

      this.originalWaveForm = WaveformData.create(waveformData);
      this.maxTimelineScale = Math.min(
        this.maxTimelineScale,
        Math.floor(
          this.originalWaveForm.sample_rate / this.originalWaveForm.scale,
        ),
      );

      this.timelineScale = Math.min(this.timelineScale, this.maxTimelineScale);

      let maxSample = 0;
      const max_array = this.originalWaveForm.channel(0).max_array();
      for (let i = 0; i < max_array.length; i++) {
        if (max_array[i] > maxSample) {
          maxSample = max_array[i];
        }
      }
      this.maxOriginalWaveformSample = maxSample;
    } catch (e) {
      console.log('loadOriginalWaveformData error', e);
      throw e;
    }
  }

  async loadTranscription() {
    if (!this.story?.id) {
      throw new Error('Story ID is not set');
    }

    try {
      // Delegate to transcriptionStore for loading
      await this.rootStore.transcriptionStore.loadTranscription(
        this.story.id,
        this.story.useAws,
      );

      // Get transcription data from store and update processor
      const transcriptionElements =
        this.rootStore.transcriptionStore.getFinalTranscriptionElements();
      this.videoTranscriptionProcessor.setTranscriptionElements(
        structuredClone(toJS(transcriptionElements)),
      );
      console.log('transcription loaded');

      // Apply changes if needed
      if (
        this.currentVideo &&
        !this.currentVideo.transcriptionSnapshot?.elements
      ) {
        console.log('loadTranscription: applying transcription changes');
        this.videoTranscriptionProcessor.applyChangesToOriginalTranscription(
          toJS(this.currentVideo.transcriptionChanges || []) || [],
        );
        this.fixPhotoHighlights();
      }
    } catch (e) {
      console.log('loadTranscription error', e);
      throw e;
    }
  }

  async requestSubtitlesForCurrentVideo() {
    if (!this.currentVideo) {
      throw new Error('Current video not initialized');
    }

    // Get transcription elements from the store
    const transcriptionElements =
      this.rootStore.transcriptionStore.getFinalTranscriptionElements();
    if (!transcriptionElements || transcriptionElements.length === 0) {
      throw new Error('Transcription elements not initialized');
    }

    // Get language from transcription store
    const originalTranscription =
      this.rootStore.transcriptionStore.getOriginalTranscription();
    const transcriptionLanguage = originalTranscription?.language || 'en';
    try {
      this.subtitleLoadingStatus = SubtitleStatus.None;
      const subtitles =
        await this.datoClientStore.transcriptRepository.generateSubtitles(
          transcriptionElements.filter(
            (el) =>
              el.state !== 'removed' &&
              el.state !== 'cut' &&
              el.state !== 'muted',
          ),
          transcriptionLanguage,
        );

      if (subtitles) {
        this.currentVideo.subtitles = subtitles;
        this.subtitlesProcessor.setSubtitleElements(
          this.getVideoSubtitleElements(),
        );
        this.subtitleLoadingStatus = SubtitleStatus.Loaded;
      }
      return subtitles;
    } catch (err) {
      console.log('error requesting subtitles', err);
      this.subtitleLoadingStatus = SubtitleStatus.Failed;
      return null;
    }
  }

  initializeFuse(transcriptElements: TranscriptElement[]) {
    // find start and stop index of every sentence in the elements array
    let sentences: any = [];
    let sentenceStart = 0;
    let sentenceEnd = 0;
    let sentenceText = '';
    let sentenceStartTime = 0;
    let sentenceEndTime = 0;
    let sentenceDuration = 0;
    transcriptElements.forEach((el: any, index: number) => {
      sentenceText += el.value || '';

      if (el.type === 'text') {
        sentenceEndTime = el.end_ts;
        sentenceDuration = sentenceEndTime - sentenceStartTime;
      }
      if (el.value === '.' || el.value === '?' || el.value === '!') {
        sentenceEnd = index;
        sentences.push({
          text: sentenceText,
          startIndex: sentenceStart,
          endIndex: sentenceEnd,
          startTime: sentenceStartTime,
          endTime: sentenceEndTime,
          duration: sentenceDuration,
        });
        sentenceText = '';
        sentenceStart = index + 1;
        sentenceStartTime = el.end_ts;
      }
    });

    this.fuse = new Fuse(sentences, {
      includeScore: true,
      keys: ['text'],
    });
  }

  getSentences(transcriptElements: TranscriptElement[]) {
    // find start and stop index of every sentence in the elements array
    let sentences: any = [];
    let sentenceStart = 0;
    let sentenceEnd = 0;
    let sentenceText = '';
    let sentenceStartTime = 0;
    let sentenceEndTime = 0;
    let sentenceDuration = 0;
    transcriptElements.forEach((el: any, index: number) => {
      sentenceText += el.value || '';

      if (el.type === 'text') {
        sentenceEndTime = el.end_ts;
        sentenceDuration = sentenceEndTime - sentenceStartTime;
      }
      if (el.value === '.' || el.value === '?' || el.value === '!') {
        sentenceEnd = index;
        sentences.push({
          text: sentenceText,
          startIndex: sentenceStart,
          endIndex: sentenceEnd,
          startTime: sentenceStartTime,
          endTime: sentenceEndTime,
          duration: sentenceDuration,
        });
        sentenceText = '';
        sentenceStart = index + 1;
        sentenceStartTime = el.end_ts;
      }
    });

    return sentences;
  }

  disposeRenderer() {
    if (this.renderer) {
      console.log('dispose renderer');
      this.reframingModeManager.reset();
      this.renderer.dispose();
      this.renderer = undefined;
    }
  }

  async initializeVideoPlayer(
    htmlElement: HTMLDivElement,
    mode: 'interactive' | 'player' = 'interactive',
    origin: string = 'creator-studio',
  ) {
    this.disposeRenderer();

    const renderer = new Renderer(
      htmlElement,
      mode,
      'public-juuabbc8amz25dcfhribv3ss',
    );

    renderer.onReady = async () => {
      await renderer.setZoom('auto');
      await renderer.setLoop(false);
      await renderer.setCacheBypassRules([]);
      this.videoTranscriptionProcessor.setRenderer(renderer);
      this.karaokeProducer.setRenderer(renderer);
      if (this.currentVideo) {
        const videoSourceUrl = this.getValidVideoSourceUrlForResLevel(
          DEFAULT_PREVIEW_VIDEO_RES,
        );
        if (!this.cachedAssets.has(videoSourceUrl)) {
          await this.cacheVideoSource(videoSourceUrl);
          this.cachedAssets.add(videoSourceUrl);
        }
        await this.renderer!.setSource(this.currentVideo.videoSource);
      }
    };

    renderer.onLoad = async () => {
      runInAction(() => (this.isLoading = true));
    };

    renderer.onLoadComplete = async () => {
      runInAction(() => (this.isLoading = false));
    };

    renderer.onPlay = () => {
      runInAction(() => (this.isPlaying = true));
    };

    renderer.onPause = () => {
      runInAction(() => (this.isPlaying = false));
      runInAction(() => (this.whenPaused = new Date().getTime()));
    };

    renderer.onTimeChange = (time) => {
      if (
        origin === 'clip-player' &&
        this.whenPaused &&
        this.whenPaused - new Date().getTime() === 0
      ) {
        this.renderer?.play();
      }
      this.onRendererTimeChange(time);
    };

    renderer.onActiveElementsChange = (elementIds) => {
      runInAction(() => {
        this.activeElementIds = elementIds;
        this.textBrandingService.switchTemplateByType(elementIds);
        this.openElementSidebar(elementIds);
        this.reframingModeManager.toggleReframingMode();
      });
    };

    renderer.onStateChange = async (state) => {
      if (!this.isPlaying && this.state && this.frameLockedTracks.length > 0) {
        const applied = await this.trackManager.applyChangesToFrameLockedTracks(
          this.state,
          state,
        );
        // applying changes to other tracks will trigger state change again, skipping rest
        if (applied) return;
      }

      // TODO: Convert everything within this method into a handler
      this.stateChangeObserver.handle(this, state);

      runInAction(() => {
        // populate missing field trimStart
        state.elements.forEach((element) => {
          element.trimStart = element.source.trim_start || 0;
        });
        this.state = state;
        this.duration = state.duration;
        if (!this.stateReady && state.elements.length && state.duration)
          this.timelineStore.setShouldCalcTimelineScale(true);
        this.stateReady = Boolean(state.duration && state.elements.length);
      });

      if (this.isPlaying) {
        return;
      }

      if (inDebugMode()) {
        // Group by elements[i].track
        const perTrackState = groupBy(
          state.elements,
          (element) => element.track,
        );
        console.log('STATE CHANGE', perTrackState);
      } else {
        // Preserving original behavior.
        console.log('Renderer state change', state);
      }

      const maxElementDuration = state?.elements.reduce(
        (acc, el) => Math.max(el.duration, acc),
        0,
      );

      runInAction(() => {
        this.tracks = groupBy(state.elements, (element) => element.track);

        if (
          DEBUG_TRANSCRIPTION_TIMELINE &&
          process.env.REACT_APP_API_URL?.startsWith('http://localhost')
        ) {
          this.tracks.set(
            KARAOKE_TRACK_NUMBER - 1,
            test_injectTranscriptionElementsInTimeline(
              this.rootStore.transcriptionStore.getFinalTranscriptionElements() ||
                [],
            ),
          );
        }
        this.maxCanvasScale = Math.min(
          this.maxTimelineScale,
          MAX_CANVAS_WIDTH / maxElementDuration, // waveform canvas cannot be wider than 32767px, will not render
        );
      });
      for (const element of state.elements) {
        if (
          element.source.type === 'audio' &&
          !this.audioTracksData[element.source.source]
        ) {
          this.loadWaveformForSource({
            source: element.source.source,
            name: element.source.name,
          });
        }
      }

      await this.trackManager.lockVideoTrackOnStateChange(state.elements);
    };

    this.renderer = renderer;
    return true;
  }

  async refreshSourceAndPlay() {
    await this.reframingModeManager.exitReframingMode();
    await this.renderer?.setSource(this.renderer?.getSource(), false);
    this.renderer?.play();
  }

  async loadWaveformForSource(source: { source: string; name: string }) {
    this.audioTracksData[source.source] = 'loading';
    try {
      const { waveformJson, duration } =
        await this.datoClientStore.transcriptRepository.fetchWaveformDataForAudioWithTitle(
          source.name,
        );
      runInAction(() => {
        this.audioTracksData[source.source] = {
          waveform: WaveformData.create(waveformJson),
          originalTrackDuration: duration,
        };
      });
    } catch (err) {
      console.log('error loading waveform', err);
      delete this.audioTracksData[source.source];
    }
  }

  async onRendererTimeChange(time: number) {
    if (this.isScrubbing) return;
    const shouldApplyVolumeKeyPoints =
      this.isPlaying && Math.abs(time - this.keyPointsLastUpdateTime) > 1;
    if (time < this.time - 1 || Math.abs(time - this.smootherTime) > 1) {
      runInAction(() => (this.smootherTime = time));
    }
    runInAction(() => (this.time = time));
    if (!shouldApplyVolumeKeyPoints) return;
    // change the audio of clips dynamically based on VolumeKeyPoints
    this.state?.elements.forEach((element) => {
      const volumeKeyPoints = getVolumeKeyPointsOf(this, element);

      // check if element is playing and has volumeKeyPoints
      if (
        element.localTime > time ||
        element.localTime + element.duration < time ||
        !volumeKeyPoints ||
        volumeKeyPoints.length < 2
      )
        return;

      // interpolate between two closest volumeKeyPoints to this time
      const relativeTime = time - element.localTime;
      const prevVolumeKeyPoint = volumeKeyPoints.reduce(
        (prev: any, curr: any) => {
          const currTime = parseFloat(curr.time);
          const prevTime = parseFloat(prev.time);
          return currTime < relativeTime &&
            relativeTime - currTime < relativeTime - prevTime
            ? curr
            : prev;
        },
        volumeKeyPoints[0],
      );
      const prevVolumeKeyPointIndex =
        volumeKeyPoints.indexOf(prevVolumeKeyPoint);
      const nextVolumeKeyPoint = volumeKeyPoints[prevVolumeKeyPointIndex + 1];
      const prevTime = parseFloat(prevVolumeKeyPoint.time);
      const nextTime = parseFloat(nextVolumeKeyPoint.time);
      const prevVolume = parseFloat(prevVolumeKeyPoint.value);
      const nextVolume = parseFloat(nextVolumeKeyPoint.value);
      const volume =
        nextTime - prevTime < 0.001
          ? prevVolume
          : prevVolume +
            ((nextVolume - prevVolume) * (relativeTime - prevTime)) /
              (nextTime - prevTime);

      if (volume >= 0 && volume < 100) {
        // console.log('changing volume');
        // todo does it create undo point?
        this.renderer?.setModifications({
          [`${element.source.id}.volume`]: volume,
        });
      }
    });
    runInAction(() => (this.keyPointsLastUpdateTime = time));
  }

  getMaxTrack(): number {
    return Math.max(
      ...(this.state?.elements.map((element) =>
        element.track < KARAOKE_TRACK_NUMBER ? element.track : 0,
      ) || []),
      1,
    );
  }

  async setTime(
    time: number,
    resetAnimations: boolean = false,
    forceSnap: boolean = false,
  ): Promise<void> {
    // make sure time is on a single frame
    time = this.snapTime(time, forceSnap);
    time = Math.ceil(time * 1000) / 1000;
    this.time = time;
    if (resetAnimations) {
      runInAction(() => (this.smootherTime = time));
    }
    await this.renderer?.setTime(time);
  }

  setShiftDown(shiftDown: boolean) {
    this.isShiftKeyDown = shiftDown;
  }

  snapTime(time: number, forceSnap: boolean = false): number {
    if (this.isShiftKeyDown || forceSnap) {
      return Math.round(time * 24) / 24;
    } else {
      return time;
    }
  }

  async setDuration(time: number | null): Promise<void> {
    if (time != null) {
      runInAction(() => (this.duration = time));
    }

    const renderer = this.renderer;
    if (!renderer || !renderer.state) {
      return;
    }

    const source = renderer.getSource(renderer.state);

    source.duration = time;

    await renderer.setSource(source, true);
    this.createDefaultUndoPoint();
  }

  async applyVideoStateModifications(
    modifications: Record<
      string,
      string | number | boolean | Record<string, any> | null | undefined
    >,
    undo: boolean = false,
    actionLabel?: string,
    stackSameAction?: boolean,
  ) {
    if (undo && (!stackSameAction || this.getUndoLabel() === actionLabel)) {
      this.saveVideoStateSnapshot('undo', actionLabel);
    }
    await this.renderer!.applyModifications(modifications);
  }

  saveVideoStateSnapshot(into: 'undo' | 'redo' = 'undo', label?: string) {
    if (
      into === 'undo' &&
      this.currentVideo &&
      this.currentVideoVersions?.find((v) => v.meta.is_current)?.versionId ===
        this.currentVideo?.versionId &&
      Date.now() - +new Date(this.currentVideo!._publishedAt || 0) >
        AUTOSAVE_INTERVAL
    ) {
      this.saveStoryAndVideo(false, true, false, true);
    } else {
      this.prepareCurrentVideoForSave(true);
    }
    const videoSnapshot: CurrentLoadedVideo & { label?: string } = {
      ...this.getSafeCurrentVideoCopy(),
      subtitles: this.subtitlesProcessor.getSubtitleLines(),
      label,
    };

    if (into === 'undo') {
      this.videoSnapshots.push(videoSnapshot);
      if (this.videoSnapshots.length > MAX_UNDO_REDO_STACK_SIZE) {
        this.videoSnapshots.shift();
        this.undoStack.shift();
      }
      this.redoSnapshots = [];
      this.redoStack = [];
      this.undoStack.push({
        undoCommand: async () => {
          this.isLoading = true;
          this.saveVideoStateSnapshot('redo', label);
          const videoSnapshot = this.videoSnapshots.pop()!;
          await this.renderVideo(
            videoSnapshot,
            false,
            DEFAULT_PREVIEW_VIDEO_RES,
          );
          this.textBrandingService.loadDefaultTemplateOnVideoLoad();
          this.isLoading = false;
        },
        redoCommand: async () => {
          this.isLoading = true;
          const videoSnapshotRedo = this.redoSnapshots.pop()!;
          // console.log('redo snapshots', toJS(videoSnapshot));
          this.videoSnapshots.push(videoSnapshot);
          await this.renderVideo(
            videoSnapshotRedo,
            false,
            DEFAULT_PREVIEW_VIDEO_RES,
          );
          this.textBrandingService.loadDefaultTemplateOnVideoLoad();
          this.isLoading = false;
        },
      });
    } else {
      this.redoSnapshots.push(videoSnapshot);
    }
  }

  createDefaultUndoPoint() {
    // this.undoStack.push({
    //   undoCommand: () => this.renderer?.undo(),
    //   redoCommand: () => this.renderer?.redo(),
    // });
  }

  createUndoPointForTranscription() {
    // this.undoStack.push({
    //   undoCommand: () => this.videoTranscriptionProcessor.undo(),
    //   redoCommand: () => this.videoTranscriptionProcessor.redo(),
    // });
  }

  openElementSidebar(elementIds: string[]) {
    if (elementIds.length !== 1) return;
    const elementId = elementIds[0];
    const element = this.renderer
      ?.getElements()
      ?.find((e) => e.source.id === elementId);

    if (!element) return;

    if (element.source.track === KARAOKE_TRACK_NUMBER) {
      this.sidebarOptions = SidebarOption.karaoke;
    } else if (element.source.type === 'text') {
      this.sidebarOptions = SidebarOption.text;
    } else {
      this.sidebarOptions = SidebarOption.editing;
    }
  }

  setActiveElement(
    element: ElementState,
    shiftDown: boolean = false,
    doubleClick: boolean = false,
  ) {
    const isPunchlistItem = this.punchListManager.punchListData?.some(
      (p) => element.source.id === p.id,
    );

    const imageElement = this.getImageElement(element);
    const elementSource = imageElement.source;

    if (isPunchlistItem && (!elementSource.source || doubleClick)) {
      this.openPhotoElementReplacementModal = {
        element: this.state!.elements.find(
          (el) => el.source.id === element.source.id,
        )!,
        isForPunchlist: true,
      };
      this.sidebarOptions = SidebarOption.aiProducer;
    } else {
      this.sidebarOptions = SidebarOption.editing;
    }

    if (shiftDown && this.activeElementIds.length > 0) {
      if (this.activeElementIds.includes(element.source.id)) {
        this.setActiveElements(
          ...this.activeElementIds.filter((id) => id !== element.source.id),
        );
        return;
      }

      const currentActiveElement = this.state?.elements.find(
        (el) => el.source.id === this.activeElementIds[0],
      );
      if (
        !currentActiveElement ||
        currentActiveElement.track !== element.track ||
        !this.isOriginalVideoElement(element.source)
      ) {
        this.setActiveElements(...this.activeElementIds, element.source.id);
        return;
      }

      let elementsInBetween = [];
      if (currentActiveElement.globalTime < element.globalTime) {
        elementsInBetween =
          this.state?.elements.filter(
            (el) =>
              el.globalTime + el.duration > currentActiveElement.globalTime &&
              el.globalTime < element.globalTime + element.duration,
          ) || [];
      } else {
        elementsInBetween =
          this.state?.elements.filter(
            (el) =>
              el.globalTime <
                currentActiveElement.globalTime +
                  currentActiveElement.duration &&
              el.globalTime + el.duration > element.globalTime,
          ) || [];
      }
      this.setActiveElements(
        ...new Set([
          ...this.activeElementIds,
          ...elementsInBetween.map((el) => el.source.id),
          element.source.id,
        ]),
      );
    } else if (shiftDown) {
      const elementsInBetween =
        this.state?.elements.filter(
          (el) =>
            el.globalTime + el.duration > element.globalTime &&
            el.globalTime < element.globalTime + element.duration,
        ) || [];
      this.setActiveElements(...elementsInBetween.map((el) => el.source.id));
    } else {
      this.setActiveElements(element.source.id);
    }

    if (
      element.source.track === KARAOKE_TRACK_NUMBER &&
      element.source.type === 'composition'
    ) {
      this.sidebarOptions = SidebarOption.karaoke;
    }

    if (element.source.type === 'text')
      this.sidebarOptions = SidebarOption.text;

    if (this.isOriginalVideoElement(element.source)) {
      this.trackManager.unLockVideoTrack();
    } else {
      if (!this.trackManager.isVideoTrackLocked())
        this.trackManager.lockVideoTrack();
    }
  }

  async setActiveElements(...elementIds: string[]): Promise<void> {
    this.activeElementIds = elementIds;
    this.selectedTrack = -1;
    // to prevent onActiveElementsChange from calling toggleReframingMode first, causing active element focus loss
    this.reframingModeManager.allowToggle(false);
    await this.renderer?.setActiveElements(elementIds);
    await this.renderer?.setActiveComposition(null);
    this.reframingModeManager.allowToggle(true);
    await this.reframingModeManager.toggleReframingMode();
  }

  getActiveElement(): ElementState | undefined {
    if (!this.renderer || this.activeElementIds.length === 0) {
      return undefined;
    }

    const id = this.activeElementIds[0];
    return this.renderer.findElement(
      (element) => element.source.id === id,
      this.state,
    );
  }

  async createElement(
    elementSource: Record<string, any>,
    undo: boolean = true,
    pickTrackToMovePast?: (s: Record<string, any>) => number | null,
  ): Promise<void> {
    await this.reframingModeManager.exitReframingMode();

    const renderer = this.renderer;
    if (!renderer || !renderer.state) {
      return;
    }
    if (elementSource.time != null && elementSource.time < 0) {
      console.error('Element time cannot be negative');
      return;
    }
    if (elementSource.duration != null && elementSource.duration <= 0) {
      console.error('Element duration must be positive');
    }

    if (undo) {
      this.saveVideoStateSnapshot(
        undefined,
        `creating ${elementSource.type} element`,
      );
    }
    const source = renderer.getSource(renderer.state);
    // check if top track is free
    const currTime = this.time;
    const maxTrack = this.getMaxTrack();
    const id = uuid();
    let track = maxTrack + 1;

    if (!this.hasDefaultAspectRatio()) {
      await this.adjustElementSourceToAspectRatio(elementSource);
    }

    source.elements.push({
      id,
      track: maxTrack + 1,
      time: currTime,
      ...elementSource,
    });

    const trackToMovePast = pickTrackToMovePast && pickTrackToMovePast(source);
    if (trackToMovePast) {
      await this.trackManager.moveTrackPastAnother(
        track,
        trackToMovePast,
        deepClone(source),
      );
    } else {
      await renderer.setSource(source, undo);
      if (undo) {
        this.createDefaultUndoPoint();
      }
    }

    await this.setActiveElements(id);
  }

  pickOriginalVideoTrack = (
    source: Record<string, any> = this.renderer!.getSource(),
  ): number | null => {
    const originalVideoElement = source.elements.find((el: any) =>
      this.isOriginalVideoElement(el),
    );
    return originalVideoElement ? originalVideoElement.track : null;
  };

  async deleteElementsInTrack(
    track: number,
    saveSnapshot: boolean = true,
  ): Promise<void> {
    if (track === -1) {
      console.warn('deleteElementsInTrack: no track selected');
      return;
    }
    if (track === KARAOKE_TRACK_NUMBER) {
      console.warn('deleteElementsInTrack: use deleteKaraokeElements instead');
      return;
    }

    await this.reframingModeManager.exitReframingMode();

    const state = deepClone(this.renderer!.state);
    if (!state) {
      return;
    }

    const elementsToDelete = state.elements.filter((el) => el.track === track);
    if (!elementsToDelete.length) {
      console.warn('deleteElementsInTrack: no elements in track');
      return;
    }
    if (elementsToDelete.some((el) => this.isOriginalVideoElement(el.source))) {
      console.warn(
        'deleteElementsInTrack: cannot delete track containing original video element',
      );
      return;
    }

    if (saveSnapshot) {
      this.saveVideoStateSnapshot(
        undefined,
        `deleting track of ${elementsToDelete[0].source.type} elements`,
      );
    }

    state.elements = state.elements.filter(
      (element) => element.track !== track,
    );
    const newSource =
      this.videoTranscriptionProcessor.adjustTrackNumbersToStartFromOne(
        this.renderer!.getSource(state),
      );
    await this.renderer!.setSource(newSource, true);
    this.trackManager.updateFrameLockedTracksAfterRearrange(
      state.elements,
      this.renderer!.state!.elements,
    );

    const ids = elementsToDelete.map((el) => el.source.id);

    const fadeProducer = new FadeProducer(this, null);
    await fadeProducer.removeElementOverlaysOnVideo(ids);

    const idsWithPhotoHighlights = ids.filter((id) =>
      this.hasPhotoHighlight(id),
    );
    if (idsWithPhotoHighlights.length) {
      this.punchListManager.removePunchListItems(idsWithPhotoHighlights);
      this.createUndoPointForTranscription();
    }

    this.clearLogoElementsMetadata(ids);
  }

  async deleteElementWithTranscription(
    elementId: string,
    saveSnapshot: boolean = true,
  ): Promise<void> {
    await this.reframingModeManager.exitReframingMode();

    const state = deepClone(this.renderer!.state);
    if (!state) {
      return;
    }

    const elementToDelete = state.elements.find(
      (el) => el.source.id === elementId,
    );

    if (!elementToDelete) {
      return;
    }

    if (saveSnapshot) {
      this.saveVideoStateSnapshot(
        undefined,
        `deleting ${elementToDelete.source.type} element`,
      );
    }

    const fadeProducer = new FadeProducer(this, elementToDelete);

    if (this.isOriginalVideoElement(elementToDelete.source)) {
      await this.videoTranscriptionProcessor.deleteOriginalVideoTrack(
        elementId,
      );
      await fadeProducer.tidyOriginalVideoOverlay();
      this.refreshKaraokeElements();
      this.createUndoPointForTranscription();
    } else {
      // if (elementToDelete?.source.type === 'audio') {
      //   state.elements.forEach((element) => {
      //     if (
      //       element.source.type === 'audio' &&
      //       element.globalTime >=
      //       elementToDelete.globalTime + elementToDelete.duration
      //     ) {
      //       element.source.time = element.globalTime - elementToDelete.duration;
      //     }
      //   });
      // }

      // Remove the element
      state.elements = state.elements.filter(
        (element) => element.source.id !== elementId,
      );

      // Set source by the mutated state
      const newSource =
        this.videoTranscriptionProcessor.adjustTrackNumbersToStartFromOne(
          this.renderer!.getSource(state),
        );
      await this.renderer!.setSource(newSource, true);
      this.trackManager.updateFrameLockedTracksAfterRearrange(
        state.elements,
        this.renderer!.state!.elements,
      );

      await fadeProducer.removeElementOverlaysOnVideo([elementId]);
      if (this.hasPhotoHighlight(elementId)) {
        this.punchListManager.removePunchListItem(elementId);
        this.createUndoPointForTranscription();
      }
    }
  }

  async deleteKaraokeElements() {
    this.saveVideoStateSnapshot(undefined, `deleting karaoke`);
    await this.karaokeProducer.deleteKaraokeClips();
    this.removeAllKaraokeBreaks();
  }

  async addPunctuation(
    code: 'Comma' | 'Period' | 'Space' | 'Enter',
    position: number,
    isSubtitles = false,
  ) {
    if (!isSubtitles) {
      this.videoTranscriptionProcessor.addPunctuation(code, position, {
        ...(code === 'Enter' &&
          this.karaokeProducer.hasElements() &&
          !this.hasKaraokeBreakNear(position) && { karaoke_break: true }),
      });
    } else {
      this.subtitlesProcessor.addPunctuation(code, position);
    }
    this.refreshKaraokeElements();
  }

  hasKaraokeBreakNear(position: number, isSubtitles = false): boolean {
    const transcriptionElements =
      this.rootStore.transcriptionStore.getFinalTranscriptionElements();

    const startIndex = Math.max(
      getClosestNotRemovedTextIndexToLeft(position, transcriptionElements),
      0,
    );
    let endIndex = getClosestNotRemovedTextIndexToRight(
      position,
      transcriptionElements,
    );
    if (endIndex === -1) {
      endIndex = transcriptionElements.length;
    }
    for (let i = startIndex; i < endIndex; i++) {
      const element = transcriptionElements[i];
      if (
        element.state !== 'removed' &&
        element.state !== 'cut' &&
        element.karaoke_break
      ) {
        return true;
      }
    }
    return false;
  }

  addKaraokeBreaks(positions: number[], isSubtitles = false) {
    console.trace('add karaoke breaks', positions, isSubtitles);
    if (!isSubtitles) {
      this.videoTranscriptionProcessor.addKaraokeBreaks(positions);
    } else {
      this.subtitlesProcessor.addKaraokeBreaks(positions);
    }
    this.refreshKaraokeElements();
  }

  removeKaraokeBreak(position: number, isSubtitles = false) {
    if (!isSubtitles) {
      this.videoTranscriptionProcessor.removeKaraokeBreak(position);
    } else {
      this.subtitlesProcessor.removeKaraokeBreak(position);
    }
    this.refreshKaraokeElements();
  }

  removeAllKaraokeBreaks(isSubtitles = false) {
    if (!isSubtitles) {
      this.videoTranscriptionProcessor.removeAllKaraokeBreaks();
    } else {
      this.subtitlesProcessor.removeAllKaraokeBreaks();
    }
    this.refreshKaraokeElements();
  }

  removeKaraokeMutes(isSubtitles: boolean = false) {
    if (!isSubtitles) {
      this.videoTranscriptionProcessor.removeKaraokeMutes();
    } else {
      this.subtitlesProcessor.removeKaraokeMutes();
    }
  }

  isSelectedVolumeKeyPoint(point: VolumeKeyPoint): boolean {
    return (
      point.time === this.selectedVolumeKeyPoint?.time &&
      point.value === this.selectedVolumeKeyPoint?.value
    );
  }

  selectVolumeKeyPoint(point: VolumeKeyPoint | null): void {
    this.selectedVolumeKeyPoint = point;
  }

  deleteSelectedVolumeKeyPoint(elementId: string): void {
    if (!this.selectedVolumeKeyPoint) {
      return;
    }
    const oldVolumeKeyPoints =
      toJS(
        (
          this.currentVideo!.extraElementData[
            elementId
          ] as ExtraElementData | null
        )?.volumeKeyPoints,
      ) || [];
    let newVolumeKeyPoints = oldVolumeKeyPoints.filter(
      (p) => !this.isSelectedVolumeKeyPoint(p),
    );
    if (newVolumeKeyPoints.length === 2) {
      newVolumeKeyPoints = [];
    }
    this.applyVolumeKeyPoints(elementId, newVolumeKeyPoints);
    this.selectedVolumeKeyPoint = null;
  }

  deleteAllVolumeKeyPoints(elementId: string): void {
    this.applyVolumeKeyPoints(elementId, []);
    this.selectedVolumeKeyPoint = null;
  }

  private extendRangeToNecessaryWhitespaces(
    startIndex: number,
    endIndex: number,
    elements: TranscriptElement[],
  ): [number, number][] {
    if (!elements) {
      return [[startIndex, endIndex]];
    }
    endIndex--;
    /*
     * look for the prev visible element
     * if found and it's not a whitespace
     *   -> include preceding whitespace into restore
     */
    const prevVisibleElementIndex = getClosestNotRemovedElementIndexToLeft(
      startIndex - 1,
      elements,
    );
    if (
      prevVisibleElementIndex >= 0 &&
      elements[prevVisibleElementIndex].value !== ' ' &&
      elements[startIndex - 1]?.value === ' '
    ) {
      startIndex--;
    }
    /*
     * look for the next visible element
     * if found and it's not a punctuation
     *   -> include following whitespace into restore
     *   if restored range is not immediately followed by a whitespace
     *     -> find whitespace before next visible element and create a new range with it alone
     */
    const nextVisibleElementIndex = getClosestNotRemovedElementIndexToRight(
      endIndex + 1,
      elements,
    );
    if (
      nextVisibleElementIndex < elements.length &&
      nextVisibleElementIndex > -1 &&
      elements[nextVisibleElementIndex].type === 'text'
    ) {
      if (elements[endIndex + 1].value === ' ') {
        return [[startIndex, endIndex + 2]];
      } else {
        for (let i = endIndex + 2; i < nextVisibleElementIndex; i++) {
          if (elements[i]?.value === ' ') {
            return [
              [startIndex, endIndex + 1],
              [i, i + 1],
            ];
          }
        }
      }
    }
    return [[startIndex, endIndex + 1]];
  }

  excludeMutedByHideFillers = (
    ranges: [number, number][],
    elements: TranscriptElement[],
  ): [number, number][] => {
    const result: [number, number][] = [];
    for (const [startIndex, endIndex] of ranges) {
      for (let i = startIndex, rangeStart = startIndex; i <= endIndex; i++) {
        if (elements[i].muted_by_hideFillers) {
          if (i === 0) {
            rangeStart++;
          } else if (i === endIndex) {
            result.push([rangeStart, endIndex - 1]);
          } else {
            result.push([rangeStart, i - 1]);
            rangeStart = i + 1;
          }
        } else if (i === endIndex) {
          result.push([rangeStart, endIndex]);
        }
      }
    }
    return result;
  };

  async restoreTranscriptAndVideo(
    startIndex: number,
    endIndex: number,
    isSubtitles: boolean = false,
    shouldExcludeMutedByHideFillers: boolean = false,
  ) {
    const elements = isSubtitles
      ? this.subtitleElements
      : this.rootStore.transcriptionStore.getFinalTranscriptionElements();
    if (!elements) {
      console.error('No elements to restore');
      return;
    }
    await this.reframingModeManager.exitReframingMode();
    this.saveVideoStateSnapshot(
      undefined,
      `restoring ${isSubtitles ? 'subtitles' : 'text'}`,
    );

    let ranges = this.extendRangeToNecessaryWhitespaces(
      startIndex,
      endIndex,
      elements,
    );

    if (shouldExcludeMutedByHideFillers) {
      ranges = this.excludeMutedByHideFillers(ranges, elements);
    }

    for (const [fromElement, toElement] of ranges) {
      let fromIndex = fromElement;
      // loop through all removed elements in the range
      while (fromIndex >= 0 && fromIndex <= toElement) {
        const nextIndex = getClosestNotRemovedTextIndexToRight(
          fromIndex,
          elements,
          false,
        );
        const nextRemovedElement =
          nextIndex > -1
            ? getClosestRemovedIndexToLeft(nextIndex, elements)
            : elements.length - 1;
        if (nextRemovedElement === -1) break;

        if (isSubtitles) {
          this.subtitlesProcessor.restoreMutedTextElements(
            fromIndex,
            Math.min(nextRemovedElement + 1, toElement),
          );
        } else if (elements[fromIndex].state === 'muted') {
          await this.videoTranscriptionProcessor.restoreMutedTextElement(
            fromElement,
            Math.min(nextRemovedElement + 1, toElement),
          );
        } else {
          await this.videoTranscriptionProcessor.restoreTextElementsFromOriginal(
            fromIndex,
            Math.min(nextRemovedElement + 1, toElement),
          );
        }
        this.createUndoPointForTranscription();
        fromIndex = getClosestRemovedIndexToRight(
          nextRemovedElement + 1,
          elements,
        );
      }
    }

    const fadeProducer = new FadeProducer(this);
    await fadeProducer.tidyOriginalVideoOverlay();
    if (ranges.length > 0 && !isSubtitles) {
      this.refreshKaraokeElements({
        fromIndex: ranges[0][0],
        toIndex: ranges.at(-1)![1],
      });
    } else {
      this.refreshKaraokeElements();
    }
  }

  replaceTranscriptionElements(
    selections: {
      startIndex: number;
      endIndex: number;
      newValue: string;
      retainState?: boolean;
    }[],
    isSubtitles = false,
  ) {
    this.saveVideoStateSnapshot(
      undefined,
      `replacing ${isSubtitles ? 'subtitles' : 'text'}`,
    );
    if (isSubtitles) {
      selections.forEach(({ startIndex, endIndex, newValue }, index) => {
        this.subtitlesProcessor.replaceSubtitlesElement(
          startIndex,
          endIndex,
          newValue,
          index !== selections.length - 1,
        );
      });
    } else {
      selections.forEach(
        ({ startIndex, endIndex, newValue, retainState }, index) => {
          this.videoTranscriptionProcessor.replaceTextElement(
            startIndex,
            endIndex,
            newValue,
            index !== selections.length - 1,
            retainState,
          );
        },
      );
    }
    this.refreshKaraokeElements();
    this.createUndoPointForTranscription();
  }

  updateTranscriptionElement(
    index: number,
    endIndex: number,
    newTiming: { start_ts: number; end_ts: number },
    isSubtitles = false,
  ) {
    this.saveVideoStateSnapshot(undefined, `updating transcript timing`);

    this.videoTranscriptionProcessor.replaceElementTs(
      index,
      endIndex,
      newTiming['start_ts'],
      newTiming['end_ts'],
    );

    console.log('updating transcript maybe');
    this.refreshKaraokeElements();
    this.createUndoPointForTranscription();
  }

  hideKaraoke(
    boundaries: { startIndex: number; endIndex: number },
    isSubtitles = false,
  ) {
    this.saveVideoStateSnapshot(undefined, `hiding karaoke`);
    if (isSubtitles) {
      this.subtitlesProcessor.hideKaraoke(boundaries);
    } else {
      this.videoTranscriptionProcessor.hideKaraoke(boundaries);
    }
    this.refreshKaraokeElements();
  }

  async refetchSubtitlesForCurrentVideo() {
    if (this.currentVideo!.subtitles) {
      await this.requestSubtitlesForCurrentVideo();
    }
    this.refreshKaraokeElements();
  }

  getVideoSubtitleElements(): TranscriptElement[] | undefined {
    if (!this.currentVideo?.subtitles?.lines?.length) {
      return;
    }
    return this.subtitlesProcessor.convertToSubtitleElements(
      this.currentVideo?.subtitles,
    );
  }

  async refreshKaraokeElements(addBreaksForRange?: {
    fromIndex: number;
    toIndex: number;
  }) {
    if (this.karaokeProducer.hasElements()) {
      this.karaokeProducer.produceKaraoke(undefined, addBreaksForRange);
    }
  }

  async cutTranscriptAndVideo(
    startIndex: number,
    endIndex: number,
    autoCorrect: boolean = false,
    isSubtitles = false,
  ) {
    await this.reframingModeManager.exitReframingMode();
    this.saveVideoStateSnapshot(
      undefined,
      `removing ${isSubtitles ? 'subtitles' : 'text'}`,
    );
    if (isSubtitles) {
      this.subtitlesProcessor.removeSubtitleElements(startIndex, endIndex);
    } else {
      await this.videoTranscriptionProcessor.removeTextElements(
        startIndex,
        endIndex,
        false,
        autoCorrect,
      );
      const fadeProducer = new FadeProducer(this);
      await fadeProducer.tidyOriginalVideoOverlay();
    }
    this.refreshKaraokeElements();
    this.createUndoPointForTranscription();
  }

  async cropTranscriptAndVideoTo(startIndex: number, endIndex: number) {
    await this.videoTranscriptionProcessor.cropVideoToKeepTextElements(
      startIndex,
      endIndex,
    );
    await this.makeAdjustmentsAfterCroppingTranscriptAndVideo();
  }

  async cropTranscriptAndVideoInMultiplePlaces(
    ranges: { fromElement: number; toElement: number }[],
  ) {
    await this.videoTranscriptionProcessor.cropVideoToKeepTextElementsInMultiplePlaces(
      ranges,
    );
    await this.makeAdjustmentsAfterCroppingTranscriptAndVideo();
  }

  async makeAdjustmentsAfterCroppingTranscriptAndVideo() {
    const fadeProducer = new FadeProducer(this);
    await fadeProducer.tidyOriginalVideoOverlay();
    this.refreshKaraokeElements();
    this.createUndoPointForTranscription();
    this.timelineStore.setShouldCalcTimelineScale(true);
  }

  async cutSentence(
    startIndex: number,
    endIndex: number,
    autoCorrect: boolean = false,
  ) {
    await this.reframingModeManager.exitReframingMode();
    this.saveVideoStateSnapshot(undefined, 'cutting text');
    await this.videoTranscriptionProcessor.removeTextElements(
      startIndex,
      endIndex + 1,
      true,
      autoCorrect,
    );
    this.refreshKaraokeElements();
    this.createUndoPointForTranscription();
  }

  async pasteSentence(intoPosition: number) {
    await this.reframingModeManager.exitReframingMode();
    const addedRange =
      await this.videoTranscriptionProcessor.pasteFromClipboard(intoPosition);

    const fadeProducer = new FadeProducer(this);
    await fadeProducer.tidyOriginalVideoOverlay();

    this.refreshKaraokeElements(addedRange);
    this.createUndoPointForTranscription();
  }

  // not used
  async moveSentence(
    startIndex: number,
    endIndex: number,
    newStartIndex: number,
  ) {
    await this.reframingModeManager.exitReframingMode();
    this.saveVideoStateSnapshot(undefined, `moving text`);
    await this.videoTranscriptionProcessor.moveTextElements(
      startIndex,
      endIndex,
      newStartIndex,
    );
    this.createUndoPointForTranscription();
  }

  formatAndFixOffTimeVolumeKeyPoints(
    elementId: string,
    volumeKeyPoints: VolumeKeyPoint[],
  ) {
    const element = this.state?.elements.find(
      (el) => el.source.id === elementId,
    );
    if (!element) return [];
    const fixedKeyPoints = volumeKeyPoints
      .filter(
        (keyPoint) =>
          parseFloat(keyPoint.time) >= 0 &&
          parseFloat(keyPoint.time) <= element.duration,
      )
      .map((keyPoint) => ({
        value: `${parseFloat(keyPoint.value)} %`,
        time: `${parseFloat(keyPoint.time)} s`,
      }));
    if (volumeKeyPoints.length > fixedKeyPoints.length) {
      fixedKeyPoints.push({
        value: `${parseFloat(volumeKeyPoints.at(-1)!.value)} %`,
        time: `${element.duration} s`,
      });
    }
    return fixedKeyPoints;
  }

  async applyVolumeKeyPoints(
    elementId: string,
    volumeKeyPoints: VolumeKeyPoint[],
    undo: boolean = true,
  ) {
    const oldVolumeKeyPoints = toJS(
      (
        this.currentVideo!.extraElementData[
          elementId
        ] as ExtraElementData | null
      )?.volumeKeyPoints,
    );
    const newVolumeKeyPoints = this.formatAndFixOffTimeVolumeKeyPoints(
      elementId,
      volumeKeyPoints,
    );
    if (
      newVolumeKeyPoints.length === oldVolumeKeyPoints?.length &&
      newVolumeKeyPoints.every(
        (kp, idx) =>
          kp.time === oldVolumeKeyPoints[idx].time &&
          kp.value === oldVolumeKeyPoints[idx].value,
      )
    ) {
      return;
    }

    if (undo) {
      this.saveVideoStateSnapshot('undo', 'modifying volume keyframes');
    }

    this.currentVideo!.extraElementData[elementId] = {
      ...(this.currentVideo!.extraElementData[elementId] || {}),
      volumeKeyPoints: newVolumeKeyPoints,
    };
  }

  async adjustTextDimensions(aspectRatio: Video['aspectRatio']) {
    const elements = this.renderer?.getElements() || [];

    let modifications = {} as Record<string, any>;
    const elementsInComposition = {} as any;
    const defaultTextSetting =
      this.textProducer.getBasicTextSettingByAspectRatio(aspectRatio);
    const defaultKaraokeSetting =
      this.karaokeProducer.getKaraokeTextSettingByAspectRatio(aspectRatio);
    let hasKaraoke = false;

    let width = this.renderer?.getSource()?.width || VIDEO_DEFAULT_WIDTH;
    let height = this.renderer?.getSource()?.height || VIDEO_DEFAULT_HEIGHT;

    for (let el of elements) {
      if (el.source.type === 'composition') {
        el.elements?.forEach((e: any) => {
          elementsInComposition[e.source.id] = e;
        });
      }
    }
    for (let el of elements) {
      if (
        el.source.type === 'text' &&
        !elementsInComposition[el.source.id] &&
        el.track !== KARAOKE_TRACK_NUMBER
      ) {
        for (let [key, value] of Object.entries(defaultTextSetting)) {
          if (key === 'font_size') {
            const vhValue = convertFromPixels(parseInt(value), 'vh', {
              height,
              width,
            });
            value = `${vhValue}vh`;
            modifications[`${el.source.id}.${key}`] = value;
          }
        }
      }

      if (el.source.type === 'text' && el.track === KARAOKE_TRACK_NUMBER) {
        for (let [key, value] of Object.entries(defaultKaraokeSetting)) {
          if (key === 'font_size') {
            const vhValue = convertFromPixels(parseInt(value), 'vh', {
              height,
              width,
            });
            value = `${vhValue}vh`;
            modifications[`${el.source.id}.${key}`] = value;
          }
        }
        hasKaraoke = true;
      }
    }

    if (hasKaraoke) {
      await this.karaokeProducer.rerenderWithNewConfig({
        ...this.karaokeProducer.getKaraokeConfig(),
        ...defaultKaraokeSetting,
      });
    }

    if (Object.keys(modifications).length) {
      await this.renderer?.applyModifications({
        ...modifications,
      });
    }
  }

  private async adjustVideoSourceToAspectRatio(aspectRatio: string) {
    const [w, h] = aspectRatio.split(':').map((n) => parseInt(n));
    const width = this.currentVideo!.videoSource.height * (w / h);
    this.currentVideo!.videoSource.width = width;

    for (const element of this.currentVideo!.videoSource.elements) {
      await this.adjustElementSourceToAspectRatio(element);
    }
  }

  isVideoElement(element: ElementState): boolean {
    return element.source.type === 'video';
  }

  isRegularImageElement(element: ElementState): boolean {
    return this.isImageElement(element) && !this.isLogoElement(element);
  }

  async adjustElementSourceToAspectRatio(
    elementSource: Record<string, any>,
  ): Promise<boolean> {
    const element = mapToElementState(elementSource) as ElementState;
    const isVideo = this.isVideoElement(element);
    const isRegularImage = this.isRegularImageElement(element);
    if (!isRegularImage && !isVideo) {
      return false;
    }
    const adjustments = await this.getElementAdjustmentsForAspectRatio(element);
    elementSource.x = adjustments.x;
    elementSource.y = adjustments.y;
    elementSource.width = adjustments.width;
    elementSource.height = adjustments.height;
    return true;
  }

  private async getElementAdjustmentsForAspectRatio(
    element: ElementState,
  ): Promise<Record<string, string>> {
    const result = {
      x: '50%',
      y: '50%',
      width: '100%',
      height: '100%',
    };
    if (['fill', 'contain'].includes(element.source?.fit)) {
      return result;
    }
    const prevDimensions = {
      width: VIDEO_DEFAULT_WIDTH,
      height: VIDEO_DEFAULT_HEIGHT,
    };
    const elementWidth = 100;
    const newWidth = Math.max(
      100,
      Number(
        (
          (prevDimensions.width * elementWidth) /
          this.currentVideo!.videoSource.width
        ).toFixed(4),
      ),
    );
    result.width = `${newWidth}%`;
    const naturalDimensions = this.isOriginalVideoElement(element)
      ? null
      : await this.getNaturalDimensionsForElement(element);
    if (naturalDimensions) {
      const naturalAspectRatio =
        naturalDimensions.width / naturalDimensions.height;
      const prevAspectRatio = prevDimensions.width / prevDimensions.height;
      result.width = `${(newWidth * naturalAspectRatio) / prevAspectRatio}%`;
    }
    return result;
  }

  private async getNaturalDimensionsForElement(
    element: ElementState,
  ): Promise<NaturalDimensions> {
    if (element.source.arbor_naturalDimensions) {
      return element.source.arbor_naturalDimensions;
    } else if (this.isVideoElement(element)) {
      return await getVideoNaturalDimensions(element.source?.source);
    } else if (this.isRegularImageElement(element)) {
      return await getImageNaturalDimensions(
        this.getImageElement(element).source?.source,
      );
    }
    return null;
  }

  async adjustModificationsToAspectRatio(
    modifications: Record<string, any>,
    elementSource: ElementState['source'],
  ): Promise<Record<string, any>> {
    const elementSourceOutput: Record<string, any> = { ...elementSource };
    if (await this.adjustElementSourceToAspectRatio(elementSourceOutput)) {
      const id = elementSource.id;
      const { x, y, width, height } = elementSourceOutput;
      modifications[`${id}.x`] = x;
      modifications[`${id}.y`] = y;
      modifications[`${id}.width`] = width;
      modifications[`${id}.height`] = height;
    }
    return modifications;
  }

  // gets the source of compositions properly
  getElementSource(element: ElementState): Record<string, any> {
    return this.renderer?.getSource(element) || element.source;
  }

  getImageElement(element: ElementState) {
    if (element.source?.type === 'composition') {
      const image_element = element?.elements?.find(
        (e) => e.source?.type === 'image',
      );
      if (image_element) return image_element;
    }
    return element;
  }

  isImageElementComposition(element: ElementState) {
    if (element.source?.type === 'composition') {
      return element?.elements?.some((e) => e.source?.type === 'image');
    }
    return false;
  }

  async removeBlackFrames() {
    const compositionElements = this.renderer
      ?.getElements()
      ?.filter((e) => this.isImageElementComposition(e));
    let modifications = {} as Record<string, any>;

    for (let el of compositionElements || []) {
      const imageElement = (el.elements || [])?.find(
        (e) => e.source.type === 'image',
      );
      const elementSource = {
        ...el.source,
      };
      elementSource.source = imageElement!.source.source;
      elementSource.type = 'image';
      elementSource.fit = 'cover';

      elementSource.locked = false;
      delete elementSource.id;

      for (let key in elementSource) {
        modifications[`${el.source.id}.${key}`] = elementSource[key];
      }
    }

    if (Object.keys(modifications).length) {
      await this.renderer?.applyModifications({
        ...modifications,
      });
    }
  }

  isLogoElement(element: ElementState): boolean {
    return (
      this.isImageElement(element) &&
      !!(
        this.currentVideo?.extraElementData[
          `logo_el_${element.source.id}`
        ] as ExtraElementData
      )?.isLogo
    );
  }

  isImageElement(element: ElementState) {
    return (
      this.isImageElementComposition(element) ||
      element.source?.type === 'image'
    );
  }

  imageCompositionDurationModifications(
    element: ElementState,
    duration: number,
  ) {
    const additionalModifications: Record<string, any> = {};

    if (this.isImageElementComposition(element)) {
      for (let e of element.elements || []) {
        additionalModifications[`${e.source.id}.duration`] = duration;
      }
    }
    return additionalModifications;
  }

  async moveElements(elementIds: string[], timeShift: number) {
    this.saveVideoStateSnapshot(undefined, `moving elements`);
    await this.videoTranscriptionProcessor.moveElements(elementIds, timeShift);
    // await fadeProducer.tidyOriginalVideoOverlay(); todo for multiple elements?
    this.createUndoPointForTranscription();
    this.refreshKaraokeElements();
    return;
  }

  async applyPlacement(
    element: ElementState,
    placement: Pick<ElementState, 'duration' | 'globalTime' | 'trimStart'>,
  ) {
    if (
      element.globalTime === placement.globalTime &&
      element.duration === placement.duration
    )
      return;

    this.saveVideoStateSnapshot(
      undefined,
      `moving ${element.source.type} element`,
    );
    const fadeProducer = new FadeProducer(this, element);

    if (
      this.isOriginalVideoElement(element.source) &&
      this.rootStore.transcriptionStore.getFinalTranscriptionElements()?.length
    ) {
      let addBreaksForRange;
      if ((element.source.trim_start || 0) !== (placement.trimStart || 0)) {
        addBreaksForRange =
          await this.videoTranscriptionProcessor.trimTrackStart(
            element.source.id,
            placement.globalTime,
            placement.trimStart,
            placement.duration,
          );
      } else if (element.globalTime !== placement.globalTime) {
        await this.videoTranscriptionProcessor.moveTrack(
          element.source.id,
          placement.globalTime,
        );
      } else if (element.duration !== placement.duration) {
        addBreaksForRange =
          await this.videoTranscriptionProcessor.trimTrackDuration(
            element.source.id,
            placement.duration,
          );
      }

      await fadeProducer.tidyOriginalVideoOverlay();
      this.createUndoPointForTranscription();
      this.refreshKaraokeElements(addBreaksForRange);
      return;
    }

    if (element.source.type === 'audio') {
      await this.applyPlacementOnAudio(element, placement);
      return;
    }

    if (element.track === KARAOKE_TRACK_NUMBER) {
      const originalTranscription =
        this.rootStore.transcriptionStore.getOriginalTranscription();
      const originalLanguage = originalTranscription?.language;
      const karaokeConfig = this.karaokeProducer.getKaraokeConfig();

      await this.videoTranscriptionProcessor.applyKaraokeElementPlacement(
        element,
        placement,
        !originalLanguage?.includes('en') &&
          karaokeConfig.language.includes('en'),
      );
      this.refreshKaraokeElements();
      return;
    }

    const newVideoOverlays = await fadeProducer.resetCrossfadeOnVideo(
      placement.globalTime,
      placement.duration,
    );

    // for non-video elements and non-karaoke elements
    await this.renderer?.applyModifications({
      ...newVideoOverlays,
      [`${element.source.id}.time`]: placement.globalTime,
      [`${element.source.id}.duration`]: placement.duration,
      ...this.imageCompositionDurationModifications(
        element,
        placement.duration,
      ),
    });
    this.createDefaultUndoPoint();
  }

  private async applyPlacementOnAudio(
    element: ElementState,
    placement: Pick<ElementState, 'duration' | 'globalTime' | 'trimStart'>,
  ) {
    const trimStart = placement.trimStart || 0;
    const time = Math.max(placement.globalTime, 0);
    if ((element.source.trim_start || 0) !== trimStart) {
      //handle trim audio start
      await this.trimAudioTrackStart(
        element,
        time,
        trimStart,
        placement.duration,
      );
    } else if (element.globalTime !== time) {
      //handle move track
      await this.renderer?.applyModifications({
        [`${element.source.id}.time`]: time,
        [`${element.source.id}.duration`]: element.source.duration,
      });
    } else if (element.duration !== placement.duration) {
      // handle trim audio end
      await this.renderer?.applyModifications({
        [`${element.source.id}.time`]: time,
        [`${element.source.id}.duration`]: placement.duration,
      });
    }
    // const volumeKeyPoints = (
    //   this.currentVideo!.extraElementData[
    //     element.source.id
    //   ] as ExtraElementData | null
    // )?.volumeKeyPoints;

    // if (volumeKeyPoints && volumeKeyPoints.length > 0) {
    //   runInAction(() => {
    //     this.applyVolumeKeyPoints(element.source.id, volumeKeyPoints, false);
    //   });
    // }
    this.createDefaultUndoPoint();
  }

  private async trimAudioTrackStart(
    element: ElementState,
    newStartTime: number,
    trimStart: number,
    newDuration: number,
  ) {
    const elementId = element.source.id;

    const stockSongs = this.stockMusic.flatMap((m) => m.collection) || [];
    const ownSongs = this.story?.myAudios?.map((o) => o.song) || [];
    const allSongs = [...stockSongs, ...ownSongs];

    let originalAudio = allSongs.find((c) => c.url === element.source.source);

    let mediaDuration = element.mediaDuration!;
    let originalMediaDuration = parseFloat(originalAudio?.customData.duration!);

    const currentTrimStart = element.source.trim_start || 0;

    if (currentTrimStart < trimStart) {
      mediaDuration = Math.min(
        Math.max(mediaDuration - trimStart, 0),
        originalMediaDuration,
      );
    } else {
      //untrim
      const untrimedTime = currentTrimStart - trimStart;
      mediaDuration = Math.min(
        mediaDuration + untrimedTime,
        originalMediaDuration,
      );
    }

    // Trim the element
    await this.renderer?.applyModifications({
      [`${elementId}.time`]: newStartTime,
      [`${elementId}.trim_start`]: trimStart,
      [`${elementId}.duration`]: newDuration,
      [`${elementId}.media_duration`]: mediaDuration,
    });
  }

  removeAllPhotoHighlightTranscriptChanges() {
    this.videoTranscriptionProcessor.removeAllPhotoHighlights();
    this.createUndoPointForTranscription();
  }

  async removeAllAutoPhotoHighlightTranscriptChanges() {
    this.videoTranscriptionProcessor.removeAllAutoPhotoHighlights();
    this.createUndoPointForTranscription();
  }

  async resetPhotoHighlights() {
    this.removeAllPhotoHighlightTranscriptChanges();

    const timelineElements = this.renderer?.getElements();
    // Punchlist wtf?
    for (let item of this.punchListManager.punchListData || []) {
      const id = item.id!;
      const element = timelineElements?.find((el: any) => el.source.id === id);

      if (element) {
        const duration = element?.duration;
        const startTime = element.localTime;
        const endTime = startTime + duration;

        const startIndex =
          this.videoTranscriptionProcessor.findClosestIndexToTimestamp(
            startTime,
            'ts',
          );
        const endIndex =
          this.videoTranscriptionProcessor.findClosestIndexToTimestamp(
            endTime,
            'end_ts',
            'ts_lookbehind',
          );
        this.videoTranscriptionProcessor.addPhotoHighlight(
          startIndex,
          endIndex,
          id,
        );
      }
    }
    this.createUndoPointForTranscription();
  }

  hasPhotoHighlight(id: string) {
    return (
      this.videoTranscriptionProcessor
        .getTranscriptionChanges()
        .some(
          (change) =>
            change?.type === 'photo_highlight' &&
            change?.newPhotoHighlightId === id,
        ) ||
      this.rootStore.transcriptionStore
        .getFinalTranscriptionElements()
        ?.some(
          (el) =>
            ['text', 'punc'].includes(el?.type) &&
            el?.photo_highlight_id === id,
        )
    );
  }

  removeAPhotoHighlightTranscriptChange(id: string) {
    this.videoTranscriptionProcessor.removeSinglePhotoHighlight(id);
    this.createUndoPointForTranscription();
  }

  handleResetPhotoHighlight(
    element: ElementState,
    start: string | null = null,
    length: string | null = null,
  ) {
    const elementId = element.source.id;
    const hasHighlight = this.hasPhotoHighlight(elementId);
    if (!hasHighlight) return;
    this.removeAPhotoHighlightTranscriptChange(elementId);

    const time = start !== null ? start : element.source.time;
    const duration = length || element.duration;

    let startIndex;
    let endIndex;
    if (time !== null) {
      startIndex = this.videoTranscriptionProcessor.findClosestIndexToTimestamp(
        parseFloat(time),
      );
    }

    if (duration) {
      const endTime = parseFloat(time) + parseFloat(duration.toString());
      endIndex = this.videoTranscriptionProcessor.findClosestIndexToTimestamp(
        endTime,
        'end_ts',
        'ts_lookbehind',
      );
    }

    if (startIndex !== undefined && endIndex !== undefined) {
      this.videoTranscriptionProcessor.addPhotoHighlight(
        startIndex,
        endIndex,
        elementId,
      );
    }
  }

  //todo move to VideoProcessor
  async cutCurrentTrack() {
    const activeElement = this.getActiveElement()!;
    await this.reframingModeManager.exitReframingMode();

    const renderer = this.renderer!;
    const source = renderer.getSource(renderer.state);

    const elementIndex = source.elements.findIndex(
      (el: any) => el.id === activeElement.source.id,
    );
    const elementSource = source.elements[elementIndex];

    if (
      activeElement.globalTime > this.time ||
      activeElement.globalTime + activeElement.duration < this.time
    )
      return;

    this.saveVideoStateSnapshot(undefined, `cutting track`);
    const hasHighlight = this.hasPhotoHighlight(elementSource.id);

    // create head and tail elements
    const tailElementSource = {
      ...elementSource,
      id: uuid(),
      audio_fade_in: 0,
      animations: elementSource.animations?.filter(
        (a: any) => a.time === 'end',
      ),
    };
    const headElementSource = {
      ...elementSource,
      id: uuid(),
      audio_fade_out: 0,
      animations: elementSource.animations?.filter(
        (a: any) => a.time === 'start' || a.time === 0,
      ),
    };
    headElementSource.duration = this.time - (activeElement.globalTime || 0);
    tailElementSource.time = this.time;
    tailElementSource.duration =
      activeElement.duration - headElementSource.duration;
    tailElementSource.trim_start =
      (parseFloat(elementSource.trim_start || '0') || 0) +
      this.time -
      (activeElement.globalTime || 0);

    // get original volumeKeyPoints
    const volumeKeyPoints = (
      this.currentVideo!.extraElementData[
        elementSource.id
      ] as ExtraElementData | null
    )?.volumeKeyPoints;

    const fadeProducer = new FadeProducer(this, activeElement);
    fadeProducer.processCrossfadeOnElementCut(
      headElementSource,
      tailElementSource,
      source.elements,
    );

    source.elements.splice(
      elementIndex,
      1,
      headElementSource,
      tailElementSource,
    );

    // split volumeKeyPoints if exist
    if (volumeKeyPoints) {
      const headVolumeKeyPoints = volumeKeyPoints.filter(
        (kp) => parseFloat(kp.time) < headElementSource.duration,
      );
      const tailVolumeKeyPoints = volumeKeyPoints.filter(
        (kp) => parseFloat(kp.time) >= headElementSource.duration,
      );

      // figure out the volume at the cut point and add it to the head and the tail at the cut point
      // by interpolating the volume between the two closest volumeKeyPoints to the cut point
      const relativeTime = this.time - activeElement.globalTime;
      const prevVolumeKeyPoint =
        headVolumeKeyPoints[headVolumeKeyPoints.length - 1];
      const nextVolumeKeyPoint = tailVolumeKeyPoints[0];
      const prevTime = parseFloat(prevVolumeKeyPoint.time);
      const nextTime = parseFloat(nextVolumeKeyPoint.time);
      const prevVolume = parseFloat(prevVolumeKeyPoint.value);
      const nextVolume = parseFloat(nextVolumeKeyPoint.value);
      const volume =
        prevVolume +
        ((nextVolume - prevVolume) * (relativeTime - prevTime)) /
          (nextTime - prevTime);
      headVolumeKeyPoints.push({
        time: headElementSource.duration,
        value: `${volume} %`,
      });
      tailVolumeKeyPoints.unshift({ time: `${0} s`, value: `${volume} %` });

      this.currentVideo!.extraElementData[headElementSource.id] = {
        ...(this.currentVideo!.extraElementData[headElementSource.id] || {}),
        volumeKeyPoints: headVolumeKeyPoints,
      };
      this.currentVideo!.extraElementData[tailElementSource.id] = {
        ...(this.currentVideo!.extraElementData[tailElementSource.id] || {}),
        volumeKeyPoints: tailVolumeKeyPoints,
      };
    }

    await this.renderer!.setSource(source, true);
    await fadeProducer.processCrossfadeOnOriginalVideoCut();

    if (hasHighlight) {
      const headStartIndex =
        this.videoTranscriptionProcessor.findClosestIndexToTimestamp(
          headElementSource.time,
        );
      const headEndIndex =
        this.videoTranscriptionProcessor.findClosestIndexToTimestamp(
          headElementSource.time + headElementSource.duration,
          'end_ts',
        );

      const tailStartIndex =
        this.videoTranscriptionProcessor.findClosestIndexToTimestamp(
          tailElementSource.time,
        );
      const tailEndIndex =
        this.videoTranscriptionProcessor.findClosestIndexToTimestamp(
          tailElementSource.time + tailElementSource.duration,
          'end_ts',
        );

      this.punchListManager.addCutPhotoToPunchlist(
        elementSource.id,
        {
          id: headElementSource.id,
          transcriptPosition: {
            startIndex: headStartIndex,
            endIndex: headEndIndex,
          },
        },
        {
          id: tailElementSource.id,
          transcriptPosition: {
            startIndex: tailStartIndex,
            endIndex: tailEndIndex,
          },
        },
      );
      this.resetPhotoHighlights();
    }

    this.createDefaultUndoPoint();
    await this.setActiveElements(tailElementSource.id);
  }

  timeout(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async sleep(fn: Function, delay: number, ...args: any[]) {
    return fn(...args);
  }

  removePosterFromVideoElements(elements: any[]) {
    const POSTER_TRACK = 100;
    return elements.filter((s: any) => s.track !== POSTER_TRACK) || [];
  }

  shouldAdjustSourceAspectRatio(source: Record<string, any>): boolean {
    const EPSILON = 0.000001;
    const sourceAspectRatio = source.width / source.height;

    let desiredAspectRatio: number;
    const aspectRatio = this.currentVideo?.aspectRatio || AspectRatio.AR_16_9;
    switch (aspectRatio) {
      case AspectRatio.AR_1_1:
        desiredAspectRatio = 1;
        break;
      case AspectRatio.AR_9_16:
        desiredAspectRatio = 9 / 16;
        break;
      default:
        desiredAspectRatio = 16 / 9;
    }

    return Math.abs(sourceAspectRatio - desiredAspectRatio) > EPSILON;
  }

  setVideoDimensionByAspectRatio(source: Record<string, any>) {
    const aspectRatio = this.currentVideo?.aspectRatio || AspectRatio.AR_16_9;

    switch (aspectRatio) {
      case AspectRatio.AR_1_1:
        source.width = source.height;
        break;
      case AspectRatio.AR_9_16:
        source.width = source.height;
        source.height = (source.width * 16) / 9;
        break;
      case AspectRatio.AR_16_9:
        source.width = (source.height * 16) / 9;
    }
    return source;
  }

  setElementsDuration(source: Record<string, any>) {
    source.elements.forEach((element: any) => {
      if (element.type === 'audio' && !element.duration) {
        const stockSongs = this.stockMusic.flatMap((m) => m.collection) || [];
        const ownSongs = this.story?.myAudios?.map((o) => o.song) || [];
        const allSongs = [...stockSongs, ...ownSongs];

        const song = allSongs.find((song) => song.url === element.source);
        if (song) {
          const trimStart = parseFloat(element.trim_start || '0');
          element.duration = parseFloat(song.customData.duration) - trimStart;
        }
      }
    });
    return source;
  }

  async finishVideo(
    resLevel?: VideoResolution,
    renderUsingChunks: boolean = false,
  ): Promise<any> {
    const renderer = this.renderer;
    if (!renderer) {
      return;
    }

    let renderUrl = '';
    let webhook_url = '';
    if (process.env.REACT_APP_API_URL) {
      renderUrl = `${process.env.REACT_APP_API_URL}/api/render`;
      webhook_url = `${process.env.REACT_APP_API_URL}/api/webhooks/render`;
    }

    if (!renderUrl || !webhook_url) {
      throw Error('Render URL is not defined');
    }

    if (this.renderQueueing || this.isLoading || !renderer.state) {
      // wait when queued rendering is started and video is loaded
      await new Promise((resolve) => {
        const interval = setInterval(() => {
          if (!this.renderQueueing && !this.isLoading && renderer.state) {
            clearInterval(interval);
            resolve(null);
          }
        }, 300);
      });
    }

    if (this.isError) {
      throw Error('Error occurred during video loading, cannot render');
    }

    this.renderQueueing = true;
    this.renderingStatus = 'none';

    try {
      let source = this.reframingModeManager.restoreVideoSourceForExit(
        renderer.getSource(renderer.state),
      );
      this.currentVideo!.videoSource!.elements =
        this.removePosterFromVideoElements(
          this.currentVideo!.videoSource.elements,
        );
      const asFinal = false;
      const withRenderer = true;
      const resetTimeline = false;

      await this.saveStoryAndVideo(asFinal, withRenderer, resetTimeline);
      let videoId = this.currentVideo!.id!;
      this.renderVideoResLevel = resLevel || null;

      try {
        // do not await
        const captionService = new CaptionService(
          this,
          this.datoClientStore.storyRepository,
          this.datoClientStore.captionRepository,
          this.datoClientStore.aiPromptRepository,
        );
        captionService.generateAllCaptions(videoId, 'Social Posts');
      } catch (error) {
        console.log('An error occurred when generating captions');
      }
      // only takes elements with volumeKeyPoints and sends them to the backend
      const filteredExtraElementData = Object.entries(
        this.currentVideo?.extraElementData || {},
      ).filter(
        ([id, element]) =>
          (element as ExtraElementData | null)?.volumeKeyPoints?.length,
      );

      const mappedExtraElementData = filteredExtraElementData.map(
        ([id, element]) => ({
          id,
          volumeKeyPoints: (element as ExtraElementData).volumeKeyPoints,
        }),
      );

      const recreatedElementsData = mappedExtraElementData.reduce(
        (obj, element) => {
          obj[element.id] = element;
          return obj;
        },
        {} as any,
      );

      //Remove temporary poster if applied
      source.elements = this.removePosterFromVideoElements(source.elements);
      if (resLevel && resLevel !== VideoResolution.Default) {
        source = this.replaceWithValidVideoSourceUrl(source, resLevel);
        const { width, height } = this.getDimensionsForResLevel(
          { width: source.width, height: source.height },
          resLevel,
        );
        source.width = width;
        source.height = height;
      }
      const sourceVideoUrl = this.getValidVideoSourceUrlForResLevel(
        resLevel ?? VideoResolution.Default,
      );
      if (this.shouldAdjustSourceAspectRatio(source)) {
        source = this.setVideoDimensionByAspectRatio(source);
      }
      source = this.setElementsDuration(source);
      source.duration = this.duration;

      analytics.track('video_published', {
        storyId: this.story?.id,
        storyName: this.storyName,
        videoId: this.currentVideo!.id,
        videoTitle: this.currentVideo!.title,
        resLevel: resLevel || 'default',
      });

      await fetch(renderUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          source,
          extraElementData: recreatedElementsData,
          videoId,
          videoTitle: this.currentVideo!.title,
          organizationId: this.organization?.id,
          organizationName: this.organization?.title,
          ...(!this.story?.useAws && {
            datoVideoUrl: this.story!.originalVideo.url,
            datoVideoUploadId: this.story!.originalVideo.id,
          }),
          storyId: this.story?.id,
          storyName: this.storyName,
          webhook_url: webhook_url,
          aspectRatio: this.currentVideo!.aspectRatio,
          renderUsingChunks:
            renderUsingChunks &&
            (this.story?.useAws || resLevel === VideoResolution.Original),
          sourceVideoUrl,
          trackedUserData: this.rootStore.analyticsStore.getTrackedUserData(),
        }),
      });

      this.renderingStatus = 'rendering';
      this.renderingVideoIds.push(this.currentVideo!.id!);
    } catch (err) {
      throw err;
    } finally {
      this.renderQueueing = false;
    }
  }

  async handleVideoRenderingStatusUpdate(video: VideoRenderingStatus) {
    if (
      (video.videoStatus === 'rendered' && !video.videoFilePrimary?.video) ||
      video.videoStatus === 'rendering'
    ) {
      return;
    }
    this.renderingStatus = video.videoStatus;
    let videoPublished: VideoClip | AssociatedVideo | undefined;
    let videoTitle: string | undefined;

    // find video in story other videos or their associated videos with the same id as in video rendering status
    for (const storyVideo of this.story?.otherVideos!) {
      if (storyVideo.id === video.id) {
        videoPublished = storyVideo;
      } else {
        videoPublished = storyVideo.associatedVideos.find(
          (v) => v.id === video.id,
        );
      }
      if (videoPublished) {
        videoTitle = storyVideo.title; // associated video doesn't contain title
        break;
      }
    }

    if (videoPublished) {
      videoPublished.videoFilePrimary = video.videoFilePrimary;
      videoPublished.isHidden = video.isHidden;
      videoPublished.lastActionJson = video.lastActionJson;
    }
    if (this.currentVideo?.id === video.id) {
      this.currentVideo!.videoFilePrimary = video.videoFilePrimary;
      this.currentVideo!.isHidden = video.isHidden;
      this.currentVideo!.lastActionJson = video.lastActionJson;
    }
    if (this.renderingStatus !== 'error') this.renderVideoResLevel = null;
    this.renderingVideoIds = this.renderingVideoIds.filter(
      (id) => id !== video.id,
    );

    this.currentVideoVersionsTotalPages = undefined;
    this.loadCurrentVideoVersionsHistory(0);

    this.showRenderFinishedNotification(
      video,
      videoTitle || this.currentVideo?.title,
    );
  }

  private showRenderFinishedNotification(
    renderUpdate: VideoRenderingStatus,
    videoTitleRaw: string | undefined,
  ) {
    const videoTitle = videoTitleRaw ? ` ’${videoTitleRaw}’` : '';

    let state: 'error' | 'success';
    let message: string;
    switch (renderUpdate.videoStatus) {
      case 'rendered':
        state = 'success';
        message = `Your video${videoTitle} was successfully published!`;
        break;
      case 'error':
        state = 'error';
        message = `Publishing failed${
          videoTitle ? ' of' : ''
        }${videoTitle}, please contact Arbor`;
        break;
      default:
        return;
    }

    this.toastState = {
      state,
      message,
      delay: -1,
      position: 'top',
    };
  }

  get canUndo() {
    return this.undoStack.length > 0;
  }

  getUndoLabel() {
    return this.videoSnapshots.at(-1)?.label;
  }

  getRedoLabel() {
    return this.redoSnapshots.at(-1)?.label;
  }

  get canRedo() {
    return this.redoStack.length > 0;
  }

  get isVideoCreatorReady() {
    return !this.isLoading && this.stateReady;
  }

  findOrReplaceInTimeline = async (
    id: string,
    source: string,
    track: number | null = null,
    time: number | null = null,
    artifactAspectRatio: number | null = null,
  ) => {
    const freeTrack = this.getFreeMediaTrack('image', 8);
    const videoAspectRatio =
      this.currentVideo?.aspectRatio || AspectRatio.AR_16_9;
    const animations = [
      {
        start_scale: '100%',
        end_scale: DEFAULT_ANIMATION_SPEED,
        x_anchor: '50%',
        y_anchor: '50%',
        fade: false,
        scope: 'element',
        easing: 'linear',
        type: 'scale',
        arbor_subType: 'zoomIn',
      },
    ];

    const element = this.state!.elements?.find((e) => e.source.id === id);
    const hasId = !!element;
    const isPortrait = !!artifactAspectRatio && artifactAspectRatio < 1;
    if (hasId) {
      const modifications =
        isPortrait && videoAspectRatio === AspectRatio.AR_16_9
          ? {
              [`${id}.type`]: 'composition',
              [`${id}.source`]: null,
              [`${id}.elements`]: getImageWithBlackFrameElements({
                source,
              }),
              [`${id}.animations`]: animations,
            }
          : {
              [`${id}.type`]: 'image',
              [`${id}.source`]: source,
              [`${id}.fit`]: 'cover',
              [`${id}.smart_crop`]: true,
              [`${id}.elements`]: [],
              [`${id}.animations`]: animations,
            };
      await this.reframingModeManager.exitReframingMode();
      if (
        !this.hasDefaultAspectRatio() &&
        modifications[`${id}.fit`] === 'cover'
      ) {
        await this.adjustModificationsToAspectRatio(modifications, {
          ...this.getElementSource(element),
          fit: 'cover',
          type: 'image',
          source,
        });
      }
      await this.renderer?.applyModifications(modifications);
      await this.setActiveElements(id);
      this.createDefaultUndoPoint();
    } else {
      const element =
        isPortrait && videoAspectRatio === AspectRatio.AR_16_9
          ? {
              id,
              type: 'composition',
              duration: '8 s',
              elements: getImageWithBlackFrameElements({
                source,
              }),
              animations,
            }
          : {
              id,
              type: 'image',
              source,
              duration: '8 s',
              autoplay: true,
              fit: 'cover',
              smart_crop: true,
              ...(time && { time }),
              ...(freeTrack && { track: freeTrack }),
              animations,
            };
      await this.createElement(element);
    }
  };

  setAiImageFeatureFlag(flagString: string) {
    if (!this.story?.id) return;
    if (this.datoClientStore.environment !== 'production') return;
    const flags = flagString?.trim()?.split(',') || [];
    if (!flags.includes(this.story.id)) {
      this.disableAiImageGenerate = true;
    }
  }

  getSourceVideoFrameRate() {
    return this.story?.originalVideo?.video?.framerate;
  }

  getDefaultSource(params?: { videoRes: VideoResolution }) {
    const id = uuid();
    const videoRes = params?.videoRes;

    let sourceUrl = this.getValidVideoSourceUrlForResLevel(
      videoRes || VideoResolution.Original,
    );

    return {
      output_format: 'mp4',
      width: VIDEO_DEFAULT_WIDTH,
      height: VIDEO_DEFAULT_HEIGHT,
      frame_rate: '24 fps',
      // duration: this.originalVideoDuration, - let duration autoadjust
      elements: [
        {
          id,
          duration: this.originalVideoDuration,
          track: 1,
          time: 0,
          type: 'video',
          source: sourceUrl,
          autoplay: true,
          // locked: true,
        },
      ],
    };
  }

  getFreeMediaTrack(
    type: 'audio' | 'video' | 'image',
    mediaDuration: number,
    currTime = this.time,
  ) {
    const tracks = this.renderer?.state?.elements?.filter((el) => {
      if (type !== 'image')
        return (
          el.source.type === type && !this.isOriginalVideoElement(el.source)
        );
      return this.isImageElement(el) && !this.isLogoElement(el);
    });

    const mediaSpan = currTime + mediaDuration;

    const overlappedTracks =
      tracks
        ?.filter((track) => {
          const time = track.source.time;
          const duration = track.duration;
          const timeSpan = time + duration;
          if (
            (time <= currTime && timeSpan >= currTime) ||
            (time <= mediaSpan && timeSpan >= mediaSpan)
          ) {
            return true;
          }
          return false;
        })
        ?.map((t) => t.track) || [];

    return tracks?.find((t) => !overlappedTracks.includes(t.track))?.track;
  }

  getFreeMediaDuration(mediaDuration: number, currTime = this.time): number {
    return Math.min(mediaDuration, this.duration - currTime);
  }

  fixTranscription() {
    this.rootStore.analyticsStore.trackEvent({
      eventName: 'fix_transcription',
      label: 'Repair transcription icon is clicked',
      extraProperties: {
        storyId: this.story?.id,
        storyTitle: this.story?.title,
        videoId: this.currentVideo?.id,
        videoTitle: this.currentVideo?.title,
      },
    });
    const source = this.renderer?.getSource();
    const state = this.renderer?.state;
    if (!source || !state) {
      throw Error('No source or state found to fix transcription');
    }

    const restoredTranscriptionChanges = restoreTranscriptionFromVideoSource(
      this,
      state.elements.filter((el) => this.isOriginalVideoElement(el.source)),
      this.rootStore.transcriptionStore.getOriginalTranscription()!,
    );

    const oldTranscriptElements = [
      ...this.rootStore.transcriptionStore.getFinalTranscriptionElements(),
    ];

    this.videoTranscriptionProcessor.applyChangesToOriginalTranscription(
      restoredTranscriptionChanges,
    );
    this.videoTranscriptionProcessor.reapplyEdits(oldTranscriptElements);

    this.resetPhotoHighlights();
    this.refreshKaraokeElements();
  }

  handleUpdateMyAudioMetadata(audios: myAudio[]) {
    const existingAudios = this.story?.myAudios || [];
    const updatedmyAudios = existingAudios.map((audio) => {
      const currAudio = audios.find((a) => a.id === audio.id);
      console.log('currAudio', currAudio);
      if (currAudio) {
        if (currAudio.song.url && !this.audioTracksData[currAudio.song.url]) {
          this.loadWaveformForSource({
            source: currAudio.song.url,
            name: currAudio.song.title,
          });
        }
        return currAudio;
      }
      return audio;
    });

    const isCompleted = audios.every(
      (audio) => audio.metadataStatus === 'success' && audio.waveformUploadId,
    );

    const isFailed = audios.some((audio) => audio.metadataStatus === 'failed');

    if (isCompleted) {
      this.toastState = {
        state: 'success',
        message: 'Audio Uploaded',
      };
    }

    if (isFailed) {
      this.toastState = {
        state: 'error',
        message: 'Audio Upload failed, please contact arbor',
      };
    }

    this.story!.myAudios = updatedmyAudios;
  }

  addUnsaveVideoAction(): void {
    this.addVideoAction({
      editor: this.rootStore.userIdentityStore.currentEditor,
      type: 'unsave',
      date: new Date().getTime(),
    });
  }

  private addVideoAction(newVideoAction: VideoAction): void {
    if (!this.currentVideo) {
      return;
    }
    this.currentVideo!.lastActionJson = newVideoAction;
    for (const storyVideo of this.story?.otherVideos || []) {
      if (storyVideo.id === this.currentVideo.id) {
        storyVideo.lastActionJson = newVideoAction;
      }
    }
  }

  async findOneStory(id: string): Promise<Story | null> {
    const story = await this.datoClientStore.storyRepository?.findOne(id);

    if (
      story &&
      this.rootStore.userIdentityStore.currentUserType === 'external'
    ) {
      story.otherVideos = story.otherVideos.filter((c) => c.isClientReady);
    }

    return story || null;
  }

  async findManyStories(albumId: string) {
    let stories =
      (await this.datoClientStore.storyRepository?.findMany(albumId, {
        includeDrafts: true,
        excludeInvalid: false, // newly added stories may not have originalVideo.video field available yet, which will throw an error
        environment: this.rootStore.userIdentityStore.environment,
      })) || [];

    if (this.rootStore.userIdentityStore.currentUserType === 'external') {
      stories = stories.map((s) => ({
        ...s,
        visibleVideos: s.otherVideos.filter((v) => v.isClientReady),
      }));
    }

    stories.sort((a, b) => {
      // show unpublished stories first
      const aDate = a._publishedAt ? new Date(a._publishedAt) : new Date();
      const bDate = b._publishedAt ? new Date(b._publishedAt) : new Date();
      return bDate.getTime() - aDate.getTime();
    });

    return stories;
  }

  // Condensed debug data set for displaying in DebugMode.
  getDebugContent() {
    const videoSource = this.currentVideo?.videoSource ?? {};
    const { elements, ...videoSourceWithoutElements } = videoSource;

    const {
      transcriptionChanges,
      transcriptionSnapshot,
      ...currentVideoWithoutTranscriptions
    } = this.currentVideo ?? {};

    return {
      time: this.time,
      duration: this.duration,
      currentVideo: {
        ...currentVideoWithoutTranscriptions,
        videoSource: videoSourceWithoutElements,
      },
      // currentVideoVersions: this.currentVideoVersions,
    };
  }

  async createStory(story: Partial<StoryDTO>): Promise<DatoStory> {
    if (!this.datoClientStore.storyRepository) {
      throw new Error('createStory: no story repo');
    }
    return await this.datoClientStore.storyRepository.create(story);
  }

  async updateStory(
    story: Partial<StoryDTO> & { id: string },
  ): Promise<DatoStory> {
    return await this.datoClientStore.storyRepository!.update(story);
  }

  async softDeleteStory(storyId: string) {
    if (!this.datoClientStore.storyRepository) {
      throw new Error('deleteStory: no story repo');
    }
    if (this.organization) {
      return await this.datoClientStore.storyRepository.removeReferenceFromShowcase(
        storyId,
        this.organization.id,
      );
    }
  }

  async upsertStoryTeller({
    name,
    title,
  }: {
    name: string;
    title?: string;
  }): Promise<Person> {
    if (!this.datoClientStore.gqlClient) {
      throw new Error('findOrCreateStoryTeller: no gqlClient');
    }
    const response = (await this.datoClientStore.gqlClient.request(
      PERSON_BY_NAME_QUERY,
      {
        name,
      },
    )) as { allPeople: (Person & { id: string })[] };
    if (response.allPeople.length) {
      const person = response.allPeople[0];
      if (person.title !== title && title) {
        this.datoClientStore.datoClient?.items.update(person.id, {
          title,
        });
        person.title = title;
      }
      return person;
    }

    if (!this.datoClientStore.datoClient) {
      throw new Error('findOrCreateStoryTeller: no datoClient');
    }
    const itemType =
      await this.datoClientStore.datoClient.itemTypes.find('person');
    const newPerson = await this.datoClientStore.datoClient.items.create({
      item_type: { type: 'item_type', id: itemType.id },
      name,
      title,
    });
    return {
      id: newPerson.id,
      name: newPerson.name,
      title: newPerson.title,
    } as Person;
  }

  setShowSubtitles(showSubtitles: boolean) {
    this.showSubtitles = showSubtitles;
  }

  getShowSubtitles(): boolean {
    return this.showSubtitles;
  }

  hasDefaultAspectRatio(): boolean {
    const aspectRatio = this.currentVideo?.aspectRatio || AspectRatio.AR_16_9;
    return aspectRatio === AspectRatio.AR_16_9;
  }

  setFlags(flags: Record<string, boolean>) {
    if (inDebugMode()) {
      console.debug('Setting flags', flags);
    }
    this.reframingModeManager.setFlags(flags);
  }

  handleRenderingError(error: any) {
    this.renderingStatus = 'error';
    this.rootStore.analyticsStore.trackEvent({
      eventName: 'client_rendering_error',
      label: 'RENDER VIDEO | CLIENT SIDE ERROR',
      extraProperties: {
        organizationId: this.organization?.id,
        organizationName: this.organization?.title,
        storyId: this.story?.id,
        storyTitle: this.story?.title,
        videoId: this.currentVideo?.id,
        videoTitle: this.currentVideo?.title,
        error: error?.message,
        stack: error?.stack,
      },
      logMethod: 'error',
    });
  }

  clearLogoElementsMetadata(ids: string[]) {
    if (!this.currentVideo) {
      return;
    }
    for (const id of ids) {
      delete this.currentVideo.extraElementData[`logo_el_${id}`];
    }
  }
}

export default VideoCreatorStore;
