export const CutTrackIcon = () => {
  return (
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none">
      <path
        d="M4.11111 3L1.88889 3C1.39797 3 1 3.27552 1 3.61538L1 10.3846C1 10.7245 1.39797 11 1.88889 11H4.11111C4.60204 11 5 10.7245 5 10.3846L5 3.61538C5 3.27552 4.60204 3 4.11111 3Z"
        stroke="#F2D093"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1111 3L11.8889 3C11.398 3 11 3.27552 11 3.61538L11 10.3846C11 10.7245 11.398 11 11.8889 11H14.1111C14.602 11 15 10.7245 15 10.3846V3.61538C15 3.27552 14.602 3 14.1111 3Z"
        stroke="#F2D093"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M8 1V13" stroke="#F2D093" strokeLinecap="round" />
    </svg>
  );
};

export const TextIcon = (props: {
  width?: string;
  height?: string;
  fillColor?: string;
  viewBox?: string;
}) => (
  <svg
    width={props.width || '24'}
    height={props.height || '24'}
    viewBox={props.viewBox || '0 0 24 24'}
    fill={props.fillColor}
  >
    <path d="M18.5,4L19.66,8.35L18.7,8.61C18.25,7.74 17.79,6.87 17.26,6.43C16.73,6 16.11,6 15.5,6H13V16.5C13,17 13,17.5 13.33,17.75C13.67,18 14.33,18 15,18V19H9V18C9.67,18 10.33,18 10.67,17.75C11,17.5 11,17 11,16.5V6H8.5C7.89,6 7.27,6 6.74,6.43C6.21,6.87 5.75,7.74 5.3,8.61L4.34,8.35L5.5,4H18.5Z" />
  </svg>
);

export const TranscriptHistoryIcon = ({
  strokeColor = '#484848',
}: {
  strokeColor?: string;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M11.3474 9.81641C11.6106 10.1233 11.8082 10.4808 11.9282 10.8668C12.0483 11.2528 12.0881 11.6593 12.0454 12.0614C12.0026 12.4633 11.8782 12.8524 11.6797 13.2046C11.4811 13.5567 11.2128 13.8646 10.891 14.1094C10.0378 14.6917 9.03786 15.0219 8.00568 15.0622C6.97351 15.1026 5.95083 14.8515 5.05478 14.3375L4.07666 13.7833"
      stroke={strokeColor}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.2605 1.82838C9.08689 0.752439 4.9896 0.11122 4.0658 3.1543C3.93266 3.60027 3.91774 4.0732 4.02255 4.52667C4.12734 4.98015 4.34823 5.39858 4.66355 5.74091C5.52984 6.41761 6.47952 6.98012 7.48926 7.41461"
      stroke={strokeColor}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M0.880859 7.45239H15.119"
      stroke={strokeColor}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const CopyIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <g>
      <rect x="5" y="1" width="10" height="10" rx="2" stroke="currentColor" />
      <path
        d="M3.72727 5H3C1.89543 5 1 5.89543 1 7V13C1 14.1046 1.89543 15 3 15H9C10.1046 15 11 14.1046 11 13V12.2727"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export const HistoryIcon = (props: {
  width?: string;
  height?: string;
  viewBox?: string;
  strokeColor?: string;
}) => (
  <svg
    width={props.width || '24'}
    height={props.height || '24'}
    viewBox={props.viewBox || '0 0 16 16'}
    color={props.strokeColor}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2768_10872)">
      <path
        d="M8 4.28516V8.53005L10.7592 9.90965"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.7129 9.59136C13.9943 12.6336 11.2613 14.8975 7.99952 14.8975C4.18988 14.8975 1.10156 11.8092 1.10156 7.99952C1.10156 4.18988 4.18988 1.10156 7.99952 1.10156C10.6608 1.10156 12.9701 2.60862 14.1205 4.81585"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <path
        d="M14.8972 2.69336V5.34642H12.2441"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2768_10872">
        <rect
          width={props.width || '16'}
          height={props.width || '16'}
          fill="currentColor"
        />
      </clipPath>
    </defs>
  </svg>
);

export const EditIcon = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.8837 21.5976L8.21191 22L8.6168 17.3574L18.3341 7.76261C18.4793 7.61522 18.6524 7.4981 18.8437 7.41814C19.0347 7.33818 19.2401 7.297 19.4476 7.297C19.655 7.297 19.8604 7.33818 20.0516 7.41814C20.2429 7.4981 20.416 7.61522 20.561 7.76261L22.5387 9.74346C22.6847 9.88734 22.8005 10.0585 22.8796 10.2471C22.9587 10.4357 22.9994 10.6379 22.9994 10.8422C22.9994 11.0465 22.9587 11.2488 22.8796 11.4374C22.8005 11.626 22.6847 11.7971 22.5387 11.941L12.8837 21.5976Z"
      stroke="#F2D093"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.40987 7.31419C2.86338 7.2197 2.86338 6.44009 3.40987 6.34561C5.38972 6.00331 6.9644 4.50474 7.39414 2.5539L7.42708 2.40436C7.5453 1.86762 8.31436 1.86428 8.43731 2.39997L8.4773 2.57424C8.92294 4.51588 10.4981 6.0018 12.4724 6.34314C13.0217 6.4381 13.0217 7.22169 12.4724 7.31665C10.4981 7.65799 8.92294 9.14391 8.4773 11.0856L8.43731 11.2598C8.31436 11.7955 7.5453 11.7922 7.42708 11.2554L7.39414 11.1059C6.9644 9.15505 5.38972 7.65648 3.40987 7.31419Z"
      stroke="#F2D093"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ProduceIcon = (props: {
  width?: string;
  height?: string;
  fillColor?: string;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || '16'}
    height={props.height || '16'}
    viewBox="0 0 16 16"
    fill="none"
  >
    <g clipPath="url(#clip0_4500_1401)">
      <path
        d="M14.0237 0.880981H1.9761C1.37121 0.880981 0.880859 1.37134 0.880859 1.97622V14.0238C0.880859 14.6287 1.37121 15.1191 1.9761 15.1191H14.0237C14.6286 15.1191 15.119 14.6287 15.119 14.0238V1.97622C15.119 1.37134 14.6286 0.880981 14.0237 0.880981Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.06152 10.4425V5.71111C6.06194 5.63194 6.08326 5.5543 6.12336 5.48604C6.16346 5.41777 6.22088 5.36132 6.28982 5.32241C6.35876 5.28349 6.43675 5.26349 6.51592 5.26444C6.59508 5.26538 6.67258 5.28723 6.74057 5.32777L10.793 7.68254C10.8612 7.72196 10.9178 7.77864 10.9572 7.8469C10.9966 7.91514 11.0173 7.99255 11.0173 8.07135C11.0173 8.15015 10.9966 8.22756 10.9572 8.2958C10.9178 8.36406 10.8612 8.42074 10.793 8.46015L6.74057 10.8259C6.67258 10.8664 6.59508 10.8883 6.51592 10.8892C6.43675 10.8902 6.35876 10.8702 6.28982 10.8312C6.22088 10.7923 6.16346 10.7359 6.12336 10.6676C6.08326 10.5994 6.06194 10.5217 6.06152 10.4425Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_4500_1401">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const CommaIcon = (props: {
  width?: string;
  height?: string;
  fillColor?: string;
}) => {
  return (
    <svg
      width="6"
      height="11"
      viewBox="0 0 6 11"
      fill={props.fillColor || '#484848'}
    >
      <path d="M5.24613 0.92C5.40613 1.66667 5.48613 2.62667 5.48613 3.8C5.48613 4.97333 5.01947 6.24 4.08613 7.6C3.17947 8.96 2.33947 9.88 1.56613 10.36C1.1128 10.1733 0.686133 9.86667 0.286133 9.44C0.579466 8.56 1.41947 7.32 2.80613 5.72C3.12613 5.34667 3.3128 5.13333 3.36613 5.08C2.4328 5.08 1.7928 5.05333 1.44613 5C1.28613 4.46667 1.20613 3.77333 1.20613 2.92C1.20613 2.04 1.2328 1.41333 1.28613 1.04C1.8728 0.906667 2.48613 0.84 3.12613 0.84C3.7928 0.84 4.49947 0.866667 5.24613 0.92Z" />
    </svg>
  );
};

export const PeriodIcon = (props: {
  width?: string;
  height?: string;
  fillColor?: string;
}) => {
  return (
    <svg
      width="6"
      height="6"
      viewBox="0 0 6 6"
      fill={props.fillColor || '#484848'}
    >
      <path d="M5.20187 5.356C4.36987 5.548 3.55387 5.644 2.75387 5.644C1.98587 5.644 1.21787 5.596 0.449867 5.5C0.257867 4.604 0.161867 3.708 0.161867 2.812C0.161867 1.884 0.193867 1.196 0.257867 0.748C0.961867 0.588 1.69787 0.508 2.46587 0.508C3.26587 0.508 4.11387 0.54 5.00987 0.604C5.20187 1.436 5.29787 2.252 5.29787 3.052C5.29787 3.82 5.26587 4.588 5.20187 5.356Z" />
    </svg>
  );
};

export const LockIcon = (props: {
  width?: string;
  height?: string;
  strokeColor?: string;
  fillColor?: string;
}) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M8.14286 18C7.82857 18 7.55962 17.8882 7.336 17.6646C7.11238 17.441 7.00038 17.1718 7 16.8571V11.1429C7 10.8286 7.112 10.5596 7.336 10.336C7.56 10.1124 7.82895 10.0004 8.14286 10H8.71429V8.85714C8.71429 8.06667 8.99295 7.39295 9.55029 6.836C10.1076 6.27905 10.7813 6.00038 11.5714 6C12.3615 5.99962 13.0354 6.27829 13.5931 6.836C14.1509 7.39371 14.4293 8.06743 14.4286 8.85714V10H15C15.3143 10 15.5834 10.112 15.8074 10.336C16.0314 10.56 16.1432 10.829 16.1429 11.1429V16.8571C16.1429 17.1714 16.031 17.4406 15.8074 17.6646C15.5838 17.8886 15.3147 18.0004 15 18H8.14286ZM8.14286 16.8571H15V11.1429H8.14286V16.8571ZM11.5714 15.1429C11.8857 15.1429 12.1549 15.031 12.3789 14.8074C12.6029 14.5838 12.7147 14.3147 12.7143 14C12.7139 13.6853 12.6021 13.4164 12.3789 13.1931C12.1556 12.9699 11.8865 12.8579 11.5714 12.8571C11.2564 12.8564 10.9874 12.9684 10.7646 13.1931C10.5417 13.4179 10.4297 13.6869 10.4286 14C10.4274 14.3131 10.5394 14.5823 10.7646 14.8074C10.9897 15.0326 11.2587 15.1444 11.5714 15.1429ZM9.85714 10H13.2857V8.85714C13.2857 8.38095 13.119 7.97619 12.7857 7.64286C12.4524 7.30952 12.0476 7.14286 11.5714 7.14286C11.0952 7.14286 10.6905 7.30952 10.3571 7.64286C10.0238 7.97619 9.85714 8.38095 9.85714 8.85714V10Z"
        fill={props.fillColor || '#F2D093'}
      />
    </svg>
  );
};

export const RepairIcon = (props: {
  width?: string;
  height?: string;
  strokeColor?: string;
  fillColor?: string;
}) => {
  return (
    <svg
      fill={props.fillColor || '#484848'}
      height={props.height || '16px'}
      width={props.width || '16px'}
      viewBox="0 0 231.233 231.233"
    >
      <path
        d="M230.505,102.78c-0.365-3.25-4.156-5.695-7.434-5.695c-10.594,0-19.996-6.218-23.939-15.842
      c-4.025-9.855-1.428-21.346,6.465-28.587c2.486-2.273,2.789-6.079,0.705-8.721c-5.424-6.886-11.586-13.107-18.316-18.498
      c-2.633-2.112-6.502-1.818-8.787,0.711c-6.891,7.632-19.27,10.468-28.836,6.477c-9.951-4.187-16.232-14.274-15.615-25.101
      c0.203-3.403-2.285-6.36-5.676-6.755c-8.637-1-17.35-1.029-26.012-0.068c-3.348,0.37-5.834,3.257-5.723,6.617
      c0.375,10.721-5.977,20.63-15.832,24.667c-9.451,3.861-21.744,1.046-28.621-6.519c-2.273-2.492-6.074-2.798-8.725-0.731
      c-6.928,5.437-13.229,11.662-18.703,18.492c-2.133,2.655-1.818,6.503,0.689,8.784c8.049,7.289,10.644,18.879,6.465,28.849
      c-3.99,9.505-13.859,15.628-25.156,15.628c-3.666-0.118-6.275,2.345-6.68,5.679c-1.016,8.683-1.027,17.535-0.049,26.289
      c0.365,3.264,4.268,5.688,7.582,5.688c10.07-0.256,19.732,5.974,23.791,15.841c4.039,9.855,1.439,21.341-6.467,28.592
      c-2.473,2.273-2.789,6.07-0.701,8.709c5.369,6.843,11.537,13.068,18.287,18.505c2.65,2.134,6.504,1.835,8.801-0.697
      c6.918-7.65,19.295-10.481,28.822-6.482c9.98,4.176,16.258,14.262,15.645,25.092c-0.201,3.403,2.293,6.369,5.672,6.755
      c4.42,0.517,8.863,0.773,13.32,0.773c4.23,0,8.461-0.231,12.692-0.702c3.352-0.37,5.834-3.26,5.721-6.621
      c-0.387-10.716,5.979-20.626,15.822-24.655c9.514-3.886,21.752-1.042,28.633,6.512c2.285,2.487,6.063,2.789,8.725,0.73
      c6.916-5.423,13.205-11.645,18.703-18.493c2.135-2.65,1.832-6.503-0.689-8.788c-8.047-7.284-10.656-18.879-6.477-28.839
      c3.928-9.377,13.43-15.673,23.65-15.673l1.43,0.038c3.318,0.269,6.367-2.286,6.768-5.671
      C231.476,120.379,231.487,111.537,230.505,102.78z M115.616,182.27c-36.813,0-66.654-29.841-66.654-66.653
      s29.842-66.653,66.654-66.653s66.654,29.841,66.654,66.653c0,12.495-3.445,24.182-9.428,34.176l-29.186-29.187
      c2.113-4.982,3.229-10.383,3.228-15.957c0-10.915-4.251-21.176-11.97-28.893c-7.717-7.717-17.978-11.967-28.891-11.967
      c-3.642,0-7.267,0.484-10.774,1.439c-1.536,0.419-2.792,1.685-3.201,3.224c-0.418,1.574,0.053,3.187,1.283,4.418
      c0,0,14.409,14.52,19.23,19.34c0.505,0.505,0.504,1.71,0.433,2.144l-0.045,0.317c-0.486,5.3-1.423,11.662-2.196,14.107
      c-0.104,0.103-0.202,0.19-0.308,0.296c-0.111,0.111-0.213,0.218-0.32,0.328c-2.477,0.795-8.937,1.743-14.321,2.225l0.001-0.029
      l-0.242,0.061c-0.043,0.005-0.123,0.011-0.229,0.011c-0.582,0-1.438-0.163-2.216-0.94c-5.018-5.018-18.862-18.763-18.862-18.763
      c-1.242-1.238-2.516-1.498-3.365-1.498c-1.979,0-3.751,1.43-4.309,3.481c-3.811,14.103,0.229,29.273,10.546,39.591
      c7.719,7.718,17.981,11.968,28.896,11.968c5.574,0,10.975-1.115,15.956-3.228l29.503,29.503
      C141.125,178.412,128.825,182.27,115.616,182.27z"
      />
    </svg>
  );
};
