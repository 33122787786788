import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useObserver } from 'mobx-react-lite';
import { LocalizedName, parse as parseFont } from 'opentype.js';
import { get, set } from 'lodash';

import {
  Artifact,
  BrandingCustomFont,
  BrandingFontMetadata,
  FileData,
} from '../../../types.ts/story';
import VideoCreatorStore from '../../../stores/VideoCreatorStore';
import { formatUploadToArtifact } from '../../../utility/general';
import { AlbumRepository, AssetRepository } from '@src/repositories';

declare module 'opentype.js' {
  interface FontNames {
    preferredFamily?: LocalizedName;
    preferredSubfamily?: LocalizedName;
  }
}

export const useFontDropzone = ({
  onDrop,
}: {
  onDrop: (acceptedFiles: File[]) => void;
}) => {
  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    accept: {
      'font/ttf': [],
      'font/otf': [],
      'font/woff': [],
      'font/woff2': [],
    },
    onDrop,
  });
  return { getRootProps, getInputProps, open };
};

const useBrandKitFontUpload = (
  videoCreator: VideoCreatorStore,
  assetRepository: AssetRepository,
  albumRepository: AlbumRepository,
) => {
  // TODO: Store access.
  const organization = useObserver(() => videoCreator.organization);

  const [uploading, setUploading] = useState(false);
  const uploadFontsToBrandKit = useCallback(
    async (files: File[]) => {
      try {
        setUploading(true);
        const requests = files.map(async (file) => {
          const fontData = await parseFontData(file);
          const artifact = await uploadFontArtifact(
            assetRepository,
            file,
            fontData.postScriptName,
          );
          return { artifact, fontData };
        });
        const results = await Promise.all(requests);

        const customFontFiles = results.map((result) => result.artifact);
        set(organization!, 'branding.customFontFiles', [
          ...(get(organization, 'branding.customFontFiles') || []),
          ...customFontFiles,
        ]);

        const customFonts = results.map((result) =>
          buildCustomFont(result.artifact, result.fontData),
        );
        set(organization!, 'branding.customFonts', [
          ...(get(organization, 'branding.customFonts') || []),
          ...customFonts,
        ]);

        await albumRepository?.update(organization!);
      } catch (error) {
        console.error('Custom font upload error:', error);
      } finally {
        setUploading(false);
      }
    },
    [organization],
  );

  const [archiving, setArchiving] = useState(false);
  const archiveFontFromBrandKit = useCallback(
    async (fontToArchive: BrandingCustomFont) => {
      try {
        setArchiving(true);
        const customFonts = get(organization, 'branding.customFonts') || [];
        const updated: BrandingCustomFont[] = customFonts.map((font) =>
          font.artifactId === fontToArchive.artifactId
            ? { ...font, archivedAt: new Date().toISOString() }
            : font,
        );
        set(organization!, 'branding.customFonts', updated);
        await albumRepository?.update(organization!);
      } catch (error) {
        console.error('Custom font archive error:', error);
      } finally {
        setArchiving(false);
      }
    },
    [organization],
  );

  return {
    uploadFontsToBrandKit,
    uploading,
    archiveFontFromBrandKit,
    archiving,
  };
};

export const parseFontData = async (file: File) => {
  const getFontNameString = (name: LocalizedName) =>
    name['en'] || Object.values(name)[0];

  const font = parseFont(await file.arrayBuffer());
  const fontFamily = font.names.preferredFamily || font.names.fontFamily;
  const fontSubfamily =
    font.names.preferredSubfamily || font.names.fontSubfamily;
  const fullName = font.names.fullName;
  const postScriptName = font.names.postScriptName;
  const usWeightClass = font.tables.os2.usWeightClass;
  return {
    fontFamily: getFontNameString(fontFamily),
    fontSubfamily: getFontNameString(fontSubfamily),
    fullName: getFontNameString(fullName),
    postScriptName: getFontNameString(postScriptName),
    usWeightClass,
  } as BrandingFontMetadata;
};

const uploadFontArtifact = async (
  assetRepository: AssetRepository | undefined,
  file: File,
  fontName: string,
) => {
  const fileExtension = file.name.split('.')[1];
  const fileData: FileData & { fileName: string } = {
    type: 'artifact',
    file,
    fileName: `${fontName}.${fileExtension}`,
    title: `${fontName}.${fileExtension}`,
    alt: '',
  };
  const newUpload = await assetRepository?.uploadFile(fileData);
  return formatUploadToArtifact(newUpload!, {});
};

const buildCustomFont: (
  artifact: Artifact,
  fontData: BrandingFontMetadata,
) => BrandingCustomFont = (artifact, fontData) =>
  ({
    artifactId: artifact.id,
    format: artifact.format,
    archivedAt: null,

    // font metadata
    fontFamily: fontData.fontFamily,
    fontSubfamily: fontData.fontSubfamily,
    fullName: fontData.fullName,
    postScriptName: fontData.postScriptName,
    usWeightClass: fontData.usWeightClass,

    // creatomate specific properties
    style: getFontStyle(fontData.fontSubfamily),
    source: artifact.url,
  }) as BrandingCustomFont;

const getFontStyle = (fontSubfamily: string) =>
  fontSubfamily.toLowerCase().includes('italic') ? 'italic' : 'normal';

export default useBrandKitFontUpload;
