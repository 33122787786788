import { TranscriptElement } from '../../types.ts/transcript';

type Range = { fromIndex: number; toIndex: number };

export default class KaraokeTranscriptRange {
  private elements: TranscriptElement[];
  private range: Range;

  constructor(mutableElements: TranscriptElement[], range: Range) {
    this.elements = mutableElements;
    this.range = range;
  }

  public markForReSegmentation(): void {
    for (let i = this.range.fromIndex; i < this.range.toIndex; i++) {
      this.elements[i].should_re_segment = true;
    }
  }

  public unmarkAfterReSegmentation(): void {
    for (let i = this.range.fromIndex; i < this.range.toIndex; i++) {
      delete this.elements[i].should_re_segment;
    }
  }
}
