import { useReducer, useState } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';
import { pickBy } from 'lodash';

import { useStore } from '@src/stores-v2/StoreContext';
import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';
import { BrandingCustomFontItem } from '@src/types.ts/story';

import { useClickAway } from '../../utility/useClickAway';
import { useFontDropzone } from './utils/useBrandKitFontUpload';
import { getCustomFontFromPostScriptName } from '@src/utility/fonts';
import { FONT_SIZE_VALUES } from '../../utility/general';

import Accordion from './Accordion';
import FontDropdownSelect from '../common/FontDropdownSelect';
import ItemDropdownSelect from '../common/ItemDropdownSelect';
import BrandKitCustomFontsDialog from './BrandKitCustomFontsDialog';

import PencilIcon from '../../svgs/PencilIcon';
import PlusIcon from '../../svgs/PlusIcon';
import UploadIcon from '../../svgs/UploadIcon';
import FontTypeIcon from '../../svgs/FontTypeIcon';

const BrandKitFonts = observer(() => {
  const videoCreator = useVideoCreatorStore();
  const { datoClientStore } = useStore();

  const branding = videoCreator.organization?.branding;
  const brandFonts = branding?.brandFonts;
  const customFonts = branding?.customFonts;

  const [isDropdownOpen, toggleDropdown] = useReducer((state) => !state, false);
  const [isDialogOpen, toggleDialog] = useReducer((state) => !state, false);

  const handleAddNewStyle = async () => {
    const organization = videoCreator.organization;
    organization!.branding = {
      ...organization!.branding!,
      brandFonts: [
        ...(organization!.branding?.brandFonts || []),
        {
          id: uuid(),
          title: '',
          fontFamily: 'Inter',
          fontSize: 24,
        },
      ],
    };
    await datoClientStore.albumRepository?.update(organization!);
  };

  const [fontsToUpload, setFontsToUpload] = useState<File[] | null>(null);
  const { getRootProps, getInputProps, open } = useFontDropzone({
    onDrop: (files) => {
      setFontsToUpload(files);
      toggleDialog();
    },
  });
  const handleAddNewFont = () => {
    if (customFonts) toggleDialog();
    else open();
  };

  return (
    <Accordion
      id="fonts"
      title={`Fonts${brandFonts ? ` (${brandFonts.length})` : ''}`}
    >
      <Container>
        {branding !== undefined ? (
          brandFonts?.length ? (
            brandFonts.map((font: BrandingCustomFontItem) => (
              <BrandFontItem key={font.id} font={font} />
            ))
          ) : (
            <EmptyMessage role="button" onClick={handleAddNewStyle}>
              <PlusIcon width="40" strokeColor="#f3e9d7" />
              <span>Add your first font</span>
            </EmptyMessage>
          )
        ) : null}
        {brandFonts?.length ? (
          <AddButtonContainer>
            <AddButton role="button" onClick={toggleDropdown}>
              Add New
            </AddButton>
            {isDropdownOpen ? (
              <>
                <AddBrandFontDialog
                  onAddFont={handleAddNewFont}
                  onAddStyle={handleAddNewStyle}
                  onClose={toggleDropdown}
                />
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                </div>
              </>
            ) : null}
          </AddButtonContainer>
        ) : null}
      </Container>
      {isDialogOpen && (
        <BrandKitCustomFontsDialog
          onClose={() => {
            toggleDialog();
            setFontsToUpload(null);
          }}
          fontsToUpload={fontsToUpload}
        />
      )}
    </Accordion>
  );
});

const BrandFontItem = (props: { font: BrandingCustomFontItem }) => {
  const videoCreator = useVideoCreatorStore();
  const { datoClientStore } = useStore();

  const { font } = props;
  const [isEditing, toggleEditing] = useReducer((state) => !state, false);
  const [title, setFontTitle] = useState(font.title);
  const [fontFamily, setFontFamily] = useState(font.fontFamily || 'Inter');
  const [fontSize, setFontSize] = useState(`${font.fontSize}` || '16');

  const customFont = getCustomFontFromPostScriptName(
    fontFamily || font.postScriptName || font.fontFamily,
    videoCreator.organization?.branding?.customFonts || [],
  );

  const [isSaving, setIsSaving] = useState(false);
  const handleSave = async () => {
    setIsSaving(true);
    const organization = videoCreator.organization;
    organization!.branding = {
      ...organization!.branding!,
      brandFonts:
        organization!.branding?.brandFonts.map((f) =>
          f.id === font.id
            ? {
                ...f,
                fontFamily,
                ...pickBy(customFont),
                title,
                fontSize: Number(fontSize),
              }
            : f,
        ) || [],
    };
    await datoClientStore.albumRepository?.update(organization!);
    setIsSaving(false);
    toggleEditing();
  };

  const handleDelete = async () => {
    const organization = videoCreator.organization;
    organization!.branding = {
      ...organization!.branding!,
      brandFonts:
        organization!.branding?.brandFonts.filter((f) => f.id !== font.id) ||
        [],
    };
    await datoClientStore.albumRepository?.update(organization!);
  };

  const handleCancel = () => {
    setFontTitle(font.title);
    setFontFamily(font.fontFamily as string);
    setFontSize(`${font.fontSize}`);
    toggleEditing();
  };

  return (
    <SBrandFontItem>
      {isEditing ? (
        <BrandFontForm
          fontFamily={customFont?.postScriptName || fontFamily}
          fontSize={fontSize}
        >
          <div className="fields">
            <input
              className="title"
              placeholder="Enter font title"
              maxLength={32}
              value={title}
              onChange={(e) => setFontTitle(e.target.value)}
            />
            <FontDropdownSelect
              className="font-family"
              action={(_, value) => setFontFamily(value || 'Inter')}
              propertyName="fontFamily"
              defaultValue={fontFamily}
            />
            <ItemDropdownSelect
              className="font-size"
              hasStickyBottom={false}
              action={(_, value) => setFontSize(value || '16')}
              propertyName="fontSize"
              defaultValue={fontSize}
              values={FONT_SIZE_VALUES.map((value) => ({
                caption: `${value}px`,
                value: `${value}`,
              }))}
            />
          </div>
          <div className="actions">
            <button
              className="delete-btn"
              disabled={isSaving}
              onClick={handleDelete}
            >
              Delete
            </button>
            <button
              className="cancel-btn"
              disabled={isSaving}
              onClick={handleCancel}
            >
              Cancel
            </button>
            <button
              className="save-btn"
              disabled={isSaving}
              onClick={handleSave}
            >
              {isSaving ? 'Saving...' : 'Save'}
            </button>
          </div>
        </BrandFontForm>
      ) : (
        <>
          <span className="title">{title ? title : <i>Untitled</i>}</span>
          <span className="font-family">
            {customFont?.fontFamily || fontFamily}
          </span>
          <span className="font-size">{fontSize}px</span>
          <button className="edit-btn" onClick={toggleEditing}>
            <PencilIcon width="22" height="22" strokeColor="#f2d093" />
          </button>
        </>
      )}
    </SBrandFontItem>
  );
};

type AddBrandFontDialogProps = {
  onClose: () => void;
  onAddFont: () => void;
  onAddStyle: () => void;
};

const AddBrandFontDialog = (props: AddBrandFontDialogProps) => {
  const { onClose, onAddFont, onAddStyle } = props;
  const ref = useClickAway(onClose);
  const handleAddFont = () => {
    onAddFont();
    onClose();
  };
  const handleAddNewStyle = () => {
    onAddStyle();
    onClose();
  };
  return (
    <DropdownMenu ref={ref}>
      <div className="action-btn action-primary" onClick={handleAddFont}>
        <span>Upload Custom Font</span>
        <UploadIcon width="24" height="24" />
      </div>
      <div className="action-btn action-primary" onClick={handleAddNewStyle}>
        <span>Save Favorite</span>
        <FontTypeIcon width="24" height="24" />
      </div>
    </DropdownMenu>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const SBrandFontItem = styled.div`
  display: flex;
  align-items: center;
  background: #262630;
  border-radius: 8px;
  padding: 24px;
  gap: 8px;

  > span.title {
    flex-grow: 1;
    color: #f3e9d7;
    font-family: Inter, sans-serif;
    font-size: 24px;
    word-break: break-word;
    margin-right: auto;
  }

  > span.font-family,
  > span.font-size {
    flex-shrink: 0;
    height: 40px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    background-color: #484848;
  }

  > .edit-btn {
    background: none;
    border: none;
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    transition: 0.2s;
    &:hover {
      background-color: #484848;
    }
  }
`;

const BrandFontForm = styled.div<{
  fontFamily: string;
  fontSize: string;
}>`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .fields {
    display: grid;
    grid-template-columns: 1fr 180px 120px;
    gap: 8px;

    > input.title {
      width: 100%;
      min-height: 40px;
      padding: 8px 12px;
      background: none;
      outline: none;
      border: 1px solid #484848;
      border-radius: 8px;
      color: #f3e9d7;
      font-family: ${({ fontFamily }) => fontFamily || 'Inter, sans-serif'};
      font-size: ${({ fontSize }) => fontSize || 24}px;
      margin-right: auto;
      &:focus {
        border-color: #f2d093;
      }
      &::placeholder {
        color: #484848;
      }
    }
    > div.font-family {
      flex-shrink: 0;
      & > div:first-child {
        padding-right: 8px;
      }
      & > div:last-child {
        width: 180px;
      }
    }
    > div.font-size {
      flex-shrink: 0;
      & > div:first-child {
        padding-right: 8px;
        height: 40px;
        gap: 8px;
      }
      & > div:last-child {
        width: 120px;
      }
    }
  }

  .actions {
    display: flex;
    align-self: flex-end;
    gap: 8px;
    > button {
      display: block;
      height: 40px;
      padding: 12px 16px;
      border-radius: 8px;
      font-size: 14px;
      font-weight: 700;
      border: none;
      opacity: 1;
      cursor: pointer;
      transition: 0.2s;
      &:hover:not(:disabled) {
        filter: brightness(1.2) saturate(1.2);
      }
      &:disabled {
        opacity: 0.6;
        cursor: not-allowed;
      }
      &.delete-btn {
        background: transparent;
        color: #848484;
        border: 1px solid #848484;
      }
      &.cancel-btn {
        background: transparent;
        color: #17c964;
        border: 1px solid #17c964;
      }
      &.save-btn {
        background: #17c964;
        color: #03041a;
        padding: 12px 24px;
      }
    }
  }
`;

const EmptyMessage = styled.div`
  height: 120px;
  background: #262630;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  transition: 0.2s;
  &:hover {
    background: #484848;
    cursor: pointer;
  }
  > span {
    color: #f3e9d7;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
  }
`;

const AddButtonContainer = styled.div`
  align-self: flex-end;
  position: relative;
  z-index: 2;
`;

const AddButton = styled.button<{ isSaving?: boolean }>`
  display: block;
  height: 48px;
  padding: 12px 24px;
  border-radius: 8px;
  background: #17c964;
  color: #03041a;
  font-size: 14px;
  font-weight: 700;
  border: none;
  transition: 0.2s;
  opacity: ${({ isSaving }) => (isSaving ? 0.6 : 1)};
  cursor: ${({ isSaving }) => (isSaving ? 'not-allowed' : 'pointer')};
  &:hover {
    filter: brightness(1.2) saturate(1.2);
  }
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 56px;
  right: 0;
  border-radius: 8px;
  border: 1px solid #484848;
  background: #03041a;
  box-shadow: 8px 16px 8px 0px rgba(0, 0, 0, 0.4);
  padding: 8px 16px;
  width: 280px;
  display: flex;
  flex-direction: column;
  z-index: 2;

  > * {
    padding: 16px 0;
  }

  > *:not(:last-child) {
    border-bottom: 1px solid #484848;
  }

  > .action-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: 0.2s;
    &:hover {
      padding-left: 4px;
      padding-right: 4px;
      cursor: pointer;
    }
    &.action-primary:hover {
      color: #17c964;
    }
    &.action-delete:hover {
      color: #ef5d6f;
    }
  }
`;

export default BrandKitFonts;
