import {
  ChangeEvent,
  FormEvent,
  KeyboardEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled, { css } from 'styled-components';

type Props = {
  placeholder?: string;
  hasOutline?: boolean;
  hasBorder?: boolean;
  width?: string;
  fontSize?: string;
  getValue: (text: string) => void;
  handleOnEnter?: () => void;
  customHeight?: string;
  borderOnFocus?: boolean;
  value?: string;
  disableAutoResize?: boolean;
  maxResizeHeight?: number;
  onBlur?: () => void;
  customStyles?: { [key: string]: string };
};
const AutoResizeableMultilineInput = (props: Props) => {
  const initialValueRef = useRef<string>(props.value || '');
  const [value, setValue] = useState(props.value || '');
  const {
    hasOutline = true,
    hasBorder = true,
    width = '500px',
    fontSize = '12px',
    customHeight,
    borderOnFocus = true,
    disableAutoResize = false,
    onBlur = () => {},
    customStyles = {},
  } = props;

  useEffect(() => {
    if (value === initialValueRef.current) {
      const val = props.value || '';
      setValue(val);
      props.getValue(val);
      initialValueRef.current = val;
    }
  }, [props.value]);

  const handleAdjustInputHeight = (e: FormEvent<HTMLTextAreaElement>) => {
    if (disableAutoResize) return;
    const textarea = e.target as HTMLTextAreaElement;
    if (props.maxResizeHeight && textarea.scrollHeight >= props.maxResizeHeight)
      return;
    const scrollHeight = customHeight ? parseInt(customHeight) : 50;
    if (textarea.scrollHeight < scrollHeight) return;
    textarea.style.height = textarea.scrollHeight + 'px';
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && props.handleOnEnter) {
      e.preventDefault();
      props.handleOnEnter();
    }
  };

  return (
    <Description
      style={customStyles}
      hasOutline={hasOutline}
      hasBorder={hasBorder}
      width={width}
      fontSize={fontSize}
      maxHeight={props.maxResizeHeight}
      customHeight={customHeight}
      borderOnFocus={borderOnFocus}
      onClick={(e) => e.stopPropagation()}
      onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
        setValue(e.target.value);
        props.getValue(e.target.value);
      }}
      value={value}
      placeholder={props.placeholder}
      onInput={(e: FormEvent<HTMLTextAreaElement>) =>
        handleAdjustInputHeight(e)
      }
      onKeyDown={handleKeyDown}
      onBlur={onBlur}
    />
  );
};

export default AutoResizeableMultilineInput;

const Description = styled.textarea<{
  hasOutline: boolean;
  hasBorder: boolean;
  width: string;
  fontSize: string;
  customHeight: string | undefined;
  borderOnFocus: boolean;
  maxHeight?: number;
}>`
  box-sizing: border-box;
  display: flex;
  padding: ${(props) => !props.customHeight && '10px'};
  flex-direction: column;
  align-items: flex-start;
  font-family: 'Inter', sans-serif;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  border: ${(props) => (props.hasBorder ? '1px solid #5D5D5D' : 'none')};
  text-align: left;
  font-size: ${(props) => props.fontSize};
  line-height: 1.5;
  background-color: transparent;
  color: #f3e9d7;
  resize: none;
  overflow: ${(props) => props.customHeight && 'auto'};
  //   height: ${(props) => props.customHeight || '50px'};
  max-height: ${(props) => props.maxHeight && `${props.maxHeight}px`};
  &:focus {
    outline: ${(props) => props.borderOnFocus && '1px solid #f3e9d7'};
    ${(props) =>
      props.customHeight && props.borderOnFocus ? 'padding: 3px' : ''}
  }
  &::placeholder {
    color: #949494;
  }
  @media only screen and (max-width: 1400px) {
    height: ${(props) => !props.customHeight && '60px'};
  }

  @media only screen and (max-width: 900px) {
    height: ${(props) => !props.customHeight && '70px'};
  }
  width: ${(props) => props.width};
  outline: ${(props) => !props.hasOutline && 0};
`;
