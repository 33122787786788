import React, { useCallback, useEffect, useState } from 'react';
import { DraggableData, DraggableEvent } from 'react-draggable';
import { observer } from 'mobx-react-lite';
import { runInAction } from 'mobx';
import styled from 'styled-components';

import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';
import { Draggable } from '../Draggable';

export const EndMarker: React.FC = observer(() => {
  const videoCreator = useVideoCreatorStore();
  const timelineScale = videoCreator.timelineScale;
  const _duration = videoCreator.duration;

  const [duration, setDuration] = useState(_duration);
  useEffect(() => {
    if (_duration !== duration) setDuration(_duration);
  }, [_duration]);

  const handleStart = useCallback(
    (e: DraggableEvent, data: DraggableData) => {
      runInAction(() => (videoCreator.isScrubbing = true));
      return { startX: data.x, startTime: duration };
    },
    [duration],
  );

  const handleDrag = useCallback(
    (e: DraggableEvent, data: DraggableData, context: any) => {
      const time = Math.max(
        context.startTime + (data.x - context.startX) / timelineScale,
        0,
      );
      setDuration(time);
    },
    [timelineScale],
  );

  const handleStop = useCallback(async () => {
    await videoCreator.setDuration(duration);
    runInAction(() => (videoCreator.isScrubbing = false));
  }, [duration]);

  return (
    <Draggable onStart={handleStart} onDrag={handleDrag} onStop={handleStop}>
      {(ref, context) => (
        <Main ref={ref} style={{ left: duration * timelineScale + 8.5 }}>
          <Head>
            <Line />
          </Head>
        </Main>
      )}
    </Draggable>
  );
});

const Main = styled.div`
  position: absolute;
  z-index: 1;
  top: 20px;
`;

const Head = styled.div`
  box-sizing: content-box;
  padding: 4px;
  height: 8px;
  background: rgb(73, 128, 241);
  border-radius: 2px;
  cursor: ew-resize;
`;

const Line = styled.div`
  width: 1px;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
`;
