import { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { format, isToday } from 'date-fns';
import { SimpleSchemaTypes } from '@datocms/cma-client-browser';
import styled, { css } from 'styled-components';
import { orderBy } from 'lodash';

import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';
import type { VideoAction, VideoVersion } from '../../types.ts/story';
import { Pager } from '../common/Pager';
import SpinningLoading from '../SpinningLoading';
import DocumentIcon from '../../svgs/DocumentIcon';
import {
  AggregateVideoVersion,
  SameLastActionAggregation,
  aggregateVersionsOfTheSameLastAction,
} from './VideoVersionsAggregation';

const toFormattedAction = (lastAction?: VideoAction): string => {
  if (!lastAction?.type) {
    return 'Modified';
  }
  return {
    save: 'Saved',
    autosave: 'Autosaved',
    unsave: 'Unsaved',
    publish: 'Published',
    system_render_start: 'Modified',
    system_render_update: 'Modified',
    system_render_error: 'Modified',
    system_render_confirm: 'Published',
  }[lastAction.type];
};

const toPublishingInfo = (lastAction?: VideoAction): string => {
  if (!lastAction?.type) {
    return '';
  }
  const { type, resolution } = lastAction;
  return type === 'publish' && resolution ? ` in ${resolution}` : '';
};

const toEditor = (video: VideoVersion): string => {
  return (
    video.lastActionJson?.editor ||
    (video.editor as SimpleSchemaTypes.User).full_name ||
    'Arbor Platform'
  );
};

const toFormattedEditor = (video: VideoVersion): string => {
  let formattedEditor = toEditor(video);
  if (video.lastActionJson?.type === 'autosave') {
    formattedEditor = ` (${formattedEditor})`;
  } else {
    formattedEditor = ' by ' + formattedEditor;
  }
  return formattedEditor;
};

export const VideoVersionHistory: React.FC<{
  sidebarRef: React.RefObject<HTMLDivElement>;
}> = observer((props) => {
  const videoCreator = useVideoCreatorStore();
  function switchToVersion(versionId: string) {
    videoCreator.setCurrentVersionVideo(versionId);
  }
  const page = videoCreator.currentVideoVersionsPage;
  const totalPages = videoCreator.currentVideoVersionsTotalPages;

  const versionsByDate = useMemo(() => {
    const aggregated = aggregateVersionsOfTheSameLastAction(
      videoCreator.currentVideoVersions,
    );
    const groupedByDate = groupVersionsByDate(aggregated);
    const sorted = sortVersionsByDate(groupedByDate);
    return sorted;
  }, [videoCreator.currentVideoVersions]);

  return (
    <Wrapper>
      {videoCreator.isVersionsHistoryLoading && (
        <SpinningLoading
          text="Updating..."
          customStyle={{ minHeight: '260px' }}
          Ico={
            <DocumentIcon width={'24'} height={'26'} strokeColor="#17c964" />
          }
        />
      )}
      <List>
        {!videoCreator.currentVideoVersions?.length && (
          <NoVersions>
            No saved versions for this clip yet. Save the first one.
          </NoVersions>
        )}

        {versionsByDate.map((group) => {
          const isDateToday = isToday(group.date);
          return (
            <GroupItem key={group.date}>
              <Weekday>
                {isDateToday ? 'Today' : format(group.date, 'EEEE, MMM d')}
              </Weekday>
              {group.versions.map((video) => {
                return (
                  <ListElement
                    key={video.id}
                    onClick={() => switchToVersion(video.versionId)}
                    isCurrent={
                      videoCreator.currentVideo?.versionId === video.versionId
                    }
                  >
                    <VersionDate>
                      {format(video.meta.created_at, 'h:mm a')}
                    </VersionDate>
                    <EditingInfo>
                      {toFormattedAction(video.lastActionJson)}
                      {toFormattedEditor(video)}
                      {toPublishingInfo(video.lastActionJson)}
                    </EditingInfo>
                    {!!video.pastVersions?.length && (
                      <SameLastActionAggregation
                        allVersions={[video, ...video.pastVersions]}
                        formattedAction={toFormattedAction(
                          video.lastActionJson,
                        )}
                        editor={toEditor(video)}
                        sidebarRef={props.sidebarRef}
                      />
                    )}
                  </ListElement>
                );
              })}
            </GroupItem>
          );
        })}
      </List>

      <Pager
        page={page}
        totalPages={totalPages}
        toPage={(page: number) =>
          videoCreator.loadCurrentVideoVersionsHistory(page)
        }
      />
    </Wrapper>
  );
});

const groupVersionsByDate = (versions: AggregateVideoVersion[]) => {
  const result: Record<string, AggregateVideoVersion[]> = {};
  versions.forEach((version) => {
    const versionDate = format(version.meta.created_at, 'yyyy-MM-dd');
    if (!result[versionDate]) result[versionDate] = [];
    result[versionDate].push(version);
  });
  return result;
};

const sortVersionsByDate = (
  versionsRecord: Record<string, AggregateVideoVersion[]>,
) => {
  const mapped = Object.entries(versionsRecord).map(([date, versions]) => ({
    date,
    versions,
  }));
  const sorted = orderBy(mapped, 'date', 'desc');
  return sorted;
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const List = styled.div`
  width: 100%;
  overflow-y: auto;
  padding-right: 10px;
`;

const GroupItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 0;
  border-bottom: 1px solid #484848;
  box-sizing: border-box;
`;

const Weekday = styled.div`
  color: #f3e9d7;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
`;

const ListElement = styled.div.attrs((props: { isCurrent: boolean }) => props)`
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 8px;
  padding: 4px 16px;
  color: #949494;
  transition: 150ms;

  ${(props) =>
    props.isCurrent &&
    css`
      color: #17c964;
    `}

  &:hover {
    color: #17c964;
  }

  &::after {
    content: '';
    position: absolute;
    width: 14px;
    height: 14px;
    border-radius: 14px;
    border: 1px solid #949494;
    right: 8px;
    top: 0;
    bottom: 0;
    margin: auto;
    ${(props) =>
      props.isCurrent &&
      css`
        font-size: 9px;
        font-weight: 600;
        text-align: center;
        content: '✓';
        border-color: #17c964;
        color: #17c964;
      `}
  }
`;

const VersionDate = styled.div`
  font-size: 10px;
  font-weight: 700;
  line-height: normal;
`;

const EditingInfo = styled.div`
  font-size: 10px;
  font-weight: 500;
  line-height: normal;
`;

const NoVersions = styled.div`
  padding: 0px 40px;
  box-sizing: border-box;
  text-align: center;
  width: 100%;
  margin-top: 16px;
  color: #f3e9d7;
  font-size: 12px;
  font-weight: 700;
  line-height: 150%;
`;
