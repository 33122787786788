import { makeAutoObservable, runInAction } from 'mobx';

import VideoCreatorStore from '../VideoCreatorStore'; // Adjust import path as needed
import {
  ExtraElementData,
  PunchListItem,
  StoryDTO,
} from '../../types.ts/story';

import { SidebarOption, MediaCard, ImageKey } from '../../types.ts/general';

import ChatGPTService from '../../services/ChatGPTService';

import { v4 as uuid } from 'uuid';
import { getImageWithBlackFrameElements } from '../../utility/elements';
import { AspectRatio } from '@src/types.ts/video';
import { DatoClientStore } from '@src/stores-v2/DatoClientStore';
import { DEFAULT_ANIMATION_SPEED } from '@src/config/imageSettings';

class PunchListManager {
  videoCreator: VideoCreatorStore;
  punchListData: PunchListItem[] | null = null;
  addedPunchListItemId: string | null = null;
  sidebarOptions = SidebarOption.media;
  mediaSubMenu = MediaCard.photo;

  private datoClientStore: DatoClientStore;

  constructor(store: VideoCreatorStore, datoClientStore: DatoClientStore) {
    // TODO: DatoClientStore
    makeAutoObservable(this, {
      videoCreator: false,
    });
    this.videoCreator = store;
    this.datoClientStore = datoClientStore;
  }

  length = (): number => {
    if (this.punchListData === null || !Array.isArray(this.punchListData))
      return 0;
    return this.punchListData.length;
  };

  set punchListItems(items: PunchListItem[]) {
    this.punchListData = items;
  }

  get punchListItems(): PunchListItem[] {
    if (this.length() === 0 || this.punchListData === null) return [];
    return [...this.punchListData];
  }

  findPunchListItemById(id: string): PunchListItem | undefined {
    return this.punchListData?.find((item) => item.id === id);
  }

  updatePunchListItem(id: string, changes: Partial<PunchListItem>): void {
    const item = this.findPunchListItemById(id);
    if (item) {
      Object.assign(item, changes);
    }
  }

  // Mutator: Remove a punch list item by ID
  removePunchListItem(id: string): void {
    this.removePunchListItems([id]);
  }

  removePunchListItems(ids: string[]): void {
    this.punchListData =
      this.punchListData?.filter((i) => i.id && !ids.includes(i.id)) || null;
    this.videoCreator.videoTranscriptionProcessor.removeMultiplePhotoHighlights(
      ids,
    );
  }

  async addPhotoToPunchList(
    text: string,
    photoUrl: string,
    description: string,
    type: ImageKey,
    startIndex: number,
    endIndex: number,
    startTime: number,
    endTime: number,
    aspectRatio: number,
  ) {
    const punchListId = uuid();
    try {
      this.addedPunchListItemId = punchListId;

      const duration = endTime - startTime;
      const maxTrack = this.videoCreator.getMaxTrack();
      const currTrack = maxTrack + 1;
      const freeTrack = this.videoCreator.getFreeMediaTrack(
        'image',
        duration,
        startTime,
      );
      const punchListTrack = freeTrack || currTrack;

      const newPunchListData: PunchListItem = {
        id: punchListId,
        type,
        sub_type: 'manual',
        description,
        line: text,
        artifactSrc: photoUrl,
        artifactAspectRatio: aspectRatio,
        transcriptPosition: {
          startIndex,
          endIndex,
        },
        metadata: {},
        evocative: '',
        dalleImages: [],
        elementId: '',
        dallePrompt: '',
        stockKeywords: '',
      };

      let punchListData = this.insertPunchListAtIndex([newPunchListData]);
      this.punchListData = punchListData;

      await this.addPunchListItemToTimeline(
        this.punchListData.find((p) => p.id === punchListId)!,
        duration,
        startTime,
        endTime,
        punchListTrack,
      );

      this.videoCreator.videoTranscriptionProcessor.addPhotoHighlight(
        startIndex,
        endIndex,
        punchListId,
      );
    } catch (error) {
      console.log('An error occurred: ', error);
      this.removePunchListItem(punchListId); // revert
    } finally {
      this.addedPunchListItemId = null;
    }
  }

  async addPunchListItemToTimeline(
    punchListItem: PunchListItem,
    duration: number,
    startTime: number,
    endTime: number,
    currTrack: number,
  ) {
    const isPortrait =
      !!punchListItem.artifactAspectRatio &&
      punchListItem.artifactAspectRatio < 1;
    const videoAspectRatio =
      this.videoCreator.currentVideo?.aspectRatio || AspectRatio.AR_16_9;
    const animations = [
      {
        start_scale: '100%',
        end_scale: DEFAULT_ANIMATION_SPEED,
        x_anchor: '50%',
        y_anchor: '50%',
        fade: false,
        scope: 'element',
        easing: 'linear',
        type: 'scale',
        arbor_subType: 'zoomIn',
      },
    ];
    const element =
      isPortrait && videoAspectRatio === AspectRatio.AR_16_9
        ? {
            id: punchListItem.id,
            type: 'composition',
            time: startTime,
            duration,
            elements: getImageWithBlackFrameElements({
              source: punchListItem.artifactSrc || '',
            }),
            animations,
          }
        : {
            id: punchListItem.id,
            type: 'image',
            source: punchListItem.artifactSrc || '',
            duration,
            autoplay: true,
            fit: 'cover',
            smart_crop: true,
            track: currTrack,
            time: startTime,
            animations,
          };
    await this.videoCreator.createElement(element);
    punchListItem.startTime = startTime;
    punchListItem.endTime = endTime;
    punchListItem.duration = duration;
  }

  // TODO: Move to Repository pattern whenever we move Story
  async attachPunchListPhotosToStory(): Promise<StoryDTO> {
    const storyDTO: StoryDTO = { ...this.videoCreator.story! };
    const srcUrls = (
      this.videoCreator.state?.elements?.map((e) => {
        if (e.source.type === 'image') return e.source.source;
      }) || []
    ).filter(Boolean);
    try {
      if (
        this.videoCreator.photoDataForDato &&
        Object.values(this.videoCreator.photoDataForDato)
      ) {
        for (let [id, blobData] of Object.entries(
          this.videoCreator.photoDataForDato,
        )) {
          if (!srcUrls?.includes(blobData.url)) {
            continue;
          }

          const idx = this.punchListData?.findIndex((p) => p.id === id);
          if (idx && idx > -1) {
            if (!this.punchListData?.length) continue;

            if ('uploadId' in blobData && blobData.uploadId) {
              storyDTO.aiPhotos?.push({ id: blobData.uploadId });
              continue;
            }

            const line = this.punchListData[idx].line;
            const fileName = line
              .split(' ')
              .join('_')
              .toLowerCase()
              .slice(0, 30);

            const newPhotoData = {
              ...blobData,
              fileName,
              alt: line,
              title: line,
              metaData: {
                alt: line,
                title: line,
                custom_data: {},
              },
            };

            const newUpload =
              await this.datoClientStore.assetRepository?.uploadFile(
                newPhotoData,
              );

            this.punchListData[idx] = {
              ...this.punchListData[idx],
              artifactSrc: newUpload!.url,
              artifactAspectRatio:
                (newUpload!.width || 0) / (newUpload!.height || 1),
            };

            if (blobData.type === 'ai') {
              storyDTO.aiPhotos?.push(newUpload!);
            } else if (blobData.type === 'stock') {
              storyDTO.storyAssets?.push(newUpload!);
            }
          } else {
            if (!('fileName' in blobData)) continue;

            const newPhotoData = {
              ...blobData,
              alt: blobData.alt || '',
              title: blobData.title || '',
            };

            const newUpload =
              await this.datoClientStore.assetRepository?.uploadFile(
                newPhotoData,
              );

            if (blobData.type === 'stock') {
              storyDTO.storyAssets?.push(newUpload!);
            } else if (blobData.type === 'ai') {
              storyDTO.aiPhotos?.push(newUpload!);
            }
          }
        }
      }
    } catch (error) {
      console.error('Error attaching punch list photos to story: ', error);
      console.log('returning unmodified storyDTO', storyDTO);
    }

    return storyDTO;
  }

  insertPunchListAtIndex = (
    newPunchListData: PunchListItem[],
    punchListData = this.punchListData,
  ) => {
    const sortedPunchList = newPunchListData.sort(
      (a, b) => a.transcriptPosition.endIndex - b.transcriptPosition.endIndex,
    );
    const lastPunchList = sortedPunchList[sortedPunchList.length - 1];
    const endIndex = lastPunchList.transcriptPosition.endIndex;

    const punchListLength = punchListData!.length;
    if (
      !punchListLength ||
      endIndex > punchListData![punchListLength - 1].transcriptPosition.endIndex
    ) {
      const existingPunchListData = punchListData || [];
      punchListData = [...existingPunchListData, ...sortedPunchList];
    } else {
      const indexToInsert = punchListData?.findIndex(
        (p) => p.transcriptPosition.endIndex > endIndex,
      );
      const punchListBefore = punchListData?.slice(0, indexToInsert!) || [];
      const punchListAfter = punchListData?.slice(indexToInsert!) || [];
      punchListData = [
        ...punchListBefore,
        ...sortedPunchList,
        ...punchListAfter,
      ];
    }
    return punchListData;
  };

  getPunchListTrack = () => {
    let punchListItemId: string | undefined = undefined;
    let extraElementData =
      this.videoCreator.currentVideo?.extraElementData || {};
    if (this.punchListData?.length) {
      punchListItemId = this.punchListData[0].id;
    } else if (Object.keys(extraElementData).length) {
      punchListItemId = Object.keys(extraElementData).find(
        (key) =>
          (extraElementData[key] as ExtraElementData | null)?.punchListData?.id,
      );
    }

    if (punchListItemId) {
      const element = this.videoCreator.renderer?.state?.elements?.find(
        (el) => el.source.id === punchListItemId,
      );

      return element?.track;
    }
  };

  addCutPhotoToPunchlist = async (
    existingId: string,
    head: {
      id: string;
      transcriptPosition: Record<'startIndex' | 'endIndex', number>;
    },
    tail: {
      id: string;
      transcriptPosition: Record<'startIndex' | 'endIndex', number>;
    },
  ) => {
    const idx = this.punchListData?.findIndex((p) => p.id === existingId);
    if (idx !== undefined && idx > -1) {
      const punchlistItem = this.punchListData![idx];
      const headItem = { ...punchlistItem, ...head };
      const tailItem = { ...punchlistItem, ...tail };
      this.punchListData!.splice(idx, 1, headItem, tailItem);
    }
  };

  public isEmptyItem(punchListItem: PunchListItem): boolean {
    return !punchListItem.artifactSrc;
  }

  public isAiGeneratedItem(punchListItem: PunchListItem): boolean {
    return punchListItem.sub_type !== 'manual';
  }

  private getPunchListForMatching(
    mode: 'replace-missing' | 'replace-all',
  ): PunchListItem[] {
    let result = this.punchListItems.filter(this.isAiGeneratedItem);
    if (mode === 'replace-missing') {
      result = result.filter(this.isEmptyItem);
    }
    return result;
  }

  public async matchWithSources(
    sources: string[],
    mode: 'replace-missing' | 'replace-all',
  ): Promise<void> {
    const gptService = new ChatGPTService(
      this.videoCreator,
      this.datoClientStore.aiPromptRepository,
    );
    const response = await gptService.matchPunchListWithSources(
      this.getPunchListForMatching(mode),
      sources,
    );
    if (response.punchList) {
      await this.applyPunchListUpdates(response.punchList);
    }
  }

  private async applyPunchListUpdates(updatedPunchListItems: PunchListItem[]) {
    const newPunchList: PunchListItem[] = [];
    for (let punchListItem of this.punchListItems) {
      const updatedPunchListItem = updatedPunchListItems.find(
        (item) => item.id === punchListItem.id,
      );
      if (!updatedPunchListItem) {
        newPunchList.push(punchListItem);
        continue;
      }
      newPunchList.push(updatedPunchListItem);
      if (punchListItem.artifactSrc !== updatedPunchListItem.artifactSrc) {
        await this.videoCreator.findOrReplaceInTimeline(
          updatedPunchListItem.id!,
          updatedPunchListItem.artifactSrc || '',
          null,
          updatedPunchListItem.startTime,
          updatedPunchListItem.artifactAspectRatio,
        );
      }
    }
    runInAction(() => {
      this.punchListData = newPunchList;
    });
  }
}

export default PunchListManager;
