import { RendererState } from '@src/renderer/RendererState';
import VideoCreatorStore, {
  KARAOKE_TRACK_NUMBER,
} from '@src/stores/VideoCreatorStore';
import { KaraokeConfig } from '@src/videoTranscriptionProcessor/types/karaokeTypes';

function detectAndApplyManualChangesToKaraokeConfig(
  videoCreator: VideoCreatorStore,
  newState: RendererState,
): boolean {
  const activeElementOld = videoCreator.getActiveElement();
  if (!activeElementOld || activeElementOld.track !== KARAOKE_TRACK_NUMBER) {
    return false;
  }
  const activeElementNew = newState.elements.find(
    (el) => el.source.id === activeElementOld.source.id,
  );
  if (!activeElementNew) {
    return false;
  }
  const karaokeConfig = videoCreator.karaokeProducer.getKaraokeConfig();
  const manualChangeFields: (keyof KaraokeConfig)[] = ['width', 'x', 'y'];

  const propertyMap = manualChangeFields.reduce((acc, field) => {
    if (
      activeElementNew.source[field] != activeElementOld.source[field] &&
      activeElementNew.source[field] != karaokeConfig[field]
    ) {
      acc[field] = activeElementNew.source[field];
    }
    return acc;
  }, {} as Partial<KaraokeConfig>);

  if (Object.keys(propertyMap).length) {
    const newConfig = { ...karaokeConfig, ...propertyMap };
    videoCreator.textBrandingService.checkPropsChanged('karaoke', propertyMap);
    videoCreator.karaokeProducer.rerenderWithNewConfig(newConfig);
  }

  return false;
}

detectAndApplyManualChangesToKaraokeConfig.displayName =
  'detectAndApplyManualChangesToKaraokeConfig';

export default detectAndApplyManualChangesToKaraokeConfig;
