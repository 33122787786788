import React, { useState } from 'react';
import { get } from 'mobx';
import styled, { css } from 'styled-components';

import { observer } from 'mobx-react-lite';
import AiProducerIcon from '../../svgs/AiProducerIcon';
import { VideoResolution } from '../../types.ts/video';
import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';
import { useUserIdentity } from '@src/stores-v2/StoreContext';
import { useFlagsCombination } from '@src/utility/useFlagsCombination';

type Props = {
  isInClipsModal?: boolean;
};

export const CreateButton: React.FC<Props> = observer((props) => {
  const videoCreator = useVideoCreatorStore();
  const userIdentity = useUserIdentity();
  const { renderUsingChunks } = useFlagsCombination(userIdentity.currentRole);
  const { isInClipsModal = false } = props;
  const isEnabled = videoCreator.isVideoCreatorReady;
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const renderButtonText = (text?: string) => {
    if (isInClipsModal) {
      return (
        <>
          <AiProducerIcon strokeColor="currentColor" /> {text || 'Render'}
        </>
      );
    }
    return <div>{text || 'Publish'}</div>;
  };

  const renderDropdownArrow = () => {
    return (
      <DropdownIcon
        isOpen={dropdownOpen}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setDropdownOpen(!dropdownOpen);
        }}
      >
        <ArrowIcon />
      </DropdownIcon>
    );
  };

  if (get(videoCreator, 'renderingStatus') === 'rendering') {
    return (
      <Main active isInClipsModal={isInClipsModal} disabled={false}>
        {renderButtonText('Publishing...')}
      </Main>
    );
  }
  if (videoCreator.renderQueueing) {
    return (
      <Main active isInClipsModal={isInClipsModal} disabled={false}>
        {renderButtonText('Queueing...')}
      </Main>
    );
  }

  const onRenderClick = async (event: any, res: VideoResolution) => {
    event.stopPropagation();
    event.preventDefault();
    setDropdownOpen(false);
    try {
      await videoCreator.finishVideo(res, renderUsingChunks);
    } catch (error) {
      videoCreator.handleRenderingError(error);
    }
  };

  const originalRes = videoCreator.story?.originalVideo
    ? Math.min(
        videoCreator.story.originalVideo.width,
        videoCreator.story.originalVideo.height,
      )
    : undefined;
  const shouldDefaultToOriginalRes = true;

  return (
    <Main
      isInClipsModal={isInClipsModal}
      disabled={!isEnabled}
      className={videoCreator.renderingStatus === 'error' ? 'error' : ''}
      onClick={(e) => {
        onRenderClick(
          e,
          shouldDefaultToOriginalRes
            ? VideoResolution.Original
            : VideoResolution.High,
        );
      }}
    >
      {renderButtonText()}
      {!isInClipsModal && renderDropdownArrow()}
      {dropdownOpen && (
        <>
          <DropdownBackground
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setDropdownOpen(false);
            }}
          ></DropdownBackground>
          <Dropdown>
            <DropdownItem
              onClick={(e) => onRenderClick(e, VideoResolution.Low)}
            >
              Low (270p)
            </DropdownItem>
            <DropdownItem
              onClick={(e) => onRenderClick(e, VideoResolution.Medium)}
            >
              Medium (480p)
            </DropdownItem>
            <DropdownItem
              onClick={(e) => onRenderClick(e, VideoResolution.High)}
            >
              High (720p){shouldDefaultToOriginalRes ? '' : ' - default'}
            </DropdownItem>
            {videoCreator.story?.originalVideo && (
              <DropdownItem
                onClick={(e) => onRenderClick(e, VideoResolution.Original)}
              >
                Original ({originalRes}p)
                {!shouldDefaultToOriginalRes ? '' : ' - default'}
              </DropdownItem>
            )}
          </Dropdown>
        </>
      )}
    </Main>
  );
});

const Main = styled.div<{
  isInClipsModal: boolean;
  disabled: boolean;
  active?: boolean;
}>`
  box-sizing: border-box;
  height: 32px;
  min-width: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #03041a;
  background: #848484;
  font-size: 10px;
  font-weight: 700;
  border-radius: 4px;
  padding: 8px;
  transition: 150ms;

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &.error {
    background: #e74c3c;
  }

  ${(props) =>
    props.active &&
    css`
      background: #f2d093;
    `}
  &:hover {
    background: #f2d093;
  }

  ${(props) =>
    props.isInClipsModal &&
    css`
      background: #f2d093;
      height: auto;
      margin: 10px auto;
      width: 50%;
      padding: 16px;
      gap: 10px;
      border-radius: 8px;
      font-size: 14px;
      font-weight: bold;
      position: relative;
      flex: 1;
    `}
`;

const ArrowIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="6"
    viewBox="0 0 10 6"
    fill="none"
  >
    <path d="M9 5.5L5 1.5L1 5.5" stroke="currentColor" strokeWidth="2" />
  </svg>
);

const DropdownIcon = styled.div<{ isOpen: boolean }>`
  width: 16px;
  height: 18px;
  justify-content: end;
  align-items: center;
  margin-left: auto;
  display: flex;
  color: #03041a;

  ${(props) => !props.isOpen && `rotate: 180deg; justify-content: start;`}
`;

const Dropdown = styled.div`
  position: absolute;
  z-index: 1000;
  top: 60px;
  right: 24px;
  width: 200px;
  border: 1px solid #484848;
  border-radius: 8px;
  background: #03041a;
  padding: 8px 0;
  box-sizing: border-box;
  max-height: 500px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const DropdownBackground = styled.div`
  position: fixed;
  z-index: 999;
  inset: 0;
  box-sizing: border-box;
`;

const DropdownItem = styled.div`
  color: rgb(243, 233, 215);
  font-size: 12px;
  font-weight: 200;
  line-height: normal;
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  position: relative;
  cursor: pointer;

  &:hover {
    background: #484848;
    color: #f2d093;
    p {
      color: #f2d093;
      font-weight: 700;
    }
  }

  &:not(:hover):not(:last-child)::after {
    content: '';
    position: absolute;
    height: 1px;
    width: calc(100% - 32px);
    left: 16px;
    background: #484848;
    bottom: -1px;
  }
`;
