import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import styled, { css, keyframes } from 'styled-components';

import TimelinePhotoIcon from '../../svgs/TimelinePhotoIcon';
import { ElementState } from '../../renderer/ElementState';
import { TranscriptElement } from '../../types.ts/transcript';
import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';
import { useTranscription } from '@src/stores-v2';

type ElementProps = {
  value: string | JSX.Element | null;
  state: TranscriptElement['state'];
  index: number;
  isHighlighted: boolean;
  isPhotoHighlight: boolean;
};

const Element = React.memo((props: ElementProps) => {
  const videoCreator = useVideoCreatorStore();
  const { value, state, index, isHighlighted, isPhotoHighlight } = props;
  if (state !== 'removed' && state !== 'cut') {
    return (
      <Word
        key={`element-${index}`}
        data-index={index}
        isPhotoHighlight={isPhotoHighlight}
        isHighlighted={isHighlighted}
        state={state}
        isWhitespace={typeof value === 'string' && value.trim() === ''}
      >
        {value}
      </Word>
    );
  } else {
    return (
      <Word data-index={index} key={`removed-element-${index}`} state={state}>
        {value}
      </Word>
    );
  }
});

type Props = {
  cursor: { from: number; to: number };
};

export const TranscriptionText: React.FC<Props> = observer(
  ({ cursor }: Props) => {
    const videoCreator = useVideoCreatorStore();
    const transcriptionStore = useTranscription();
    return (
      <>
        {transcriptionStore
          .getFinalTranscriptionElements()
          ?.map((el, index) => {
            if (el.state === 'removed' || el.state === 'cut') {
              if (
                !videoCreator.isPlaying &&
                index ===
                  transcriptionStore.getFinalTranscriptionElements()!.length -
                    1 &&
                index + 1 === cursor.from
              ) {
                return <Cursor data-index={cursor.from} />;
              }
              return null;
            }

            let hasPhotoHighlight = false;
            let element: ElementState | undefined = undefined;

            if (el.last_photo_highlight && videoCreator.state?.elements) {
              element = videoCreator.state!.elements.find(
                (e) => e.source.id === el.photo_highlight_id,
              );
              const imageElement = element
                ? videoCreator.getImageElement(element)
                : null;
              hasPhotoHighlight = !!imageElement?.source?.source;
            }

            return (
              <>
                {!videoCreator.isPlaying && index === cursor.from ? (
                  <Cursor data-index={cursor.from} />
                ) : null}
                <Element
                  value={el.value}
                  state={el.state}
                  index={index}
                  isHighlighted={Boolean(
                    videoCreator.isPlaying &&
                      index >= cursor.from &&
                      index < cursor.to,
                  )}
                  isPhotoHighlight={!!el.photo_highlight_id}
                />
                {!videoCreator.isPlaying &&
                index ===
                  transcriptionStore.getFinalTranscriptionElements()!.length -
                    1 &&
                index + 1 === cursor.from ? (
                  <Cursor data-index={cursor.from} />
                ) : null}
                {el.last_photo_highlight ? (
                  <HighlightImageWrapper>
                    <HighlightImage
                      isAssigned={hasPhotoHighlight}
                      key={`highlight-${index}`}
                      onClick={() => {
                        if (!element) return;
                        videoCreator.setActiveElements(element?.source.id);
                      }}
                    >
                      <TimelinePhotoIcon
                        width="10"
                        height="10"
                        strokeColor={hasPhotoHighlight ? '#fff' : '#bdbdbd'}
                      ></TimelinePhotoIcon>
                    </HighlightImage>
                  </HighlightImageWrapper>
                ) : null}
              </>
            );
          })}
      </>
    );
  },
);

const colorByState = (
  state: string,
  isHighlighted: boolean,
  isPhotoHighlight: boolean,
  diff?: boolean,
) => {
  if (isHighlighted) return 'black';
  if (state === 'muted') return '#F69B12';
  if (isPhotoHighlight) return '#45d483';
  return (
    {
      ...(diff && { added: '#7bb975' }),
      removed: 'indianred',
      cut: '#659960', // '#7bb975',
      muted: '#F69B12',
    }[state] || '#bdbdbd'
  );
};

export const Word = React.memo(styled.span.attrs(
  (props: {
    isHighlighted: boolean;
    isCut: boolean;
    isText: boolean;
    state: 'added' | 'removed' | 'muted' | 'replaced' | 'cut';
    isPhotoHighlight: boolean;
    diff?: boolean;
    isWhitespace?: boolean;
    // cursor: 'after' | 'before' | 'none';
  }) => props,
)`
  color: ${(props) =>
    colorByState(
      props.state,
      props.isHighlighted,
      props.isPhotoHighlight,
      props.diff,
    )};
  background-color: ${(props) => (props.isHighlighted ? '#dfb615' : 'none')};
  cursor: ${(props) =>
    props.state !== 'removed' && props.state !== 'cut' ? 'pointer' : 'default'};
  text-decoration: ${(props) =>
    props.state === 'removed' || props.state === 'cut'
      ? 'line-through'
      : props.isPhotoHighlight
        ? 'underline'
        : 'none'};
  position: relative;
  opacity: ${(props) => (props.isCut ? 0.6 : 1)};

  ${(props) => props.isWhitespace && `white-space: pre-wrap;`}

  &:hover {
    color: lightgreen;
  }
`);

// ${(props) => props.cursor === 'after' && css`
//     &::after {
//       content: '';
//       position: absolute;
//       animation: ${cursorAnimation} steps(2) 1.5s infinite;
//       height: 26px;
//       width: 3px;
//       background-color: #2a85ff;
//       display: inline-block;
//       border-radius: 1px;
//       right: -2px;
//       bottom: -4px;
//     }
//   `}

const cursorAnimation = keyframes`
  100% {
    opacity: 0;
  }
`;

export const Cursor = styled.span
  .attrs({
    'data-testid': 'transcript-cursor',
  })
  .withConfig({
    displayName: 'TranscriptCursor',
  })`  
  animation: ${cursorAnimation} steps(2) 1.5s infinite;
  height: 26px;
  margin-bottom: -8px;
  width: 3px;
  background-color: #2a85ff;
  display: inline-block;
  border-radius: 1px;
`;

export const HighlightImageWrapper = styled.div.withConfig({
  displayName: 'TranscriptHighlightWrapper',
})`
  position: relative;
  display: inline;
`;

export const HighlightImage = styled.div
  .withConfig({
    displayName: 'TranscriptHighlight',
  })
  .attrs((props: { isAssigned: boolean }) => props)`
  background: ${(props) =>
    props.isAssigned
      ? 'linear-gradient(0deg, #45d483 0%, #45d483 100%), #45d483'
      : 'linear-gradient(0deg, #4f4f4f 0%, #4f4f4f 100%), lightgray'}
    50% / cover no-repeat;
  color: #4f4f4f;
  border-radius: 50%;
  padding: 6px;
  margin-left: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;
