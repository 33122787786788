import React from 'react';
import styled from 'styled-components';
import FacebookIcon from '../../svgs/FacebookIcon';
import { SharedContent } from '../../types.ts/story';
import LinkedInIcon from '../../svgs/LinkedInIcon';
import InstagramIcon from '../../svgs/InstagramIcon';
import YouTubeIcon from '../../svgs/YouTubeIcon';
import TiktokIcon from '../../svgs/TiktokIcon';
import XIcon from '../../svgs/XIcon';
import { formatAnalyticsNumber } from '../../utility/general';

type Props = {
  type: 'views' | 'likes' | 'shares' | 'comments';
  Icon: React.ReactNode;
  allReferencingSharedContents?: SharedContent[] | undefined;
};

function renderIcon(platform: SharedContent['platform']) {
  switch (platform) {
    case 'facebook':
      return <FacebookIcon />;
    case 'twitter':
      return <XIcon />;
    case 'linkedin':
      return <LinkedInIcon />;
    case 'instagram':
      return <InstagramIcon strokeColor="#F3E9D7" />;
    case 'youtube':
      return <YouTubeIcon />;
    case 'tiktok':
      return <TiktokIcon fillColor="#F3E9D7" />;
  }
}

type DataType = {
  [k: string]: Record<SharedContent['platform'], number>;
};

const AnalyticsDetailsDropdown = (props: Props) => {
  const sharedContent = props.allReferencingSharedContents
    ?.filter((c) => c[props.type])
    .reduce((acc, curr) => {
      const { platform, video } = curr;
      let title = video ? video.title : 'Shareable images';

      if (!acc[title]) {
        acc[title] = {};
      }

      if (!acc[title]?.[platform]) {
        acc[title][platform] = 0;
      }

      acc[title][platform] += curr[props.type];
      return acc;
    }, {} as DataType);

  const platforms = Object.values(sharedContent || {}).reduce(
    (acc, platformData) => {
      Object.entries(platformData).forEach(([platform, count]) => {
        acc[platform] = (acc[platform] || 0) + count;
      });
      return acc;
    },
    {} as Record<SharedContent['platform'], number>,
  );

  return (
    <Main type={props.type}>
      <Header>
        {props.Icon}
        <span>{props.type} Details</span>
      </Header>
      <Container>
        <ContentColumn>
          <ContentContainer>
            <Content>
              <GrandTotalRow>Total</GrandTotalRow>
              {Object.keys(platforms).map((p) => (
                <ContentRow>{renderIcon(p)}</ContentRow>
              ))}
            </Content>
          </ContentContainer>
        </ContentColumn>

        <ContentColumn>
          <ContentContainer>
            <ContentContainer>
              <TotalLabel>Total</TotalLabel>
              <Content>
                <ContentRowTotal>
                  {formatAnalyticsNumber(
                    Object.values(platforms).reduce(
                      (acc, curr) => curr + acc,
                      0,
                    ),
                  )}
                </ContentRowTotal>
                {Object.entries(platforms).map(([platform, total]) => (
                  <ContentRowTotal>
                    {formatAnalyticsNumber(total)}
                  </ContentRowTotal>
                ))}
              </Content>
            </ContentContainer>
          </ContentContainer>
        </ContentColumn>
        <ContentColumn>
          {Object.entries(sharedContent || {}).map(([title, data]) => (
            <ContentContainer>
              <ContentTitle>{title}</ContentTitle>
              <Content>
                <ContentRow>
                  {formatAnalyticsNumber(
                    Object.values(data).reduce((acc, curr) => curr + acc, 0),
                  )}
                </ContentRow>
                {Object.keys(platforms).map((platform) => (
                  <ContentRow>
                    {formatAnalyticsNumber(data[platform] || 0)}
                  </ContentRow>
                ))}
              </Content>
            </ContentContainer>
          ))}
        </ContentColumn>
      </Container>
    </Main>
  );
};

export default AnalyticsDetailsDropdown;

const Main = styled.div<{ type: Props['type'] }>`
  position: absolute;
  top: 90px;
  right: ${(props) =>
    (props.type === 'shares' || props.type === 'comments') && 0};
  z-index: 11;
  height: fit-content;
  box-sizing: border-box;
  border-radius: 16px;
  padding: 18px;
  background-color: #03041a;
  border: 1px solid #484848;
  box-shadow: 8px 8px 16px 0px #00000066;
  flex-direction: column;
`;

const Header = styled.div`
  color: #45d483;
  font-weight: 500;
  font-size: 12px;
  line-height: 14.52px;
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 20px;

  span {
    text-transform: uppercase;
  }
`;

const Container = styled.div`
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  border-top: 1px solid #484848;
`;

const ContentContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
`;

const Content = styled.div`
  display: flex;
  margin-left: auto;
  gap: 14px;
`;

const ContentColumn = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 16px;
`;
const ContentTitle = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 14.52px;
  color: #f3e9d7;
  width: 200px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow-y: hidden;
`;

const ContentRow = styled(ContentTitle)`
  width: 50px;
  text-align: right;
`;

const TotalLabel = styled(ContentTitle)`
  font-weight: 700;
  font-size: 14px;
  line-height: 14.52px;
  color: #f3e9d7;
  text-decoration: underline;
`;

const ContentRowTotal = styled(TotalLabel)`
  width: 50px;
  text-align: right;
  text-decoration: none;
`;

const GrandTotalRow = styled(ContentRow)`
    text-decoration: underline;
    font-weight: 700;
    font-size: 14px;
    line-height: 16.94px;
    color: background: #F3E9D7;

`;
