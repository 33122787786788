import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import Accordion from './Accordion';
import Textarea from './Textarea';

import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';
import { useStore } from '@src/stores-v2/StoreContext';

type Props = {
  property: 'brandVoice' | 'avoidWords' | 'missionStatement' | 'keyMetrics';
};

const BrandKitTextareaSection = observer((props: Props) => {
  const videoCreator = useVideoCreatorStore();
  const { datoClientStore } = useStore();

  const { property } = props;

  const branding = videoCreator.organization?.branding;
  const data = branding?.[property] as string | undefined;
  const [value, setValue] = useState(data || '');

  useEffect(() => {
    if (data) setValue(data);
  }, [Boolean(data)]);

  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const handleSave = async () => {
    setIsSaving(true);
    setIsSaved(false);
    const organization = videoCreator.organization;
    organization!.branding = {
      ...organization!.branding!,
      [property]: value.trim(),
    };
    await datoClientStore.albumRepository?.update(organization!);
    setIsSaving(false);
    setIsSaved(true);
  };

  const { id, title, placeholder, tips } = getPropsFromProperty(property);

  return (
    <Accordion id={id} title={title} isSaved={isSaved}>
      <Textarea
        value={value}
        defaultValue={data}
        onChange={(e) => setValue(e.target.value)}
        onSave={handleSave}
        isSaving={isSaving}
        placeholder={placeholder}
        tips={tips}
      />
    </Accordion>
  );
});

const getPropsFromProperty = (property: string) => {
  switch (property) {
    case 'brandVoice':
      return {
        id: 'brand-voice',
        title: 'Brand Voice',
        placeholder: 'Add your brand voice',
        tips: 'Describe your brand’s unique personality and how you communicate with your audience. Your voice is an important way to attract people to your brand, create connections, and build trust with them. For example, “our voice is confident, casual, and friendly.”',
      };
    case 'avoidWords':
      return {
        id: 'avoid-words',
        title: 'Avoid Words',
        placeholder:
          'Provide a list of words to avoid or provide a list of words to avoid + recommended synonyms. An example of the latter,\n\nAvoid tree and replace with arbor\nAvoid person and replace with human',
      };
    case 'missionStatement':
      return {
        id: 'mission-statement',
        title: 'Mission Statement',
        placeholder: 'Add your mission statement',
      };
    case 'keyMetrics':
      return {
        id: 'key-metrics',
        title: 'Key Metrics',
        placeholder: 'List key metrics',
      };
    default:
      return {
        id: '',
        title: '',
        placeholder: '',
        tips: '',
      };
  }
};

export default BrandKitTextareaSection;
