export function logChatGptOutput(output: Record<string, any>) {
  sizeAwareLog('OpenAI ChatGPT output', output);
}

export function sizeAwareLog(
  baseMessage: string,
  input: unknown,
  logFn = console.log,
) {
  // Datadog max message size in bytes for browser SDK
  const MAX_LOG_SIZE = 225280 - 1; // Leave one byte for the newline character

  const inputString = unknownToString(input);
  const fullInput = `${baseMessage} ${inputString}`;

  // If the input size exceeds the limit, chunk it
  if (getByteSize(fullInput) > MAX_LOG_SIZE) {
    let inputStringCurrIndex = 0;
    let chunkCounter = 0;
    while (inputStringCurrIndex < inputString.length) {
      const chunkPrefix = `${baseMessage}_part_${chunkCounter + 1} `;
      const chunkSize = MAX_LOG_SIZE - chunkPrefix.length;
      logFn(
        `${chunkPrefix}${inputString.slice(
          inputStringCurrIndex,
          inputStringCurrIndex + chunkSize,
        )}`,
      );
      inputStringCurrIndex += chunkSize;
      chunkCounter++;
    }
  } else {
    logFn(fullInput);
  }

  function unknownToString(input: unknown): string {
    try {
      // Attempt to serialize input to JSON
      if (input instanceof Error) {
        // Error doesn't have any enumerable properties, ends up as empty object
        return JSON.stringify(input, Object.getOwnPropertyNames(input));
      } else {
        return JSON.stringify(input);
      }
    } catch {
      // If serialization fails (e.g., circular reference), convert to string as fallback
      return String(input);
    }
  }

  // Platform-dependent byte size calculation
  function getByteSize(input: string): number {
    return new Blob([input]).size;
  }
}
