import React, { useCallback, useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import styled from 'styled-components';
import { KaraokeConfig } from '../../videoTranscriptionProcessor/types/karaokeTypes';

type Props = {
  title: string;
  Icon: React.ReactNode;
  action: (propertyMap: Partial<KaraokeConfig>) => void;
  propertyName: keyof KaraokeConfig;
  defaultValue: any;
  unit?: string;
  reverse?: boolean;
  currValue?: number;
  min?: number;
  max?: number;
  snapPoints?: { value: number; tooltip?: string; reverse?: boolean }[];
};

const SliderSelect = (props: Props) => {
  let min = props.min ?? 1;
  let max = props.max ?? (props.propertyName === 'font_size' ? 300 : 100);
  let scale: [number, number] = [0, 100];

  const getDefaultSliderValue = useCallback(() => {
    const value = parseFloat(props.currValue || props.defaultValue);
    if (props.reverse) return scale[1] - value;
    return value;
  }, [props.currValue, props.defaultValue, props.reverse]);

  const [sliderValue, setSliderValue] = useState<number>(
    getDefaultSliderValue(),
  );
  const [hideTooltip, setHideTooltip] = useState(true);

  useEffect(() => {
    setSliderValue(getDefaultSliderValue());
  }, [getDefaultSliderValue]);

  const handleRange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    let value: string | number = e.target.value;
    setSliderValue(Number(value));
    value = Math.max(min, Math.min(max, Number(value)));
    if (props.reverse) value = scale[1] - Number(value);
    if (props.unit) value = `${value}${props.unit}`;
    props.action({ [props.propertyName]: value });
    if (props.snapPoints) {
      setHideTooltip(false);
      setTimeout(() => setHideTooltip(true), 2000);
    }
  };

  const slider = (
    <Slider
      type="range"
      min={min}
      max={max}
      value={Number(sliderValue)}
      onChange={handleRange}
    />
  );

  return (
    <Main>
      <SliderIcon>{props.Icon}</SliderIcon>

      {props.snapPoints ? (
        <SliderSnapPointsContainer>
          {props.snapPoints.map((i) => (
            <SnapPointItem
              current={sliderValue}
              min={min}
              max={max}
              scale={scale}
              value={i.value}
              tooltip={i.tooltip}
              hideTooltip={hideTooltip}
              reverse={i.reverse}
            />
          ))}
          {slider}
        </SliderSnapPointsContainer>
      ) : (
        slider
      )}
    </Main>
  );
};

function calculateLeft(min: number, max: number, x: number): number {
  const midpoint = (min + max) / 2;
  if (x >= min && x <= midpoint) return 1 - (x - min) / (midpoint - min);
  if (x >= midpoint && x <= max) return -(x - midpoint) / (max - midpoint);
  return 0;
}

const SnapPointItem = (props: {
  min: number;
  max: number;
  scale: [number, number];
  current: number;
  value: number;
  tooltip?: string;
  hideTooltip: boolean;
  reverse?: boolean;
}) => {
  const { min, max, scale, current, tooltip, hideTooltip, reverse } = props;
  let value = reverse ? scale[1] - props.value : props.value;
  const position = ((value - min) / (max - min)) * 100;
  const left = calculateLeft(min, max, value);
  const vicinityOffset = (max - min) / 15;
  return (
    <>
      <SnapPoint
        position={position}
        left={left}
        data-tooltip-id={`snap-point-${value}-tooltip`}
        data-tooltip-content={tooltip}
      />
      {tooltip && (
        <Tooltip
          isOpen={
            !hideTooltip &&
            value >= current - vicinityOffset &&
            value <= current + vicinityOffset
          }
          id={`snap-point-${value}-tooltip`}
          place="top"
          noArrow
          style={{
            padding: '4px 8px',
            fontSize: '12px',
            color: '#F3E9D7',
            borderRadius: '4px',
            backgroundColor: '#484848',
          }}
        />
      )}
    </>
  );
};

export default SliderSelect;

const Main = styled.div`
  display: flex;
  border: 1px solid #484848;
  border-radius: 8px;
  padding: 12px;
  gap: 5px;
  align-items: center;
  height: 15px;
`;

const Slider = styled.input`
  -webkit-appearance: none;
  width: 90px;
  flex: 1;
  height: 1px;
  background: #484848;
  border-radius: 2px;
  outline: none;
  position: relative;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #f2d093;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }

  &::-moz-range-thumb {
    width: 16px;
    height: 16px;
    border: none;
    border-radius: 50%;
    background: #f2d093;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
`;

const SliderIcon = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SliderSnapPointsContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
`;

const SnapPoint = styled.div<{ position: number; left: number }>`
  height: 8px;
  width: 1px;
  position: absolute;
  left: ${(props) => props.position}%;
  transform: translateX(${(props) => props.left * 8}px);
  background: #484848;
`;
