import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { toJS } from 'mobx';

import Modal from '../common/Modal';
import { LowerThirdTemplate } from '../textProcessor/TextBrandingService';
import { useVideoCreatorStore } from '@src/stores-v2/VideoCreatorStoreContext';
import { KaraokeConfig } from '@src/videoTranscriptionProcessor/types/karaokeTypes';
import { SidebarOption } from '@src/types.ts/general';

const SaveOrDiscardTemplateStyleModal: React.FC<{}> = observer(() => {
  const videoCreator = useVideoCreatorStore();
  const [isSaving, setIsSaving] = useState(false);
  const { template, config } =
    videoCreator.textBrandingService.modifiedTemplateData!;

  const handleDiscard = () => {
    if (template.type === 'karaoke') {
      videoCreator.reframingModeManager.setKaraokeConfigToRestore(
        config as KaraokeConfig,
      );
      videoCreator.karaokeProducer.rerenderWithNewConfig(config);
    } else {
      videoCreator.textBrandingService.applyLowerThirdTemplate(
        template as LowerThirdTemplate,
        config,
        true,
      );
    }
    videoCreator.textBrandingService.modifiedTemplateData = null;
  };

  const handleKeepChanges = async () => {
    setIsSaving(true);
    const currentConfig = toJS(videoCreator.karaokeProducer.getKaraokeConfig());
    const customTemplate = { ...template, id: 'custom', title: 'Custom' };
    videoCreator.textBrandingService.applyTemplate(
      customTemplate,
      template.type,
      null,
      currentConfig,
    );
    videoCreator.textBrandingService.modifiedTemplateData = null;
    setIsSaving(false);
  };

  const handleUpdateTemplate = async () => {
    // Go back to karaoke sidebar tab
    if (template.type === 'karaoke') {
      videoCreator.sidebarOptions = SidebarOption.karaoke;
      videoCreator.setActiveElements();
    }
    // Update template
    setIsSaving(true);
    await videoCreator.textBrandingService.save(
      template!.title as string,
      template!.type,
      null,
      config,
    );
    videoCreator.textBrandingService.modifiedTemplateData = null;
    setIsSaving(false);
  };

  return (
    <Modal isOpen closeModal={handleDiscard}>
      <Wrapper>
        <Heading>
          Current styles <strong>not</strong> saved
        </Heading>
        <Buttons>
          <PrimaryButton disabled={isSaving} onClick={handleKeepChanges}>
            Use Selected Styles
          </PrimaryButton>
          <PrimaryButton disabled={isSaving} onClick={handleUpdateTemplate}>
            Update Template
          </PrimaryButton>
          <OutlineButton
            className="discard-btn"
            disabled={isSaving}
            onClick={handleDiscard}
          >
            Discard Changes
          </OutlineButton>
        </Buttons>
      </Wrapper>
    </Modal>
  );
});

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 380px;
  padding: 24px;
  border-radius: 16px;
  border: 1px solid #484848;
  box-sizing: border-box;
  background: #03041a;
`;

const Heading = styled.div`
  color: #45d483;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.92px;
  text-transform: uppercase;
  strong {
    font-weight: 700;
  }
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const OutlineButton = styled.button`
  box-sizing: border-box;

  display: flex;
  height: 48px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  cursor: pointer;

  background: #03041a;
  color: #17c964;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;

  border-radius: 8px;
  border: 1px solid #17c964;

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

  &.discard-btn {
    margin-top: 8px;
  }
`;

const PrimaryButton = styled(OutlineButton)`
  background: #17c964;
  color: #03041a;
`;

export default SaveOrDiscardTemplateStyleModal;
