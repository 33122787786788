import { action, makeAutoObservable } from 'mobx';
import { DashboardStore } from './DashboardStore';
import { DashboardStory, Story } from '@src/types.ts/story';
import { v4 as uuid } from 'uuid';

const TEMP_ID_FOR_STORY_BEING_CREATED = 'story-from-modal';

export class UserStoryUploadFlowStore {
  newStoryCompletionPercentRecord: Record<string, number> = {};
  inProgressModalIds: string[] = [];
  bannerHeight: number | undefined = undefined;

  constructor(private dashboardStore: DashboardStore) {
    makeAutoObservable(this, {
      openNewModal: action.bound,
      handleModalClose: action.bound,
      handleNewStoryInitialized: action.bound,
      handleNewStoryCreated: action.bound,
      handleNewStoryProgress: action.bound,
      setBannerHeight: action.bound,
      getNewStoryCompletionPercent: action.bound,
      setNewStoryCompletionPercent: action.bound,
    });
  }

  handleModalClose(modalId: string) {
    // premature closing should hide banner
    this.handleNewStoryProgress(0, TEMP_ID_FOR_STORY_BEING_CREATED, true);
    this.closeModal(modalId);
  }

  handleNewStoryInitialized(story: Story) {
    const newStory = story as unknown as DashboardStory;
    // transfer progress from temp id to created story id, will hide banner
    this.handleNewStoryProgress(
      this.getNewStoryCompletionPercent(),
      newStory.id,
      true,
    );
    this.dashboardStore.addStory(newStory);
  }

  handleNewStoryCreated(story: Story, modalId: string) {
    const newStory = story as unknown as DashboardStory;
    this.handleNewStoryProgress(
      Math.max(50, this.getNewStoryCompletionPercent(newStory.id)),
      newStory.id,
      true,
    );
    this.dashboardStore.updateStory(newStory);
    this.closeModal(modalId);
  }

  handleNewStoryProgress(percent: number, storyId?: string, reset?: boolean) {
    const currentCompletionRecord = this.newStoryCompletionPercentRecord;
    const updatedRecord = {
      ...currentCompletionRecord,
    };
    if (
      storyId &&
      percent >
        this.getNewStoryCompletionPercent(storyId, currentCompletionRecord)
    ) {
      updatedRecord[storyId] = percent;
    }
    if (
      !storyId &&
      percent >
        this.getNewStoryCompletionPercent(storyId, currentCompletionRecord)
    ) {
      updatedRecord[TEMP_ID_FOR_STORY_BEING_CREATED] = percent;
    }
    if (reset) {
      updatedRecord[TEMP_ID_FOR_STORY_BEING_CREATED] = 0;
    }
    this.setNewStoryCompletionPercentRecord(updatedRecord);
  }

  getNewStoryCompletionPercent(
    storyId?: string,
    currentCompletionRecord?: any,
  ): number {
    const completionRecord =
      currentCompletionRecord || this.newStoryCompletionPercentRecord;
    const key = storyId || TEMP_ID_FOR_STORY_BEING_CREATED;
    if (key in completionRecord) {
      return completionRecord[key];
    } else {
      return 0;
    }
  }

  setNewStoryCompletionPercent(storyId: string, percent: number) {
    this.setNewStoryCompletionPercentRecord({
      ...this.newStoryCompletionPercentRecord,
      [storyId]: percent,
    });
  }

  openNewModal() {
    const modalId = uuid();
    this.setInProgressModalIds([...this.inProgressModalIds, modalId]);
  }

  closeModal(modalId: string) {
    this.setInProgressModalIds(
      this.inProgressModalIds.filter((id) => id !== modalId),
    );
  }

  setNewStoryCompletionPercentRecord(record: Record<string, number>) {
    this.newStoryCompletionPercentRecord = record;
  }

  setInProgressModalIds(ids: string[]) {
    this.inProgressModalIds = ids;
  }

  setBannerHeight(height: number | undefined) {
    this.bannerHeight = height;
  }
}
