import React, { createContext, useContext, ReactNode, useEffect } from 'react';
import VideoCreatorStore from '../stores/VideoCreatorStore';
import { useStore } from './StoreContext';
import { useFlagsCombination } from '@src/utility/useFlagsCombination';

const VideoCreatorStoreContext = createContext<VideoCreatorStore | null>(null);

interface VideoCreatorStoreProviderProps {
  children: ReactNode;
}

export const VideoCreatorStoreProvider = ({
  children,
}: VideoCreatorStoreProviderProps) => {
  const rootStore = useStore();
  const flags = useFlagsCombination(rootStore.userIdentityStore.currentRole);

  // Initialize VideoCreatorStore with RootStore
  const videoCreatorStore = React.useMemo(
    () => new VideoCreatorStore(rootStore),
    [rootStore],
  );

  useEffect(() => {
    videoCreatorStore.setFlags(flags);
  }, [rootStore.userIdentityStore.currentRole]);

  return (
    <VideoCreatorStoreContext.Provider value={videoCreatorStore}>
      {children}
    </VideoCreatorStoreContext.Provider>
  );
};

export const useVideoCreatorStore = () => {
  const context = useContext(VideoCreatorStoreContext);
  if (!context) {
    throw new Error(
      'useVideoCreatorStore must be used within a VideoCreatorStoreProvider',
    );
  }
  return context;
};

// Helper type for components that need VideoCreatorStore
export type WithVideoCreatorStore<P = {}> = P & {
  videoCreatorStore?: VideoCreatorStore;
};
