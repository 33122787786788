type Props = {
  width?: string;
  height?: string;
  viewBox?: string;
  strokeColor?: string;
};

const ScaleIcon = ({
  width = '16',
  height = '16',
  viewBox = '0 0 16 16',
  strokeColor = '#F2D093',
}: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
    >
      <path d="M1.61133 6.0816V1.60938H6.08355" stroke={strokeColor} />
      <path d="M9.27734 9.27344L14.3885 14.3845" stroke={strokeColor} />
      <path d="M1.61133 1.60938L6.72244 6.72049" stroke={strokeColor} />
      <path d="M14.3882 9.91406V14.3863H9.91602" stroke={strokeColor} />
      <path d="M1.61133 9.91406V14.3863H6.08355" stroke={strokeColor} />
      <path d="M9.27734 6.72049L14.3885 1.60938" stroke={strokeColor} />
      <path d="M1.61133 14.3845L6.72244 9.27344" stroke={strokeColor} />
      <path d="M14.3882 6.0816V1.60938H9.91602" stroke={strokeColor} />
    </svg>
  );
};

export default ScaleIcon;
