import {
  Client,
  SimpleSchemaTypes,
  SchemaTypes,
} from '@datocms/cma-client-browser';
import { GraphQLClient } from 'graphql-request';
import {
  Video,
  VideoItem,
  VideoFilesQueryResult,
  VideoVersion,
  ExtraElementData,
} from '../types.ts/story';
import { TranscriptChange } from '../types.ts/transcript';
import { randomString } from '../utility/general';
import ApiClient from '../apiClient/ApiClient';
import { AspectRatio } from '../types.ts/video';
import { VIDEO_FILES_QUERY } from '../gql/media-gql';

type VideoVersionDTO = Video & { versionId?: string } & {
  editor?: SimpleSchemaTypes.User | SimpleSchemaTypes.ItemVersion['editor'];
} & { transcriptionText?: string };

type DatoVideoVersion = SimpleSchemaTypes.ItemVersion &
  VideoItem & { versionId: string };

type DatoVideoVersionsResponse = {
  videoVersions: DatoVideoVersion[];
  totalCount: number;
};

const PRIMARY_ASPECT_RATIO = AspectRatio.AR_16_9;
const MAX_RECORD_SIZE = 1_300_000;

const mapItemVideoVersion = (
  item: SchemaTypes.ItemVersion,
): DatoVideoVersion => {
  const { attributes, relationships, ...rest } = item;
  return {
    ...attributes,
    ...rest,
    item: relationships.item.data,
    item_type: relationships.item_type.data,
    editor: relationships.editor.data,
  } as DatoVideoVersion;
};

export class VideoRepository {
  private dClient: Client | ApiClient;
  private gqlClient: GraphQLClient;

  constructor(dClient: Client | ApiClient, gqlClient: GraphQLClient) {
    this.dClient = dClient;
    this.gqlClient = gqlClient;
  }

  async getVersionsByVideoId(
    id: string,
    pageLimit: number,
    page: number = 0,
    sizeAware: boolean = false,
  ): Promise<{ videoVersions: VideoVersion[]; totalCount: number }> {
    const data = sizeAware
      ? await this.loadVersionsInParallel(id, pageLimit, page)
      : await this.loadVersionsAllAtOnce(id, pageLimit, page);
    const videoVersions = await this.mapVideoVersions(id, data.videoVersions);
    return {
      videoVersions,
      totalCount: data.totalCount,
    };
  }

  private async mapVideoVersions(
    id: string,
    videoVersions: DatoVideoVersion[],
  ): Promise<VideoVersion[]> {
    const editors: any = {};
    videoVersions.forEach((videoVersion) => {
      editors[videoVersion.editor.type + videoVersion.editor.id] =
        videoVersion.editor;
    });

    // change to fetch associated videos
    const latestVersionVideoFiles = (
      (await this.gqlClient.request(VIDEO_FILES_QUERY, {
        id,
      })) as VideoFilesQueryResult
    ).video!;

    await Promise.all(
      Object.keys(editors).map(async (key) => {
        try {
          const user = await this.dClient.users.find(editors[key]);
          editors[key] = user;
        } catch (err) {}
      }),
    );

    const videoVersionsMapped: VideoVersion[] = videoVersions.map(
      (videoVersion) => {
        let extraElementData: Video['extraElementData'] =
          JSON.parse(videoVersion.extra_elements_json) || {};

        const punchList = Object.entries(extraElementData)
          .filter(
            ([id, element]) => (element as ExtraElementData).punchListData,
          )
          .map(([id, element]) => ({
            id,
            ...(element as ExtraElementData | null)?.punchListData!,
          }))
          .sort(
            (a, b) =>
              a.transcriptPosition.startIndex - b.transcriptPosition.startIndex,
          );

        const parentVideoQueryResult =
          latestVersionVideoFiles._allReferencingVideos?.at(0);
        const subtitles = JSON.parse(videoVersion.subtitles_json);
        return {
          ...videoVersion,
          // title: videoVersion.title,
          versionId: videoVersion.id,
          id: id,
          subtitles:
            !subtitles || Object.keys(subtitles).length === 0
              ? null
              : subtitles,
          videoSource: JSON.parse(videoVersion.video_json),
          videoFilePrimary: videoVersion.meta.is_current
            ? latestVersionVideoFiles.videoFilePrimary
            : undefined,
          associatedVideos: latestVersionVideoFiles.associatedVideos,
          parentVideo: parentVideoQueryResult
            ? {
                ...parentVideoQueryResult,
                videoSource: parentVideoQueryResult.videoJson,
                videoJson: undefined,
              }
            : undefined,
          aspectRatio: videoVersion.aspect_ratio,
          transcriptionChanges: (videoVersion.transcription_json
            ? JSON.parse(videoVersion.transcription_json).changelog
            : []) as TranscriptChange[],
          transcriptionSnapshot: JSON.parse(
            videoVersion.transcription_snapshot_json,
          ),
          transcriptionText: videoVersion.transcription_text,
          punchList,
          extraElementData,
          videoStatus: videoVersion.video_status,
          editor: editors[videoVersion.editor.type + videoVersion.editor.id],
          thumbnail: latestVersionVideoFiles?.thumbnail,
          sourcePlatform: videoVersion.source_platform,
          clipJson: videoVersion.clip_json
            ? JSON.parse(videoVersion.clip_json)
            : {},
          isClientReady: videoVersion.is_client_ready,
          isDownloadEnabled: videoVersion.is_download_enabled,
          isHidden: videoVersion.is_hidden,
          lastActionJson: videoVersion.last_action_json
            ? JSON.parse(videoVersion.last_action_json)
            : {},
          _publishedAt: videoVersion.meta.created_at,
        };
      },
    );

    return videoVersionsMapped;
  }

  private async loadVersionsInParallel(
    id: string,
    pageLimit: number,
    page: number = 0,
  ): Promise<DatoVideoVersionsResponse> {
    const firstItemToLoad = page * pageLimit;
    const totalItemsToLoad = firstItemToLoad + pageLimit;
    let totalCount = 0;
    const videoVersions = (
      await Promise.all(
        Array(totalItemsToLoad - firstItemToLoad)
          .fill('')
          .map(async (_, i) => {
            try {
              let { data, meta } = await this.dClient.itemVersions.rawList(id, {
                nested: true,
                page: {
                  limit: 1,
                  offset: firstItemToLoad + i,
                },
              });
              const [item] = data;
              if (!i) totalCount = meta.total_count;
              return mapItemVideoVersion(item);
            } catch (err) {
              console.error('Failed to fetch video version', err);
            }
          }),
      )
    ).filter(Boolean) as DatoVideoVersion[];
    return { videoVersions, totalCount };
  }

  private async loadVersionsAllAtOnce(
    id: string,
    pageLimit: number,
    page: number = 0,
  ): Promise<DatoVideoVersionsResponse> {
    const response = await this.dClient.itemVersions.rawList(id, {
      nested: true,
      page: {
        limit: pageLimit,
        offset: page * pageLimit,
      },
    });
    const videoVersions = response.data.map(mapItemVideoVersion);
    return { videoVersions, totalCount: response.meta.total_count };
  }

  async saveOrUpdateVideo(video: Partial<VideoVersionDTO>): Promise<{
    id: string;
    slug: string;
    hash: string;
  }> {
    let videoData: { id: string; slug: string; hash: string } | undefined;
    if (video.id) {
      videoData = await this.updateVideo(
        video as Partial<VideoVersionDTO> & { id: string },
      );
    } else {
      videoData = await this.createVideo(
        video as Partial<VideoVersionDTO> & { id: undefined },
      );
    }
    return videoData;
  }

  async copyParentAndAssociatedVideos(
    videoId: string,
    newTitle?: string,
    sourcePlatform?: Video['sourcePlatform'],
    lastActionJson?: string,
  ): Promise<string> {
    const parentVideoCopy = (await this.dClient.items.duplicate(
      videoId,
    )) as unknown as VideoItem;
    const associatedVideos = [];
    for (const associatedVideoId of parentVideoCopy.associated_videos) {
      const associatedVideoCopy = (await this.dClient.items.duplicate(
        associatedVideoId,
      )) as unknown as VideoItem;
      await this.dClient.items.update(associatedVideoCopy.id, {
        slug: randomString(12),
        hash: randomString(12),
        title: newTitle || associatedVideoCopy.title,
        source_platform: sourcePlatform || associatedVideoCopy.source_platform,
        video_file_primary: null,
        last_action_json:
          lastActionJson || associatedVideoCopy.last_action_json,
      });
      associatedVideos.push(associatedVideoCopy.id);
    }
    await this.dClient.items.update(parentVideoCopy.id, {
      slug: randomString(12),
      hash: randomString(12),
      associated_videos: associatedVideos,
      title: newTitle || parentVideoCopy.title,
      source_platform: sourcePlatform || parentVideoCopy.source_platform,
      video_file_primary: null,
      last_action_json: lastActionJson || parentVideoCopy.last_action_json,
    });
    return parentVideoCopy.id;
  }

  estimateRecordSize(video: Partial<VideoVersionDTO>): number {
    const transcription_snapshot_json = JSON.stringify(
      video.transcriptionSnapshot || {},
    );
    const video_source_json = JSON.stringify(video.videoSource || {});
    const subtitles_json = JSON.stringify(video.subtitles || {});
    const extra_elements_json = JSON.stringify(video.extraElementData || {});
    const title = video.title || '';

    return (
      1.5 *
      (new Blob([video_source_json.replaceAll('"', '\\"')]).size +
        new Blob([subtitles_json.replaceAll('"', '\\"')]).size +
        new Blob([extra_elements_json.replaceAll('"', '\\"')]).size +
        new Blob([title]).size +
        new Blob([transcription_snapshot_json.replaceAll('"', '\\"')]).size)
    );
  }

  async updateVideo(
    video: Partial<VideoVersionDTO> & { id: string },
  ): Promise<{ id: string; slug: string; hash: string }> {
    // todo rework

    const transcription_snapshot_json = JSON.stringify(
      video.transcriptionSnapshot,
    );

    const savedVideo = await this.dClient.items.update(video.id, {
      ...(video.videoSource && {
        video_json: JSON.stringify(video.videoSource),
      }),

      ...(video.subtitles && {
        subtitles_json: JSON.stringify(video.subtitles || {}),
      }),

      ...(video.title && { title: video.title }),

      ...(video.transcriptionChanges && {
        transcription_json: JSON.stringify({
          changelog: video.transcriptionChanges,
        }),
      }),

      ...(video.transcriptionSnapshot &&
        this.estimateRecordSize(video) < MAX_RECORD_SIZE && {
          transcription_snapshot_json,
          transcription_json: JSON.stringify({
            changelog: [],
          }),
        }),

      ...(video.extraElementData && {
        extra_elements_json: JSON.stringify(video.extraElementData),
      }),

      ...(video.thumbnail && {
        thumbnail: {
          upload_id: video.thumbnail.id,
        },
      }),

      ...(video.aspectRatio && {
        aspect_ratio: video.aspectRatio,
      }),

      ...(video.associatedVideos && {
        associated_videos: video.associatedVideos.map((v) => v.id),
      }),

      ...(video.isClientReady != null && {
        is_client_ready: video.isClientReady,
      }),

      ...(video.isDownloadEnabled != null && {
        is_download_enabled: video.isDownloadEnabled,
      }),

      ...(video.isHidden != null && {
        is_hidden: video.isHidden,
      }),

      ...(video.clipJson && {
        clip_json: JSON.stringify(video.clipJson),
      }),

      ...(video.lastActionJson && {
        last_action_json: JSON.stringify(video.lastActionJson),
      }),

      // TODO other fields
    });
    // auto-publish
    return {
      id: savedVideo.id,
      slug: savedVideo.slug as string,
      hash: savedVideo.hash as string,
    };
  }

  async createVideo(
    video: Partial<VideoVersionDTO> & { id: undefined },
  ): Promise<{ id: string; slug: string; hash: string }> {
    const extraElementData = video.extraElementData || {};
    video.punchList?.forEach((punchListItem) => {
      extraElementData[punchListItem.id!] = {
        ...(extraElementData[punchListItem.id!] || {}),
        punchListData: punchListItem,
      };
    });

    const transcription_snapshot_json = JSON.stringify(
      video.transcriptionSnapshot,
    );

    const itemType = await this.dClient.itemTypes.find('video');
    const savedVideo = await this.dClient.items.create({
      item_type: { type: 'item_type', id: itemType.id },
      title: video.title,
      hash: randomString(12),
      slug: randomString(12),
      video_json: JSON.stringify(video.videoSource),
      subtitles_json: JSON.stringify(video.subtitles || {}),
      transcription_json: JSON.stringify({
        changelog: video.transcriptionChanges,
      }),
      ...(video.transcriptionSnapshot &&
        this.estimateRecordSize(video) < MAX_RECORD_SIZE && {
          transcription_snapshot_json,
          transcription_json: JSON.stringify({
            changelog: [],
          }),
        }),
      // transcription_text: video.transcriptionText,
      extra_elements_json: JSON.stringify(extraElementData),
      aspect_ratio: video.aspectRatio || PRIMARY_ASPECT_RATIO,
      associated_videos: video.associatedVideos?.map((v) => v.id),
      source_platform: video.sourcePlatform,
      clip_json: JSON.stringify(video.clipJson || {}),
      is_client_ready: video.isClientReady,
      is_download_enabled: video.isDownloadEnabled,
      is_hidden: video.isHidden,
      last_action_json: JSON.stringify(video.lastActionJson || {}),
    });
    // TODO other fields
    // auto-publish
    return {
      id: savedVideo.id,
      slug: savedVideo.slug as string,
      hash: savedVideo.hash as string,
    };
  }

  async updateThumbnail(
    id: string,
    thumbnailId: string,
    shareableImageId: string | undefined,
  ): Promise<string> {
    const savedVideo = await this.dClient.items.update(id, {
      thumbnail: {
        upload_id: thumbnailId,
      },
      shareable_image_id: shareableImageId || '',
    });
    return savedVideo.id;
  }
}
