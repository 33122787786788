import { useUserStoryUploadFlow } from '@src/stores-v2';
import AddStoriesModal from './Dashboard/user-story/AddStoriesModal';
import { observer } from 'mobx-react-lite';

const UserStoryUploadFlow = observer(() => {
  const {
    inProgressModalIds,
    handleModalClose,
    handleNewStoryInitialized,
    handleNewStoryCreated,
    handleNewStoryProgress,
    setBannerHeight,
  } = useUserStoryUploadFlow();

  return (
    <>
      {inProgressModalIds.map((modalId) => (
        <AddStoriesModal
          key={modalId}
          modalId={modalId}
          closeModal={handleModalClose}
          onStoryInitialized={handleNewStoryInitialized}
          onStoryCreated={handleNewStoryCreated}
          onStoryProgress={handleNewStoryProgress}
          setBannerHeight={setBannerHeight}
        />
      ))}
    </>
  );
});

export default UserStoryUploadFlow;
