import { Renderer } from '../renderer/Renderer';
import VideoCreatorStore, {
  KARAOKE_TRACK_NUMBER,
} from '../stores/VideoCreatorStore';
import { deepClone } from '../utility/deepClone';
import {
  adjustTrackNumbersToStartFromOne,
  applyDeletionToTranscript,
  applyShiftingToTranscript,
  getClosestElementIndexToLeftByFilter,
  getClosestElementIndexToRightByFilter,
  getClosestNotRemovedElementIndexToLeft,
  getClosestNotRemovedTextIndexToLeft,
  getClosestNotRemovedTextIndexToRight,
  getClosestTextIndexToLeft,
  getClosestTextIndexToRight,
} from './utils';

import {
  TranscriptChange,
  TranscriptElement,
  TranscriptTextElement,
} from '../types.ts/transcript';
import { ElementState } from '../renderer/ElementState';
import TranscriptionProcessor from './TranscriptionProcessor';
import TimelineClip from './lib/TimelineClip';

export default class VideoToTranscriptionProcessor {
  private videoCreator: VideoCreatorStore;
  private renderer?: Renderer;
  private transcriptionProcessor: TranscriptionProcessor;

  constructor(
    videoCreator: VideoCreatorStore,
    transcriptionProcessor: TranscriptionProcessor,
  ) {
    this.videoCreator = videoCreator;
    this.transcriptionProcessor = transcriptionProcessor;
  }

  setRenderer(renderer: Renderer) {
    this.renderer = renderer;
  }

  /*
    Operations on video tracks reflected in transcription
  */
  async trimTrackStart(
    elementId: number,
    newStartTime: number,
    trimStart: number,
    newDuration: number,
  ) {
    let returnRanges;
    const state = deepClone(this.renderer!.state);
    if (!state) {
      return;
    }

    const elementToTrim = state.elements.find(
      (el) => el.source.id === elementId,
    );

    if (!elementToTrim) {
      return;
    }

    const currentTrimStart = elementToTrim.source.trim_start || 0;

    if (elementToTrim.source.type === 'video') {
      if (currentTrimStart < trimStart) {
        const newChange = applyDeletionToTranscript(
          elementToTrim.globalTime,
          elementToTrim.globalTime + trimStart - currentTrimStart,
          true,
          this.transcriptionProcessor.getFinalTranscriptionElements(),
        );
        if (newChange) {
          newChange.command = `trimTrackStart(${elementId}, ${newStartTime}, ${trimStart}, ${newDuration})`;
          this.transcriptionProcessor.applyChange(newChange);
        }
      } else {
        returnRanges = this.untrimTextElements(
          trimStart,
          currentTrimStart,
          newStartTime,
          'start',
        );
      }
    }

    // Trim the element
    await this.renderer?.applyModifications({
      [`${elementId}.time`]: newStartTime,
      [`${elementId}.trim_start`]: trimStart,
      [`${elementId}.duration`]: newDuration,
    });
    return returnRanges;
  }

  async moveElements(elementIds: string[], time: number) {
    console.log('move active tracks by', time);
    const state = this.renderer!.state;
    if (!state) {
      return;
    }

    const selectedOriginalVideoElements = state.elements
      .filter(
        (el) =>
          elementIds.includes(el.source.id) &&
          this.videoCreator.isOriginalVideoElement(el.source),
      )
      .sort((el) => el.globalTime);

    if (selectedOriginalVideoElements.length > 0) {
      const fromTs = selectedOriginalVideoElements.at(0)!.globalTime;
      const toTs =
        selectedOriginalVideoElements.at(-1)!.globalTime +
        selectedOriginalVideoElements.at(-1)!.duration;

      const newChange = applyShiftingToTranscript(
        fromTs,
        toTs,
        fromTs + time,
        this.transcriptionProcessor.getFinalTranscriptionElements(),
      ) as TranscriptChange;
      newChange.command = `moveElements(${elementIds}, ${time})`;
      this.transcriptionProcessor.applyChange(newChange);
      this.videoCreator.subtitlesProcessor.moveSubtitles(fromTs, toTs, time);
    }

    const modificationObject: any = {};
    for (const element of state.elements) {
      if (elementIds.includes(element.source.id)) {
        modificationObject[`${element.source.id}.time`] =
          element.globalTime + time;
      }
    }

    await this.renderer?.applyModifications(modificationObject);
  }

  async moveTrack(elementId: number, newStartTime: number) {
    const state = deepClone(this.renderer!.state);
    if (!state) {
      return;
    }

    const elementToMove = state.elements.find(
      (el) => el.source.id === elementId,
    );

    if (!elementToMove) {
      return;
    }

    //// debugger;
    if (elementToMove.source.type === 'video') {
      const newChange = applyShiftingToTranscript(
        elementToMove.globalTime,
        elementToMove.globalTime + elementToMove.duration,
        newStartTime,
        this.transcriptionProcessor.getFinalTranscriptionElements(),
      );
      if (newChange) {
        newChange.command = `moveTrack(${elementId}, ${newStartTime})`;
        this.transcriptionProcessor.applyChange(newChange);
      }
      this.videoCreator.subtitlesProcessor.moveSubtitles(
        elementToMove.globalTime,
        elementToMove.globalTime + elementToMove.duration,
        newStartTime - elementToMove.globalTime,
      );
    }

    await this.renderer?.applyModifications({
      [`${elementId}.time`]: newStartTime,
    });
  }

  async trimTrackDuration(elementId: string, duration: number) {
    let newChange: TranscriptChange;
    let returnRanges;
    // Logic to trim a track from the end
    const state = deepClone(this.renderer!.state);
    if (!state) {
      return;
    }

    const elementToTrim = state.elements.find(
      (el) => el.source.id === elementId,
    );

    if (!elementToTrim || elementToTrim.source.type !== 'video') {
      return;
    }
    const trimStart = elementToTrim.source.trim_start || 0;
    // if (elementToTrim.source.type === 'video') {
    if (elementToTrim.duration > duration) {
      const deletionChange = applyDeletionToTranscript(
        elementToTrim.globalTime + duration,
        elementToTrim.globalTime + elementToTrim.duration,
        true,
        this.transcriptionProcessor.getFinalTranscriptionElements(),
      );
      if (deletionChange) {
        newChange = deletionChange;
        newChange.command = `trimTrackDuration(${elementId}, ${duration})`;
        this.transcriptionProcessor.applyChange(newChange);
      }
    } else {
      returnRanges = this.untrimTextElements(
        trimStart + elementToTrim.duration,
        trimStart + duration,
        elementToTrim.globalTime + elementToTrim.duration,
        'end',
      );
    }
    // }

    // Trim the element
    // elementToTrim.duration = duration;
    await this.renderer!.applyModifications({
      [`${elementId}.duration`]: duration,
    });
    return returnRanges;
    // Set source by the mutated state
    // await this.renderer!.setSource(this.renderer!.getSource(state), true);
  }

  /**
   * Removes a track from the video and transcription
   * @param elementId
   */
  async deleteOriginalVideoTrack(elementId: string) {
    let changes: TranscriptChange[] = [];
    // Logic to remove a track from both video and transcription
    const state = deepClone(this.renderer!.state);
    if (!state) {
      return;
    }

    const elementToDelete = state.elements.find(
      (el) => el.source.id === elementId,
    );
    if (elementToDelete?.source.type === 'video') {
      const deletionChange = applyDeletionToTranscript(
        elementToDelete.globalTime,
        elementToDelete.globalTime + elementToDelete.duration,
        false,
        this.transcriptionProcessor.getFinalTranscriptionElements(),
      );

      // shift tracks to the left after this one
      state.elements.forEach((element) => {
        if (
          element.globalTime >=
          elementToDelete.globalTime + elementToDelete.duration
        ) {
          element.source.time = element.globalTime - elementToDelete.duration;
        }
      });

      if (deletionChange) {
        deletionChange.command = `deleteOriginalVideoTrack(${elementId})`;
        this.transcriptionProcessor.applyChange(deletionChange);
        changes = [deletionChange];
      } else {
        const shiftChange = applyShiftingToTranscript(
          elementToDelete.globalTime + elementToDelete.duration,
          state.duration,
          elementToDelete.globalTime,
          this.transcriptionProcessor.getFinalTranscriptionElements(),
        ) as TranscriptChange;
        if (shiftChange) {
          shiftChange.command = `deleteOriginalVideoTrack(${elementId})`;
          this.transcriptionProcessor.applyChange(shiftChange);
          changes = [shiftChange];
        }
      }
    }

    // Remove the element
    state.elements = state.elements.filter(
      (element) => element.source.id !== elementId,
    );

    // Set source by the mutated state
    const newSource = adjustTrackNumbersToStartFromOne(
      this.renderer!.getSource(state),
    );
    await this.renderer!.setSource(newSource, true);

    // TODO: move to creatorStore where deleteOriginalVideoTrack is called
    this.videoCreator.trackManager.updateFrameLockedTracksAfterRearrange(
      state.elements,
      this.renderer!.state!.elements,
    );
  }

  async untrimTextElements(
    fromTs: number,
    toTs: number,
    newTs: number,
    type: 'start' | 'end',
  ) {
    const rangesToUntrim =
      type === 'start'
        ? this.getRangesToUntrimStart(fromTs, toTs, newTs)
        : this.getRangesToUntrimEnd(fromTs, toTs, newTs);
    const elementsCount =
      rangesToUntrim.lastIndexToUntrim - rangesToUntrim.firstIndexToUntrim;
    this.transcriptionProcessor.applyChange({
      index: -1,
      count: 0,
      type: type === 'start' ? 'untrim_start' : 'untrim_end',
      fromTs,
      toTs,
      newTs,
      datetime: new Date().toISOString(),
      command: `untrimTextElements(${fromTs}, ${toTs}, ${newTs}, ${type})`,
    });

    if ('firstTextElementIndex' in rangesToUntrim) {
      let fromIndex =
        getClosestTextIndexToLeft(
          rangesToUntrim.firstTextElementIndex - 1,
          this.transcriptionProcessor.getFinalTranscriptionElements(),
        ) - elementsCount;
      fromIndex = fromIndex >= 0 ? fromIndex : 0;
      return {
        fromIndex,
        toIndex: rangesToUntrim.firstTextElementIndex,
      };
    } else {
      let toIndex = getClosestTextIndexToRight(
        rangesToUntrim.lastTextElementIndex + 1,
        this.transcriptionProcessor.getFinalTranscriptionElements(),
      );
      toIndex =
        toIndex >= 0
          ? toIndex + elementsCount
          : this.transcriptionProcessor.getFinalTranscriptionElements().length;
      return {
        fromIndex: rangesToUntrim.lastTextElementIndex,
        toIndex,
      };
    }
  }

  async applyKaraokeElementPlacement(
    element: ElementState,
    placement: Pick<ElementState, 'duration' | 'globalTime' | 'trimStart'>,
    isSubtitles: boolean = false,
  ) {
    if (
      element.globalTime === placement.globalTime &&
      element.duration === placement.duration
    )
      return;

    const transcriptElements = isSubtitles
      ? this.videoCreator.subtitleElements!
      : this.transcriptionProcessor.getFinalTranscriptionElements();
    const lastTranscriptElementIndex = getClosestNotRemovedElementIndexToLeft(
      transcriptElements.length - 1,
      transcriptElements,
    );

    // NEW PLANM
    // Find karaoke block via element id matching.
    // x.findIndex( e => e.source.id == element.source.id )
    // Then go to transcript, get all transcript elements from that block (discard everyting before break index)
    // Use those transcript elements to adjust timing.

    // Get which karaoke break we're on.
    // Pre-filter karaoke elements to avoid repeated filter calls
    const karaokeElements =
      this.renderer?.state?.elements.filter(
        (e) => e.track == KARAOKE_TRACK_NUMBER,
      ) || [];

    // Determine the index of the current karaoke block
    const karaokeBlockIndex = karaokeElements.findIndex(
      (el) => el.source.id === element.source.id,
    );

    // Validate if a karaoke block was found
    if (karaokeBlockIndex === -1) {
      console.error('No karaoke block found');
      return;
    }

    // Check if the current block is the last one
    const isLastKaraokeBlock = karaokeBlockIndex === karaokeElements.length - 1;

    // Variables to track the start and end of the current karaoke block
    let karaokeBreakCount = 0;
    let breakStartIndex, breakEndIndex;

    // Special handling for the first karaoke block in the sequence
    if (karaokeBlockIndex === 0) {
      // Find first active element, assume a 'break' right before.
      breakStartIndex =
        getClosestNotRemovedTextIndexToRight(0, transcriptElements) - 1;
      breakEndIndex = 0;

      // Find the end of the block by locating the first karaoke break, or reach the end
      while (
        breakEndIndex < transcriptElements.length &&
        !transcriptElements[breakEndIndex].karaoke_break
      ) {
        breakEndIndex++;
      }

      // Adjust if no trailing karaoke break is found
      if (!transcriptElements[breakEndIndex]?.karaoke_break) {
        breakEndIndex = transcriptElements.length;
      }
    } else {
      // Iterate over transcript elements to find the nth karaoke block
      for (let i = 0; i < transcriptElements.length; i++) {
        if (transcriptElements[i].karaoke_break) {
          karaokeBreakCount++;
          // Check if current element is the start of the desired karaoke block
          if (karaokeBreakCount === karaokeBlockIndex) {
            breakStartIndex = i;
            breakEndIndex = i + 1;

            // Continue to find the next karaoke break to mark end of this block
            while (
              breakEndIndex < transcriptElements.length &&
              !transcriptElements[breakEndIndex].karaoke_break
            ) {
              breakEndIndex++;
            }

            // If it's the last block, adjust the end index to the very end if needed
            if (
              isLastKaraokeBlock &&
              !transcriptElements[breakEndIndex]?.karaoke_break
            ) {
              breakEndIndex = transcriptElements.length;
            }

            break; // Exit loop once the current block is processed
          }
        }
      }
    }

    // Need to check breakStartIndex explicilty since `index` can be 0
    if (breakStartIndex == undefined || !breakEndIndex) {
      console.error('No karaoke break element found');
      return;
    }

    console.log('karaoke block', breakStartIndex, breakEndIndex);

    // Grab transcriptElement timing immediately after/before our start/end karaoke breaks.
    const startTranscriptElementIndex = getClosestElementIndexToRightByFilter(
      // @ts-ignore
      breakStartIndex,
      transcriptElements,
      (el: TranscriptElement) =>
        el.state !== 'removed' &&
        el.state !== 'cut' &&
        !!el.value &&
        el.ts != null,
    );
    const startTranscriptElement =
      transcriptElements[startTranscriptElementIndex];
    const endTranscriptElementIndex = getClosestElementIndexToLeftByFilter(
      breakEndIndex,
      transcriptElements,
      (el: TranscriptElement) =>
        el.state !== 'removed' &&
        el.state !== 'cut' &&
        !!el.value &&
        el.ts != null,
    );
    const endTranscriptElement = transcriptElements[endTranscriptElementIndex];

    let changes: Array<
      TranscriptChange & {
        type:
          | 'change_karaoke_start_break_time'
          | 'change_karaoke_end_break_time';
      }
    > = [];

    const updatedKaraokeBreakStartTsDiff =
      placement.globalTime - startTranscriptElement.ts!;

    const updatedKaraokeBreakEndTsDiff =
      placement.globalTime + placement.duration - endTranscriptElement.end_ts!;

    const timestamp = new Date().toISOString();

    if (isSubtitles) {
      this.videoCreator.subtitlesProcessor.applyPlacementToElement(
        startTranscriptElementIndex,
        {
          karaoke_break_start_ts_diff: updatedKaraokeBreakStartTsDiff,
        },
      );

      this.videoCreator.subtitlesProcessor.applyPlacementToElement(
        endTranscriptElementIndex,
        {
          karaoke_break_end_ts_diff: updatedKaraokeBreakEndTsDiff,
        },
      );
    } else {
      changes.push(
        {
          type: 'change_karaoke_start_break_time',
          index: startTranscriptElementIndex,
          timeShift: updatedKaraokeBreakStartTsDiff,
          count: 1,
          datetime: timestamp,
          version: 2,
        },
        {
          type: 'change_karaoke_end_break_time',
          index: endTranscriptElementIndex,
          timeShift: updatedKaraokeBreakEndTsDiff,
          count: 1,
          datetime: timestamp,
          version: 2,
        },
      );

      for (let change of changes) {
        this.transcriptionProcessor.applyChange(change);
      }
    }

    await this.renderer?.applyModifications({
      [`${element.source.id}.time`]: placement.globalTime,
      [`${element.source.id}.duration`]: placement.duration,
    });
  }

  async applyKaraokeElementPlacementOld(
    element: ElementState,
    placement: Pick<ElementState, 'duration' | 'globalTime' | 'trimStart'>,
    isSubtitles: boolean = false,
  ) {
    if (
      element.globalTime === placement.globalTime &&
      element.duration === placement.duration
    )
      return;

    const transcriptElements = isSubtitles
      ? this.videoCreator.subtitleElements!
      : this.transcriptionProcessor.getFinalTranscriptionElements();
    const lastTranscriptElementIndex = getClosestNotRemovedElementIndexToLeft(
      transcriptElements.length - 1,
      transcriptElements,
    );

    const endTranscriptElement = transcriptElements.findIndex(
      (el, index, arr) => {
        if (!el.karaoke_break && index !== lastTranscriptElementIndex)
          return false;
        const lastTextElementIndex = getClosestNotRemovedTextIndexToLeft(
          index,
          arr,
        );
        if (lastTextElementIndex < 0) return false;
        const lastTextEl = arr[lastTextElementIndex] as TranscriptTextElement;

        if (lastTextEl.karaoke_break_end_ts_diff) {
          return (
            Math.abs(
              lastTextEl.end_ts +
                lastTextEl.karaoke_break_end_ts_diff -
                (element.globalTime + element.duration),
            ) < 0.001
          );
        }
        if (lastTextElementIndex > -1) {
          return (
            Math.abs(
              lastTextEl.end_ts - (element.globalTime + element.duration),
            ) < 0.001
          );
        }

        return false;
      },
    );
    if (endTranscriptElement < 0) {
      console.error('No end karaoke break element found');
      return;
    }

    const startTranscriptElement = getClosestElementIndexToLeftByFilter(
      endTranscriptElement,
      transcriptElements,
      (el: TranscriptElement, index: number) => {
        const prev = getClosestNotRemovedElementIndexToLeft(
          index - 1,
          transcriptElements,
          true,
          true,
        );
        if (prev < 0) return true;
        const prevElement = transcriptElements[prev];
        if (prevElement.karaoke_break) return true;
        return false;
      },
    );
    if (startTranscriptElement < 0) {
      console.error('No start karaoke break element found');
      return;
    }

    const closestTextElementStartIndex = getClosestNotRemovedTextIndexToRight(
      startTranscriptElement,
      transcriptElements,
    );
    const closestTextElementStart = transcriptElements[
      closestTextElementStartIndex
    ] as TranscriptTextElement;
    const closestTextElementEndIndex = getClosestNotRemovedTextIndexToLeft(
      endTranscriptElement,
      transcriptElements,
    );
    const closestTextElementEnd = transcriptElements[
      closestTextElementEndIndex
    ] as TranscriptTextElement;

    let changes: Array<
      TranscriptChange & {
        type:
          | 'change_karaoke_start_break_time'
          | 'change_karaoke_end_break_time';
      }
    > = [];

    if (element.globalTime !== placement.globalTime) {
      if (isSubtitles) {
        this.videoCreator.subtitlesProcessor.applyPlacementToElement(
          closestTextElementStartIndex,
          {
            karaoke_break_start_ts_diff:
              placement.globalTime - closestTextElementStart.ts!,
          },
        );
      }
      changes.push({
        type: 'change_karaoke_start_break_time',
        index: closestTextElementStartIndex,
        timeShift: placement.globalTime - closestTextElementStart.ts!,
        count: 1,
        datetime: new Date().toISOString(),
        version: 2,
      });
    }
    if (
      Math.abs(
        element.globalTime +
          element.duration -
          placement.globalTime -
          placement.duration,
      ) > 0.01
    ) {
      if (isSubtitles) {
        this.videoCreator.subtitlesProcessor.applyPlacementToElement(
          closestTextElementEndIndex,
          {
            karaoke_break_end_ts_diff:
              placement.globalTime +
              placement.duration -
              closestTextElementEnd.end_ts!,
          },
        );
      }
      changes.push({
        type: 'change_karaoke_end_break_time',
        index: closestTextElementEndIndex,
        timeShift:
          placement.globalTime +
          placement.duration -
          closestTextElementEnd.end_ts!,
        count: 1,
        datetime: new Date().toISOString(),
        version: 2,
      });
    }
    if (changes.length === 0) return;
    if (!isSubtitles) {
      for (let change of changes) {
        this.transcriptionProcessor.applyChange(change);
      }
    }
    await this.renderer?.applyModifications({
      [`${element.source.id}.time`]: placement.globalTime,
      [`${element.source.id}.duration`]: placement.duration,
    });
  }

  private getRangesToUntrimStart(fromTs: number, toTs: number, intoTs: number) {
    const originalElements =
      this.transcriptionProcessor.getTranscriptionElements();
    let finalElements =
      this.transcriptionProcessor.getFinalTranscriptionElements();
    const addedDuration = toTs - fromTs;

    let firstTextElementIndex = finalElements.findIndex(
      (el) =>
        el.type === 'text' &&
        el.state !== 'removed' &&
        el.state !== 'cut' &&
        el.end_ts > intoTs + addedDuration,
    );
    // debugger;
    const firstIndexToUntrim = originalElements.findIndex(
      (el) =>
        el.type === 'text' &&
        el.end_ts! > fromTs &&
        el.end_ts! - fromTs > fromTs - el.ts!,
    );

    let lastIndexToUntrim = originalElements.findIndex(
      (el) => el.type === 'text' && el.end_ts! > toTs,
    );
    return { firstIndexToUntrim, lastIndexToUntrim, firstTextElementIndex };
  }

  private getRangesToUntrimEnd(fromTs: number, toTs: number, intoTs: number) {
    const originalElements =
      this.transcriptionProcessor.getTranscriptionElements();
    let finalElements =
      this.transcriptionProcessor.getFinalTranscriptionElements();
    //@ts-ignore
    let lastTextElementIndex = finalElements.findLastIndex(
      //@ts-ignore
      (el) =>
        el.type === 'text' &&
        el.state !== 'removed' &&
        el.state !== 'cut' &&
        el.ts < intoTs,
    );
    // debugger;
    //@ts-ignore
    const lastIndexToUntrim = originalElements.findLastIndex(
      //@ts-ignore
      (el) =>
        el.type === 'text' &&
        el.ts! < toTs &&
        el.end_ts! - toTs < toTs - el.ts!,
    );

    //@ts-ignore
    let firstIndexToUntrim = originalElements.findLastIndex(
      //@ts-ignore
      (el) => el.type === 'text' && el.ts < fromTs,
    );

    return { firstIndexToUntrim, lastIndexToUntrim, lastTextElementIndex };
  }
}
